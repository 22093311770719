import React from 'react';

const Documents = props => (
	<svg width={ 78 } height={ 75 } viewBox="0 0 78 75" { ...props }>
		<g 
			fill="none" 
			fillRule="evenodd"
		>
			<path fill="#D2F0F2" d="M32.161 74.604h45.356V20.593H32.161z"/>
			<path fill="#C3DEE7" d="M37.208 34.148h35.295v-3.089H37.208zM37.208 47.148h35.295v-3.089H37.208zM37.208 57.148h35.295v-3.089H37.208z"/>
			<path fill="#FFF" d="M7.42 66.292h46.8V1.396H7.42z"/>
			<path stroke="#C3DEE7" d="M7.42 66.292h46.8V1.396H7.42z"/>
			<path stroke="#C3DEE7" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.2" d="M42.766 38.652h-28.76v14.77h33.317v-8"/>
			<path stroke="#63BA68" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M36.766 45.297l4.125 4.125 6.875-11"/>
			<path fill="#C3DEE7" d="M13.402 16.567h59.245v-3.09H13.402z"/>
			<path fill="#D2F0F2" d="M13.402 22.694h57.705v-2.09H13.402zM.482 47.373h32.372v-3.081H.482z"/>
		</g>
	</svg>

);

export default Documents;