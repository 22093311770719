import * as React from 'react';

const CheckWhite = props => (
	<svg width={ 11 } height={ 10 } viewBox="0 0 11 10" { ...props }>
		<path
			d="M1 5.248l2.976 2.933L9.756 1"
			stroke="#FFF"
			strokeWidth={ 1.2 }
			fill="none"
			fillRule="evenodd"
		/>
	</svg>
);

export default CheckWhite;
