const defaultState = {
	radioState: -1
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case 'UPDATE_PROVIDE_SECOND_BORROWER':
			return {
				...state,
				radioState: action.payload.radioState
			};
		default:
			return state;
	}
};
export default reducers;
