// Created by: Cristian Restituyo
// Here is where reusable hooks are placed
import { useState, useEffect } from 'react';

// Custom Hook that returns the screensize as state
export const useScreenWidth = () => {
	const [screenWidth, setScreenWidth] = useState(window.innerWidth);

	useEffect(() => {
		const resizeHandler = () => {
			setScreenWidth(window.innerWidth);
		};
		window.addEventListener('resize', resizeHandler);
		return () => {
			window.removeEventListener('resize', resizeHandler);	
		};
	});

	return screenWidth;
};