const defaultState = {
	amount: '',
	period: '',
	radio: 1
};

const reducers = (
	state = defaultState,
	{ type, payload: { amount, period, radio } = {} }
) => {
	switch (type) {
		case 'UPDATE_TEMP_RATE_REDUCTION':
			return {
				...state,
				amount,
				period,
				radio
			};
		default:
			return state;
	}
};

export default reducers;
