import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';

import CheckWhite from 'ui/svg/CheckWhite';

import styles from './CheckboxInput.scss';
import * as formStyles from '../form.scss';
import { findDOMNode } from 'react-dom';

const CheckboxInput = React.forwardRef(( props, ref ) => {

	const id = uuid();

	const getWrapperClassNames = () => {
		let className = styles.checkboxWrapper;
		className += (props.isHidden ? ' ' + formStyles.itemHidden : '');
		className += (props.hasError ? ' ' + formStyles.formCheckboxError : '');
		className += (props.isValid ? ' ' + formStyles.formItemValid : '');
		return className;
	};

	const getInputClassNames = () => {
		return styles.inputElem;
	};

	const handleInputChange = (event) => {
		props.inputOnChange(event.currentTarget.checked);
	};

	const handleKeyDown = (event) => {

		if(!event.shiftKey && (event.keyCode === 'Tab' || event.keyCode === 9)) {
			event.preventDefault();
			if(!props.required || (props.required && props.checked)) {
				goToNextRef();
			}
		}
		else if(event.shiftKey && (event.keyCode === 'Tab' || event.keyCode === 9)) {
			event.preventDefault();
			goToPrevRef();
		}
		else if(event.keyCode === 13) { // on Enter
			event.preventDefault();
			props.inputOnChange(!props.checked);
		}

	};

	const goToNextRef = () => {

		if(ref && props.nextRef) {
			let nextInput = findDOMNode(props.nextRef.current);
			if(nextInput) {
				nextInput.focus();
			}
		}

	};

	const goToPrevRef = () => {

		if(ref && props.prevRef) {
			let prevInput = findDOMNode(props.prevRef.current);
			if(prevInput) {
				prevInput.focus();
			}
		}

	};

	return (
		<div className={ getWrapperClassNames() }
			ref={ ref }
			onKeyDown={ handleKeyDown }
			tabIndex="0"
			data-testid={ props.testId ? props.testId : null }
		>
			<div className={ styles.wrapper }>
				<input
					data-testid={ props.testId ? 'checkbox-' + props.testId : null }
					id={ id }
					type="checkbox"
					className={ getInputClassNames() }
					onChange={ handleInputChange }
					checked={ props.checked }
					required={ props.required ? props.required : false }
				/>
				<label
					htmlFor={ id }
					data-testid={ props.testId ? 'checkbox-label-' + props.testId : null }
				>
					<span className={ styles.checkIconWrapper }>
						<CheckWhite />
					</span>
					{ props.label }
				</label>
				{ props.subLabel ? (
					<label
						htmlFor={ id }
						className={ styles.subLabel }
					>{ props.subLabel }</label>
				) : null }
			</div>
		</div>
	);

});

CheckboxInput.propTypes = {
	label: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.element
	]).isRequired,
	subLabel: PropTypes.string,
	checked: PropTypes.bool.isRequired,
	inputOnChange: PropTypes.func.isRequired,
	isHidden: PropTypes.bool,
	required: PropTypes.bool,
	hasError: PropTypes.bool,
	isValid: PropTypes.bool,
	prevRef: PropTypes.object,
	nextRef: PropTypes.object,
	testId: PropTypes.string
};

export default CheckboxInput;
