/** Actions Type */
const UPDATE_TOKEN = 'UPDATE_TOKEN';
const UPDATE_AUTH = 'UPDATE_AUTH';
const UPDATE_PAGE = 'UPDATE_PAGE';
const UPDATE_RELOAD = 'UPDATE_RELOAD';
const UPDATE_BUTTON_LOADING = 'UPDATE_BUTTON_LOADING';
const UPDATE_APP_LOADING = 'UPDATE_APP_LOADING';
const IS_SHOW_OVERLAY = 'IS_SHOW_OVERLAY';
const UPDATE_STEPS = 'UPDATE_STEPS';
const UPDATE_CURRENT_STEP = 'UPDATE_CURRENT_STEP';
const UPDATE_CURRENT_SCREEN = 'UPDATE_CURRENT_SCREEN';
const UPDATE_PROGRESS = 'UPDATE_PROGRESS';

/** Actions Creators */
export const updateToken = payload => ({
	type: UPDATE_TOKEN,
	payload
});

export const updateAuth = payload => ({
	type: UPDATE_AUTH,
	payload
});

export const updatePage = payload => ({
	type: UPDATE_PAGE,
	payload
});

export const updateSteps = payload => ({
	type: UPDATE_STEPS,
	payload
});

export const updateCurrentStep = payload => ({
	type: UPDATE_CURRENT_STEP,
	payload
});

export const updateCurrentScreen = payload => ({
	type: UPDATE_CURRENT_SCREEN,
	payload
});

export const updateProgress = payload => ({
	type: UPDATE_PROGRESS,
	payload
});

export const updateReload = payload => ({
	type: UPDATE_RELOAD,
	payload
});

export const updateButtonLoading = payload => ({
	type: UPDATE_BUTTON_LOADING,
	payload
});

export const updateAppLoading = payload => ({
	type: UPDATE_APP_LOADING,
	payload
});

export const updateOverlay = payload => ({
	type: IS_SHOW_OVERLAY,
	payload
});
