import _ from 'lodash';

const defaultState = {
	title: 'none',
	salutation: '',
	firstName: '',
	lastName: ''
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case 'UPDATE_NAME':
			return _.merge({}, state, {
				title: action.payload.title,
				salutation: action.payload.salutation,
				firstName: action.payload.firstName,
				lastName: action.payload.lastName
			});
		case 'UPDATE_NAME_FROM_API':
			return _.merge({}, state, {
				title: action.payload.title,
				salutation: action.payload.salutation,
				firstName: action.payload.firstname,
				lastName: action.payload.lastname
			});
		default:
			return state;
	}
};

export default reducers;
