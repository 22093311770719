import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { findDOMNode } from 'react-dom';

import styles from './Button.scss';
import * as utils from '../../../../utils/utils';

const Button = props => {
	const getStyleClass = () => {
		if (props.buttonStyle === 'secondary') {
			return styles.secondary;
		} else if (props.buttonStyle === 'link') {
			return styles.link;
		} else {
			return styles.primary;
		}
	};

	const handleKeyDown = event => {
		if (!event.shiftKey && (event.keyCode === 'Tab' || event.keyCode === 9)) {
			event.preventDefault();
			goToNextRef();
		} else if (
			event.shiftKey &&
			(event.keyCode === 'Tab' || event.keyCode === 9)
		) {
			event.preventDefault();
			goToPrevRef();
		}
	};

	const goToNextRef = () => {
		if (props.buttonRef && props.nextRef) {
			let nextInput = findDOMNode(props.nextRef.current);
			if (nextInput) {
				nextInput.focus();
			}
		}
	};

	const goToPrevRef = () => {
		if (props.buttonRef && props.prevRef) {
			let prevInput = findDOMNode(props.prevRef.current);
			if (prevInput) {
				prevInput.focus();
			}
		}
	};

	return (
		<button
			data-testid={
				props.type === 'submit'
					? 'submit'
					: props.testId
						? 'button-' + props.testId
						: null
			}
			ref={ props.buttonRef ? props.buttonRef : null }
			type={ props.type }
			onKeyDown={ handleKeyDown }
			tabIndex={ props.buttonRef ? 0 : null }
			className={
				styles.button +
				' ' +
				getStyleClass() +
				(props.prependIcon ? ' ' + styles.prependIcon : '') +
				(props.appendIcon ? ' ' + styles.appendIcon : '') +
				(props.additionalClass ? ' ' + props.additionalClass : '')
			}
			disabled={ !!(props.isLoading || props.disabled) }
			onClick={ event => {
				if (props.onButtonClick) {
					event.preventDefault();
					props.onButtonClick();
				} else if (props.path && props.history) {
					if (props.onAdditionalClick) {
						props.onAdditionalClick();
					}
					utils.goToPage(props.history, props.path);
				}
			} }
		>
			{!props.isLoading ? (
				<>
					{props.prependIcon ? (
						<div
							className={ styles.iconWrapper + ' ' + styles.prependIconWrapper }
						>
							{props.prependIcon}
						</div>
					) : null}
					<span className={ styles.buttonText }>{props.text}</span>
					{props.appendIcon ? (
						<div
							className={ styles.iconWrapper + ' ' + styles.appendIconWrapper }
						>
							{props.appendIcon}
						</div>
					) : null}
				</>
			) : (
				<div className={ styles.spinner }>
					<div className={ styles.bounce1 } />
					<div className={ styles.bounce2 } />
					<div className={ styles.bounce3 } />
				</div>
			)}
		</button>
	);
};

Button.propTypes = {
	additionalClass: PropTypes.string,
	onButtonClick: PropTypes.func,
	onAdditionalClick: PropTypes.func,
	path: PropTypes.string,
	history: PropTypes.object,
	type: PropTypes.oneOf(['button', 'submit']).isRequired,
	text: PropTypes.string.isRequired,
	buttonStyle: PropTypes.oneOf(['primary', 'secondary', 'link']),
	disabled: PropTypes.bool,
	isLoading: PropTypes.bool,
	prependIcon: PropTypes.element,
	appendIcon: PropTypes.element,
	buttonRef: PropTypes.object,
	prevRef: PropTypes.object,
	nextRef: PropTypes.object,
	testId: PropTypes.string
};

export default withRouter(Button);
