import * as utils from '../../utils/utils';

const defaultState = {
	radioState: 1,
	selectedOption: '',
	records: '',
	availableOptions: [{
		label: utils.m('solutions.option1.label', 'fields'),
		value: 'option1',
		tooltipInfo: {
			title: utils.m('solutions.option1.tooltipInfo.title'),
			description: utils.m('solutions.option1.tooltipInfo.description')
		}
	},
	{
		label: utils.m('solutions.option2.label', 'fields'),
		value: 'option2',
		tooltipInfo: {
			title: utils.m('solutions.option2.tooltipInfo.title'),
			description: utils.m('solutions.option2.tooltipInfo.description')
		}
	},
	{
		label: utils.m('solutions.option3.label', 'fields'),
		value: 'option3',
		tooltipInfo: {
			title: utils.m('solutions.option3.tooltipInfo.title'),
			description: utils.m('solutions.option3.tooltipInfo.description')
		}
	}],
	originalOptions: [{
		label: utils.m('solutions.option1.label', 'fields'),
		value: 'option1',
		tooltipInfo: {
			title: utils.m('solutions.option1.tooltipInfo.title'),
			description: utils.m('solutions.option1.tooltipInfo.description')
		}
	},
	{
		label: utils.m('solutions.option2.label', 'fields'),
		value: 'option2',
		tooltipInfo: {
			title: utils.m('solutions.option2.tooltipInfo.title'),
			description: utils.m('solutions.option2.tooltipInfo.description')
		}
	},
	{
		label: utils.m('solutions.option3.label', 'fields'),
		value: 'option3',
		tooltipInfo: {
			title: utils.m('solutions.option3.tooltipInfo.title'),
			description: utils.m('solutions.option3.tooltipInfo.description')
		}
	}]

};

const reducers = (state = defaultState, action) => {
	switch(action.type) {
		case 'UPDATE_SOLUTIONS':
			return {
				...state,
				radioState: action.payload.radioState,
				selectedOption: action.payload.selectedOption,
				records: action.payload.records,
				availableOptions: action.payload.availableOptions
			};
		default:
			return state;
	}
};
export default reducers;
