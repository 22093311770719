// A1: Name
// A2: Birthday
// A3: FinancingNumber
// A4: CreditCardNumber
// A5: Address
// A6: Contact

// B1: PaymentStatus
// B2: PaymentStatusPaid
// B3: PaymentStatusWillPay
// B4: FuturePaymentProblem
// B5: Thankyou

// C1: PaymentProblems
// C2: Unemployment
// C3: NewAddress
// C4: ShortEmployment
// C5: (NO SCREEN)
// C6: InabilityOfWork
// C7: InputOtherReason

// D1: EconomicSituationChanged
// D3: NumberOfChildren
// D2: ProvideSecondBorrower
// D4: (NO SCREEN)
// D5: MonthlyIncome
// D6: MonthlyOutgoings

// F1: Solutions
// F2: DefermentOfPayment
// F3: TempRateReduction
// F4: GeneralRateReduction
// F5: ThankyouEnd

export const constants = {
	PRODUCT_TYPE: {
		GIRO: 'BESTGIRO',
		GMK: 'GMK',
		CREDIT_CARD: 'CREDITCARD',
		LIMIT: 'LIMIT',
		MAHNNUNG: 'MAHNUNG',
		PRECOLLECTION: 'PRECOLLECTION',
		EINFACHZALHEN: 'EINFACHZAHLEN'
	},
	FRONTEND: {
		GIRO: '/giro',
		GMK: '/gmk',
		CREDIT_CARD: '',
		CBU: '/refinanzierung',
		LIMIT: '/limit',
		MAHNNUNG: '/mahnung',
		PRECOLLECTION: '/precollection',
		EINFACHZAHLEN: '/einfachzahlen',
		PERSONAL_DATA: '/personendaten'
	},
	ROUTES: {
		NAME: '/',
		LOGIN: '/login',
		BIRTHDAY: '/geburtsdatum',
		CREDIT_CARD_NUMBER: '/kreditkartennummer',
		EMAIL: '/email',
		EMAIL_SENT: '/email-gesendet',
		EMAIL_SUCCESS: '/email-bestaetigt',
		EMAIL_ERROR: '/email-nicht-bestaetigt',
		MOBILE_PHONE: '/mobiltelefon',
		PASSCODE: '/zugangscode',
		NATIONALITY: '/nationalitaet',
		ADDRESS: '/adresse',
		PREVIOUS_ADDRESS: '/vorherige-adresse',
		MARITAL_STATUS: '/familienstand',
		OCCUPATION: '/beschaeftigungsverhaeltnis',
		FATCA: '/fatca',
		PURCHASE: '/kauf-bestaetigen',
		THANKYOU: '/vielen-dank',
		CHILDREN: '/kinder',
		GIRO_ACCOUNT: '/giro-konto',
		FINANCIAL_STATUS: '/finanzieller-status',
		CHECK_DATA: '/daten-pruefen',
		FINANCING_NUMBER: '/finanzierungsnummer',
		CONTACT: '/kontakt',
		PAYMENT_STATUS: '/zahlungsstatus',
		PAYMENT_STATUS_PAID: '/zahlungsstatuszahlt',
		PAYMENT_STATUS_WILL_PAY: '/wirdbezahlen',
		FUTURE_PAYMENT_PROBLEM: '/zukunftzahlungsproblem',
		PAYMENT_PROBLEMS: '/zahlungsproblems',
		UNEMPLOYMENT: '/arbeitslosigkeit',
		NEW_ADDRESS: '/neueadresse',
		SHORT_EMPLOYMENT: '/kurzarbeit',
		INABILITY_TO_WORK: '/arbeitsunfahigkeit',
		INPUT_OTHER_REASON: '/anderegrunde',
		ECONOMIC_SITUATION_CHANGED: '/wirtschaftliche',
		PROVIDE_SECOND_BORROWER: '/zweiten-darlehensnehmer',
		MONTHLY_INCOME: '/monatlichen-einnahmen',
		NUMBER_OF_CHILDREN: '/kinderzahl',
		MONTHLY_OUTGOINGS: '/monatliche-ausgaben',
		GENERAL_RATE_REDUCTION: '/allgemeine-ratenreduzierung',
		TEMP_RATE_REDUCTION: '/befristete-ratenreduzierung',
		SOLUTIONS:'/lösungen',
		DEFERMENT_OF_PAYMENT: '/stundung',
		THANKYOU_END: '/vielen-dank-ende',
		TEST_PAGE: '/testpage'
	},
	SCREENS: {
		A1: '/',
		LOGIN: '/login',
		A2: '/geburtsdatum',
		A4: '/kreditkartennummer',
		EMAIL: '/email',
		EMAIL_SENT: '/email-gesendet',
		EMAIL_SUCCESS: '/email-bestaetigt',
		EMAIL_ERROR: '/email-nicht-bestaetigt',
		MOBILE_PHONE: '/mobiltelefon',
		PASSCODE: '/zugangscode',
		NATIONALITY: '/nationalitaet',
		A5: '/adresse',
		PREVIOUS_ADDRESS: '/vorherige-adresse',
		MARITAL_STATUS: '/familienstand',
		OCCUPATION: '/beschaeftigungsverhaeltnis',
		FATCA: '/fatca',
		PURCHASE: '/kauf-bestaetigen',
		B5: '/vielen-dank',
		CHILDREN: '/kinder',
		GIRO_ACCOUNT: '/giro-konto',
		FINANCIAL_STATUS: '/finanzieller-status',
		CHECK_DATA: '/daten-pruefen',
		A3: '/finanzierungsnummer',
		A6: '/kontakt',
		B1: '/zahlungsstatus',
		B2: '/zahlungsstatuszahlt',
		B3: '/wirdbezahlen',
		B4: '/zukunftzahlungsproblem',
		C1: '/zahlungsproblems',
		C2: '/arbeitslosigkeit',
		C3: '/neueadresse',
		C4: '/kurzarbeit',
		C6: '/arbeitsunfahigkeit',
		C7: '/anderegrunde',
		D1: '/wirtschaftliche',
		D2: '/zweiten-darlehensnehmer',
		D5: '/monatlichen-einnahmen',
		D3: '/kinderzahl',
		D6: '/monatliche-ausgaben',
		F1:'/lösungen',
		F2:'/stundung',
		F3: '/befristete-ratenreduzierung',
		F4: '/allgemeine-ratenreduzierung',
		TEST_PAGE: '/testpage'
	},
	PAYMENT_PROBLEMS_OPTIONS: {
		CHANGE_IN_EMPLOYMENT_RELATIONSHIP: 'EMPLOYMENT',
		ONE_OFF_SPECIAL_EXPENSES: 'SPECIAL_EXPENSES',
		PARENTAL_LEAVE: 'PARENTAL_LEAVE',
		RETIREMENT_AGE: 'RETIREMENT_AGE',
		DIVORCE: 'DIVORCE',
		EXCESSIVE_DEBT: 'EXCESSIVE_DEBT',
		ENERGY_COSTS: 'ENERGY_COSTS',
		OTHER: 'OTHER'
	},
	CHANGE_IN_EMPLOYMENT_RELATIONSHIP_OPTIONS: {
		UNEMPLOYED_WITH_FUTURE_EMPLOYMENT: 'UNEMPLOYED_WITH_FUTURE_EMPLOYMENT',
		UNEMPLOYED_WITHOUT_FUTURE_EMPLOYMENT:
			'UNEMPLOYED_WITHOUT_FUTURE_EMPLOYMENT',
		TEMPORARY_SHORT_TIME_WORK: 'TEMPORARY_SHORT_TIME_WORK',
		INCOME_REDUCTION: 'INCOME_REDUCTION',
		INABILITY_TO_WORK: 'INABILITY_TO_WORK',
		OTHER: 'OTHER'
	},
	ONE_OFF_SPECIAL_EXPENSES_OPTIONS: {
		ACCIDENT_COSTS: 'ACCIDENT_COSTS',
		ILLNESS_DEATH: 'ILLNESS_DEATH',
		SUBSEQUENT_PAYMENT: 'SUBSEQUENT_PAYMENT',
		PARADE: 'PARADE',
		OTHER: 'OTHER'
	},
	RECORDS: {
		NEW_EMPLOYMENT_CONTRACT: 'NEW_EMPLOYMENT_CONTRACT',
		UNEMPLOYMENT_BENEFIT_NOTE: 'UNEMPLOYMENT_BENEFIT_NOTE',
		NOTICE_OF_SICK_PAY: 'NOTICE_OF_SICK_PAY',
		PROOF_OF_SHORT_TIME_WORK: 'PROOF_OF_SHORT_TIME_WORK',
		EVIDENCE_INCOME_REDUCTION: 'EVIDENCE_INCOME_REDUCTION',
		PARENTAL_ALLOWANCE_NOTICE: 'PARENTAL_ALLOWANCE_NOTICE',
		NOTICE_OF_RETIREMENT: 'NOTICE_OF_RETIREMENT'
	},
	SCREEN_SIZES :{
		MAX_MOBILE_WIDTH: 574
	},
	PROGRESS_BAR: {
		personalData: 'Persönliche Daten',
		statement: 'Erklärung',
		budgetAccounting: 'Haushaltsrechnung',
		solution: 'Lösung'
	},
	STEPS: [
		{
			key: 'personalData',
			display:'Persönliche Daten',
			steps: ['A1', 'A2', 'A3', 'A4', 'A5', 'A6']
		},
		{
			key: 'statement',
			display:'Erklärung',
			steps: ['B1', 'B2', 'B3', 'B4', 'B5', 'C1', 'C2', 'C3','C4','C6','C7']
		},
		{
			key: 'budgetAccounting',
			display: 'Haushaltsrechnung',
			steps: [ 'D1', 'D3', 'D2','D4','D5', 'D6']
		},
		{
			key: 'solution',
			display:'Lösung',
			steps: [ 'F1', 'F2', 'F3', 'F4', 'F5']
		}
	]
};

export default constants;
