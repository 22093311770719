import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { findDOMNode } from 'react-dom';

import styles from './DropdownInput.scss';
import * as formStyles from '../form.scss';
import * as utils from '../../../../utils/utils';
import _ from 'lodash';

import DropdownArrow from 'ui/svg/DropdownArrow';
import ButtonLink from 'ui/buttons/ButtonLink/ButtonLink';

const DropdownInput = React.forwardRef(( props, ref ) => {

	const [focus, setFocus] = useState(false);
	const [val, setVal] = useState('');
	const [err, setErr] = useState(false);
	const [cursor, setCursor] = useState(null);

	const scrollBox = useRef(null);

	const itemRefs = useRef([]);

	useEffect(() => {

		if(props.init) {
			const valueItem = _.find(props.items, (item) => {
				return item.value === props.init;
			});
			if(valueItem) {
				setVal(valueItem.value);
			}

			setCursorByIndex(props.init);
		}

	}, [props.init]);

	// Reset current value whenever array of recevied items change in number.
	useEffect(() => {
		if(utils.isEmpty(props.init)){
			setVal('');
		} 
	}, [props.items.length]);

	const getWrapperClassNames = () => {
		let className = formStyles.formItem;
		className += (props.isHidden ? ' ' + formStyles.itemHidden : '' );
		className += (props.disabled ? ' ' + formStyles.itemDisabled : '' );
		className += (props.hasError || err ? ' ' + formStyles.formItemError : '' );
		className += (props.isValid ? ' ' + formStyles.formItemValid : '' );
		className += (focus ? ' ' + formStyles.formItemActive : '');
		return className;
	};

	const getMobileWrapperClassNames = () => {
		let className = formStyles.formItem + ' ' + styles.mobileDropdownWrapper;
		className += (props.isHidden ? ' ' + formStyles.itemHidden : '' );
		className += (props.disabled ? ' ' + formStyles.itemDisabled : '' );
		className += (props.hasError ? ' ' + formStyles.formItemError : '' );
		className += (props.isValid ? ' ' + formStyles.formItemValid : '' );
		className += (focus ? ' ' + formStyles.formItemActive : '');
		return className;
	};

	const getTexByValue = (value) => {
		const item = _.find(props.items, (item) => {
			return item.value === value;
		});
		if(item) {
			return item.text;
		}
		else {
			return value;
		}
	};

	const setCursorByIndex = (init) => {
		const valueIndex = _.findIndex(props.items, (item) => {
			return item.value === init;
		});
		if(valueIndex > -1) {
			setCursor(valueIndex);
		}
	};

	const onClick = () => {
		if(!focus) {
			openDropdown();
		}
	};


	const onClickItem = (item) => {
		setVal(item.value);
		props.inputOnChange(item.value);
		setCursorByIndex(item.value);
		unfocusDropdown();
		closeDropdown();
	};

	const selectChangeHandle = (e) => {
		let index = e.nativeEvent.target.selectedIndex,
			selectItemValue = e.nativeEvent.target[index].value;

		setVal(selectItemValue);
		props.inputOnChange(selectItemValue);
		setCursorByIndex(selectItemValue);
		unfocusDropdown();
		closeDropdown();
	};

	const openDropdown = () => {
		setFocus(true);
		if(cursor) {
			scrollToItem(cursor);
		}
	};

	const unfocusDropdown = () => {
		setFocus(false);
		ref.current.blur();
	};

	const closeDropdown = (tabBack) => {
		if(!tabBack && ref && props.nextRef) {
			let nextInput = findDOMNode(props.nextRef.current);
			if(nextInput) {
				nextInput.focus();
			}
		}
		else if(tabBack && ref && props.prevRef) {
			let prevInput = findDOMNode(props.prevRef.current);
			if(prevInput) {
				prevInput.focus();
			}
		}
	};

	const handleKeyDown = (event) => {

		let newCursor = cursor;

		if (event.keyCode === 38 && cursor > 0) {

			// Up
			event.preventDefault();

			if(!newCursor && newCursor !== 0) {
				newCursor = 0;
			}
			else {
				newCursor--;
			}

			setCursor(newCursor);

			setVal(props.items[newCursor].value);
			props.inputOnChange(props.items[newCursor].value);
			scrollToItem(newCursor);

		}
		else if (event.keyCode === 40 && cursor < props.items.length - 1) {

			// Down
			event.preventDefault();

			if(!newCursor && newCursor !== 0) {
				newCursor = 0;
			}
			else {
				newCursor++;
			}

			setCursor(newCursor);

			setVal(props.items[newCursor].value);
			props.inputOnChange(props.items[newCursor].value);
			scrollToItem(newCursor);

		}
		else if (event.keyCode === 13 || (!event.shiftKey && (event.keyCode === 'Tab' || event.keyCode === 9))) {
			event.preventDefault();
			if(val && val.length > 0) {
				closeDropdown();
				setErr(false);
			}
			else {
				setErr(true);
			}
		}
		else if (event.shiftKey && (event.keyCode === 'Tab' || event.keyCode === 9)) {
			event.preventDefault();
			closeDropdown(true);
		}
		else if ((event.keyCode >= 48 && event.keyCode <= 90) || event.keyCode === 222 || event.keyCode === 186 || event.keyCode === 219) {

			let char = '';

			if(event.keyCode === 222) {
				char = 'ä';
			}
			else if(event.keyCode === 186) {
				char = 'ö';
			}
			else if(event.keyCode === 219) {
				char = 'ü';
			}
			else {
				char = String.fromCharCode(event.keyCode).toLowerCase();
			}

			if(char && char.length === 1) {
				let index = _.findIndex(props.items, (item) => {
					return item.text.toLocaleLowerCase('de-DE').charAt(0) === char;
				});

				if(index > -1) {
					setCursor(index);
					setVal(props.items[index].value);
					props.inputOnChange(props.items[index].value);
					scrollToItem(index);
				}

			}

		}

	};

	const scrollToItem = (index) => {
		
		if(scrollBox.current) {

			// TODO: FIND A BETTER WAY TO SOLVE RERENDERING ISSUE
			// setTimeout(() => {

			// 	const scrollBoxNode = findDOMNode(scrollBox.current);
	
			// 	if(index) {
	
			// 		const itemNode = findDOMNode(itemRefs.current[index].current);
	
			// 		if(itemNode) {
			// 			scrollBoxNode.scrollTo({ top: itemNode.offsetTop, behavior: 'smooth' });
			// 		}
			// 		else {
			// 			scrollBoxNode.scrollTo({ top: 0, behavior: 'smooth' });
			// 		}
	
			// 	}
			// 	else {
			// 		scrollBoxNode.scrollTo({ top: 0, behavior: 'smooth' });
			// 	}
				
			// }, 1);
		}

	};

	return (
		<div
			className={ styles.dropownWrapper + ' ' + (props.noPlaceholder ? styles.noPlaceholder : '') }
			onBlur={ unfocusDropdown }
			onKeyDown={ handleKeyDown }
			ref={ ref }
			tabIndex="0"
			onFocus={ openDropdown }
		>
			<div
				data-testid={ props.testId ? 'dropdown-' + props.testId : null }
				className={ styles.valueWrapper }
				onClick={ onClick }
			>
				<div className={ getWrapperClassNames() }>
					<div>
						<label className={ formStyles.formLabel }>{ props.label }</label>
						<div className={ styles.inputElem }>
							<div className={ styles.dropownInputWrapper }>{ val ? getTexByValue(val) : (<span className={ styles.dropdownInputDefaultValue }>Bitte wählen</span>) }</div>
							<div className={ styles.dropownArrowWrapper }>
								<DropdownArrow />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className={ styles.listWrapper + (focus ? ' ' + styles.active : '') }>
				<div
					className={ styles.listWrapperInner + (err ? ' ' + styles.listWrapperInnerError : '') }
					ref={ scrollBox }
				>
					<ul>
						{ props.items.map((item, index) => (
							<li
								ref={ ref => itemRefs.current[index] = ref }
								id={ 'scroll-id-' + index }
								key={ item.value + '-' + index }
								data-testid={ 'dropdown-item-' + item.value }
								onMouseDown={ (e) => { onClickItem(item); e.preventDefault(); } }
								className={ val && val === item.value ? styles.listItemActive : null }
							><span>{ item.text }</span><ButtonLink /></li>
						)) }
					</ul>
				</div>
			</div>
			<div className={ getMobileWrapperClassNames() } >
				<label className={ formStyles.formLabel }>{ props.label }</label>
				<select
					data-testid={ props.testId ? 'dropdown-mobile-' + props.testId : null }
					className={ styles.selectCss }
					onChange={ (e) => { selectChangeHandle(e); e.preventDefault(); } }
					defaultValue=""
				>
					<option disabled hidden value="">Bitte wählen</option>
					{ props.items.map((item, index) => (
						<option
							data-testid={ 'dropdown-mobile-item' + item.value }
							key={ item.value + '-' + index }
							value={ item.value }
							selected={ val === item.value ? true : null }
						>{ item.text }</option>
					)) }
				</select>
				<div className={ styles.dropownArrowWrapper }>
					<DropdownArrow />
				</div>
			</div>
		</div>
	);

});

DropdownInput.propTypes = {
	label: PropTypes.string.isRequired,
	init: PropTypes.string,
	inputOnChange: PropTypes.func.isRequired,
	items: PropTypes.arrayOf(PropTypes.shape({
		text: PropTypes.string.isRequired,
		value: PropTypes.string.isRequired
	})).isRequired,
	isHidden: PropTypes.bool,
	disabled: PropTypes.bool,
	required: PropTypes.bool,
	hasError: PropTypes.bool,
	isValid: PropTypes.bool,
	prevRef: PropTypes.object,
	nextRef: PropTypes.object,
	testId: PropTypes.string,
	noPlaceholder: PropTypes.bool
};

export default DropdownInput;
