/**
 * Icon with Tooltip
 *
 * Created by Moritz Kippenberg
 * Last modified by Moritz Kippenberg
 * Last modified date : 15-10-2019
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Tooltip from 'react-tooltip-lite';
import uuid from 'uuid/v4';

import ButtonQuestionmarkIcon from 'ui/buttons/ButtonQuestionmarkIcon/ButtonQuestionmarkIcon';
import ButtonInfoIcon from 'ui/buttons/ButtonInfoIcon/ButtonInfoIcon';
import styles from './TooltipIcon.scss';

const TooltipIcon = (props) => {

	const id = uuid();
	const [tempOpen, setTempOpen] = useState(false);
	const [forceClose] = useState(false);

	useEffect(() => {

		if(hasOpenForSeconds()) {
			setTimeout(() => {
				setTempOpen(true);
				setTimeout(() => {
					setTempOpen(false);
				}, (props.openForSeconds * 1000));
			}, (1000));
		}
		else {
			setTempOpen(false);
		}

	}, [props.openForSeconds]);

	// useEffect(() => {
	// 	if(!props.reducers.app.sidebarActive) {
	// 		setForceClose(true);
	// 		setTimeout(() => {
	// 			setForceClose(false);
	// 		}, 1000);
	// 	}
	// }, [props.reducers.app.sidebarActive]);

	const hasOpenForSeconds = () => {
		return props.openForSeconds && !isNaN(props.openForSeconds);
	};

	const renderButton = () => {
		if(!props.isInfoIcon){
			return (
				<ButtonQuestionmarkIcon
					color={ props.tooltipIconColor }
				/>
			);
		}
		return (
			<ButtonInfoIcon color = { props.tooltipIconColor } />
		);
	};

	return(
		<Tooltip
			id={ id }
			content={ (<div className={ styles.tooltipContentWrapper }>{ props.content }</div>) }
			className={ 'tooltipTarget tooltipTarget-' + id }
			tipContentClassName="tooltipWrapper"
			eventToggle={ props.isClickable || hasOpenForSeconds() ? 'onClick' : null }
			useHover={ !props.isClickable && !hasOpenForSeconds() }
			isOpen={ !forceClose ? (hasOpenForSeconds() ? tempOpen : null) : false }
			background = { props.background }
		> 
			<div
				className={ styles.tooltipIconWrapper }
			>

				{renderButton()}
				{/* <ButtonQuestionmarkIcon
					color={ props.tooltipIconColor }
				/>
				<ButtonInfoIcon color = { props.tooltipIconColor } /> */}
			</div>
		</Tooltip>
	);
};

TooltipIcon.propTypes = {
	reducers: PropTypes.object,
	tooltipIconColor: PropTypes.string,
	content: PropTypes.element,
	isClickable: PropTypes.bool,
	openForSeconds: PropTypes.number,
	background: PropTypes.string,
	isInfoIcon: PropTypes.bool
};

const mapStateToProps = (state) => {
	return{
		reducers: state
	};
};

export default connect(mapStateToProps, null)(TooltipIcon);
