/**
 * ShortEmployment Page
 *
 * Created by: Cristian Restituyo
 */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Page from 'mod/Page/Page';
import { Row, Col } from 'mod/FlexGrid/index';
import _ from 'lodash';
import C from '../../constants';
import moment from 'moment';

import * as AppActions from '../../store/App/actions';
import * as EntryActions from '../../store/ShortEmployment/actions';
import * as utils from '../../utils/utils';

import DefaultInput from 'ui/inputs/DefaultInput/DefaultInput';
import * as googleEvents from '../../utils/googleEvents';
import * as regex from '../../utils/regex';

const ShortEmployment = ({ reducers, actions, history, location }) => {
	const [state, setState] = useState({
		path: C.ROUTES.SHORT_EMPLOYMENT,
		currentFlow: utils.getCurrentFlow(location),
		month: '',
		isMonthErr: false,
		year: '',
		isYearErr: false,
		month2: '',
		isMonth2Err: false,
		year2: '',
		isYear2Err: false,
		errorMsg: '',
		isShowError: false
	});

	useEffect(() => {
		// Set the page Index for navigation
		actions.updatePage(utils.getPageIndex(state.path, state.currentFlow));

		// Set the default value on page load
		setState({
			...state,
			month: reducers.shortEmployment.month,
			year: reducers.shortEmployment.year,
			month2: reducers.shortEmployment.month2,
			year2: reducers.shortEmployment.year2
		});

		// Block access from user type in url directly
		utils.checkIsFromNav(
			history,
			reducers.app.isReload,
			state.currentFlow + C.ROUTES.NAME
		);

		// Set the first element on focus
		if (!reducers.app.appLoading) {
			monthRef.current.focus();
		}
	}, []);

	const onFormSubmit = () => {
		/*** Validation ***/
		let isValid = true;
		let cloneState = _.cloneDeep(state);

		cloneState.errorMsg = '';

		if (
			parseInt(state.month) < 1 ||
			state.month.length > 2 ||
			parseInt(state.month) > 12
		) {
			isValid = false;
			utils.showError(
				cloneState,
				'isMonthErr',
				utils.m('month.validation.invalid', 'fields')
			);
		} else {
			cloneState.isMonthErr = false;
		}

		if (
			parseInt(state.month2) < 1 ||
			state.month2.length > 2 ||
			parseInt(state.month2) > 12
		) {
			isValid = false;
			utils.showError(
				cloneState,
				'isMonth2Err',
				utils.m('month.validation.invalid', 'fields')
			);
		} else {
			cloneState.isMonth2Err = false;
		}

		let currentYear = moment().year();

		if (
			!utils.isNumber(state.year) ||
			state.year.length !== 4 ||
			parseInt(state.year) < currentYear - 80
		) {
			isValid = false;
			utils.showError(
				cloneState,
				'isYearErr',
				utils.m('year.validation.invalid', 'fields')
			);
		} else {
			cloneState.isYearErr = false;
		}

		if (
			!utils.isNumber(state.year2) ||
			state.year2.length !== 4 ||
			parseInt(state.year2) < currentYear - 80
		) {
			isValid = false;
			utils.showError(
				cloneState,
				'isYear2Err',
				utils.m('year.validation.invalid', 'fields')
			);
		} else {
			cloneState.isYear2Err = false;
		}

		if (
			cloneState.isMonthErr ||
			cloneState.isYearErr ||
			cloneState.isMonth2Err ||
			cloneState.isYear2Err
		) {
			cloneState.errorMsg =
				utils.m('date.validation.invalid', 'fields') + cloneState.errorMsg;
		}

		if (isValid) {
			utils.hideError(state, setState);

			googleEvents.continueValid();

			actions.onUpdateShortEmployment(state);
			actions.updateReload(false);

			utils.goToPage(
				history,
				state.currentFlow + C.SCREENS.D3
			
			);
		} else {
			// on validation failure
			googleEvents.continueInvalid();
			setState(cloneState);
		}
	};

	const hasEmptyFields = () => {
		return utils.isEmpty(state.month) 
		|| utils.isEmpty(state.year)
		|| utils.isEmpty(state.month2)
		|| utils.isEmpty(state.year2);
	};

	const monthRef = useRef(null);
	const yearRef = useRef(null);
	const month2Ref = useRef(null);
	const year2Ref = useRef(null);
	const submitRef = useRef(null);

	return (
		<Page
			title={ utils.m('shortEmployment.title', state.currentFlow) }
			subtitle={ utils.m('shortEmployment.subtitle', state.currentFlow) }
			isShowError={ state.isShowError }
			errorMsg={ state.errorMsg }
			hasNextButton={ true }
			onBackButtonClick={ history.goBack }
			onFormSubmit={ onFormSubmit }
			nextButtonDisabled={ hasEmptyFields() }
			location={ location }
			currentFlow={ state.currentFlow }
			pageName='ShortEmployment'
			currentStep={ C.PROGRESS_BAR.statement }
			screen='C4'
			showButtonDivider={ false }
			submitRef={ submitRef }
			prevRef={ year2Ref }
			nextRef={ monthRef }
			gtmStep='userdata_geburtsdatum'
			gtmStatus='registration_pending'
			gtmSubStatus={ null }
		>
			<Row justifyContent='center'>
				<Col xs={ 12 } sm={ 6 }>
					<DefaultInput
						inputOnChange={ val => {
							utils.onInputChange(setState, state, 'month', val);
						} }
						label={ utils.m('month.label', 'fields') }
						hasError={ state.isMonthErr }
						init={ state.month }
						maxlength={ 2 }
						placeholder='MM'
						type='number'
						ref={ monthRef }
						prevRef={ monthRef }
						nextRef={ yearRef }
						regex={ regex.digit() }
						jumpToNextOnMaxLength={ true }
						testId='month'
					/>
				</Col>

				<Col xs={ 12 } sm={ 6 }>
					<DefaultInput
						inputOnChange={ val => {
							utils.onInputChange(setState, state, 'year', val);
						} }
						label={ utils.m('year.label', 'fields') }
						hasError={ state.isYearErr }
						init={ state.year }
						maxlength={ 4 }
						placeholder='JJJJ'
						type='number'
						ref={ yearRef }
						prevRef={ monthRef }
						nextRef={ month2Ref }
						regex={ regex.digit() }
						jumpToNextOnMaxLength={ true }
						testId='year'
					/>
				</Col>

				<Col xs={ 12 }>
					<span className='h2'>{utils.m('howlongToLast.label', 'fields')}</span>
				</Col>

				<Col xs={ 12 } sm={ 6 }>
					<DefaultInput
						inputOnChange={ val => {
							utils.onInputChange(setState, state, 'month2', val);
						} }
						label={ utils.m('month.label', 'fields') }
						hasError={ state.isMonthErr }
						init={ state.month2 }
						maxlength={ 2 }
						placeholder='MM'
						type='number'
						ref={ month2Ref }
						prevRef={ yearRef }
						nextRef={ year2Ref }
						regex={ regex.digit() }
						jumpToNextOnMaxLength={ true }
						testId='month2'
					/>
				</Col>

				<Col xs={ 12 } sm={ 6 }>
					<DefaultInput
						inputOnChange={ val => {
							utils.onInputChange(setState, state, 'year2', val);
						} }
						label={ utils.m('year.label', 'fields') }
						hasError={ state.isYearErr }
						init={ state.year2 }
						maxlength={ 4 }
						placeholder='JJJJ'
						type='number'
						ref={ year2Ref }
						prevRef={ month2Ref }
						nextRef={ submitRef }
						regex={ regex.digit() }
						testId='year2'
					/>
				</Col>
			</Row>
		</Page>
	);
};

ShortEmployment.propTypes = {
	reducers: PropTypes.object,
	actions: PropTypes.object,
	history: PropTypes.object
};

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(_.merge({}, AppActions, EntryActions), dispatch)
});

const mapStateToProps = state => {
	return {
		reducers: state
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(ShortEmployment);
