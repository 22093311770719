import * as React from 'react';

const IconQuestionMark = props => (
	<svg width={ 24 } height={ 24 } viewBox="0 0 24 24" { ...props }>
		<defs>
			<path id="icon-questionmark_svg__a" d="M10 18.8c-4.853 0-8.8-3.948-8.8-8.8S5.147 1.2 10 1.2c4.853 0 8.8 3.948 8.8 8.8s-3.947 8.8-8.8 8.8M10 0C4.478 0 0 4.477 0 10s4.478 10 10 10 10-4.477 10-10S15.522 0 10 0m0 13.257a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5m.597-7.421c-1.459-.156-2.527.75-2.571.788a.5.5 0 0 0 .655.757c.007-.006.801-.666 1.806-.551.539.058 1.01.45 1.148.954.065.24.099.697-.422 1.087l-.218.178c-.901.738-1.614 1.32-1.527 2.74a.5.5 0 0 0 .499.469l.03-.001a.5.5 0 0 0 .469-.53c-.056-.907.326-1.22 1.163-1.904l.201-.166c.703-.524.991-1.322.77-2.136-.246-.904-1.052-1.581-2.003-1.685"/>
		</defs>
		<use 
			fill = { props.color }
			fillRule="evenodd" 
			opacity=".8" 
			transform="translate(2 2)" 
			xlinkHref="#icon-questionmark_svg__a"
		/>
	</svg>
);

export default IconQuestionMark;
