const defaultState = { };

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case 'UPDATE_QUERY_PARAMS':
			return {
				...state,
				...state.queryParams, 
				...action.payload
			};
		default:
			return state;
	}
};

export default reducers;
