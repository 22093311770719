/**
 * Email sent page
 * 
 * Created by Perry Tang
 * Last modified by Perry Tang
 * Last modified date : 23-06-2019
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Col } from 'mod/FlexGrid/index';
import Page from 'mod/Page/Page';
import _ from 'lodash';
import C from '../../constants';

import ServiceHotline from 'mod/Hints/ServiceHotline/ServiceHotline';

import * as AppActions from '../../store/App/actions';
import * as utils from '../../utils/utils';

// import styles from './Thankyou.scss';

const Thankyou = ({ reducers, actions, history, location }) => {

	const [state] = useState({
		path: C.ROUTES.THANKYOU,
		currentFlow: utils.getCurrentFlow(location)
	});

	useEffect(() => {
		actions.updateAppLoading(false);
		// Set the page Index for navigation
		actions.updatePage(utils.getPageIndex(state.path, state.currentFlow));

		// Block access from user type in url directly
		utils.checkIsFromNav(history, reducers.app.isReload, state.currentFlow + C.ROUTES.NAME);
	}, []);

	return(
		<Page
			title={ utils.m('thankYou.title', state.currentFlow) }
			hasNextButton={ false }
			location={ location }
			currentFlow={ state.currentFlow }
			pageName="Thankyou"
			currentStep={ C.PROGRESS_BAR.statement }
			screen='B5'
			hideProgressBar={ false }
			gtmStep="thank_you"
			gtmStatus="registration_failed"
			prevButtonPath='hidden'
		>
			<Row justifyContent="center">

				<Col xs={ 12 }>
					<ServiceHotline
						location={ location }
					/>
				</Col>

			</Row>
		</Page>
	);
};

Thankyou.propTypes = {
	actions: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators( _.merge({}, AppActions), dispatch)
});

const mapStateToProps = (state) => {
	return{
		reducers: state
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Thankyou);
