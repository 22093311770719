import React, { useState } from 'react';
import PropTypes from 'prop-types';

import * as formStyles from '../form.scss';
import styles from './RadioGroupMulti.scss';

import CheckWhite from 'ui/svg/CheckWhite';
import { findDOMNode } from 'react-dom';
import TooltipIcon from 'ui/TooltipIcon/TooltipIcon';
import {useScreenWidth} from '../../../../hooks';
import C from '../../../../constants';

const RadioGroupMulti = React.forwardRef(( props, ref ) => {
	const [focus, setFocus] = useState(false);
	const [isInputFocus] = useState(false);
	const screenWidth = useScreenWidth();

	const getWrapperClassNames = () => {

		let className = styles.formItem + ' ' + styles.radioGroupWrapper;
		className += (props.isHidden ? ' ' + formStyles.itemHidden : '' );
		className += (props.disabled ? ' ' + formStyles.itemDisabled : '' );
		className += (props.hasError ? ' ' + formStyles.formItemError + ' ' + styles.formItemError : '' );
		className += (props.isValid ? ' ' + formStyles.formItemValid : '' );
		className += ( (focus || isInputFocus) ? ' ' + styles.formItemActive : '');
		return className;

	};

	const onFocus = (event) => {
		event.preventDefault();
		setFocus(true);
	};

	const onBlur = (event) => {
		event.preventDefault();
		setFocus(false);
	};


	// Called upon the radio button is clicked
	const onBtnClick = (value) => {
		props.radioOnChange(value);
	};

	const handleKeyDown = (event) => {


		// Tab Behaviour
		if (!event.shiftKey && (event.keyCode === 'Tab' || event.keyCode === 9)) {
			event.preventDefault();
			if(props.radioState >= 0 && props.radioState < props.options.length - 1) {
				props.radioOnChange(props.options[props.radioState+1].value);
			} else {
				if(props.nextRef) {
					const nextInput = findDOMNode(props.nextRef.current);
					nextInput.focus();
				} else {
					props.radioOnChange(props.options[0].value);
				}
			}

		}
		else if (event.shiftKey && (event.keyCode === 'Tab' || event.keyCode === 9)) {
			event.preventDefault();

			if(props.radioState === 0) {
				if(props.prevRef) {
					const prevInput = findDOMNode(props.prevRef.current);
					prevInput.focus();
				} else {
					props.radioOnChange(props.options[props.options.length - 1].value);
				}
			} else if(props.radioState <= props.options.length - 1 && props.radioState > 0 ) {
				props.radioOnChange(props.options[props.radioState - 1].value);
			}

		}

		// Arrow Left Behaviour
		if ( event.keyCode === 37 ) {
			if(props.radioState <= props.options.length - 1 && props.radioState > 0 ) {
				props.radioOnChange(props.options[props.radioState - 1].value);
			}
		}

		// Arrow Right Behaviour
		if ( event.keyCode === 39 ) {
			if(props.radioState >= 0 && props.radioState <= props.options.length - 2) {
				props.radioOnChange(props.options[props.radioState + 1].value);
			}
		}

	};

	const getRadioClasses = (value) => {
		return props.radioState === value ? ` ${styles.radioElementActive}`: '' ;
	};

	const renderOptions = () => {

		return props.options.map((option, idx) => {
			
			return (
				<div
					key = { option.value } 
					className={ styles.radioElem + getRadioClasses(idx) }
					onClick={ (e) => onBtnClick(option.value) }
					data-testid={ props.testId ? `radio-group-${props.testId}-${option.value.toLowerCase()}` : null }
				>
					<div className = { styles.textWrapper }>
						{ option.label }
					</div>
					<div className={ styles.radioBtn } >
						<div className={ styles.radioBtnInnerBorder } />
						<div className={ styles.radioBtnInnerCircle + (props.radioState === idx ? ' ' + styles.active : '') }>
							<CheckWhite />
						</div>
					</div>

					{option.tooltipInfo && (
						<div className = { styles.tooltipIconWrapper } onClick = { event => event.stopPropagation() } >
							<TooltipIcon 
								background = '#d2f0f2' 
								isClickable = { screenWidth <= C.SCREEN_SIZES.MAX_MOBILE_WIDTH }
								content = { 
									<div className = { styles.tooltipContentWrapper }>
										<div className = { styles.tooltipTitle }>
											{ option.tooltipInfo.title }
										</div>
										<div className = { styles.tooltipDescription }>
											{ option.tooltipInfo.description }
										</div>
									</div> } 
							/>
						</div>
					)}
					
				</div>
			);
		});
	};

	return (
		<div className={ getWrapperClassNames() }>
			<div
				className={ styles.radioWrapper }
				onKeyDown={ handleKeyDown }
				tabIndex="0"
				onFocus={ onFocus }
				onBlur={ onBlur }
				ref={ ref }
			>
				{ renderOptions() }
			</div>
		</div>
	);

});

RadioGroupMulti.propTypes = {
	init: PropTypes.string,
	radioState: PropTypes.number,
	radioOnChange: PropTypes.func.isRequired,
	isHidden: PropTypes.bool,
	disabled: PropTypes.bool,
	required: PropTypes.bool,
	maxlength: PropTypes.number,
	hasError: PropTypes.bool,
	isValid: PropTypes.bool,
	nextRef: PropTypes.object,
	testId: PropTypes.string,
	options:PropTypes.arrayOf(PropTypes.shape(
		{
			label: PropTypes.string,
			value: PropTypes.string,
			tooltipInfo: PropTypes.shape(
				{
					title: PropTypes.string,
					description: PropTypes.string
				}
			)
		}
	)).isRequired
};

export default RadioGroupMulti;
