/**
 * BrowserTable component
 *
 * Created by Cristian Restituyo
 */
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './BorrowersTable.scss';
import DefaultInput from 'ui/inputs/DefaultInput/DefaultInput';
import DropdownInput from 'ui/inputs/DropdownInput/DropdownInput';
import TooltipIcon from 'ui/TooltipIcon/TooltipIcon';
import * as regex from '../../../../utils/regex';
import * as utils from '../../../../utils/utils';
import PlusIcon from '../../svg/Plus';
import { useScreenWidth } from '../../../../hooks';
import C from '../../../../constants';
import _ from 'lodash';

const BorrowersTable = React.forwardRef((props, ref) => {

	const screenWidth = useScreenWidth();
	const [focusedRow, setFocusedRow] = useState(-1);
	const [currentExtraField, setCurrentExtraField] = useState('');
	const [isNewFieldAvailable, setIsNewFieldAvailable] = useState(false);
	const [availableExtraFields, setAvailableExtraFields] = useState(props.extraFields);
	const [tempField, setTempField] = useState({
		value1: '',
		value2: ''
	});
	
	const desktopBorrowers1Refs = useRef([]); 
	const desktopBorrowers2Refs = useRef([]); 
	const desktopBorrowerButtonRef = useRef(null);

	const mobileBorrowers1Refs = useRef([]);
	const mobileBorrowers2Refs = useRef([]);
	const mobileBorrowers1ButtonRef = useRef(null);
	const mobileBorrowers2ButtonRef = useRef(null);

	const desktopDropdownRef = useRef(null);
	const mobileDropdownRef1 = useRef(null);
	const mobileDropdownRef2 = useRef(null);
	const desktopExtraInputFieldRef1 = useRef(null);
	const desktopExtraInputFieldRef2 = useRef(null);

	useEffect(() => {
		addOtherReasonField();
		setIsNewFieldAvailable(false);
	}, [currentExtraField]);

	useEffect(()=> {
		props.setExtraFields(availableExtraFields);
	}, [availableExtraFields]);

	const getFields = () => {
		return Object.keys(props.borrowers[0]);
	};

	const addOtherReasonField = () => {
		setIsNewFieldAvailable(true);
		if(!utils.isEmpty(currentExtraField)) {
			
			const newField = currentExtraField;
			const clonedBorrowers = _.cloneDeep(props.borrowers);

			const clonedAvailableExtraFields = availableExtraFields.filter( extraField => extraField.value !== currentExtraField);

			const currentNewField = availableExtraFields.find(element => element.value === newField);

			clonedBorrowers[0][newField] = {};
			clonedBorrowers[0][newField].text = currentNewField.text;
			clonedBorrowers[0][newField].value = tempField.value1;
			clonedBorrowers[0][newField].tooltipInfo = currentNewField.tooltipInfo;	
			clonedBorrowers[0][newField].isMandatory = false;
			clonedBorrowers[0][newField].isErr = false;
			if(clonedBorrowers.length > 1) {
				clonedBorrowers[1][newField] = {};
				clonedBorrowers[1][newField].text = currentNewField.text;
				clonedBorrowers[1][newField].value = tempField.value2;
				clonedBorrowers[1][newField].tooltipInfo = currentNewField.tooltipInfo;
				clonedBorrowers[1][newField].isMandatory = false;
				clonedBorrowers[1][newField].isErr = false;
			}

			
			setCurrentExtraField('');
			setTempField({
				value1: '',
				value2: ''
			});
			props.setBorrowers(clonedBorrowers);
			setAvailableExtraFields(clonedAvailableExtraFields);
		}
		
	};



	const onRowFocus = (event, idx) => {
		setFocusedRow(idx);
	};

	const getDesktopNextRef = (borrower, currentIdx) => {
		if(borrower === 'borrower1'){
			if(desktopBorrowers2Refs.current[currentIdx]){
				return desktopBorrowers2Refs.current[currentIdx];
			} 
			else {
				if(currentIdx === desktopBorrowers1Refs.current.length - 1) {
					return desktopBorrowerButtonRef.current;
				}
				return desktopBorrowers1Refs.current[currentIdx + 1];
			}
		}
		else {
			if(currentIdx === desktopBorrowers2Refs.current.length - 1) {
				return desktopBorrowerButtonRef.current;
			}
			return desktopBorrowers1Refs.current[currentIdx + 1];
		}
	};

	const getDesktopPrevRef = (borrower, currentIdx) => {
		if(borrower === 'borrower1'){
			if(desktopBorrowers2Refs.current[currentIdx - 1]){
				return desktopBorrowers2Refs.current[currentIdx - 1];
			} 
			else {
				return desktopBorrowers1Refs.current[currentIdx - 1];
			}
		} 
		else {
			return desktopBorrowers1Refs.current[currentIdx];
		}
	};

	const getMobileNextRef = (borrower, currentIdx) => {
		if(borrower === 'borrower1') {
			if(currentIdx === mobileBorrowers1Refs.current.length - 1) {
				return mobileBorrowers1ButtonRef.current;
			}
			return mobileBorrowers1Refs.current[currentIdx + 1];
		}
		else {
			if(currentIdx === mobileBorrowers2Refs.current.length - 1) {
				return mobileBorrowers2ButtonRef.current;
			}
			return mobileBorrowers2Refs.current[currentIdx + 1];
		}
	};

	const getMobilePrevRef = (borrower, currentIdx) => {
		if(borrower === 'borrower1') {
			return mobileBorrowers1Refs.current[currentIdx - 1];
		}
		else {
			if(currentIdx === 0) { 
				return mobileBorrowers1ButtonRef.current;
			}
			return mobileBorrowers2Refs.current[currentIdx - 1];
		}
	};

	const renderDesktopInputFields = () => {
		if(availableExtraFields.length > 0 && isNewFieldAvailable) {
			return (
				<div className = { styles.Row + ' ' + styles.ActiveRow + ' ' + styles.ExtraRow }>
					<div className = { styles.LargeCol } >
						<div className = { styles.customDropDown } >
							<DropdownInput
								inputOnChange={ (val) => { 
									setCurrentExtraField(val);
								} }
								label={ 'Bitte Wählen' }
								hasError={ false }
								items={ availableExtraFields }
								ref = { desktopDropdownRef }
								testId="extra-field"
								nextRef = { desktopExtraInputFieldRef1 }
							/>
						</div>
					</div>
					<div className = { styles.SmallCol } >
						<DefaultInput
							customClass = { styles.customField }
							inputOnChange={ (val) => {
								const clonedTempObj = {...tempField};
								clonedTempObj.value1 = val;
								setTempField(clonedTempObj);
							} }
							label = { '' }
							init={ tempField.value1 }
							maxlength={ 5 }
							placeholder=""
							type="number"
							ref={ desktopExtraInputFieldRef1 }
							testId="new-input-1"
							regex = { regex.numberWithDotAndComma() }
							isCurrency
							originalValue = { tempField.value1 }
							prevRef = { desktopDropdownRef }
							nextRef = { desktopExtraInputFieldRef2 ? desktopExtraInputFieldRef2 : desktopBorrowerButtonRef }
						/>
					</div>
					{ props.borrowers.length > 1 && (
						<div className = { styles.SmallCol } >
							<DefaultInput
								customClass = { styles.customField }
								inputOnChange={ (val) => {
									const clonedTempObj = {...tempField};
									clonedTempObj.value2 = val;
									setTempField(clonedTempObj);
								} }
								label = { '' } 
								init={ tempField.value2 }
								maxlength={ 5 }
								placeholder=""
								type="number"
								ref={ desktopExtraInputFieldRef2 }
								testId="new-input-2"
								regex = { regex.numberWithDotAndComma() }
								isCurrency
								originalValue = { tempField.value2 }
								prevRef = { desktopExtraInputFieldRef1 }
								nextRef = { desktopBorrowerButtonRef }
							/>
						</div>
					)}
					
				</div>
			);
		}
		
	};

	const renderMobileInputFields = (borrowerIdx) => {
		if(availableExtraFields.length > 0 && isNewFieldAvailable) {
			return (
				<div className = { styles.DropdownCol }>
					<DropdownInput
						inputOnChange={ (val) => { 
							setCurrentExtraField(val);
						} }
						label={ '' }
						hasError={ false }
						items={ availableExtraFields }
						ref = { borrowerIdx === 0 ? mobileDropdownRef1 : mobileDropdownRef2 }
						testId="extra-field"
						nextRef = { desktopExtraInputFieldRef1 }
					/>
				</div>
			);
		}
	};

	const getFilledClass = idx => {
		if(desktopBorrowers1Refs.current[idx] ) {
			if(!utils.isEmpty(desktopBorrowers1Refs.current[idx].value) 
				|| (desktopBorrowers2Refs.current[idx] && !utils.isEmpty(desktopBorrowers2Refs.current[idx].value))) {
				return styles.filledRow;
			}
		}
		return '';
	};

	const getMandatoryFieldsClass = field => {
		return props.borrowers[0][field].isMandatory ? styles.filledRow : '';
	};

	const renderDesktopLayout = () => {
		const { primaryColumnLabel, buttonLabel } = props;
		return (
			<>
			<div className = { styles.Header }>
				<div className = { styles.LargeCol + ' ' + styles.HeaderCol } >
					<span>{primaryColumnLabel}</span>
				</div>
				{props.secondaryColumnLabels.map((label, idx) => (
					<div key = { idx } className = { styles.SmallCol + ' ' + styles.HeaderCol } >
						<span>{label}</span>
					</div>
				))}
			</div>

			<div className = { styles.Wrapper } >
				{getFields().map((field, idx) => {
					return (
						<div 
							key = { idx } 
							className = { 
								styles.Row + ' ' + ((focusedRow === idx ) ? styles.ActiveRow : '') 
										+ ' ' + (focusedRow - idx === 1 ? styles.PrevRow : '') 
										+ ' ' + getFilledClass(idx)
										+ ' ' + getMandatoryFieldsClass(field)
							} 
							onFocus = { (event) => onRowFocus(event, idx) }
							onMouseEnter = { (event) => onRowFocus(event, idx) }
							onMouseLeave = { (event) => setFocusedRow(-1) }
						>
							<div className = { styles.LargeCol + ' ' + styles.FieldName } >
								{ props.borrowers[0][field].text } { props.borrowers[0][field].isMandatory ? ' *': '' }
								<div className = { styles.tooltipIconWrapper } onClick = { event => event.stopPropagation() } >
									<TooltipIcon 
										background = '#d2f0f2' 
										content = { 
											<div className = { styles.tooltipContentWrapper }>
												<div className = { styles.tooltipText }>
													{ props.borrowers[0][field].tooltipInfo.text }
												</div>
											</div> } 
									/>
								</div>
							</div>
							<div className = { styles.SmallCol } >
								<DefaultInput
									customClass = { styles.customField }
									inputOnChange={ (val) => {
										const clonedBorrowers = _.cloneDeep(props.borrowers);
										clonedBorrowers[0][field].value = val;
										props.setBorrowers(clonedBorrowers);
									} }
									label = { '' }
									init={ props.borrowers[0][field].value }
									maxlength={ 5 }
									placeholder=""
									type="number"
									ref={ ref => desktopBorrowers1Refs.current[idx] = ref }
									prevRef={ getDesktopPrevRef('borrower1', idx) }
									nextRef={ getDesktopNextRef('borrower1', idx) }
									testId={ 
										`${props.borrowers[0][field]
											.text
											.toLowerCase()
											.replace(/\s+/g, '-')}-darlehensnehmer-1` }
									regex = { regex.numberWithDotAndComma() }
									isCurrency
									originalValue = { props.borrowers[0][field].value }
								/>
							</div>
							{ props.borrowers.length > 1 && (
								<div className = { styles.SmallCol } >
									<DefaultInput
										customClass = { styles.customField }
										inputOnChange={ (val) => {
											const clonedBorrowers = _.cloneDeep(props.borrowers);
											clonedBorrowers[1][field].value = val;
											props.setBorrowers(clonedBorrowers);
										} }
										label = { '' } 
										init={ props.borrowers[1][field].value }
										maxlength={ 5 }
										placeholder=""
										type="number"
										ref={ ref => desktopBorrowers2Refs.current[idx] = ref }
										prevRef={ getDesktopPrevRef('borrower2', idx) }
										nextRef={ getDesktopNextRef('borrower2', idx) }
										testId={ `
												${props.borrowers[1][field]
											.text
											.toLowerCase()
											.replace(/\s+/g, '-')}-darlehensnehmer-2` }
										regex = { regex.numberWithDotAndComma() }
										isCurrency
										originalValue = { props.borrowers[1][field].value }
									/>
								</div>
							)}
						</div>
					);
				})}
			</div>
			
			{ renderDesktopInputFields() }
			{props.extraFields.length > 0 && (
				<button 
					className = { styles.AddFieldBtn } 
					onClick = { addOtherReasonField } 
					ref = { desktopBorrowerButtonRef }
					data-testid = "button-add-new-row"
				>
					<PlusIcon /><span>{buttonLabel}</span>
				</button>
			)}
			</>
		);
	};

	const renderMobileLayout = () => {
		const { buttonLabel } = props;
		return props.borrowers.map((borrower, borrowerIdx) => (
			<div key = { borrowerIdx } className = { styles.Table } >
				<div className={ styles.Header }>
					<span>{props.secondaryColumnLabels[borrowerIdx]}</span>
				</div>
				<div className = { styles.Wrapper } >
					{getFields().map((field, fieldIdx) => (
						<div key = { fieldIdx } className = { styles.InputWrapper } >
							<DefaultInput
								customClass = { styles.FieldName }
								inputOnChange={ (val) => {
									const clonedBorrowers = _.cloneDeep(props.borrowers);
									clonedBorrowers[borrowerIdx][field].value = val;
									props.setBorrowers(clonedBorrowers);
								} }
								label = { props.borrowers[borrowerIdx][field].text + (props.borrowers[borrowerIdx][field].isMandatory ? ' *': '') }
								init={ props.borrowers[borrowerIdx][field].value }
								maxlength={ 5 }
								placeholder=""
								type="number"
								ref={ ref => { 
									if(borrowerIdx === 0) {
										mobileBorrowers1Refs.current[fieldIdx] = ref;
									}
									else {
										mobileBorrowers2Refs.current[fieldIdx] = ref;
									}
								} }
								prevRef={ getMobilePrevRef(`borrower${borrowerIdx + 1}`, fieldIdx) }
								nextRef={ getMobileNextRef(`borrower${borrowerIdx + 1}`, fieldIdx) }
								testId={ 
									`${props.borrowers[borrowerIdx][field]
										.text
										.toLowerCase()}-darlehensnehmer-${borrowerIdx+1}` }
								regex = { regex.numberWithDotAndComma() }
								isCurrency
								originalValue = { props.borrowers[borrowerIdx][field].value }
							/>
							<div className = { styles.tooltipIconWrapper } onClick = { event => event.stopPropagation() } >
								<TooltipIcon 
									background = '#d2f0f2' 
									content = { 
										<div className = { styles.tooltipContentWrapper }>
											<div className = { styles.tooltipText }>
												{ props.borrowers[0][field].tooltipInfo.text }
											</div>
										</div> } 
									isClickable
								/>
							</div>
						</div>
					))}
					{ renderMobileInputFields(borrowerIdx) }
				</div>
				
				{props.extraFields.length > 0 && (
					<button 
						data-testid = "button-add-new-row"
						className = { styles.AddFieldBtn } 
						onClick = { addOtherReasonField } 
						ref = { borrowerIdx === 0 ? mobileBorrowers1ButtonRef : mobileBorrowers2ButtonRef } 
					>
						<PlusIcon /><span>{buttonLabel}</span>
					</button>
				)}
			</div>
		));
	};
	
	const renderTable = () => {
		if(screenWidth <= C.SCREEN_SIZES.MAX_MOBILE_WIDTH) {
			return (
				<div className = { styles.BorrowersTableMobile }>
					{ renderMobileLayout() }
				</div>
			);
		}

		return (
			<div className = { styles.BorrowersTableDesktop }>
				{ renderDesktopLayout() }
			</div>
		);
	};
	return (
		<div ref = { ref }>
			{ renderTable() }	
		</div>
	);
});

BorrowersTable.propTypes = {
	borrowers: PropTypes.array.isRequired,
	setBorrowers: PropTypes.func.isRequired,
	extraFields: PropTypes.array.isRequired,
	setExtraFields: PropTypes.func.isRequired,
	primaryColumnLabel: PropTypes.string.isRequired,
	buttonLabel: PropTypes.string.isRequired,
	secondaryColumnLabels: PropTypes.arrayOf(PropTypes.string).isRequired
};

BorrowersTable.defaultProps = {
	secondaryColumnLabels:['']
};

export default BorrowersTable;