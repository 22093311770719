import React from 'react';
import { Container, Row, Col } from 'mod/FlexGrid/index';

import footerStyles from 'mod/Footer/Footer.scss';
import FooterSvg from 'ui/svg/Footer.jsx';

const Footer = () => {

	return(
		<div className={ footerStyles.footerWrapper }>
			<div className={ footerStyles.footerWrapperInner }>
				<Container>
					<Row justifyContent="center">
						<Col md={ 8 } lg={ 6 } xl={ 4 }>
							{ <FooterSvg className={ footerStyles.footerSvg } /> }
						</Col>
					</Row>
				</Container>
			</div>
		</div>
	);
};

export default Footer;