/**
 * MonthlyOutgoings
 *
 * Created by Cristian Restituyo
 * Last modified date : 23-07-2019
 */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Page from 'mod/Page/Page';
import { Row, Col } from 'mod/FlexGrid';
import _ from 'lodash';
import C from '../../constants';
import * as googleEvents from '../../utils/googleEvents';
import * as AppActions from '../../store/App/actions';
import * as EntryActions from '../../store/MonthlyOutgoings/actions';
import * as SolutionActions from '../../store/Solutions/actions';
import * as utils from '../../utils/utils';


import BorrowersTable from 'ui/inputs/BorrowersTable/BorrowersTable';

const MonthlyOutgoings = ({ reducers, actions, history, location }) => {

	const [state, setState] = useState({
		path: C.ROUTES.MONTHLY_OUTGOINGS,
		currentFlow: utils.getCurrentFlow(location)
	});
	const [borrowers, setBorrowers] = useState([]);
	const [extraFields, setExtraFields] = useState([]);

	const [nextRoute, setNextRoute] = useState('');


	const skeletonData = {
		housingCosts:{
			text:utils.m('monthlyOutgoings.items.housingCosts.label', 'fields'),
			value: '',
			isMandatory: true,
			isErr: false,
			tooltipInfo: {
				text:utils.m('monthlyOutgoings.items.housingCosts.tooltipInfo.text', 'fields')
			}
		},
		incidentalExpenses: {
			text: utils.m('monthlyOutgoings.items.incidentalExpenses.label', 'fields'),
			value: '',
			isMandatory: true,
			isErr: false,
			tooltipInfo: {
				text:utils.m('monthlyOutgoings.items.incidentalExpenses.tooltipInfo.text', 'fields')
			}
		},
		telecommunicationCosts: {
			text: utils.m('monthlyOutgoings.items.telecommunicationCosts.label', 'fields'),
			value: '',
			isMandatory: true,
			isErr: false,
			tooltipInfo: {
				text:utils.m('monthlyOutgoings.items.telecommunicationCosts.tooltipInfo.text', 'fields')
			}
		},
		insuranceContributions: {
			text: utils.m('monthlyOutgoings.items.insuranceContributions.label', 'fields'),
			value: '',
			isMandatory: false,
			isErr: false,
			tooltipInfo: {
				text:utils.m('monthlyOutgoings.items.insuranceContributions.tooltipInfo.text', 'fields')
			}
		},		
		
		mobilityCosts: {
			text: utils.m('monthlyOutgoings.items.mobilityCosts.label', 'fields'),
			value: '',
			isMandatory: false,
			isErr: false,
			tooltipInfo: {
				text:utils.m('monthlyOutgoings.items.mobilityCosts.tooltipInfo.text', 'fields')
			}
		},
		thirdPartyObligations: {
			text: utils.m('monthlyOutgoings.items.thirdPartyObligations.label', 'fields'),
			value: '',
			isMandatory: false,
			isErr: false,
			tooltipInfo: {
				text:utils.m('monthlyOutgoings.items.thirdPartyObligations.tooltipInfo.text', 'fields')
			}
		}
	};

	const skeletonExtra = [
		{	
			text: utils.m('monthlyOutgoings.items.costsForMedia.label', 'fields'),
			value: 'costsForMedia',
			isMandatory: false,
			isErr: false,
			tooltipInfo: {
				text:utils.m('monthlyOutgoings.items.costsForMedia.tooltipInfo.text', 'fields')
			}
		},
		{	
			text: utils.m('monthlyOutgoings.items.maintenanceObligations.label', 'fields'),
			value: 'maintenanceObligations',
			isMandatory: false,
			isErr: false,
			tooltipInfo: {
				text:utils.m('monthlyOutgoings.items.maintenanceObligations.tooltipInfo.text', 'fields')
			}
		},
		{	
			text: utils.m('monthlyOutgoings.items.onlineAndMailOrderBussiness.label', 'fields'),
			value: 'onlineAndMailOrderBussiness',
			isMandatory: false,
			isErr: false,
			tooltipInfo: {
				text:utils.m('monthlyOutgoings.items.onlineAndMailOrderBussiness.tooltipInfo.text', 'fields')
			}
		},
		{	
			text: utils.m('monthlyOutgoings.items.savingsRate.label', 'fields'),
			value: 'savingsRate',
			isMandatory: false,
			isErr: false,
			tooltipInfo: {
				text:utils.m('monthlyOutgoings.items.savingsRate.tooltipInfo.text', 'fields')
			}
		},
		{	
			text: utils.m('monthlyOutgoings.items.otherExpenditure.label', 'fields'),
			value: 'otherExpenditure',
			isMandatory: false,
			isErr: false,
			tooltipInfo: {
				text:utils.m('monthlyOutgoings.items.otherExpenditure.tooltipInfo.text', 'fields')
			}
		}
	];

	


	useEffect(() => {
		// Set the page Index for navigation
		actions.updatePage(utils.getPageIndex(state.path, state.currentFlow));

		// Set the default value on page load
		setState({
			...state
		});

		setBorrowers(reducers.monthlyOutgoings.borrowers);
		setExtraFields(reducers.monthlyOutgoings.extraFields);

		// Block access from user type in url directly
		utils.checkIsFromNav(history, reducers.app.isReload, state.currentFlow + C.ROUTES.NAME);

		// Set the first element on focus
		if(!reducers.app.appLoading) {
			// dayRef.current.focus();
		}
	}, []);

	useEffect(() => {
		const { provideSecondBorrower } = reducers;
	
		if(provideSecondBorrower.radioState === 1 ) {
			initializeBorrowers(1);
		}
		else {
			initializeBorrowers(2);
		}

	},[reducers.provideSecondBorrower.radioState]);

	useEffect(() => {
		// const {availableOptions} = reducers.solutions;
		// if(availableOptions.length === 1) {
		// 	setNextRoute(C.SCREENS.F2);	
			
		// } else {
		// 	setNextRoute(C.SCREENS.F1);
		// }
		setNextRoute(C.SCREENS.F1);
	},[reducers.solutions.availableOptions]);


	const initializeBorrowers = times => {
		
		if(reducers.monthlyOutgoings.currentProvidedBorrowerOption !== reducers.provideSecondBorrower.radioState) {
			let tempBorrowers = [];
			for(let index = 0; index < times; index++) {
				const tempData = _.cloneDeep(skeletonData);
				tempBorrowers.push(tempData);
			}
			setBorrowers(tempBorrowers);
			setExtraFields(skeletonExtra);
			resetStoreInfo();
		}
	};

	const resetStoreInfo = () => {
		actions.onUpdateMonthlyOutgoings({
			borrowers:[],
			extraFields: skeletonExtra,
			currentProvidedBorrowerOption: -2
		});
	};

	const onFormSubmit = () => {

		googleEvents.continueValid();

		actions.onUpdateMonthlyOutgoings({
			borrowers,
			extraFields,
			currentProvidedBorrowerOption: reducers.provideSecondBorrower.radioState
		});
		actions.updateReload(false);

		utils.goToPage(history, state.currentFlow + nextRoute);
	};

	const hasEmptyFields = () => {
		if(borrowers.length === 1) {
			return (
				(utils.isEmpty(borrowers[0].housingCosts.value) || parseFloat(borrowers[0].housingCosts.value) <= 0 ) ||
					(utils.isEmpty(borrowers[0].incidentalExpenses.value) || parseFloat(borrowers[0].incidentalExpenses.value) <= 0) ||
					(utils.isEmpty(borrowers[0].telecommunicationCosts.value) || parseFloat(borrowers[0].telecommunicationCosts.value) <= 0)
			);
		} else if (borrowers.length > 1) {
			return (
				(
					(isEmptyAndEqualToZero(borrowers[0].housingCosts.value) && isEmptyAndEqualToZero(borrowers[1].housingCosts.value) ) ||
					(isEmptyAndEqualToZero(borrowers[0].incidentalExpenses.value) && isEmptyAndEqualToZero(borrowers[1].incidentalExpenses.value)) ||
					(isEmptyAndEqualToZero(borrowers[0].telecommunicationCosts.value) && isEmptyAndEqualToZero(borrowers[1].telecommunicationCosts.value)) 
				)
			);
		}
		return true;
	};

	const isEmptyAndEqualToZero = val => {
		return utils.isEmpty(val) || parseFloat(val) <= 0;
	};

	const renderTable = () => {
		let labelList = [];
		const { name } = reducers;

		if(!utils.isEmpty(name.firstName) && borrowers.length === 1) {
			labelList = [];
			labelList.push(`${name.firstName} ${name.lastName}`);
		}

		if(utils.isEmpty(name.firstName) && borrowers.length === 1) {
			labelList = [];
			labelList.push(utils.m('monthlyOutgoings.secondaryColumn1.label', 'fields'));	
		}

		if(borrowers.length > 1) {
			labelList = [];
			labelList.push(utils.m('monthlyOutgoings.secondaryColumn1.label', 'fields'));
			labelList.push(utils.m('monthlyOutgoings.secondaryColumn2.label', 'fields'));
		}
		return borrowers.length > 0 && (
			<Col xs = { 12 }>
				<BorrowersTable 
					borrowers = { borrowers }
					setBorrowers = { setBorrowers }
					extraFields = { extraFields }
					setExtraFields = { setExtraFields }
					primaryColumnLabel = { utils.m('monthlyOutgoings.primaryColumn.label', 'fields') }
					secondaryColumnLabels = { labelList }
					buttonLabel = { utils.m('monthlyOutgoings.addRowButton.label', 'fields') }
				/>
			</Col>
			
		);
	};
	

	const reasonForLateTransferRef = useRef(null);
	const submitRef = useRef(null);

	return (
		<Page
			title={ utils.m('monthlyOutgoings.title', state.currentFlow) }
			subtitle={ utils.m('monthlyOutgoings.subtitle', state.currentFlow) }
			isShowError={ state.isShowError }
			errorMsg={ state.errorMsg }
			hasNextButton={ true }
			onBackButtonClick={ history.goBack }
			onFormSubmit={ onFormSubmit }
			nextButtonDisabled={ hasEmptyFields() }
			location={ location }
			currentFlow={ state.currentFlow }
			pageName="MonthlyOutgoings"
			currentStep={ C.PROGRESS_BAR.budgetAccounting }
			screen='D6'
			showButtonDivider={ false }
			submitRef={ submitRef }
			prevRef={ submitRef }
			nextRef={ reasonForLateTransferRef }
			gtmStep="userdata_geburtsdatum"
			gtmStatus="registration_pending"
			gtmSubStatus={ null }
			isMandatoryFields
			mandatoryLabel = '*Pflichtfeld'
		>
			<Row justifyContent="center">
				{ renderTable() }
			</Row>
		</Page>
	);
};

MonthlyOutgoings.propTypes = {
	reducers: PropTypes.object,
	actions: PropTypes.object,
	history: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(_.merge({}, AppActions, EntryActions,SolutionActions), dispatch)
});

const mapStateToProps = (state) => {
	return {
		reducers: state
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MonthlyOutgoings);
