/**
 * Action Type
*/

const UPDATE_PRODUCT = 'UPDATE_PRODUCT';

/*
* Action Creators
*/

export const onUpdateProduct = payload => ({
	type: UPDATE_PRODUCT,
	payload
});