import _ from 'lodash';

const defaultState = {
	day: '',
	month: '',
	year: '',
	acceptPrivacyPolicies: false
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case 'UPDATE_BIRTHDAY':
			return _.merge({}, state, {
				day: action.payload.day,
				month: action.payload.month,
				year: action.payload.year,
				acceptPrivacyPolicies: action.payload.acceptPrivacyPolicies
			});
		default:
			return state;
	}
};

export default reducers;
