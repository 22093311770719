import React from 'react'; 
import PropTypes from 'prop-types';
import styles from './DocumentCard.scss';
import DocumentIcon from '../../svg/Documents';

const DocumentCard = props => {
	return (
		<div className={ styles.stepBoxStepContent }>
			<div className={ styles.stepBoxStepContentText }>
				<h3>{ props.title }</h3>
				{/* <p>{ props.description }</p> */}
				<p dangerouslySetInnerHTML = { {__html: props.description} }/>
			</div>
			<div className={ styles.stepBoxStepContentIcon }>
				<DocumentIcon />
			</div>
		</div>
	);
};

DocumentCard.propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.string
};

export default DocumentCard;