/**
 * Component for the title of eact page
 * 
 * Created by Simon Orzel
 * Last modified by Perry Tang
 * Last modified date : 20-5-2019
 */

import React from 'react';
import PropTypes from 'prop-types';
import pageTitleStyles from 'mod/PageTitle/PageTitle.scss';

const PageTitle = (props) => {

	const getTextAlign = () => {
		if(props.textAlign) {
			if(props.textAlign === 'center') {
				return ' ' + pageTitleStyles.textAlignCenter;
			}
			else if(props.textAlign === 'right') {
				return ' ' + pageTitleStyles.textAlignRight;
			}
			else if(props.textAlign === 'left') {
				return ' ' + pageTitleStyles.textAlignLeft;
			}
			else {
				return '';
			}
		}
		else {
			return '';
		}
	};

	return(
		<div className={ pageTitleStyles.pageTitle + getTextAlign() }>
			<h1 className="h1" dangerouslySetInnerHTML = { {__html: props.title} } />
			<p className="copyText">{ props.subtitle }</p>
		</div>
	);
};

// Check the props type
PageTitle.propTypes = {
	title: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.element
	]),
	subtitle: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.element
	]),
	textAlign: PropTypes.string
};

// Specifies the default values for props:
PageTitle.defaultProps = {
	title: '',
	subtitle: ''
};

export default PageTitle;
