/**
 * Component for the progress bar
 *
 * Created by Simon Orzel
 * Last modified by Cristian Restituyo
 * Last modified date : 02-07-2019
 */

import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'mod/FlexGrid/index';
import progressBarStyles from 'mod/CustomProgressBar/CustomProgressBar.scss';

const CustomProgressBar = ({ steps, currentStep, currentScreen, progress }) => {
	const currentIndex = steps.findIndex(s => s.display === currentStep);

	const Content = () =>
		steps.map((step, index) => (
			<Col key={ index } textAlign="center">
				{currentIndex === index ? (
					<p style={ { color: 'black', fontWeight:'bold' } }>{step.display}</p>
				) : (
					<p>{step.display}</p>
				)}
			</Col>
		));

	return (
		<div className={ progressBarStyles.wrapper }>
			<Container>
				<Row justifyContent="center">
					<Col md={ 10 } lg={ 8 } xl={ 6 }>
						<Row justifyContent="center">
							<Content />
						</Row>
						<div className={ progressBarStyles.progressBar }>
							<span style={ { width: progress } } />
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

CustomProgressBar.propTypes = {
	steps: PropTypes.arrayOf(
		PropTypes.shape({
			key: PropTypes.string,
			display: PropTypes.string,
			steps: PropTypes.arrayOf(PropTypes.string)
		})
	),
	currentStep: PropTypes.string,
	currentScreen: PropTypes.string
};

export default CustomProgressBar;
