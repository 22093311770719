import * as React from 'react';

import PropTypes from 'prop-types';
import styles from './Container.scss';

const Container = (props) => {
	return <div className={ styles.container + (props.customClass ? ' ' + props.customClass : '') }>{ props.children }</div>;
};

Container.propTypes = {
	customClass: PropTypes.string
};

export default Container;
