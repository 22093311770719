import * as React from 'react';
import PropTypes from 'prop-types';

import styles from './Row.scss';
import Col from '../Col/Col';

const Row = (props) => {
	return (
		<div className={
			styles.row +
			(props.justifyContent ? ' ' + styles['justify-content-' + props.justifyContent] : '') +
			(props.customClass ? ' ' + props.customClass : '')
		}>{ props.children }</div>
	);
};

Col.propTypes = {
	justifyContent: PropTypes.string,
	customClass: PropTypes.string
};

export default Row;
