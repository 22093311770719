/**
 * The root reducer group all other little reducers from individual component
 *
 * Created by Moritz Kippenberg
 * Last modified by Moritz Kippenberg
 * Last modified date : 01-07-2019
 */
import { combineReducers } from 'redux';
import App from './App';
import Address from './Address';
import PreviousAddress from './PreviousAddress';
import Birthday from './Birthday';
import Email from './Email';
import Name from './Name';
import Children from './Children';
import FinancialNumber from './FinancingNumber';
import CreditCardNumber from './CreditCardNumber';
import Contact from './Contact';
import QueryParams from './QueryParams';
import Product from './Product';
import PaymentStatus from './PaymentStatus';
import PaymentStatusPaid from './PaymentStatusPaid';
import PaymentStatusWillPay from './PaymentStatusWillPay';
import FuturePaymentProblem from './FuturePaymentProblem';
import PaymentProblems from './PaymentProblems';
import Unemployment from './Unemployment';
import NewAddress from './NewAddress';
import ShortEmployment from './ShortEmployment';
import InabilityOfWork from './InabilityOfWork';
import OtherReason from './InputOtherReason';
import MonthlyIncome from './MonthlyIncome';
import ProvideSecondBorrower from './ProvideSecondBorrower';
import MonthlyOutgoings from './MonthlyOutgoings';
import GeneralRateReduction from './GeneralRateReduction';
import TempRateReduction from './TempRateReduction';
import Solutions from './Solutions';
import DefermentOfPayment from './DefermentOfPayment';

export default combineReducers({
	[App.constant.NAME]: App.reducers,
	[Address.constant.NAME]: Address.reducers,
	[PreviousAddress.constant.NAME]: PreviousAddress.reducers,
	[Birthday.constant.NAME]: Birthday.reducers,
	[Email.constant.NAME]: Email.reducers,
	[Name.constant.NAME]: Name.reducers,
	[Children.constant.NAME]: Children.reducers,
	[FinancialNumber.constant.NAME]: FinancialNumber.reducers,
	[CreditCardNumber.constant.NAME]: CreditCardNumber.reducers,
	[Contact.constant.NAME]: Contact.reducers,
	[QueryParams.constant.NAME]: QueryParams.reducers,
	[PaymentStatus.constant.NAME]: PaymentStatus.reducers,
	[PaymentStatusPaid.constant.NAME]: PaymentStatusPaid.reducers,
	[PaymentStatusWillPay.constant.NAME]: PaymentStatusWillPay.reducers,
	[FuturePaymentProblem.constant.NAME]: FuturePaymentProblem.reducers,
	[PaymentProblems.constant.NAME]: PaymentProblems.reducers,
	[Unemployment.constant.NAME]: Unemployment.reducers,
	[Product.constant.NAME]: Product.reducers,
	[NewAddress.constant.NAME]: NewAddress.reducers,
	[ShortEmployment.constant.NAME]: ShortEmployment.reducers,
	[InabilityOfWork.constant.NAME]: InabilityOfWork.reducers,
	[OtherReason.constant.NAME]: OtherReason.reducers,
	[MonthlyIncome.constant.NAME]: MonthlyIncome.reducers,
	[ProvideSecondBorrower.constant.NAME]: ProvideSecondBorrower.reducers,
	[MonthlyOutgoings.constant.NAME]: MonthlyOutgoings.reducers,
	[GeneralRateReduction.constant.NAME]: GeneralRateReduction.reducers,
	[TempRateReduction.constant.NAME]: TempRateReduction.reducers,
	[Solutions.constant.NAME]: Solutions.reducers,
	[DefermentOfPayment.constant.NAME]: DefermentOfPayment.reducers
});
