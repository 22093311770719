/**
 * New Address Page
 *
 * Created by: Cristian Restituyo
 */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Page from 'mod/Page/Page';
import { Row, Col } from 'mod/FlexGrid/index';
import _ from 'lodash';
import C from '../../constants';

import * as AppActions from '../../store/App/actions';
import * as EntryActions from '../../store/NewAddress/actions';
import * as utils from '../../utils/utils';

import DefaultInput from 'ui/inputs/DefaultInput/DefaultInput';
import * as googleEvents from '../../utils/googleEvents';
import * as regex from '../../utils/regex';

const NewAddress = ({ reducers, actions, history, location }) => {
	// TODO: implement AZ Direct for autocomplete

	const [state, setState] = useState({
		path: C.ROUTES.NEW_ADDRESS,
		currentFlow: utils.getCurrentFlow(location),
		postcode: '',
		isPostcodeErr: false,
		city: '',
		isCityErr: false,
		street: '',
		isStreetErr: false,
		number: '',
		isNumberErr: false,
		errorMsg: '',
		day: '',
		isDayErr: false,
		month: '',
		isMonthErr: false,
		year: '',
		isYearErr: false,
		isShowError: false,
		isDateErr: false
	});

	useEffect(() => {
		// Set the page Index for navigation

		actions.updatePage(utils.getPageIndex(state.path, state.currentFlow));

		// Set the default value on page load
		setState({
			...state,
			postcode: reducers.newAddress.postcode,
			city: reducers.newAddress.city,
			street: reducers.newAddress.street,
			number: reducers.newAddress.number,
			day: reducers.newAddress.day,
			month: reducers.newAddress.month,
			year: reducers.newAddress.year
		});

		// Block access from user type in url directly
		utils.checkIsFromNav(
			history,
			reducers.app.isReload,
			state.currentFlow + C.ROUTES.NAME
		);

		// Set the first element on focus
		if (!reducers.app.appLoading) {
			streetRef.current.focus();
		}
	}, []);

	const onFormSubmit = () => {
		/*** Validation ***/
		let isValid = true;
		let cloneState = _.cloneDeep(state);

		cloneState.errorMsg = '';

		if (state.postcode.trim().length !== 5) {
			isValid = false;
			utils.showError(
				cloneState,
				'isPostcodeErr',
				utils.m('postcode.validation.invalid', 'fields')
			);
		} else {
			cloneState.isPostcodeErr = false;
		}

		if (state.city.trim().length < 2) {
			isValid = false;
			utils.showError(
				cloneState,
				'isCityErr',
				utils.m('city.validation.invalid', 'fields')
			);
		} else {
			cloneState.isCityErr = false;
		}

		if (state.street.trim().length < 2) {
			isValid = false;
			utils.showError(
				cloneState,
				'isStreetErr',
				utils.m('street.validation.invalid', 'fields')
			);
		} else {
			cloneState.isStreetErr = false;
		}

		// Define max days of month
		let maxDays = 31;

		if (
			!utils.isEmpty(state.month) &&
			parseInt(state.month) > 0 &&
			parseInt(state.month) <= 12
		) {
			let monthNumber = parseInt(state.month);

			if (monthNumber === 2) {
				maxDays = 29;
			} else if (
				monthNumber === 4 ||
				monthNumber === 6 ||
				monthNumber === 9 ||
				monthNumber === 11
			) {
				maxDays = 30;
			} else {
				maxDays = 31;
			}
		}

		if (
			parseInt(state.day) < 1 ||
			state.day.length > 2 ||
			parseInt(state.day) > maxDays
		) {
			isValid = false;
			utils.showError(
				cloneState,
				'isDayErr',
				utils.m('day.validation.invalid', 'fields', { maxDays: maxDays })
			);
		} else {
			cloneState.isDayErr = false;
		}

		if (
			parseInt(state.month) < 1 ||
			state.month.length > 2 ||
			parseInt(state.month) > 12
		) {
			isValid = false;
			utils.showError(
				cloneState,
				'isMonthErr',
				utils.m('month.validation.invalid', 'fields')
			);
		} else {
			cloneState.isMonthErr = false;
		}


		if (
			!utils.isNumber(state.year) ||
			state.year.length !== 4
		) {
			isValid = false;
			utils.showError(
				cloneState,
				'isYearErr',
				utils.m('year.validation.invalid', 'fields')
			);
		} else {
			cloneState.isYearErr = false;
		}

		if (cloneState.isDayErr || cloneState.isMonthErr || cloneState.isYearErr) {
			cloneState.errorMsg =
				utils.m('date.validation.invalid', 'fields') + cloneState.errorMsg;
		}

		if (isValid) {
			// on validation success
			googleEvents.continueValid();

			actions.onUpdateNewAddress(state);
			actions.updateReload(false);

			utils.goToPage(history, state.currentFlow + C.SCREENS.D1);
		} else {
			// on validation failure
			googleEvents.continueInvalid();
			setState(cloneState);
		}
	};

	const hasEmptyFields = () => {
		return (
			utils.isEmpty(state.postcode) ||
			utils.isEmpty(state.city) ||
			utils.isEmpty(state.street) ||
			utils.isEmpty(state.number) ||
			utils.isEmpty(state.day) ||
			utils.isEmpty(state.month) ||
			utils.isEmpty(state.year)
		);
	};

	const postcodeRef = useRef(null);
	const cityRef = useRef(null);
	const streetRef = useRef(null);
	const numberRef = useRef(null);
	const dayRef = useRef(null);
	const monthRef = useRef(null);
	const yearRef = useRef(null);
	const submitRef = useRef(null);

	return (
		<Page
			title={ utils.m('newAddress.title', state.currentFlow) }
			subtitle={ utils.m('newAddress.subtitle', state.currentFlow) }
			isShowError={ state.isShowError }
			errorMsg={ state.errorMsg }
			hasNextButton={ true }
			onBackButtonClick={ history.goBack }
			onFormSubmit={ onFormSubmit }
			nextButtonDisabled={ hasEmptyFields() }
			location={ location }
			currentFlow={ state.currentFlow }
			pageName='NewAddress'
			currentStep={ C.PROGRESS_BAR.statement }
			screen='C3'
			submitRef={ submitRef }
			prevRef={ numberRef }
			nextRef={ postcodeRef }
			gtmStep='personaldata_newaddress_address'
			gtmStatus='application_pending'
			gtmSubStatus={ null }
		>
			<Row justifyContent='center'>
				<Col xs={ 12 } sm={ 6 }>
					<DefaultInput
						inputOnChange={ val => {
							utils.onInputChange(setState, state, 'street', val);
						} }
						label={ utils.m('street.label', 'fields') }
						hasError={ state.isStreetErr }
						init={ state.street }
						maxlength={ 50 }
						ref={ streetRef }
						prevRef={ cityRef }
						nextRef={ numberRef }
						regex={ regex.wordWithSpaceAndDash() }
						jumpToNextOnMaxLength={ true }
						testId='street'
					/>
				</Col>

				<Col xs={ 12 } sm={ 6 }>
					<DefaultInput
						inputOnChange={ val => {
							utils.onInputChange(setState, state, 'number', val);
						} }
						label={ utils.m('houseNumber.label', 'fields') }
						hasError={ state.isNumberErr }
						init={ state.number }
						maxlength={ 5 }
						ref={ numberRef }
						prevRef={ streetRef }
						nextRef={ hasEmptyFields() ? postcodeRef : submitRef }
						regex={ regex.wordWithSpaceAndDashAndNumbers() }
						jumpToNextOnMaxLength={ true }
						testId='house-number'
					/>
				</Col>

				<Col xs={ 12 } sm={ 6 }>
					<DefaultInput
						inputOnChange={ val => {
							utils.onInputChange(setState, state, 'postcode', val);
						} }
						label={ utils.m('postcode.label', 'fields') }
						hasError={ state.isPostcodeErr }
						init={ state.postcode }
						maxlength={ 5 }
						type='number'
						ref={ postcodeRef }
						prevRef={ hasEmptyFields() ? numberRef : submitRef }
						nextRef={ cityRef }
						regex={ regex.digit() }
						jumpToNextOnMaxLength={ true }
						testId='postcode'
					/>
				</Col>

				<Col xs={ 12 } sm={ 6 }>
					<DefaultInput
						inputOnChange={ val => {
							utils.onInputChange(setState, state, 'city', val);
						} }
						label={ utils.m('city.label', 'fields') }
						hasError={ state.isCityErr }
						init={ state.city }
						maxlength={ 50 }
						ref={ cityRef }
						prevRef={ postcodeRef }
						nextRef={ dayRef }
						regex={ regex.wordWithSpaceAndDash() }
						jumpToNextOnMaxLength={ true }
						testId='city'
					/>
				</Col>
				<Col xs={ 12 }>
					<span className='h2'>
						{' '}
						{utils.m('specificDateOfMoving.label', 'fields')}{' '}
					</span>
				</Col>
				<Col xs={ 12 } sm={ 4 }>
					<DefaultInput
						inputOnChange={ val => {
							utils.onInputChange(setState, state, 'day', val);
						} }
						label={ utils.m('day.label', 'fields') }
						hasError={ state.isDayErr }
						init={ state.day }
						maxlength={ 2 }
						placeholder='TT'
						type='number'
						ref={ dayRef }
						prevRef={ cityRef }
						nextRef={ monthRef }
						regex={ regex.digit() }
						jumpToNextOnMaxLength={ true }
						testId='day'
					/>
				</Col>
				<Col xs={ 12 } sm={ 4 }>
					<DefaultInput
						inputOnChange={ val => {
							utils.onInputChange(setState, state, 'month', val);
						} }
						label={ utils.m('month.label', 'fields') }
						hasError={ state.isMonthErr }
						init={ state.month }
						maxlength={ 2 }
						placeholder='MM'
						type='number'
						ref={ monthRef }
						prevRef={ monthRef }
						nextRef={ yearRef }
						regex={ regex.digit() }
						jumpToNextOnMaxLength={ true }
						testId='month'
					/>
				</Col>

				<Col xs={ 12 } sm={ 4 }>
					<DefaultInput
						inputOnChange={ val => {
							utils.onInputChange(setState, state, 'year', val);
						} }
						label={ utils.m('year.label', 'fields') }
						hasError={ state.isYearErr }
						init={ state.year }
						maxlength={ 4 }
						placeholder='JJJJ'
						type='number'
						ref={ yearRef }
						prevRef={ monthRef }
						nextRef={ hasEmptyFields() ? yearRef : submitRef }
						regex={ regex.digit() }
						jumpToNextOnMaxLength={ true }
						testId='year'
					/>
				</Col>
			</Row>
		</Page>
	);
};

NewAddress.propTypes = {
	reducers: PropTypes.object,
	actions: PropTypes.object,
	history: PropTypes.object
};

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(_.merge({}, AppActions, EntryActions), dispatch)
});

const mapStateToProps = state => {
	return {
		reducers: state
	};
};

export default 
connect(
	mapStateToProps,
	mapDispatchToProps
)(NewAddress);
