/**
 * MonthlyIncome
 *
 * Created by Cristian Restituyo
 * Last modified date : 23-07-2019
 */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Page from 'mod/Page/Page';
import { Row, Col } from 'mod/FlexGrid/index';
import _ from 'lodash';
import C from '../../constants';
import * as googleEvents from '../../utils/googleEvents';
import * as AppActions from '../../store/App/actions';
import * as EntryActions from '../../store/MonthlyIncome/actions';
import * as utils from '../../utils/utils';


import BorrowersTable from 'ui/inputs/BorrowersTable/BorrowersTable';

const MonthlyIncome = ({ reducers, actions, history, location }) => {

	const [state, setState] = useState({
		path: C.ROUTES.MONTHLY_INCOME,
		currentFlow: utils.getCurrentFlow(location)
	});
	const [borrowers, setBorrowers] = useState([]);
	const [extraFields, setExtraFields] = useState([]);

	const skeletonData = {
		netIncome:{
			text:utils.m('monthlyIncome.items.netIncome.label', 'fields'),
			value: '',
			isMandatory: true,
			isErr: false,
			tooltipInfo: {
				text:utils.m('monthlyIncome.items.netIncome.tooltipInfo.text', 'fields')
			}
		},
		annuity: {
			text: utils.m('monthlyIncome.items.annuity.label', 'fields'),
			value: '',
			isMandatory: true,
			isErr: false,
			tooltipInfo: {
				text:utils.m('monthlyIncome.items.annuity.tooltipInfo.text', 'fields')
			}
		},
		unemploymentAllowance: {
			text: utils.m('monthlyIncome.items.unemploymentAllowance.label', 'fields'),
			value: '',
			isMandatory: true,
			isErr: false,
			tooltipInfo: {
				text:utils.m('monthlyIncome.items.unemploymentAllowance.tooltipInfo.text', 'fields')
			}
		},
		insuranceBenefits: {
			text: utils.m('monthlyIncome.items.insuranceBenefits.label', 'fields'),
			value: '',
			isMandatory: true,
			isErr: false,
			tooltipInfo: {
				text:utils.m('monthlyIncome.items.insuranceBenefits.tooltipInfo.text', 'fields')
			}
		},
		additionalIncome: {
			text: utils.m('monthlyIncome.items.additionalIncome.label', 'fields'),
			value: '',
			isMandatory: false,
			isErr: false,
			tooltipInfo: {
				text:utils.m('monthlyIncome.items.additionalIncome.tooltipInfo.text', 'fields')
			}
		},
		parentalAllowance: {
			text: utils.m('monthlyIncome.items.parentalAllowance.label', 'fields'),
			value: '',
			isMandatory: false,
			isErr: false,
			tooltipInfo: {
				text:utils.m('monthlyIncome.items.parentalAllowance.tooltipInfo.text', 'fields')
			}
		}
	};

	const skeletonExtra = [
		{	
			text: utils.m('monthlyIncome.items.alimony.label', 'fields'),
			value: 'alimony',
			isMandatory: false,
			isErr: false,
			tooltipInfo: {
				text:utils.m('monthlyIncome.items.alimony.tooltipInfo.text', 'fields')
			}
		},
		{
			text: utils.m('monthlyIncome.items.rentalIncome.label', 'fields'),
			value: 'rentalIncome',
			isMandatory: false,
			isErr: false,
			tooltipInfo: {
				text:utils.m('monthlyIncome.items.rentalIncome.tooltipInfo.text', 'fields')
			}
		}
	];

	

	useEffect(() => {
		// Set the page Index for navigation
		actions.updatePage(utils.getPageIndex(state.path, state.currentFlow));

		// Set the default value on page load
		setState({
			...state
		});

		setBorrowers(reducers.monthlyIncome.borrowers);
		setExtraFields(reducers.monthlyIncome.extraFields);

		// Block access from user type in url directly
		utils.checkIsFromNav(history, reducers.app.isReload, state.currentFlow + C.ROUTES.NAME);

		// Set the first element on focus
		if(!reducers.app.appLoading) {
			// dayRef.current.focus();
		}
	}, []);

	useEffect(() => {
		const { provideSecondBorrower } = reducers;
	
		if(provideSecondBorrower.radioState === 1 ) {
			initializeBorrowers(1);
		}
		else {
			initializeBorrowers(2);
		}

	},[reducers.provideSecondBorrower.radioState]);


	const initializeBorrowers = times => {
		
		if(reducers.monthlyIncome.currentProvidedBorrowerOption !== reducers.provideSecondBorrower.radioState) {
			let tempBorrowers = [];
			for(let index = 0; index < times; index++) {
				const tempData = _.cloneDeep(skeletonData);
				tempBorrowers.push(tempData);
			}
			setBorrowers(tempBorrowers);
			setExtraFields(skeletonExtra);
			resetStoreInfo();
		}
	};

	const resetStoreInfo = () => {
		actions.onUpdateMonthlyIncome({
			borrowers:[],
			extraFields: skeletonExtra,
			currentProvidedBorrowerOption: -2
		});
	};

	const onFormSubmit = () => {

		googleEvents.continueValid();

		actions.onUpdateMonthlyIncome({
			borrowers,
			extraFields,
			currentProvidedBorrowerOption: reducers.provideSecondBorrower.radioState
		});
		actions.updateReload(false);

		utils.goToPage(history, state.currentFlow + C.ROUTES.MONTHLY_OUTGOINGS);
	};

	const hasEmptyFields = () => {
		if(borrowers.length === 1) {
			return (
				(utils.isEmpty(borrowers[0].netIncome.value) || parseFloat(borrowers[0].netIncome.value) <= 0) &&
					(utils.isEmpty(borrowers[0].annuity.value) || parseFloat(borrowers[0].annuity.value) <= 0 ) &&
					(utils.isEmpty(borrowers[0].unemploymentAllowance.value) || parseFloat(borrowers[0].unemploymentAllowance.value) <= 0 ) &&
					(utils.isEmpty(borrowers[0].insuranceBenefits.value) || parseFloat(utils.isEmpty(borrowers[0].insuranceBenefits.value) <= 0))

			);
		} else if (borrowers.length > 1) {
			return (
				(
					(utils.isEmpty(borrowers[0].netIncome.value) || parseFloat(borrowers[0].netIncome.value) <= 0) &&
					(utils.isEmpty(borrowers[0].annuity.value) || parseFloat(borrowers[0].annuity.value) <= 0 ) &&
					(utils.isEmpty(borrowers[0].unemploymentAllowance.value) || parseFloat(borrowers[0].unemploymentAllowance.value) <= 0 ) &&
					(utils.isEmpty(borrowers[0].insuranceBenefits.value) || utils.isEmpty(borrowers[0].insuranceBenefits.value)) &&
					
					(utils.isEmpty(borrowers[1].netIncome.value) || parseFloat(borrowers[1].netIncome.value) <= 0) &&
					(utils.isEmpty(borrowers[1].annuity.value) || parseFloat(borrowers[1].annuity.value) <= 0 ) &&
					(utils.isEmpty(borrowers[1].unemploymentAllowance.value) || parseFloat(borrowers[1].unemploymentAllowance.value) <= 0 ) &&
					(utils.isEmpty(borrowers[1].insuranceBenefits.value) || parseFloat(utils.isEmpty(borrowers[1].insuranceBenefits.value) <= 0))
				
				)
			);
		}
		return true;
	};

	const renderTable = () => {

		let labelList = [];
		const { name } = reducers;

		if(!utils.isEmpty(name.firstName) && borrowers.length === 1) {
			labelList = [];
			labelList.push(`${name.firstName} ${name.lastName}`);
		}

		if(utils.isEmpty(name.firstName) && borrowers.length === 1) {
			labelList = [];
			labelList.push(utils.m('monthlyIncome.secondaryColumn1.label', 'fields'));	
		}

		if(borrowers.length > 1) {
			labelList = [];
			labelList.push(utils.m('monthlyIncome.secondaryColumn1.label', 'fields'));
			labelList.push(utils.m('monthlyIncome.secondaryColumn2.label', 'fields'));
		}
		
		return borrowers.length > 0 && (
			<Col xs = { 12 }>
				<BorrowersTable 
					borrowers = { borrowers }
					setBorrowers = { setBorrowers }
					extraFields = { extraFields }
					setExtraFields = { setExtraFields }
					primaryColumnLabel = { utils.m('monthlyIncome.primaryColumn.label', 'fields') }
					secondaryColumnLabels = { labelList }
					buttonLabel = { utils.m('monthlyIncome.addRowButton.label', 'fields') }
				/>
			</Col>
			
		);
	};
	

	const reasonForLateTransferRef = useRef(null);
	const submitRef = useRef(null);

	return (
		<Page
			title={ utils.m('monthlyIncome.title', state.currentFlow) }
			subtitle={ utils.m('monthlyIncome.subtitle', state.currentFlow) }
			isShowError={ state.isShowError }
			errorMsg={ state.errorMsg }
			hasNextButton={ true }
			onBackButtonClick={ history.goBack }
			onFormSubmit={ onFormSubmit }
			nextButtonDisabled={ hasEmptyFields() }
			location={ location }
			currentFlow={ state.currentFlow }
			pageName="MonthlyIncome"
			currentStep={ C.PROGRESS_BAR.budgetAccounting }
			screen='D5'
			showButtonDivider={ false }
			submitRef={ submitRef }
			prevRef={ submitRef }
			nextRef={ reasonForLateTransferRef }
			gtmStep="userdata_geburtsdatum"
			gtmStatus="registration_pending"
			gtmSubStatus={ null }
			isMandatoryFields
			mandatoryLabel = '*Geben Sie mindestens eine Einnahme an.'
		>
			<Row justifyContent="center">
				{ renderTable() }
			</Row>
		</Page>
	);
};

MonthlyIncome.propTypes = {
	reducers: PropTypes.object,
	actions: PropTypes.object,
	history: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(_.merge({}, AppActions, EntryActions), dispatch)
});

const mapStateToProps = (state) => {
	return {
		reducers: state
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(MonthlyIncome);
