import * as React from 'react';

const IconExternalLink = props => (
	<svg width={ 10 } height={ 10 } viewBox="0 0 16 16" { ...props }>
		<defs>
			<path
				d="M390.908 412.71c-.014-.029-.039-.05-.052-.08a1.152 1.152 0 0 0-.196-.291c-.085-.085-.187-.14-.286-.192-.03-.015-.054-.042-.085-.056a1.143 1.143 0 0 0-.459-.091h-10.929a1.16 1.16 0 1 0 0 2.322h8.136l-11.697 11.695a1.163 1.163 0 0 0 .82 1.983c.296 0 .594-.114.82-.34l11.7-11.698v8.14a1.159 1.159 0 1 0 2.319 0v-10.933a1.143 1.143 0 0 0-.091-.459"
				id="icon-external-link_svg__a"
			/>
		</defs>
		<use
			fill="#9E3667"
			xlinkHref="#icon-external-link_svg__a"
			transform="translate(-375 -412)"
			fillRule="evenodd"
		/>
	</svg>
);

export default IconExternalLink;
