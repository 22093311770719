module.exports = {
	title: 'BestGiro',
	login: {
		title: 'Schön, dass Sie wieder da sind!',
		subtitle: 'Bitte melden Sie sich mit Ihrer bereits angegebenen E-Mail-Adresse an.'
	},
	name: {
		title: 'Wie heißen Sie?',
		subtitle: 'Bitte geben Sie alle in Ihrem Ausweisdokument geführten Namen an.',
		alreadyKnown: 'Kennen wir uns schon?',
		loginHere: 'Hier anmelden'
	},
	birthday: {
		title: 'Wie alt sind Sie?',
		subtitle: 'Für die Kontoeröffnung müssen Sie volljährig sein.'
	},
	email: {
		title: 'Wie lautet Ihre E-Mail-Adresse?',
		subtitle: 'Ihre E-Mail-Adresse wird benötigt, damit Sie Ihr BestGiro digital eröffnen können. Wir schicken Ihnen anschließend eine E-Mail, mit der Sie Ihren Antrag jederzeit fortführen können.'
	},
	emailSent: {
		title: 'Bitte bestätigen Sie Ihre E-Mail-Adresse.',
		subtitle: 'Wir haben Ihnen eine E-Mail geschickt. Bitte klicken Sie auf den darin enthaltenen Link, um Ihre E-Mail-Adresse zu bestätigen.'
	},
	emailSuccess: {
		title: 'Sie haben Ihre E-Mail-Adresse erfolgreich bestätigt.',
		subtitle: 'Bitte bestätigen Sie im nächsten Schritt Ihre Mobilfunknummer.'
	},
	emailError: {
		title: 'Ihre E-Mail-Adresse konnte nicht bestätigt werden.',
		subtitle: 'Bitte versuchen Sie es noch einmal.'
	},
	mobilePhone: {
		title: 'Wie lautet Ihre Mobilfunknummer?',
		subtitle: 'Für die Kontoeröffnung ist eine Mobilfunknummer erforderlich. Bitte geben Sie Ihre Nummer zusammen mit der Ländervorwahl ein.'
	},
	passcode: {
		title: 'Bitte bestätigen Sie Ihre Mobilfunknummer.',
		subtitle: 'Wir haben Ihnen eine SMS mit einem Bestätigungs-Code geschickt.'
	},
	nationality: {
		title: 'Welche Staatsangehörigkeit haben Sie?',
		subtitle: 'Diese Angabe wird für die Kontoeröffnung benötigt.'
	},
	address: {
		title: 'Wo wohnen Sie?',
		subtitle: 'Um das Konto zu eröﬀnen, benötigen Sie eine deutsche Wohnanschrift.'
	},
	previousAddress: {
		title: 'Wo haben Sie vorher gewohnt?',
		subtitle: 'Ihre Angabe wird für die SCHUFA-Auskunft benötigt.'
	},
	maritalStatus: {
		title: 'Wie ist Ihr Familienstand?',
		subtitle: 'Diese Angabe wird für die Bonitätsprüfung benötigt.'
	},
	occupation: {
		title: 'In welchem Beschäftigungs&shy;verhältnis stehen Sie?',
		subtitle: 'Diese Angabe wird für die Eröffnung des Kontos benötigt.'
	},
	fatca: {
		title: 'Sind Sie in den USA oder in einem anderen Land außerhalb von Deutschland steuerlich ansässig?',
		subtitle: 'Aus steuerrechtlichen Gründen müssen wir Ihnen leider langweilige Fragen wie diese stellen.'
	},
	purchase: {
		title: 'Rechtliches',
		subtitle: 'Jetzt geht es noch um das Kleingedruckte ...',
		acceptTermsAndConditions: {
			description: 'Ich habe die vorvertraglichen Informationen, die produktspezifischen Bedingungen und Datenschutzhinweise, die Werbehinweise, den Informationsbogen für den Einleger sowie die AGB und das Preisverzeichnis der Santander erhalten und lege diese meinem Antrag zugrunde.',
			linkText: 'Bedingungen und Informationen zu Santander Girokonten'
		},
		summary: 'Mit dem Anklicken des Buttons "Jetzt Konto eröffnen" beauftragen Sie Santander, für Sie ein Girokonto zu eröffnen und die mit dem Girokonto verbunden Services (z.B. Einrichtung des Zugangs zum Online Banking) einzurichten. Santander wird Ihren Antrag annehmen, wenn Sie sich vollständig legitimiert haben (Identitätsüberprüfung per VideoIdent, POSTIDENT oder einer Santander Filiale). Über die Annahme informiert Santander Sie gesondert in Textform.',
		submit: 'Jetzt Konto eröffnen'
	},
	thankYou: {
		title: 'Vielen Dank für Ihre Anfrage. Wir prüfen Ihren Antrag und melden uns bei Ihnen.'
	}
};
