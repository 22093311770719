module.exports = {
	title: 'Ratenplan ändern',
	login: {
		title: 'Schön, dass Sie wieder da sind!',
		subtitle:
			'Bitte melden Sie sich mit Ihrer bereits angegebenen E-Mail-Adresse an.'
	},
	name: {
		title: 'Wie heißen Sie?',
		subtitle: ''
	},
	birthday: {
		title: 'Wann sind Sie geboren?',
		subtitle: ''
	},
	creditCardNumber: {
		title: 'Wie lautet Ihre Kreditkartennummer?',
		subtitle: ''
	},

	financingNumber: {
		title: 'Wie lautet Ihre<br/>Finanzierungsnummer?',
		subtitle: '',
		tooltipText: 'Ihre Finanzierungsnummer finden Sie rechts oben auf unseren Anschreiben oder im Verwendungszweck Ihres letzten Rateneinzuges.'
	},
	contact: {
		title: 'Wie können wir Sie erreichen?',
		subtitle: 'Bitte geben Sie Rufnummern ohne Trenn- oder Leerzeichen an.'
	},

	paymentStatus: {
		title: 'Wie ist der Status Ihrer Zahlung?',
		subtitle:
			'Haben Sie bereits überwiesen oder können Sie dies kurzfristig tun?'
	},
	paymentStatusPaid: {
		title: 'Wann und welchen Betrag haben Sie bereits überwiesen?',
		subtitle: ''
	},
	paymentStatusWillPay: {
		title: 'Wann und welchen Betrag werden Sie überwiesen?',
		subtitle: ''
	},
	futurePaymentProblem: {
		title: 'Erwarten Sie zukünftig weitere Zahlungsprobleme?',
		subtitle: ''
	},
	paymentProblems: {
		title: 'Worin liegt der Grund Ihres Zahlungsproblems?',
		subtitle: ''
	},
	unemployment: {
		title: 'Seit wann sind Sie arbeitslos gemeldet?',
		subtitle: ''
	},
	newAddress: {
		title: 'Wie lautet Ihre neue Adresse?',
		subtitle: ''
	},
	shortEmployment: {
		title: 'Ab bzw. seit wann arbeiten Sie in Kurzarbeit?',
		subtitle: ''
	},
	inabilityOfWork: {
		title: 'Seit wann sind Sie arbeitsunfähig?',
		subtitle: ''
	},
	economicSituationChanged: {
		title: 'Hat sich Ihre wirtschaftliche Situation grundlegend geändert?',
		subtitle:
			'Haben Sie z.B. ein niedrigeres Gehalt, höhere Mietkosten, neue Finanzierungen, ...?'
	},
	provideSecondBorrower: {
		title: 'Sind Sie alleiniger Darlehensnehmer?',
		subtitle:''
	},
	monthlyIncome: {
		title: 'Wie hoch sind Ihre monatlichen Einnahmen?',
		subtitle: ''
	},
	monthlyOutgoings: {
		title: 'Wie hoch sind Ihre monatlichen Ausgaben?',
		subtitle: ''
	},
	solutions: {
		title1: 'Vielen Dank. Wir können Ihnen die Prüfung der folgenden Vertragsänderung anbieten',
		title2: 'Vielen Dank. Wir können Ihnen die Prüfung der folgenden Vertragsänderungen anbieten.',
		subtitle: 'Bitte wählen Sie die für Sie passende Maßnahme aus.'
	},
	defermentOfPayment: {
		title: 'Unsere angestrebte Lösung:<br/>Stundung',
		subtitle: 'Wie viele Raten sollen ausgesetzt werden? Bitte berücksichtigen Sie hierbei auch evtl. rückständige Raten.',
		nextButtonText: 'GEBÜHRENPFLICHTIG BEANTRAGEN'
	},
	email: {
		title: 'Wie lautet Ihre E-Mail-Adresse?',
		subtitle:
			'Ihre E-Mail-Adresse wird benötigt, damit Sie die 1<sup>plus</sup> Visa Card digital eröffnen können. Wir schicken Ihnen anschließend eine E-Mail, mit der Sie Ihren Antrag jederzeit fortführen können.'
	},
	emailSent: {
		title: 'Bitte bestätigen Sie Ihre E-Mail-Adresse.',
		subtitle:
			'Wir haben Ihnen eine E-Mail geschickt. Bitte klicken Sie auf den darin enthaltenen Link, um Ihre E-Mail-Adresse zu bestätigen.'
	},
	emailSuccess: {
		title: 'Sie haben Ihre E-Mail-Adresse erfolgreich bestätigt.',
		subtitle: 'Bitte bestätigen Sie im nächsten Schritt Ihre Mobilfunknummer.'
	},
	emailError: {
		title: 'Ihre E-Mail-Adresse konnte nicht bestätigt werden.',
		subtitle: 'Bitte versuchen Sie es noch einmal.'
	},
	mobilePhone: {
		title: 'Wie lautet Ihre Mobilfunknummer?',
		subtitle:
			'Für die Beantragung der Kreditkarte ist eine Mobilfunknummer erforderlich. Bitte geben Sie Ihre Nummer zusammen mit der Ländervorwahl ein.'
	},
	passcode: {
		title: 'Bitte bestätigen Sie Ihre Mobilfunknummer.',
		subtitle: 'Wir haben Ihnen eine SMS mit einem Bestätigungs-Code geschickt.'
	},
	nationality: {
		title: 'Welche Staatsangehörigkeit haben Sie?',
		subtitle: 'Diese Angabe wird für die Beantragung der Kreditkarte benötigt.'
	},
	address: {
		title: 'Wo wohnen Sie?',
		subtitle: 'Bitte nennen Sie uns ihre aktuelle Adresse.'
	},
	previousAddress: {
		title: 'Wo haben Sie vorher gewohnt?',
		subtitle: 'Ihre Angabe wird für die SCHUFA-Auskunft benötigt.'
	},
	maritalStatus: {
		title: 'Wie ist Ihr Familienstand?',
		subtitle: 'Diese Angabe wird für die Bonitätsprüfung benötigt.'
	},
	children: {
		title: 'Haben Sie Kinder?',
		subtitle:
			'Geben Sie an, wie viele Kinder in Ihrem Haushalt leben und wie viele davon kindergeldberechtigt sind.'
	},
	occupation: {
		title: 'In welchem Beschäftigungs&shy;verhältnis stehen Sie?',
		subtitle: 'Diese Angabe wird für die Beantragung der Kreditkarte benötigt.',
		sinceWhen: 'Und seit wann?'
	},
	giroAccount: {
		title: 'Wie lautet Ihre aktuelle Bankverbindung?',
		subtitle:
			'Geben Sie hier die IBAN Ihres Girokontos an, von dem die Zahlungen mit der Kreditkarte abgebucht werden sollen.'
	},
	financialStatus: {
		title: 'Was sind Ihre aktuellen Einnahmen und Ausgaben?',
		subtitle: 'Bitte geben Sie Ihre monatlichen Einkünfte und Wohnkosten an.'
	},
	purchase: {
		title: 'Rechtliches',
		subtitle: 'Jetzt geht es noch um das Kleingedruckte ...',
		acceptTermsAndConditions: {
			description:
				'Ich habe die vorvertraglichen Informationen, die produktspezifischen Bedingungen und Datenschutzhinweise, die Werbehinweise sowie die AGB und das Preisverzeichnis der Santander erhalten und lege diese meinem Antrag zugrunde.',
			linkText: 'Bedingungen und Informationen zu Santander Kreditkarten'
		},
		submit: 'Jetzt Kreditkarte beantragen'
	},
	thankYou: {
		title:
			'Vielen Dank für Ihre Anfrage. Wir prüfen Ihren Antrag und melden uns bei Ihnen.'
	},
	otherReason: {
		title:
			'Welche Umstände haben zu Ihrem Wunsch nach einer Veränderung bei der Kreditrückzahlung geführt?',
		subtitle:
			'Wir benötigen diese Angaben, um Ihre Anfrage schnell bearbeiten zu können und anschließend eine individuelle Lösung für Sie zu finden.'
	},
	numberOfChildren: {
		title: 'Mit wie vielen Personen leben Sie insgesamt in einem Haushalt?'
	},
	generalRateReduction: {
		title: 'Unsere angestrebte Lösung: Ratenreduzierung',
		subtitle: 'Welche Ratenhöhe können Sie maximal zahlen?',
		nextButtonText: 'GEBÜHRENPFLICHTIG BEANTRAGEN'
	},
	tempRateReduction: {
		title: 'Unsere angestrebte Lösung: Ratenreduzierung',
		subtitle: 'Welche Ratenhöhe können Sie maximal zahlen?',
		nextButtonText: 'GEBÜHRENPFLICHTIG BEANTRAGEN'
	},
	thankYouEnd: {
		title: 'test',
		options: {
			// without evidences
			'F51A': {
				title: 'Vielen Dank. Ihr Antrag ist bei uns eingegangen.',
				subtitle: 'Wir prüfen ihn und melden uns innerhalb der nächsten fünf Werktage bei Ihnen.',
				phoneNumber: '+49021616907320  ',
				displayNumber: '02161 – 690 7320'
			},
			// Without evidences
			// produkt=precollection_kfz
			'F51B': {
				title: 'Vielen Dank. Ihr Antrag ist bei uns eingegangen.',
				subtitle: 'Wir prüfen ihn und melden uns innerhalb der nächsten fünf Werktage bei Ihnen.',
				phoneNumber: '+49021616907977',
				displayNumber: '02161 – 690 7977'
			},
			// With evidences
			'F52A': {
				title: 'Vielen Dank. Ihr Antrag ist bei uns eingegangen.',
				subtitle: ' Für die abschließende Prüfung und Entscheidung benötigen wir zwingend noch folgenden Nachweis von Ihnen:',
				phoneNumber: '+49021616907320',
				displayNumber: '02161 – 690 7320',
				extra: {
					content1: 'Senden Sie uns diesen gerne per Fax an <a class = "link" href = "tel:021616907208" >02161 – 690 7208</a> oder per Post an:',
					content2: 'Santander Consumer Bank AG, Santander-Platz 1, D-41061 Mönchengladbach',
					content3: '*Bitte beachten Sie, dass es sich bei der Nutzung des Kommunikationsweges E-Mail um keine End-to-End verschlüsselte Verbindung handelt. Es besteht die Möglichkeit, dass E-Mails abgefangen werden und Ihre darin enthaltenen Daten missbräuchlich verwendet werden.'
				}
			},
			// With evidences
			// produkt=precollection_kfz
			'F52B': {
				title: 'Vielen Dank. Ihr Antrag ist bei uns eingegangen.',
				subtitle: 'Für die abschließende Prüfung und Entscheidung benötigen wir zwingend noch folgenden Nachweis von Ihnen:',
				phoneNumber: '+021616907977',
				displayNumber: '02161 – 690 7977',
				extra: {
					content1: 'Senden Sie uns diesen gerne per Fax an <a class = "link" href = "tel:021616907207">02161 – 690 7207</a> oder per Post an:',
					content2: 'Santander Consumer Bank AG, Santander-Platz 1, D-41061 Mönchengladbach',
					content3: '*Bitte beachten Sie, dass es sich bei der Nutzung des Kommunikationsweges E-Mail um keine End-to-End verschlüsselte Verbindung handelt. Es besteht die Möglichkeit, dass E-Mails abgefangen werden und Ihre darin enthaltenen Daten missbräuchlich verwendet werden.'
				}
			}
		}
	}
};
