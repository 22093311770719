/**
 * Component for the success message
 * 
 * Created by Moritz Kippenberg
 * Last modified by Moritz Kippenberg
 * Last modified date : 15-07-2019
 */

import React from 'react';
import PropTypes from 'prop-types';

import SuccessIcon from 'ui/svg/SuccessIcon';

import successMsgStyles from 'mod/SuccessMsg/SuccessMsg.scss';

const SuccessMsg = (props) => {
	return(
		<div
			data-testid="success-message"
			className={ successMsgStyles.successContainer }
		>
			<span dangerouslySetInnerHTML={ {__html: props.msg} } />
			<SuccessIcon />
		</div>
	);
};

SuccessMsg.propTypes = {
	msg: PropTypes.string
};

SuccessMsg.defaultProps = {
	msg: ''
};

export default SuccessMsg;
