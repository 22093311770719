import _ from 'lodash';

const defaultState = {
	paymentProblem: '',
	changeInEmploymentRelationship: '',
	oneOffSpecialExpenses: '',
	specialExpenses: '',
	extraInfo: '',
	extraInfo2: ''
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case 'UPDATE_PAYMENT_PROBLEMS':
			return _.merge({}, state, {
				paymentProblem: action.payload.paymentProblem,
				changeInEmploymentRelationship: action.payload.changeInEmploymentRelationship,
				oneOffSpecialExpenses: action.payload.oneOffSpecialExpenses,
				specialExpenses: action.payload.specialExpenses,
				extraInfo: action.payload.extraInfo,
				extraInfo2: action.payload.extraInfo2
			});
		
		default:
			return state;
	}
};

export default reducers;
