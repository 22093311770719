/**
 * Action Type
*/

const UPDATE_PROVIDE_SECOND_BORROWER = 'UPDATE_PROVIDE_SECOND_BORROWER';

/*
* Action Creators
*/

export const onUpdateSecondBorrower = payload => ({
	type: UPDATE_PROVIDE_SECOND_BORROWER,
	payload
});