import * as React from 'react';

const Info = props => (
	<svg width={ 24 } height={ 24 } viewBox="0 0 24 24" className = { props.className }>
		<defs>
			<path id="infoicon_a" d="M10.682 8.626a26.34 26.34 0 0 0-.116-1.17c-.01-.257-.197-.456-.427-.456h-.277c-.23 0-.417.2-.427.455a26.34 26.34 0 0 0-.116 1.17c-.014.22-.024.438-.032.662-.007.242-.011.49-.011.731v5.505c0 .263.192.477.427.477h.594c.236 0 .428-.214.428-.477v-5.505a22.382 22.382 0 0 0-.043-1.393M10 4.25a.75.75 0 1 0-.001 1.5.75.75 0 0 0 0-1.5m0 14.55c-4.851 0-8.798-3.948-8.798-8.8C1.2 5.148 5.148 1.2 10 1.2c4.853 0 8.8 3.947 8.8 8.8 0 4.852-3.947 8.8-8.8 8.8M10 0C4.478 0 0 4.478 0 10c0 5.523 4.478 10 10 10 5.523 0 10-4.477 10-10 0-5.522-4.477-10-10-10"/>
		</defs>
		<use 
			fill="#6F7779" 
			fillRule="evenodd" 
			transform="translate(2 2)" 
			xlinkHref="#infoicon_a"
		/>
	</svg>

);

export default Info;