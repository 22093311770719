const defaultState = {
	day: '',
	month: '',
	year: '',
	amountTransfered: '',
	reasonForLateTransfer: ''
};

const reducers = (state = defaultState, { 
	type, 
	payload: {
		day, 
		month, 
		year, 
		amountTransfered, 
		reasonForLateTransfer
	} = {}
}) => {
	switch (type) {
		case 'UPDATE_PAYMENT_STATUS_WILL_PAY':
			return { 
				...state,
				day,
				month,
				year,
				amountTransfered,
				reasonForLateTransfer
			};
		default:
			return state;
	}
};

export default reducers;
