const defaultState = {
	otherReason: ''
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case 'UPDATE_OTHER_REASON':
			return {
				...state,
				otherReason: action.payload.otherReason
			};
		default:
			return state;
	}
};
export default reducers;
