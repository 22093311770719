/**
 * F5: Thank you page
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Col } from 'mod/FlexGrid/index';
import Page from 'mod/Page/Page';
import DocumentCard from 'ui/Cards/DocumentCard/DocumentCard';

import _ from 'lodash';
import C from '../../constants';

import ServiceHotline from 'mod/Hints/ServiceHotline/ServiceHotline';

import * as AppActions from '../../store/App/actions';
import { thankYouEnd } from '../../messages/messages-cbu';
import * as utils from '../../utils/utils';
//import styles from './ThankyouEnd.scss';
const Thankyou = ({ reducers, actions, history, location }) => {

	const [state] = useState({
		path: C.ROUTES.THANKYOU_END,
		currentFlow: utils.getCurrentFlow(location)
	});

	const getCurrentTemplate = () => {
		const { options } = thankYouEnd;

		if (reducers.product.product === 'precollection_kfz') {
			if (reducers.solutions.records) {
				return options['F52B'];
			} else {
				return options['F51B'];
			}
		} else if (reducers.solutions.records) {
			return options['F52A'];
		}
		return options['F51A'];
	};

	const getFormattedTextDocument = (text1, text2) => `${text1}<br/><br/>${text2}`;

	const currentTemplate = getCurrentTemplate();

	useEffect(() => {
		actions.updateAppLoading(false);
		// Set the page Index for navigation
		actions.updatePage(utils.getPageIndex(state.path, state.currentFlow));

		// Block access from user type in url directly
		utils.checkIsFromNav(history, reducers.app.isReload, state.currentFlow + C.ROUTES.NAME);

	}, []);

	return(
		<Page
			title={ currentTemplate.title }
			subtitle={ currentTemplate.subtitle }
			hasNextButton={ false }
			location={ location }
			currentFlow={ state.currentFlow }
			pageName="ThankyouEnd"
			currentStep={ C.PROGRESS_BAR.solution }
			screen='F5'
			hideProgressBar={ false }
			gtmStep="thank_you_end"
			gtmStatus="registration_failed"
			prevButtonPath='hidden'
		>
			<Row justifyContent="center">
				{ (currentTemplate.extra && (!utils.isEmpty(reducers.solutions.records))) &&
					(
						<Col xs = { 12 }>
							<DocumentCard
								title = { utils.getRequiredDocumentText(reducers.solutions.records) }
								description = { getFormattedTextDocument(currentTemplate.extra.content1, currentTemplate.extra.content2) }
							/>
						</Col>
					)
				}

				<Col xs={ 12 }>
					<ServiceHotline
						location={ location }
						phoneNumber={ currentTemplate.phoneNumber }
						displayNumber={ currentTemplate.displayNumber }
					/>
				</Col>
			</Row>
		</Page>
	);
};

Thankyou.propTypes = {
	actions: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators( _.merge({}, AppActions), dispatch)
});

const mapStateToProps = (state) => {
	return{
		reducers: state
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Thankyou);
