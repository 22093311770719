/**
 * PaymentStatusPaid Page
 *
 * Created by Cristian Restituyo
 * Last modified by Moritz Kippenberg
 * Last modified date : 23-07-2019
 */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Page from 'mod/Page/Page';
import { Row, Col } from 'mod/FlexGrid/index';
import _ from 'lodash';
import C from '../../constants';
import moment from 'moment';

import * as AppActions from '../../store/App/actions';
import * as EntryActions from '../../store/PaymentStatusPaid/actions';
import * as utils from '../../utils/utils';

import DefaultInput from 'ui/inputs/DefaultInput/DefaultInput';
import * as googleEvents from '../../utils/googleEvents';
import * as regex from '../../utils/regex';

import styles from './PaymentStatusPaid.scss';

const PaymentStatusPaid = ({ reducers, actions, history, location }) => {

	const [state, setState] = useState({
		path: C.ROUTES.PAYMENT_STATUS_PAID,
		currentFlow: utils.getCurrentFlow(location),
		day: '',
		isDayErr: false,
		month: '',
		isMonthErr: false,
		year: '',
		isYearErr: false,
		errorMsg: '',
		isShowError: false,
		amountTransfered: '',
		amountTransferedErr: false,
		reasonForLateTransfer: '',
		reasonForLateTransferErr: false,
		isDateErr: false
	});

	useEffect(() => {
		// Set the page Index for navigation
		actions.updatePage(utils.getPageIndex(state.path, state.currentFlow));

		// Set the default value on page load
		setState({
			...state,
			day: reducers.paymentStatusPaid.day,
			month: reducers.paymentStatusPaid.month,
			year: reducers.paymentStatusPaid.year,
			amountTransfered: reducers.paymentStatusPaid.amountTransfered,
			reasonForLateTransfer: reducers.paymentStatusPaid.reasonForLateTransfer
		});

		// Block access from user type in url directly
		utils.checkIsFromNav(history, reducers.app.isReload, state.currentFlow + C.ROUTES.NAME);

		// Set the first element on focus
		if(!reducers.app.appLoading) {
			dayRef.current.focus();
		}
	}, []);

	const onFormSubmit = () => {

		/*** Validation ***/
		let isValid = true;
		let cloneState = _.cloneDeep(state);

		cloneState.errorMsg = '';

		// Define max days of month
		let maxDays = 31;

		if (!utils.isEmpty(state.month) && parseInt(state.month) > 0 && parseInt(state.month) <= 12) {

			let monthNumber = parseInt(state.month);

			if (monthNumber === 2) {
				maxDays = 29;
			}
			else if (
				monthNumber === 4 ||
				monthNumber === 6 ||
				monthNumber === 9 ||
				monthNumber === 11
			) {
				maxDays = 30;
			}
			else {
				maxDays = 31;
			}

		}

		if (parseInt(state.day) < 1 || state.day.length > 2 || parseInt(state.day) > maxDays) {
			isValid = false;
			utils.showError(cloneState, 'isDayErr', utils.m('day.validation.invalid', 'fields', { maxDays: maxDays }));
		}
		else {
			cloneState.isDayErr = false;
		}

		if (parseInt(state.month) < 1 || state.month.length > 2 || parseInt(state.month) > 12) {
			isValid = false;
			utils.showError(cloneState, 'isMonthErr', utils.m('month.validation.invalid', 'fields'));
		}
		else {
			cloneState.isMonthErr = false;
		}


		// let currentYear = moment().year();

		if (!utils.isNumber(state.year) || state.year.length !== 4) {
			isValid = false;
			utils.showError(cloneState, 'isYearErr', utils.m('year.validation.invalid', 'fields'));
		}
		else {
			cloneState.isYearErr = false;
		}

		if(!utils.isEmpty(state.day) && !utils.isEmpty(state.month) && !utils.isEmpty(state.year)){
			let currentDate = moment();
			let inputDate = moment(state.year + '-' + state.month + '-' + state.day, 'YYYY-MM-DD');

			if(inputDate.isAfter(currentDate)) {
				isValid = false;
				utils.showError(cloneState, 'isDateErr', utils.m('year.validation.paymentStatusNotInThePast', 'fields' ));
				cloneState.isYearErr = true;
			}
		}


		if(cloneState.isDayErr || cloneState.isMonthErr || cloneState.isYearErr) {
			cloneState.errorMsg = utils.m('date.validation.invalid', 'fields') + cloneState.errorMsg;
		}

		if (isValid) {

			// on validation success
			utils.hideError(state, setState);

			googleEvents.continueValid();

			// actions.onUpdateBirthday(state);
			actions.onUpdatePaymentStatusPaid(state);
			actions.updateReload(false);

			utils.goToPage(history, state.currentFlow + C.ROUTES.FUTURE_PAYMENT_PROBLEM);

		}
		else {
			// on validation failure
			googleEvents.continueInvalid();
			setState(cloneState);
		}

	};

	const hasEmptyFields = () => {
		return utils.isEmpty(state.day) ||
			utils.isEmpty(state.month) ||
			utils.isEmpty(state.year) ||
			utils.isEmpty(state.year) ||
			utils.isEmpty(state.amountTransfered) ||
			parseFloat(state.amountTransfered) <= 0 ||
			utils.isEmpty(state.reasonForLateTransfer);
			
	};

	const dayRef = useRef(null);
	const monthRef = useRef(null);
	const yearRef = useRef(null);
	const amountTransferedRef = useRef(null);
	const reasonForLateTransferRef = useRef(null);
	const submitRef = useRef(null);

	return (
		<Page
			title={ utils.m('paymentStatusPaid.title', state.currentFlow) }
			subtitle={ utils.m('paymentStatusPaid.subtitle', state.currentFlow) }
			isShowError={ state.isShowError }
			errorMsg={ state.errorMsg }
			hasNextButton={ true }
			onBackButtonClick = { history.goBack }
			onFormSubmit={ onFormSubmit }
			nextButtonDisabled={ hasEmptyFields() }
			location={ location }
			currentFlow={ state.currentFlow }
			pageName="PaymentStatusPaid"
			currentStep={ C.PROGRESS_BAR.statement }
			screen='B2'
			showButtonDivider={ false }
			submitRef={ submitRef }
			prevRef={ submitRef }
			nextRef={ reasonForLateTransferRef }
			gtmStep="userdata_geburtsdatum"
			gtmStatus="registration_pending"
			gtmSubStatus={ null }
		>
			<Row justifyContent="center">

				<Col xs={ 12 } sm={ 4 }>
					<DefaultInput
						inputOnChange={ (val) => {
							utils.onInputChange(setState, state, 'day', val);
						} }
						label={ utils.m('day.label', 'fields') }
						hasError={ state.isDayErr }
						init={ reducers.paymentStatusPaid.day }
						maxlength={ 2 }
						placeholder="TT"
						type="number"
						ref={ dayRef }
						prevRef={ hasEmptyFields() ? reasonForLateTransferRef : submitRef }
						nextRef={ monthRef }
						regex={ regex.digit() }
						jumpToNextOnMaxLength={ true }
						testId="day"
					/>
				</Col>

				<Col xs={ 12 } sm={ 4 }>
					<DefaultInput
						inputOnChange={ (val) => {
							utils.onInputChange(setState, state, 'month', val);
						} }
						label={ utils.m('month.label', 'fields') }
						hasError={ state.isMonthErr }
						init={ reducers.paymentStatusPaid.month }
						maxlength={ 2 }
						placeholder="MM"
						type="number"
						ref={ monthRef }
						prevRef={ dayRef }
						nextRef={ yearRef }
						regex={ regex.digit() }
						jumpToNextOnMaxLength={ true }
						testId="month"
					/>
				</Col>

				<Col xs={ 12 } sm={ 4 }>
					<DefaultInput
						inputOnChange={ (val) => {
							utils.onInputChange(setState, state, 'year', val);
						} }
						label={ utils.m('year.label', 'fields') }
						hasError={ state.isYearErr }
						init={ reducers.paymentStatusPaid.year }
						maxlength={ 4 }
						placeholder="JJJJ"
						type="number"
						ref={ yearRef }
						prevRef={ monthRef }
						nextRef={ amountTransferedRef }
						regex={ regex.digit() }
						testId="year"
					/>
				</Col>

				<Col xs = { 12 }>
					<DefaultInput
						inputOnChange={ (val) => {
							utils.onInputChange(setState, state, 'amountTransfered', val);
						} }
						label={ utils.m('amountTransfered.label', 'fields') }
						hasError={ state.amountTransferedErr }
						init={ state.amountTransfered }
						maxlength={ 8 }
						placeholder= { '' }
						type="number"
						ref={ amountTransferedRef }
						prevRef={ yearRef }
						nextRef={ reasonForLateTransferRef }
						testId="amount-transfered"
						regex = { regex.numberWithDotAndComma() }
						isCurrency
						originalValue = { state.amountTransfered }
					/>
				</Col>

				<Col xs = { 12 }>
					<DefaultInput
						inputOnChange={ (val) => {
							utils.onInputChange(setState, state, 'reasonForLateTransfer', val);
						} }
						label={ utils.m('reasonForLateTransfer.label', 'fields') }
						hasError={ state.reasonForLateTransferErr }
						init={ reducers.paymentStatusPaid.reasonForLateTransfer }
						maxlength={ 500 }
						placeholder=""
						type="textarea"
						ref={ reasonForLateTransferRef }
						prevRef={ amountTransferedRef }
						nextRef={ submitRef }
						customClass = { styles.reasonInput }
						testId="reason-late-transfer"
						rows = { 20 }
						cols = { 40 }
					/>
				</Col>

			</Row>
		</Page>
	);
};

PaymentStatusPaid.propTypes = {
	reducers: PropTypes.object,
	actions: PropTypes.object,
	history: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(_.merge({}, AppActions, EntryActions), dispatch)
});

const mapStateToProps = (state) => {
	return {
		reducers: state
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentStatusPaid);
