/**
 * DefermentOfPayment Page
 *
 * Created by Cristian Restituyo
 * Last modified by Cristian Restituyo
 * Last modified date : 23-07-2019
 */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Page from 'mod/Page/Page';
import { Row, Col } from 'mod/FlexGrid/index';
import _ from 'lodash';
import C from '../../constants';

import RadioGroupMulti from 'ui/inputs/RadioGroupMulti/RadioGroupMulti';

import * as AppActions from '../../store/App/actions';
import * as EntryActions from '../../store/DefermentOfPayment/actions';
import * as utils from '../../utils/utils';
import * as googleEvents from '../../utils/googleEvents';
import * as regex from '../../utils/regex';
import { sendDataToServer } from '../../utils/api';
import styles from './DefermentOfPayment.scss';
import IconExternalLink from 'ui/svg/IconExternalLink';

const DefermentOfPayment = ({ reducers, actions, history, location }) => {

	const [state, setState] = useState({
		path: C.ROUTES.DEFERMENT_OF_PAYMENT,
		currentFlow: utils.getCurrentFlow(location),
		radioState: -1,
		isRadioErr: false,
		selectedValue: ''
	});

	const [isDataUpdated, setIsDataUpdated] = useState(false);

	const options = [
		{
			label: utils.m('defermentOfPayment.option1.label', 'fields'),
			value: 'option1'
		},
		{
			label: utils.m('defermentOfPayment.option2.label', 'fields'),
			value: 'option2'
		},
		{
			label: utils.m('defermentOfPayment.option3.label', 'fields'),
			value: 'option3'
		}
	];

	useEffect(() => {
		// Set the page Index for navigation
		actions.updatePage(utils.getPageIndex(state.path, state.currentFlow));

		// Set the default value on page load
		setState({
			...state,
			radioState:reducers.defermentOfPayment.radioState,
			selectedValue: reducers.defermentOfPayment.selectedValue
		});

		// Block access from user type in url directly
		utils.checkIsFromNav(history, reducers.app.isReload, state.currentFlow + C.ROUTES.NAME);


	}, []);

	useEffect(()=> {
		if(isDataUpdated) {
			const request = async() => {
				const data = utils.getDataToSendToAPI({ reducers });
				const shouldGoToNext = await sendDataToServer(data, actions.updateButtonLoading, alert);

				if (shouldGoToNext) {
					utils.goToPage(history, state.currentFlow + C.ROUTES.THANKYOU_END);
				}
			};
			request();
		}
	}, [isDataUpdated]);


	const onFormSubmit = async () => {
		googleEvents.continueValid();

		actions.onUpdateDefermentOfPayment(state);
		actions.updateReload(false);

		setIsDataUpdated(true);
	};

	const hasEmptyFields = () => {
		return state.radioState === -1;		
	};


	const onRadioChange = (val) => {

		const cloneState = { ...state };
		
		cloneState.radioState = 0;
		cloneState.selectedValue = '';

		options.forEach((option, idx) => {
			if(option.value === val) {
				cloneState.radioState = idx;
				cloneState.selectedValue = option.value;
			}
		});

		setState(cloneState);
	};
	
	const submitRef = useRef(null);
	const radioRef = useRef(null);

	return(
		<Page
			title={ utils.m('defermentOfPayment.title', state.currentFlow) }
			subtitle={ utils.m('defermentOfPayment.subtitle', state.currentFlow) }
			isShowError={ state.isShowError }
			errorMsg={ state.errorMsg }
			hasNextButton={ true }
			onBackButtonClick = { history.goBack }
			onFormSubmit={ onFormSubmit }
			nextButtonDisabled={ hasEmptyFields() }
			location={ location }
			currentFlow={ state.currentFlow }
			pageName="DefermentOfPayment"
			currentStep={ C.PROGRESS_BAR.solution }
			screen='F2'
			showButtonDivider={ true }
			submitRef={ submitRef }
			prevRef={ radioRef }
			nextRef={ submitRef }
			gtmStep="userdata_paymentstatus"
			gtmStatus="registration_pending"
			gtmSubStatus={ null }
			nextButtonText = { utils.m('defermentOfPayment.nextButtonText', state.currentFlow) }
		>
			<Row justifyContent="center" customClass = { styles.defermentOfPayment }>
				<Col xs = { 12 }>
					<RadioGroupMulti
						radioOnChange={ (val) => { onRadioChange(val); } }
						radioState={ state.radioState }
						hasError={ state.isRadioErr }
						init={ '' }
						maxlength={ 150 }
						ref={ radioRef }
						prevRef={ hasEmptyFields() ? radioRef : submitRef }
						nextRef={ submitRef }
						regex={ regex.wordWithSpaceAndDashAndCommaAndDot() }
						testId="deferement-of-payment"
						options = { options }
					/>
				</Col>
				<Col xs = { 12 }>
					<span className = { styles.noteFee }>
						{utils.m('noteFee.label1', 'fields')}
						<a 
							rel='noopener noreferrer' 
							href = "http://santander.de/preise" 
							className = "link" 
							target="_blank"> Preis- und Leistungsverzeichnis <IconExternalLink /> 
						</a> {utils.m('noteFee.label2', 'fields')}
					</span>
				</Col>
			</Row>
		</Page>
	);
};

DefermentOfPayment.propTypes = {
	reducers: PropTypes.object,
	actions: PropTypes.object,
	history: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators( _.merge({}, AppActions, EntryActions), dispatch)
});

const mapStateToProps = (state) => {
	return{
		reducers: state
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(DefermentOfPayment);
