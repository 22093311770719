import React from 'react';
import PropTypes from 'prop-types';

import Talk from 'ui/svg/Talk';

import styles from './ServiceHotline.scss';
import * as utils from '../../../../utils/utils';
import C from '../../../../constants';

const ServiceHotline = ({ location, phoneNumber, displayNumber }) => {

	const getPhoneNumber = () => {
		if (phoneNumber) {
			return (<a className='link' href={ `tel:${ phoneNumber }` }>{ displayNumber }</a>);
		}
		switch (utils.getCurrentFlow(location)) {
			case C.FRONTEND.CBU:
				return (<a className='link' href="tel:+492161960120">02161 - 960 120</a>);
			default:
				return (<a className='link' href="tel:+4921619060599">02161 - 90 60 599</a>);
		}
	};

	const content1 = location.pathname.includes('end') ? 
		utils.m('serviceHotline.content3', 'fields') :
		utils.m('serviceHotline.content1', 'fields');
	
	const content2 = utils.m('serviceHotline.content2', 'fields');

	return(
		<div className={ styles.serviceHotlineWrapper }>
			<Talk />
			<p className="copyText">
				{ content1 } { getPhoneNumber() } { content2 }
			</p>
		</div>
	);

};

ServiceHotline.propTypes = {
	location: PropTypes.object
};

export default ServiceHotline;
