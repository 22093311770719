import _ from 'lodash';

const defaultState = {
	creditCardNumber: '',
	acceptPrivacyPolicies: false
};

const reducers = (state = defaultState, action) => {
	switch(action.type) {
		case 'UPDATE_CREDIT_CARD_NUMBER':
			return _.merge({}, state, {
				creditCardNumber: action.payload.creditCardNumber,
				acceptPrivacyPolicies: action.payload.acceptPrivacyPolicies
			});
		default:
			return state;
	}
};
export default reducers;