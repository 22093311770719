import _ from 'lodash';

const defaultState = {
	state: '',
	postcode: '',
	city: '',
	street: '',
	number: '',
	day: '',
	month: '',
	year: ''
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case 'UPDATE_NEW_ADDRESS':
			return _.merge({}, state, {
				state: action.payload.state,
				postcode: action.payload.postcode,
				city: action.payload.city,
				street: action.payload.street,
				number: action.payload.number,
				day: action.payload.day,
				month: action.payload.month,
				year: action.payload.year
			});
		default:
			return state;
	}
};

export default reducers;