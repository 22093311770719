/**
 * Index component for the app, setup the store here
 * 
 * Created by Simon Orzel
 * Last modified by Perry Tang
 * Last modified date : 20-5-2019
 */
 
import '@babel/polyfill';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import store from './store';

import App from './App';
import DebugGrid from 'mod/FlexGrid/DebugGrid/DebugGrid';
import './sass/styles/font.scss';
import './sass/styles/index.global.scss';
import './sass/styles/app.global.scss';
import 'ui/text/headlines.global.scss';
import 'ui/text/text.global.scss';
import 'ui/text/links.global.scss';

const routes = (
	<BrowserRouter basename="/">
		<Route
			path="/"
			component={ App }/>
		{ process.env.NODE_ENV === 'development' ? (<DebugGrid />) : null }
	</BrowserRouter>
);

ReactDOM.render(
	<Provider store={ store }>
		{ routes }
	</Provider>,
	document.getElementById('root'));


