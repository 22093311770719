/**
 * 404 Error Page
 *
 * Created by Moritz Kippenberg
 * Last modified by Moritz Kippenberg
 * Last modified date : 23-07-2019
 */

import React from 'react';
import { Container, Row, Col } from 'mod/FlexGrid/index';
import PageTitle from 'mod/PageTitle/PageTitle';
import Header from 'mod/Header/Header';

import ServiceHotline from 'mod/Hints/ServiceHotline/ServiceHotline';

import styles from './Error.scss';
import * as utils from '../../utils/utils';

const Page404 = (props) => {

	return (
		<div data-testid="Error404">
			<Header
				location={ props.location }
			/>

			<Container>
				<Row justifyContent="center">
					<Col md={ 10 } lg={ 8 } xl={ 6 }>

						<div className={ styles.headerSpacer }/>

						<PageTitle
							title={ utils.m('error.404', 'fields') }
						/>

						<Row justifyContent="center">
							<Col xs={ 12 }>
								<ServiceHotline
									location={ props.location }
								/>
							</Col>
						</Row>

					</Col>

				</Row>
			</Container>
		</div>
	);
};

export default Page404;
