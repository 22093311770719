/**
 * Common functions share by the whole app
 *
 * Created by Perry Tang
 * Last modified by Moritz Kippenberg
 * Last modified date : 23-07-2019
 */

import React from 'react';
import _ from 'lodash';
import moment from 'moment/moment';
import C from '../constants';
import * as regex from './regex';

import { routes } from '../routes';
import cbuRoutes from '../routes/cbu/cbu';

import * as messagesGiro from '../messages/messages-giro';
import * as messagesGmk from '../messages/messages-gmk';
import * as messagesCbu from '../messages/messages-cbu';
import * as messagesFields from '../messages/messages-fields';
import currency from 'currency.js';
// import * as messagesPersonaldata from '../messages/messages-personaldata';

const monthNames = [
	'Januar', 'Februar', 'März',
	'April', 'Mai', 'Juni', 'Juli',
	'August', 'September', 'Oktober',
	'November', 'Dezember'
];

export const getCurrentFlow = (location) => {
	let pathArray = location.pathname.split('/');
	if(('/' + pathArray[1]) === C.FRONTEND.CBU) {
		return C.FRONTEND.CBU;
	}
	else if(('/' + pathArray[1]) === C.FRONTEND.GMK) {
		return C.FRONTEND.GMK;
	}
	else {
		return C.FRONTEND.CBU;
	}
};

export const getProductType = (currentFlow) => {
	switch (currentFlow) {
		case C.FRONTEND.CBU:
			return C.PRODUCT_TYPE.CREDIT_CARD;
		case C.FRONTEND.GMK:
			return C.PRODUCT_TYPE.GMK;
		case C.FRONTEND.GIRO:
			return C.PRODUCT_TYPE.GIRO;
		default:
			return C.PRODUCT_TYPE.CREDIT_CARD;
	}
};

export const getFlowRoutes = (currentFlow) => {
	switch (currentFlow) {
		case C.FRONTEND.CBU:
			return cbuRoutes;
		default:
			return routes;
	}
};

export const pushPageByFlowAndIndex = (history, currentFlow, index) => {
	switch (currentFlow) {
		case C.FRONTEND.CBU:
			history.push(cbuRoutes[index].path);
			break;
		default:
			return null;
	}
};

export const goPrevPage = (reducers, history) => {

	let currentFlow = getCurrentFlow(history.location);

	let page = reducers.pageIndex;
	let min = 0;

	if (page > min) {
		page--;
		pushPageByFlowAndIndex(history, currentFlow, page);
	}

};

export const goNextPage = (reducers, history) => {

	let currentFlow = getCurrentFlow(history.location);
	let length = getFlowRoutes(currentFlow).length;

	let page = reducers.pageIndex;
	let max = length - 1;

	if (page < max) {
		page++;
		pushPageByFlowAndIndex(history, currentFlow, page);
	}

};

export const goToPage = (history, route, queryString) => {
	history.push(route + (queryString ? queryString : ''), { isFromNav: true });
};

export const checkIsFromNav = (history, isReload, destination) => {
	if(process.env.NODE_ENV !== 'development' && ((history && typeof history.location.state === 'undefined') || isReload)) {
		history.push(destination);
	}
};

export const onInputChange = (setState, state, key, val) => {
	setState({
		...state,
		[key]: val
	});
};

export const showError = (state, key, error) => {
	state[key] = true;
	state.isShowError = true;
	state.errorMsg += error;
};

export const showErrorDirectly = (state, setState, key, error) => {
	setState({
		...state,
		[key]: true,
		isShowError: true,
		errorMsg: error
	});
};

export const hideError = (state, setState) => {
	setState({
		...state,
		isShowError: false,
		errorMsg: ''
	});
};

export const showSuccess = (state, key, success) => {
	state[key] = true;
	state.isShowSuccess = true;
	state.successMsg += success;
};

export const showSuccessDirectly = (state, setState, key, success) => {
	setState({
		...state,
		[key]: true,
		isShowSuccess: true,
		successMsg: success
	});
};

export const hideSuccess = (state, setState) => {
	setState({
		...state,
		isShowSuccess: false,
		successMsg: ''
	});
};

// Check if the email is fulfull x@x.xx
export const isEmail = (val) => {
	return regex.email().test(val);
};

// Check if the value is number, except 0
export const isNumber = (val) => {
	return regex.numberWithoutZero().test(val);
};

// Check if the value is number, include 0
export const isNumberWithZero = (val) => {
	return regex.digit().test(val);
};

// Check if the value is a date format
export const occupationDateFormat = (val) => {
	return regex.occupationDateFormat().test(val);
};

// Check if the value is word, enable spacing and german character
export const isWordWithSpace = (val) => {
	return regex.wordWithSpace().test(val);
};

// Check if the value is word, enable spacing and german character and dashes
export const isWordWithSpaceAndDash = (val) => {
	return regex.wordWithSpaceAndDash().test(val);
};

// Check if the value is word, enable spacing and german character and numbers from 0-9
export const isWordWithSpaceAndDashAndNumbers = (val) => {
	return regex.wordWithSpaceAndDashAndNumbers().test(val);
};

// Check if the value is word, enable spacing and german character, dashes and commas
export const isWordWithSpaceAndDashAndComma = (val) => {
	return regex.wordWithSpaceAndDashAndComma().test(val);
};

// Check if the value is word, without spacing and german character
export const isWordWithoutSpace = (val) => {
	return regex.wordWithoutSpace().test(val);
};

// Check if a string is blank or contains only white-space
export const isEmpty = (val) => {
	return (!val || (val && val.length < 1));
};

// Check if IBAN valid
export const isIbanValid = (val) => {
	let countrycodelengths = {
		'AL' : 28, 'AD' : 24, 'AT' : 20, 'AZ' : 28,
		'BE' : 16, 'BH' : 22, 'BA' : 20, 'BR' : 29,
		'BG' : 22, 'CR' : 21, 'HR' : 21, 'CY' : 28,
		'CZ' : 24, 'DK' : 18, 'DO' : 28, 'EE' : 20,
		'FO' : 18, 'FI' : 18, 'FR' : 27, 'GE' : 22,
		'DE' : 22, 'GI' : 23, 'GR' : 27, 'GL' : 18,
		'GT' : 28, 'HU' : 28, 'IS' : 26, 'IE' : 22,
		'IL' : 23, 'IT' : 27, 'KZ' : 20, 'KW' : 30,
		'LV' : 21, 'LB' : 28, 'LI' : 21, 'LT' : 20,
		'LU' : 20, 'MK' : 19, 'MT' : 31, 'MR' : 27,
		'MU' : 30, 'MC' : 27, 'MD' : 24, 'ME' : 22,
		'NL' : 18, 'NO' : 15, 'PK' : 24, 'PS' : 29,
		'PL' : 28, 'PT' : 25, 'RO' : 24, 'SM' : 27,
		'SA' : 24, 'RS' : 22, 'SK' : 24, 'SI' : 19,
		'ES' : 24, 'SE' : 24, 'CH' : 21, 'TN' : 24,
		'TR' : 26, 'AE' : 23, 'GB' : 22, 'VG' : 24
	};

	let iban = String(val).toUpperCase().replace(/[^A-Z0-9]/g, ''), // keep only alphanumeric characters
		code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/), // match and capture (1) the country code, (2) the check digits, and (3) the rest
		digits;

	// check syntax and length
	if (!code || iban.length !== countrycodelengths[code[1]]) {
		return false;
	}

	// rearrange country code and check digits, and convert chars to ints
	digits = (code[3] + code[1] + code[2]).replace(/[A-Z]/g, (letter) => {
		return letter.charCodeAt(0) - 55;
	});

	// final check
	return mod97(digits);
};

const mod97 = (string) => {
	let checksum = string.slice(0, 2), fragment;
	for (let offset = 2; offset < string.length; offset += 7) {
		fragment = String(checksum) + string.substring(offset, offset + 7);
		checksum = parseInt(fragment, 10) % 97;
	}
	return checksum;
};

// Get the german name of a month by index
export const getMonthNameByIndex = (index) => {
	return monthNames.indexOf(index) + 1;
};

// Remove all chars except digits and the leading zero
export const formatPhoneForApi = (phoneString) => {
	return parseInt(phoneString.replace(/[^0-9]/, ''), 10) + '';
};

// Get date in the format yyyy-mm-dd
export const getDateForApi = (day, month, year) => {

	let formattedDay = day;
	if(formattedDay.length === 1) {
		formattedDay = '0' + formattedDay;
	}

	let formattedMonth = month;
	if(formattedMonth.length === 1) {
		formattedMonth = '0' + formattedMonth;
	}

	return year + '-' + formattedMonth + '-' + formattedDay;

};

// Split date in day, month and year
export const getDateFromApi = (dateString) => {

	let dateArray = dateString.trim().split('-');

	return {
		day: dateArray[2],
		month: dateArray[1],
		year: dateArray[0]
	};

};

// Calculate the last day of a month
const getLastDay = (year, month) => {
	let int_d = new Date( parseInt(year), parseInt(month), 1);
	let d = new Date(int_d - 1);
	return d.getDate();
};

// Calculate the occupation start day
export const getOccupationDay = (year, month) => {
	return (year !== '' && month !== '')
		? year + '-' + month.padStart(2, 0) + '-01'
		: '';
};

// Calculate the occupation start day
export const getOccupationLimitedDay = (year, month) => {
	return (year !== '' && month !== '')
		? year + '-' + month.padStart(2, 0) + '-' + getLastDay(year, month)
		: '';
};


// Check if the age is over 18
export const checkAge18 = (year, month, day) => {
	let birthday = moment(year + '-' + month + '-' + day, 'YYYY-MM-DD');
	let years = moment().diff(birthday, 'years');
	return years >= 18;
};

// Check if an value is contained in an Object array formatted like [{ value: '...' }, ...]
export const isValueInArray = (value, items) => {
	const index = _.findIndex(items, function (o) {
		return o.value === value;
	});
	return index >= 0;
};

// Get The index of a page by the path of the route
export const getPageIndex = (path, currentFlow) => {

	let routes = getFlowRoutes(currentFlow);

	const index = _.findIndex(routes, function (o) {
		return o.path === currentFlow + path;
	});

	return index >= 0 ? index : 0;
};

/**
 * Converts a message path string to an object property selector and
 * searchs the message from the flow based message file. If the path is
 * not available for credit card, giro is the fallback. If the path is
 * not available anywhere the original message path string is returned.
 *
 * @param messagePath
 * @param currentFlow
 * @param replacementObject
 * @param convertToJsx
 * @returns string
 */
export const m = (messagePath, currentFlow, replacementObject, convertToJsx) => {

	let message = messagePath;

	try {

		if(currentFlow === C.FRONTEND.CBU) {

			let tempMessage = convertStringToObjectPath(messagePath, messagesCbu);

			if(tempMessage !== undefined && typeof tempMessage === 'string') {
				message = replaceSubstrings(tempMessage, replacementObject);
			}
			else {
				let tempMessage = convertStringToObjectPath(messagePath, messagesGiro);
				message = tempMessage !== undefined && typeof tempMessage === 'string' ? replaceSubstrings(tempMessage, replacementObject) : messagePath;
			}

		}
		else if(currentFlow === C.FRONTEND.GMK) {

			let tempMessage = convertStringToObjectPath(messagePath, messagesGmk);

			if(tempMessage !== undefined && typeof tempMessage === 'string') {
				message = replaceSubstrings(tempMessage, replacementObject);
			}
			else {
				let tempMessage = convertStringToObjectPath(messagePath, messagesGiro);
				message = tempMessage !== undefined && typeof tempMessage === 'string' ? replaceSubstrings(tempMessage, replacementObject) : messagePath;
			}

		}
		else if(currentFlow === C.FRONTEND.GIRO) {
			let tempMessage = convertStringToObjectPath(messagePath, messagesGiro);
			message = tempMessage !== undefined && typeof tempMessage === 'string' ? replaceSubstrings(tempMessage, replacementObject) : messagePath;
		}

		else {
			let tempMessage = convertStringToObjectPath(messagePath, messagesFields);
			message = tempMessage !== undefined && typeof tempMessage === 'string' ? replaceSubstrings(tempMessage, replacementObject) : messagePath;
		}

	}
	catch(e) {
		try {
			if(currentFlow === C.FRONTEND.CBU || currentFlow === C.FRONTEND.GMK) {
				let tempMessage = convertStringToObjectPath(messagePath, messagesGiro);
				message = tempMessage !== undefined && typeof tempMessage === 'string' ? replaceSubstrings(tempMessage, replacementObject) : messagePath;
			}
		}
		catch(e) {
			console.log(e);
		}
	}

	if(convertToJsx !== undefined && convertToJsx === true) {
		return (<span dangerouslySetInnerHTML={ { __html: message } } />);
	}
	else {
		return message;
	}

};

const convertStringToObjectPath = (path, obj) => {
	return path.split('.').reduce(index, obj);
};

const index = (obj, i) => {
	return obj[i];
};

/**
 * Replaces the placeholders from the message ({name}) with the substrings
 * from the replacementObject ({ 'name': 'replacement', ... })
 *
 * @param message
 * @param replacementObject
 * @returns string
 */
const replaceSubstrings = (message, replacementObject) => {

	let replacedMessage = message;

	if(replacementObject !== undefined && typeof replacementObject === 'object') {

		let key;

		for (key in replacementObject) {

			try {

				if(replacedMessage.includes('{' + key + '}') && (typeof replacementObject[key] === 'string' || typeof replacementObject[key] === 'number')) {
					replacedMessage = replacedMessage.replace('{' + key + '}', replacementObject[key]);
				}

			}
			catch(e) {
				console.log(e);
			}

		}

	}

	return replacedMessage;

};

export const htmlStripTags = (htmlString) => {
	let div = document.createElement('div');
	div.innerHTML = htmlString;
	return div.innerText;
};

export const removeBreaks = (string) => {
	return replaceAll(string, '<br/>', '');
};

export const replaceAll = (string, search, replacement) => {
	return string.split(search).join(replacement);
};
/**
 * Returns the number as an string with prepended leading zeros
 * depending the number lacks based on the max amount given.
 * @param numStr
 * @param max
 * @returns string
 */
export const appendLeadingZeros = (numStr, max) => {
	const numOfZeros = max - numStr.length;
	let numStrWithZeros = numStr;
	for(let i = 0; i < numOfZeros; i++) {
		numStrWithZeros = '0' + numStrWithZeros;
	}
	return numStrWithZeros;
};
export const removeLeadingZero = numStr => {
	return numStr[0] === '0' ? numStr.slice(1) : numStr;
};
export const formatCreditCard = creditCardStr => {
	return creditCardStr
		.replace(/\s+/g,'')
		.split('')
		.map((digit, index) => {
			return (index + 1) % 4 === 0 ? (digit + ' ') : digit;
		})
		.join('')
		.trimRight();
};
export const cleanCurrency = numStr => {
	return isNaN(numStr)
		? (numStr.length > 1 ? numStr : numStr.slice(0, numStr.length - 1) )
		: numStr;
};
/**
 * Returns the currency value in a string
 * @param numStr
 * @returns string
 */
export const getCurrencyValue = (numStr) => {
	const euro = value => currency(value, { separator: '.', decimal: ',', pattern : '# !', symbol: '€'});
	return euro(numStr).format(true);
};
/**
 * Removes the currency sign from a given string
 * @param numStr
 * @returns string
 */
export const removeCurrencySign = (numStr) => {
	return numStr.trim().replace('€', '');
};

// GetAvaiableSolutions

export const updateAvailableSolutions = (reducers, availableOptions, setRecords, setAvailableOptions, setDefaultRadius) => {
	const { paymentProblem, changeInEmploymentRelationship, specialExpenses } = reducers.paymentProblems;
	const {
		PARENTAL_LEAVE,
		RETIREMENT_AGE,
		DIVORCE,
		EXCESSIVE_DEBT
	} = C.PAYMENT_PROBLEMS_OPTIONS;

	const {
		UNEMPLOYED_WITH_FUTURE_EMPLOYMENT,
		UNEMPLOYED_WITHOUT_FUTURE_EMPLOYMENT,
		TEMPORARY_SHORT_TIME_WORK,
		INCOME_REDUCTION,
		INABILITY_TO_WORK

	} = C.CHANGE_IN_EMPLOYMENT_RELATIONSHIP_OPTIONS;

	const {
		ACCIDENT_COSTS,
		ILLNESS_DEATH,
		SUBSEQUENT_PAYMENT,
		PARADE
	} = C.ONE_OFF_SPECIAL_EXPENSES_OPTIONS;

	if(!isEmpty(changeInEmploymentRelationship)) {
		let tempOptions = _.cloneDeep(availableOptions);
		const { OTHER } = C.CHANGE_IN_EMPLOYMENT_RELATIONSHIP_OPTIONS;
		const { solutions : { originalOptions } } = reducers;
		const tempAllOptions = _.cloneDeep(originalOptions);

		switch(changeInEmploymentRelationship) {
			case UNEMPLOYED_WITH_FUTURE_EMPLOYMENT:
				tempOptions = tempOptions.filter(option => option.value !== 'option3');
				setRecords(C.RECORDS.NEW_EMPLOYMENT_CONTRACT);
				setAvailableOptions(tempOptions);
				break;
			case UNEMPLOYED_WITHOUT_FUTURE_EMPLOYMENT:
				tempOptions = tempOptions.filter(option => option.value !== 'option1' && option.value !== 'option3' );
				// tempOptions = tempOptions.filter(option => option.value !== 'option2' );
				setRecords(C.RECORDS.UNEMPLOYMENT_BENEFIT_NOTE);
				setAvailableOptions(tempOptions);
				// setDefaultRadius('option2');
				break;
			case TEMPORARY_SHORT_TIME_WORK:
				tempOptions = tempOptions.filter(option => option.value !== 'option1' && option && option.value !== 'option3');
				setRecords(C.RECORDS.PROOF_OF_SHORT_TIME_WORK);
				setAvailableOptions(tempOptions);
				break;
			case INCOME_REDUCTION:
				tempOptions = tempOptions.filter(option => option.value !== 'option1');
				setRecords(C.RECORDS.EVIDENCE_INCOME_REDUCTION);
				setAvailableOptions(tempOptions);
				// setDefaultRadius('option2');
				break;
			case INABILITY_TO_WORK:
				tempOptions = tempOptions.filter(option => option.value !== 'option3');
				setRecords(C.RECORDS.NOTICE_OF_SICK_PAY);
				setAvailableOptions(tempOptions);
				// setDefaultRadius('option1');
				break;
			case OTHER:
				setAvailableOptions(tempAllOptions);
				break;
			default:
				break;
		}
	} else if(!isEmpty(specialExpenses)) {
		let tempOptions = _.cloneDeep(availableOptions);
		const { OTHER } = C.ONE_OFF_SPECIAL_EXPENSES_OPTIONS;
		switch(specialExpenses) {
			case ACCIDENT_COSTS:
				tempOptions = tempOptions.filter(option => option.value !== 'option2' && option.value !== 'option3');
				setAvailableOptions(tempOptions);
				setDefaultRadius('option1');
				break;
			case ILLNESS_DEATH:
				tempOptions = tempOptions.filter(option => option.value !== 'option2' && option.value !== 'option3');
				setAvailableOptions(tempOptions);
				setDefaultRadius('option1');
				break;
			case SUBSEQUENT_PAYMENT:
				tempOptions = tempOptions.filter(option => option.value !== 'option2' && option.value !== 'option3');
				setAvailableOptions(tempOptions);
				setDefaultRadius('option1');
				break;
			case PARADE:
				tempOptions = tempOptions.filter(option => option.value !== 'option2' && option.value !== 'option3');
				setAvailableOptions(tempOptions);
				setDefaultRadius('option1');
				break;
			case OTHER:
				tempOptions = tempOptions.filter(option => option.value !== 'option2' && option.value !== 'option3');
				setAvailableOptions(tempOptions);
				setDefaultRadius('option1');
				break;
			default:
				break;
		}
	} else if(!isEmpty(paymentProblem)) {
		const { OTHER } = C.PAYMENT_PROBLEMS_OPTIONS;
		let tempOptions = _.cloneDeep(availableOptions);
		const { solutions : { originalOptions } } = reducers;
		const tempAllOptions = _.cloneDeep(originalOptions);
		switch(paymentProblem) {
			case PARENTAL_LEAVE:
				tempOptions = tempOptions.filter(option => option.value !== 'option3');
				setRecords(C.RECORDS.PARENTAL_ALLOWANCE_NOTICE);
				setAvailableOptions(tempOptions);
				break;
			case RETIREMENT_AGE:
				tempOptions = tempOptions.filter(option => option.value !== 'option2');
				setRecords(C.RECORDS.NOTICE_OF_RETIREMENT);
				setAvailableOptions(tempOptions);
				break;
			case DIVORCE:
				setAvailableOptions(tempOptions);
				break;
			case EXCESSIVE_DEBT:
				tempOptions = tempOptions.filter(option => option.value !== 'option1');
				setAvailableOptions(tempOptions);
				break;
			case OTHER:
				setAvailableOptions(tempAllOptions);
				break;
			default:
				break;
		}
	}
};

export const getRequiredDocumentText = val => {
	switch(val){
		case C.RECORDS.NEW_EMPLOYMENT_CONTRACT:
			return m('solutions.records.newEmploymentContract.label','fields');
		case C.RECORDS.UNEMPLOYMENT_BENEFIT_NOTE:
			return m('solutions.records.unemploymentBenefitNotice.label','fields');
		case C.RECORDS.NOTICE_OF_SICK_PAY:
			return m('solutions.records.noticeOfSickPay.label','fields');
		case C.RECORDS.PROOF_OF_SHORT_TIME_WORK:
			return m('solutions.records.proofOfShortimeWork.label','fields');
		case C.RECORDS.EVIDENCE_INCOME_REDUCTION:
			return m('solutions.records.evidenceIncomeReduction.label','fields');
		case C.RECORDS.PARENTAL_ALLOWANCE_NOTICE:
			return m('solutions.records.parentalAllowanceNotice.label','fields');
		case C.RECORDS.NOTICE_OF_RETIREMENT:
			return m('solutions.records.noticeOfRetirement.label','fields');
		default:
			return '';
	}
};

const formatDataDigitForAPI = val => {
	if(val.trim().length < 2 ) {
		return `0${val.trim()}`;
	}
	return val;
};
const getFormattedDateForAPI = (day,month, year) => {
	return year ? `${year}-${formatDataDigitForAPI(month)}-${formatDataDigitForAPI(day)}` : null ;
};

const getFlowDataForAPI = (queryParams, value1, value2) => {
	return queryParams.product ?
		(queryParams.product === 'limit' || queryParams.product === 'creditcard' ? value1.trim().replace(/\s+/g, '') : value2.trim().replace(/\s+/g, ''))
		: value2.trim().replace(/\s+/g, '');
};

const getFormattedPhoneForAPI = (code, phone) => {
	return isEmpty(phone) ? null : `${code}${phone}`;
};

const getFormattedStreetForAPI = (street, number) => {
	return isEmpty(street) ? null : `${street} ${number}`;
};

const getReasoningDetails = (changeInEmployment, specialExpenses, reducers) => {
	let reasoningDetail = {
		text: null,
		height: null,
		detail: null
	};


	const {
		UNEMPLOYED_WITH_FUTURE_EMPLOYMENT,
		UNEMPLOYED_WITHOUT_FUTURE_EMPLOYMENT,
		TEMPORARY_SHORT_TIME_WORK,
		INCOME_REDUCTION,
		INABILITY_TO_WORK

	} = C.CHANGE_IN_EMPLOYMENT_RELATIONSHIP_OPTIONS;

	const {
		ACCIDENT_COSTS,
		ILLNESS_DEATH,
		SUBSEQUENT_PAYMENT,
		PARADE
	} = C.ONE_OFF_SPECIAL_EXPENSES_OPTIONS;

	const {
		PARENTAL_LEAVE,
		RETIREMENT_AGE,
		DIVORCE,
		EXCESSIVE_DEBT
	} = C.PAYMENT_PROBLEMS_OPTIONS;

	const {paymentProblems} = reducers;
	const { paymentProblem } = paymentProblems;

	if(!isEmpty(changeInEmployment)) {
		const { OTHER } = C.CHANGE_IN_EMPLOYMENT_RELATIONSHIP_OPTIONS;
		switch(changeInEmployment) {
			case UNEMPLOYED_WITH_FUTURE_EMPLOYMENT:
				reasoningDetail['text'] = m('paymentProblems.items.changeInEmploymentRelationship.labelsForApi.unemployedWithFutureEmployment');
				break;
			case UNEMPLOYED_WITHOUT_FUTURE_EMPLOYMENT:
				reasoningDetail['text'] = m('paymentProblems.items.changeInEmploymentRelationship.labelsForApi.unemployedWithoutFutureEmployment');
				break;
			case TEMPORARY_SHORT_TIME_WORK:
				reasoningDetail['text'] = m('paymentProblems.items.changeInEmploymentRelationship.labelsForApi.temporaryShortTimeWork');
				break;
			case INCOME_REDUCTION:
				reasoningDetail['text'] = m('paymentProblems.items.changeInEmploymentRelationship.labelsForApi.incomeReduction');
				break;
			case INABILITY_TO_WORK:
				reasoningDetail['text'] = m('paymentProblems.items.changeInEmploymentRelationship.labelsForApi.inabilityToWork');
				break;
			case OTHER:
				reasoningDetail['text'] = m('paymentProblems.items.changeInEmploymentRelationship.labelsForApi.other');
				reasoningDetail['detail'] = reducers.paymentProblems.extraInfo;
				break;
			default:
				break;
		}
	} else if(!isEmpty(specialExpenses)) {
		const { OTHER } = C.ONE_OFF_SPECIAL_EXPENSES_OPTIONS;
		switch(specialExpenses) {
			case ACCIDENT_COSTS:
				reasoningDetail['text'] = m('paymentProblems.items.oneOffSpecialExpenses.labelsForApi.accidentCosts');
				reasoningDetail['height'] = parseFloat(reducers.paymentProblems.extraInfo);
				break;
			case ILLNESS_DEATH:
				reasoningDetail['text'] = m('paymentProblems.items.oneOffSpecialExpenses.labelsForApi.illnessDeath');
				reasoningDetail['height'] = parseFloat(reducers.paymentProblems.extraInfo);
				break;
			case SUBSEQUENT_PAYMENT:
				reasoningDetail['text'] = m('paymentProblems.items.oneOffSpecialExpenses.labelsForApi.subsequentPayment');
				reasoningDetail['height'] = parseFloat(reducers.paymentProblems.extraInfo);
				break;
			case PARADE:
				reasoningDetail['text'] = m('paymentProblems.items.oneOffSpecialExpenses.labelsForApi.parade');
				reasoningDetail['height'] = parseFloat(reducers.paymentProblems.extraInfo);
				break;
			case OTHER:
				reasoningDetail['text'] = m('paymentProblems.items.oneOffSpecialExpenses.labelsForApi.other');
				reasoningDetail['height'] = parseFloat(reducers.paymentProblems.extraInfo);
				reasoningDetail['detail'] = reducers.paymentProblems.extraInfo2;
				break;
			default:
				break;
		}
	} else if(!isEmpty(paymentProblem)) {
		const { OTHER } = C.PAYMENT_PROBLEMS_OPTIONS;
		switch(paymentProblem) {
			case PARENTAL_LEAVE:
				reasoningDetail['text'] = null;
				reasoningDetail['height'] = null;
				reasoningDetail['detail'] = null;
				break;
			case RETIREMENT_AGE:
				reasoningDetail['text'] = null;
				reasoningDetail['height'] = null;
				reasoningDetail['detail'] = null;
				break;
			case DIVORCE:
				reasoningDetail['text'] = null;
				reasoningDetail['height'] = null;
				reasoningDetail['detail'] = reducers.paymentProblems.extraInfo;
				break;
			case EXCESSIVE_DEBT:
				reasoningDetail['text'] = null;
				reasoningDetail['height'] = null;
				reasoningDetail['detail'] = reducers.paymentProblems.extraInfo;
				break;
			case OTHER:
				reasoningDetail['text'] = null;
				reasoningDetail['height'] = null;
				reasoningDetail['detail'] = reducers.paymentProblems.extraInfo;
				break;
			default:
				break;
		}
	}

	return reasoningDetail;
};

const getAmountOfCosts = (paymentProblems, value) => {
	const {specialExpenses, paymentProblem} = paymentProblems;
	const {
		PARADE
	} = C.ONE_OFF_SPECIAL_EXPENSES_OPTIONS;
	const {
		ONE_OFF_SPECIAL_EXPENSES
	} = C.PAYMENT_PROBLEMS_OPTIONS;

	return (paymentProblem === ONE_OFF_SPECIAL_EXPENSES && specialExpenses === PARADE ) ? null : value;
};

const getAmountOfCostsMoving = (paymentProblems, value) => {
	const {specialExpenses, paymentProblem} = paymentProblems;
	const {
		PARADE
	} = C.ONE_OFF_SPECIAL_EXPENSES_OPTIONS;
	const {
		ONE_OFF_SPECIAL_EXPENSES
	} = C.PAYMENT_PROBLEMS_OPTIONS;

	return (paymentProblem === ONE_OFF_SPECIAL_EXPENSES && specialExpenses === PARADE ) ? value : null;
};

const getPaymentProblemText = paymentProblem => {
	const {
		CHANGE_IN_EMPLOYMENT_RELATIONSHIP,
		ONE_OFF_SPECIAL_EXPENSES,
		PARENTAL_LEAVE,
		RETIREMENT_AGE,
		DIVORCE,
		EXCESSIVE_DEBT,
		ENERGY_COSTS,
		OTHER
	} = C.PAYMENT_PROBLEMS_OPTIONS;

	switch(paymentProblem){
		case CHANGE_IN_EMPLOYMENT_RELATIONSHIP:
			return m('paymentProblems.items.changeInEmploymentRelationship.labelForApi', 'fields');
		case ONE_OFF_SPECIAL_EXPENSES:
			return m('paymentProblems.items.oneOffSpecialExpenses.labelForApi', 'fields');
		case PARENTAL_LEAVE:
			return m('paymentProblems.items.parentalLeave.labelForApi', 'fields');
		case RETIREMENT_AGE:
			return m('paymentProblems.items.retirementAge.labelForApi', 'fields');
		case DIVORCE:
			return m('paymentProblems.items.divorce.labelForApi', 'fields');
		case EXCESSIVE_DEBT:
			return m('paymentProblems.items.excessiveDebt.labelForApi', 'fields');
		case ENERGY_COSTS:
			return m('paymentProblems.items.energyCosts.labelForApi', 'fields');
		case OTHER:
			return m('paymentProblems.items.other.labelForApi', 'fields');
		default:
			return '';
	}
};

const getCurrentTableValue = (index, field, borrowers) => {
	return borrowers[index] ?
		( borrowers[index][field] && !isEmpty(borrowers[index][field].value) ? parseFloat(borrowers[index][field].value) : null ) : null;
};

const getCurrentSolution = option => {
	let solution = null;
	switch(option) {
		case 'option1':
			solution = m('solutions.option1.labelForApi', 'fields');
			break;
		case 'option2':
			solution = m('solutions.option2.labelForApi', 'fields');
			break;
		case 'option3':
			solution = m('solutions.option3.labelForApi', 'fields');
			break;
		default:
			break;
	}
	return solution;
};

const getCurrentNoInstallmentReduced = (radioVal, solutionOption) => {
	let noInstallment = null;
	if(solutionOption === 'option2') {
		switch(radioVal) {
			case 0:
				noInstallment = 6;
				break;
			case 1:
				noInstallment = 12;
				break;
			case 2:
				noInstallment = 24;
				break;
			default:
				break;
		}
	}
	return noInstallment;
};

const getCurrentNoInstallmentsPostponedFuture = (option, reducers) => {
	let noInstallmentsPostponedFuture = null;
	const {solutions} = reducers;
	if(solutions.selectedOption === 'option1'){
		switch(option) {
			case 'option1':
				noInstallmentsPostponedFuture = 1;
				break;
			case 'option2':
				noInstallmentsPostponedFuture = 2;
				break;
			case 'option3':
				noInstallmentsPostponedFuture = 3;
				break;
			default:
				break;
		}
	}
	return noInstallmentsPostponedFuture;
};

const isEmptyNullItForApi = val => {
	return isEmpty(val.trim()) ? null : val;
};

const getCurrentInstallment = reducers => {
	const {solutions, tempRateReduction, generalRateReduction} = reducers;
	const tempRateReductionAmount = isEmpty(tempRateReduction.amount) ? 0 : tempRateReduction.amount;
	const generalRateReductionAmount = isEmpty(generalRateReduction.amount) ? 0 : generalRateReduction.amount;
	switch(solutions.selectedOption){
		case 'option2':
			return parseFloat(tempRateReductionAmount);
		case 'option3':
			return parseFloat(generalRateReductionAmount);
		default:
			return null;
	}
};
/**
 * Returns current data object to send to api depending of the page.
 * @param {params} object - The params as object. ({ isCreditCardFlow: bool,  reducers: reducersObject })
 * @returns object
 */

export const getDataToSendToAPI = params => {

	const {reducers} = params;

	const requestData = {};
	const requestApplication = {};
	const reasoningDetailsElements = {};
	const socialEcoData = {};
	const incomesCoDebtor = {};
	const expensesCoDebtor = {};
	const incomesDebtor = {};
	const expensesDebtor = {};
	const measure = {};

	// Reducers destructuring
	const {
		name,
		financingNumber,
		creditCardNumber,
		birthday,
		contact,
		address,
		paymentProblems,
		newAddress,
		inabilityOfWork,
		shortEmployment,
		unemployment,
		monthlyIncome,
		monthlyOutgoings,
		children,
		solutions,
		tempRateReduction,
		defermentOfPayment,
		futurePaymentProblem,
		queryParams
	} = reducers;

	requestApplication['financingNo'] = getFlowDataForAPI(queryParams,creditCardNumber.creditCardNumber,financingNumber.financingNumber);
	requestApplication['lastName'] = isEmptyNullItForApi(name.lastName);
	requestApplication['firstName'] = isEmptyNullItForApi(name.firstName);
	requestApplication['dateOfBirth'] = getFormattedDateForAPI(birthday.day, birthday.month, birthday.year);
	requestApplication['phoneNumberMobile'] = getFormattedPhoneForAPI(contact.countryCodeMobile, contact.mobilePhone);
	requestApplication['phoneNumberPrivate'] = getFormattedPhoneForAPI(contact.countryCodeLandine, contact.landline);
	requestApplication['email'] = isEmptyNullItForApi(contact.email);
	requestApplication['street'] = getFormattedStreetForAPI(address.street, address.number);
	requestApplication['city'] = isEmptyNullItForApi(address.city);
	requestApplication['zipCode'] = isEmptyNullItForApi(address.postcode);
	requestApplication['reasoning'] = getPaymentProblemText(paymentProblems.paymentProblem);
	requestApplication['reasoningDetails'] = getReasoningDetails(
		paymentProblems.changeInEmploymentRelationship,
		paymentProblems.specialExpenses,
		reducers)['text'];

	// ReasoningDetailsElements

	reasoningDetailsElements['amountOfCosts'] = getAmountOfCosts(paymentProblems, getReasoningDetails(
		paymentProblems.changeInEmploymentRelationship,
		paymentProblems.specialExpenses,
		reducers)['height']);

	reasoningDetailsElements['addressValidFrom'] = getFormattedDateForAPI(newAddress.day, newAddress.month, newAddress.year);
	reasoningDetailsElements['amountOfCostsMoving'] = getAmountOfCostsMoving(paymentProblems, getReasoningDetails(
		paymentProblems.changeInEmploymentRelationship,
		paymentProblems.specialExpenses,
		reducers)['height']);

	reasoningDetailsElements['disabilityUntil'] = getFormattedDateForAPI('01',inabilityOfWork.month2, inabilityOfWork.year2);
	reasoningDetailsElements['disabilitySince'] = getFormattedDateForAPI('01',inabilityOfWork.month, inabilityOfWork.year);
	reasoningDetailsElements['newStreet'] = getFormattedStreetForAPI(newAddress.street, newAddress.number);
	reasoningDetailsElements['newZipCode'] = isEmptyNullItForApi(newAddress.postcode);
	reasoningDetailsElements['newCity'] = isEmptyNullItForApi(newAddress.city);
	reasoningDetailsElements['reasonSpecialExpenses'] = getReasoningDetails(
		paymentProblems.changeInEmploymentRelationship,
		paymentProblems.specialExpenses,
		reducers)['detail'];
	reasoningDetailsElements['shortTimeSince'] = getFormattedDateForAPI('01', shortEmployment.month, shortEmployment.year);
	reasoningDetailsElements['shortTimeUntil'] = getFormattedDateForAPI('01', shortEmployment.month2, shortEmployment.year2);
	reasoningDetailsElements['startNewEmployment'] = getFormattedDateForAPI('01', unemployment.month2, unemployment.year2);
	reasoningDetailsElements['unemployedSince'] = getFormattedDateForAPI('01', unemployment.month, unemployment.year);

	// socialEcoData

	incomesCoDebtor['furtherIncome'] = getCurrentTableValue(1, 'additionalIncome', monthlyIncome.borrowers);
	incomesCoDebtor['netIncome'] = getCurrentTableValue(1, 'netIncome', monthlyIncome.borrowers);
	incomesCoDebtor['aliment'] = getCurrentTableValue(1, 'alimony', monthlyIncome.borrowers );
	incomesCoDebtor['rentalIncome'] = getCurrentTableValue(1, 'rentalIncome', monthlyIncome.borrowers);
	incomesCoDebtor['annuity'] = getCurrentTableValue(1, 'annuity', monthlyIncome.borrowers);
	incomesCoDebtor['unemploymentAllowance'] = getCurrentTableValue(1, 'unemploymentAllowance', monthlyIncome.borrowers);
	incomesCoDebtor['insuranceBenefits'] = getCurrentTableValue(1, 'insuranceBenefits', monthlyIncome.borrowers);
	incomesCoDebtor['parentalAllowance'] = getCurrentTableValue(1, 'parentalAllowance', monthlyIncome.borrowers);

	expensesCoDebtor['mobilityCosts'] = getCurrentTableValue(1, 'mobilityCosts', monthlyOutgoings.borrowers);
	expensesCoDebtor['furtherExpenses'] = getCurrentTableValue(1, 'otherExpenditure', monthlyOutgoings.borrowers);
	expensesCoDebtor['rent'] = getCurrentTableValue(1, 'housingCosts', monthlyOutgoings.borrowers);
	expensesCoDebtor['aliment'] = getCurrentTableValue(1, 'maintenanceObligations', monthlyOutgoings.borrowers);
	expensesCoDebtor['nonSantanderAccounts'] = getCurrentTableValue(1, 'thirdPartyObligations', monthlyOutgoings.borrowers);
	expensesCoDebtor['incidentalExpenses'] = getCurrentTableValue(1, 'incidentalExpenses', monthlyOutgoings.borrowers);
	expensesCoDebtor['telecommunicationCosts'] = getCurrentTableValue(1, 'telecommunicationCosts', monthlyOutgoings.borrowers);
	expensesCoDebtor['insuranceContributions'] = getCurrentTableValue(1, 'insuranceContributions', monthlyOutgoings.borrowers);
	expensesCoDebtor['mobilityCosts'] = getCurrentTableValue(1, 'mobilityCosts', monthlyOutgoings.borrowers);
	expensesCoDebtor['costsForMedia'] = getCurrentTableValue(1, 'costsForMedia', monthlyOutgoings.borrowers);
	expensesCoDebtor['maintenanceObligations'] = getCurrentTableValue(1, 'maintenanceObligations', monthlyOutgoings.borrowers);
	expensesCoDebtor['onlineAndMailOrderBussiness'] = getCurrentTableValue(1, 'onlineAndMailOrderBussiness', monthlyOutgoings.borrowers);
	expensesCoDebtor['savingsRate'] = getCurrentTableValue(1, 'savingsRate', monthlyOutgoings.borrowers);

	incomesDebtor['furtherIncome'] = getCurrentTableValue(0, 'additionalIncome', monthlyIncome.borrowers);
	incomesDebtor['netIncome'] = getCurrentTableValue(0, 'netIncome', monthlyIncome.borrowers);
	incomesDebtor['aliment'] = getCurrentTableValue(0, 'alimony', monthlyIncome.borrowers );
	incomesDebtor['rentalIncome'] = getCurrentTableValue(0, 'rentalIncome', monthlyIncome.borrowers);
	incomesDebtor['annuity'] = getCurrentTableValue(0, 'annuity', monthlyIncome.borrowers);
	incomesDebtor['unemploymentAllowance'] = getCurrentTableValue(0, 'unemploymentAllowance', monthlyIncome.borrowers);
	incomesDebtor['insuranceBenefits'] = getCurrentTableValue(0, 'insuranceBenefits', monthlyIncome.borrowers);
	incomesDebtor['parentalAllowance'] = getCurrentTableValue(0, 'parentalAllowance', monthlyIncome.borrowers);

	expensesDebtor['mobilityCosts'] = getCurrentTableValue(0, 'mobilityCosts', monthlyOutgoings.borrowers);
	expensesDebtor['furtherExpenses'] = getCurrentTableValue(0, 'otherExpenditure', monthlyOutgoings.borrowers);
	expensesDebtor['rent'] = getCurrentTableValue(0, 'housingCosts', monthlyOutgoings.borrowers);
	expensesDebtor['aliment'] = getCurrentTableValue(0, 'maintenanceObligations', monthlyOutgoings.borrowers);
	expensesDebtor['nonSantanderAccounts'] = getCurrentTableValue(0, 'thirdPartyObligations', monthlyOutgoings.borrowers);
	expensesDebtor['incidentalExpenses'] = getCurrentTableValue(0, 'incidentalExpenses', monthlyOutgoings.borrowers);
	expensesDebtor['telecommunicationCosts'] = getCurrentTableValue(0, 'telecommunicationCosts', monthlyOutgoings.borrowers);
	expensesDebtor['insuranceContributions'] = getCurrentTableValue(0, 'insuranceContributions', monthlyOutgoings.borrowers);
	expensesDebtor['mobilityCosts'] = getCurrentTableValue(0, 'mobilityCosts', monthlyOutgoings.borrowers);
	expensesDebtor['costsForMedia'] = getCurrentTableValue(0, 'costsForMedia', monthlyOutgoings.borrowers);
	expensesDebtor['maintenanceObligations'] = getCurrentTableValue(0, 'maintenanceObligations', monthlyOutgoings.borrowers);
	expensesDebtor['onlineAndMailOrderBussiness'] = getCurrentTableValue(0, 'onlineAndMailOrderBussiness', monthlyOutgoings.borrowers);
	expensesDebtor['savingsRate'] = getCurrentTableValue(0, 'savingsRate', monthlyOutgoings.borrowers);


	socialEcoData['incomesCoDebtor'] = {...incomesCoDebtor};
	socialEcoData['expensesCoDebtor'] = {...expensesCoDebtor};
	socialEcoData['incomesDebtor'] = {...incomesDebtor};
	socialEcoData['expensesDebtor'] = {...expensesDebtor};
	socialEcoData['numberChildren'] = children.numberChildren;
	socialEcoData['noChildrenAllowanceDebtor'] =
	(children.numberChildrenWithBenefit > 0 ? children.numberChildrenWithBenefit : null);


	measure['measure'] = getCurrentSolution(solutions.selectedOption);
	measure['installment']= getCurrentInstallment(reducers);
	measure['noInstallmentsReduced'] = getCurrentNoInstallmentReduced(tempRateReduction.radio, solutions.selectedOption);
	measure['noInstallmentsPostponedFuture'] = getCurrentNoInstallmentsPostponedFuture(defermentOfPayment.selectedValue, reducers);


	requestApplication['reasoningDetailsElements'] = {...reasoningDetailsElements};
	requestApplication['socialEcoData'] = {...socialEcoData};
	requestApplication['regularIncomesExpensesChanged'] = futurePaymentProblem.radioState === 1 ? 'ja' :
		(futurePaymentProblem.radioState !== -1 ? 'nein' : null);
	requestApplication['measure'] = {...measure};

	requestData['requestApplication'] = {...requestApplication};

	return {
		'requestData': {
			...requestData
		},
		'event': '', // TODO Fill up this data
		'productType': queryParams.product ? queryParams.product : ''
	};
};
