import _ from 'lodash';

const defaultState = {
	radioState: -1,
	selectedValue: ''
};

const reducers = (state = defaultState, action) => {
	switch(action.type) {
		case 'UPDATE_DEFERMENT_OF_PAYMENT':
			return _.merge({}, state, {
				radioState: action.payload.radioState,
				selectedValue: action.payload.selectedValue
			});
		default:
			return state;
	}
};
export default reducers;