import * as React from 'react';

const Arrow = props => (
	<svg width={ 12 } height={ 25 } viewBox="0 0 12 25" { ...props }>
		<path
			d="M11.095 23.46L1 12.228 11.095.997"
			stroke="#9E3667"
			strokeWidth={ 1.2 }
			fill="none"
			fillRule="evenodd"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</svg>
);

export default Arrow;
