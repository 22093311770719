/**
 * PopUp with activation Link
 * 
 * Created by Moritz Kippenberg
 * Last modified by Moritz Kippenberg
 * Last modified date : 01-07-2019
 */

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import styles from './Overlay.scss';

const Overlay = ({ reducers }) => {

	const getWrapperClassNames = () => {
		let className = styles.overlayWrapper;
		className += (reducers.isShowOverlay ? ' ' + styles.isShow : '');

		return className;
	};

	return(
		<div className={ getWrapperClassNames() }>	
		</div>
	);
};

Overlay.propTypes = {
	isShowOverlay: PropTypes.bool
};

const mapStateToProps = (state) => {
	return{
		reducers: state.app
	};
};

export default connect(mapStateToProps, null)(Overlay);
