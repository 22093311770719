/**
 * Mobile Phone Page
 *
 * Created by Cristian Restituyo
 * Last modified by Cristian Restituyo
 * Last modified date : 23-07-2019
 */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Row, Col } from 'mod/FlexGrid';
import Page from 'mod/Page/Page';
import _ from 'lodash';
import C from '../../constants';

import * as AppActions from '../../store/App/actions';
import * as EntryActions from '../../store/Contact/actions';
import * as utils from '../../utils/utils';

import DefaultInput from 'ui/inputs/DefaultInput/DefaultInput';
// import CheckboxInput from 'ui/inputs/CheckboxInput/CheckboxInput';
import styles from './Contact.scss';
import * as googleEvents from '../../utils/googleEvents';
import * as regex from '../../utils/regex';

const Contact = ({ reducers, actions, location, history }) => {
	const [state, setState] = useState({
		path: C.ROUTES.CONTACT,
		currentFlow: utils.getCurrentFlow(location),
		landline: '',
		mobilePhone: '',
		isMobilePhoneErr: false,
		isLandlineErr: false,
		countryCodeMobile: '',
		countryCodeLandline: '',
		isCountryCodeMobileErr: false,
		isCountryCodeLandlineErr: false,
		errorMsg: '',
		isShowError: false,
		email: '',
		isEmailErr: false
		// acceptPrivacyPolicies: false
	});

	useEffect(() => {
		// Set the page Index for navigation
		actions.updatePage(utils.getPageIndex(state.path, state.currentFlow));

		// Set the default value on page load
		setState({
			...state,
			countryCodeLandline: reducers.contact.countryCodeLandine,
			landline: reducers.contact.landline,
			countryCodeMobile: reducers.contact.countryCodeMobile,
			mobilePhone: reducers.contact.mobilePhone,
			email: reducers.contact.email
			// acceptPrivacyPolicies: reducers.contact.acceptPrivacyPolicies

		});

		// Block access from user type in url directly
		utils.checkIsFromNav(history, reducers.app.isReload, state.currentFlow + C.ROUTES.NAME);


		// Set the first element on focus
		if (!reducers.app.appLoading) {
			landlineRef.current.focus();
		}
	}, []);

	const onFormSubmit = () => {
		/*** Validation ***/
		let isValid = true;
		let cloneState = _.cloneDeep(state);

		cloneState.errorMsg = '';

		if (!utils.isEmail(state.email)) {
			isValid = false;
			utils.showError(
				cloneState,
				'isEmailErr',
				utils.m('email.validation.invalid', 'fields')
			);
		} else {
			cloneState.isEmailErr = false;
		}

		if (
			state.countryCodeMobile.trim().length < 2 &&
			!utils.isEmpty(state.countryCodeMobile)
		) {
			isValid = false;
			utils.showError(
				cloneState,
				'isCountryCodeMobileErr',
				utils.m('countryCode.validation.tooShort', 'fields')
			);
		} else {
			cloneState.isCountryCodeMobileErr = false;
		}

		if (
			state.mobilePhone.trim().length < 11 &&
			!utils.isEmpty(state.mobilePhone)
		) {
			isValid = false;
			utils.showError(
				cloneState,
				'isMobilePhoneErr',
				utils.m('mobilePhone.validation.tooShort', 'fields')
			);
		} else {
			cloneState.isMobilePhoneErr = false;
		}

		if (
			state.countryCodeLandline.trim().length < 2 &&
			!utils.isEmpty(state.countryCodeLandline)
		) {
			isValid = false;
			utils.showError(
				cloneState,
				'isCountryCodeLandlineErr',
				utils.m('countryCode.validation.tooShort', 'fields')
			);
		} else {
			cloneState.isCountryCodeLandlineErr = false;
		}

		if (state.landline.trim().length < 5 && !utils.isEmpty(state.landline)) {
			isValid = false;
			utils.showError(
				cloneState,
				'isLandlineErr',
				utils.m('landlinePhone.validation.tooShort', 'fields')
			);
		} else {
			cloneState.isLandlineErr = false;
		}

		if (isValid) {
			// on validation success
			cloneState.landline = utils.removeLeadingZero(cloneState.landline);
			cloneState.mobilePhone = utils.removeLeadingZero(cloneState.mobilePhone);

			actions.onUpdateContactInfo(cloneState);
			utils.goToPage(
				history,
				state.currentFlow +
					(reducers.creditCardNumber.creditCardNumber ||
						reducers.product.product === 'creditcard' ||
						reducers.product.product === 'limit' ||
						reducers.product.type
						? C.ROUTES.PAYMENT_STATUS
						: C.ROUTES.PAYMENT_PROBLEMS)
			);
		} else {
			// on validation failure
			googleEvents.continueInvalid();
			setState(cloneState);
		}
	};

	/* eslint-disable no-mixed-operators */
	const hasEmptyFields =
		((utils.isEmpty(state.countryCodeMobile) ||
			utils.isEmpty(state.mobilePhone)) &&
			(utils.isEmpty(state.countryCodeLandline) ||
				utils.isEmpty(state.landline))) ||
		utils.isEmpty(state.email); 
		// || !state.acceptPrivacyPolicies;
	/* eslint-enable no-mixed-operators */


	const countryCodeMobileRef = useRef(null);
	const mobilePhoneRef = useRef(null);

	const countryCodeLandlineRef = useRef(null);
	const landlineRef = useRef(null);
	// const acceptPrivacyPoliciesRef = useRef(null);
	const emailRef = useRef(null);
	const submitRef = useRef(null);

	return (
		<Page
			title={ utils.m('contact.title', state.currentFlow) }
			subtitle={ utils.m('contact.subtitle', state.currentFlow) }
			isShowError={ state.isShowError }
			errorMsg={ state.errorMsg }
			hasNextButton={ true }
			onFormSubmit={ onFormSubmit }
			nextButtonDisabled={ hasEmptyFields }
			onBackButtonClick = { history.goBack }
			location={ location }
			currentFlow={ state.currentFlow }
			pageName='Contact'
			currentStep={ C.PROGRESS_BAR.personalData }
			screen='A6'
			submitRef={ submitRef }
			prevRef={ countryCodeLandlineRef }
			nextRef={ landlineRef }
			showButtonDivider={ true }
			gtmStep='userdata_contact'
			gtmStatus='registration_pending'
		>
			<Row justifyContent='center'>
				<Col xs={ 12 } sm={ 4 }>
					<DefaultInput
						inputOnChange={ val => {
							utils.onInputChange(setState, state, 'countryCodeLandline', val);
						} }
						label={ utils.m('countryCode.label', 'fields') }
						type='number'
						maxlength={ 4 }
						customClass={ styles.countryCode }
						hasError={ state.isCountryCodeLandlineErr }
						init={ state.countryCodeLandline }
						ref={ countryCodeLandlineRef }
						prevRef={ hasEmptyFields ? landlineRef : submitRef }
						nextRef={ landlineRef }
						regex={ regex.digit() }
						testId='country-code-landline'
					/>
				</Col>

				<Col xs={ 12 } sm={ 8 }>
					<DefaultInput
						inputOnChange={ val => {
							utils.onInputChange(setState, state, 'landline', val);
						} }
						label={ utils.m('landlinePhone.label', 'fields') }
						type='number'
						maxlength={ 15 }
						hasError={ state.isLandlineErr }
						init={ state.landline }
						ref={ landlineRef }
						prevRef={ countryCodeLandlineRef }
						nextRef={ countryCodeMobileRef }
						regex={ regex.digit() }
						testId='landline'
					/>
				</Col>

				<Col xs={ 12 } sm={ 4 }>
					<DefaultInput
						inputOnChange={ val => {
							utils.onInputChange(setState, state, 'countryCodeMobile', val);
						} }
						label={ utils.m('countryCode.label', 'fields') }
						type='number'
						maxlength={ 4 }
						customClass={ styles.countryCode }
						hasError={ state.isCountryCodeMobileErr }
						init={ state.countryCodeMobile }
						ref={ countryCodeMobileRef }
						prevRef={ landlineRef }
						nextRef={ mobilePhoneRef }
						regex={ regex.digit() }
						testId='country-code-mobile'
					/>
				</Col>

				<Col xs={ 12 } sm={ 8 }>
					<DefaultInput
						inputOnChange={ val => {
							utils.onInputChange(setState, state, 'mobilePhone', val);
						} }
						label={ utils.m('mobilePhone.label', 'fields') }
						type='number'
						maxlength={ 15 }
						hasError={ state.isMobilePhoneErr }
						init={ state.mobilePhone }
						ref={ mobilePhoneRef }
						prevRef={ countryCodeMobileRef }
						nextRef={ emailRef }
						regex={ regex.digit() }
						testId='mobile-phone'
					/>
				</Col>

				<Col xs={ 12 } customClass={ styles.emailCol }>
					<DefaultInput
						inputOnChange={ val => {
							utils.onInputChange(setState, state, 'email', val);
						} }
						label={ utils.m('email.label', 'fields') }
						type='email'
						hasError={ state.isEmailErr }
						init={ state.email }
						maxlength={ 50 }
						ref={ emailRef }
						prevRef={ mobilePhoneRef }
						nextRef={ hasEmptyFields ? mobilePhoneRef : submitRef }
						regex={ regex.wordWithEmailCharacters() }
						required={ true }
						testId='email'
					/>
				</Col>

				{/* <Col xs={ 12 } customClass={ styles.checkboxCol }>
					<CheckboxInput
						inputOnChange={ onChecked }
						label={ utils.m('acceptPrivacyPolicies.description', 'fields') }
						hasError={ state.acceptPrivacyPoliciesErr }
						checked={ state.acceptPrivacyPolicies }
						ref={ acceptPrivacyPoliciesRef }
						prevRef={ emailRef }
						nextRef={ hasEmptyFields ? emailRef : submitRef }
						required={ true }
						testId='accept-privacy-policies'
					/>
				</Col> */}
			</Row>
		</Page>
	);
};

Contact.propTypes = {
	reducers: PropTypes.object,
	actions: PropTypes.object,
	history: PropTypes.object
};

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(_.merge({}, AppActions, EntryActions), dispatch)
});

const mapStateToProps = state => {
	return {
		reducers: state
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Contact);
