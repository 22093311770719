/**
 * PaymentStatus Page
 *
 * Created by Cristian Restituyo
 * Last modified by Cristian Restituyo
 * Last modified date : 23-07-2019
 */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Page from 'mod/Page/Page';
import { Row, Col } from 'mod/FlexGrid/index';
import _ from 'lodash';
import C from '../../constants';

import RadioGroupMulti from 'ui/inputs/RadioGroupMulti/RadioGroupMulti';

import * as AppActions from '../../store/App/actions';
import * as EntryActions from '../../store/PaymentStatus/actions';
import * as utils from '../../utils/utils';
import * as googleEvents from '../../utils/googleEvents';
import * as regex from '../../utils/regex';

const PaymentStatus = ({ reducers, actions, history, location }) => {

	const [state, setState] = useState({
		path: C.ROUTES.PAYMENT_STATUS,
		currentFlow: utils.getCurrentFlow(location),
		radioState: reducers.paymentStatus.radioState,
		isRadioErr: false
	});

	const options = [
		{
			label: utils.m('paymentStatus.option1.label', 'fields'),
			value: 'option1'
		},
		{
			label: utils.m('paymentStatus.option2.label', 'fields'),
			value: 'option2'
		},
		{
			label: utils.m('paymentStatus.option3.label', 'fields'),
			value: 'option3'
		}
	];

	useEffect(() => {
		// Set the page Index for navigation
		actions.updatePage(utils.getPageIndex(state.path, state.currentFlow));

		// Set the default value on page load
		setState({
			...state,
			financingNumber: reducers.financingNumber.financingNumber
		});

		// Block access from user type in url directly
		utils.checkIsFromNav(history, reducers.app.isReload, state.currentFlow + C.ROUTES.NAME);
		

	}, []);


	const onFormSubmit = () => {


		googleEvents.continueValid();

		actions.onUpdatePaymentStatus(state);
		actions.updateReload(false);
		
		if (state.radioState === 0) {
			utils.goToPage(history, state.currentFlow + C.ROUTES.PAYMENT_STATUS_PAID);
		} else if (state.radioState === 1) {
			utils.goToPage(history, state.currentFlow + C.ROUTES.PAYMENT_STATUS_WILL_PAY);
		} else if (state.radioState === 2) {
			utils.goToPage(history, state.currentFlow + C.ROUTES.PAYMENT_PROBLEMS);
		}
	
	};

	const hasEmptyFields = () => {
		return state.radioState === -1;		
	};


	const onRadioChange = (val) => {

		const cloneState = { ...state };
		
		cloneState.radioState = 0;

		options.forEach((option, idx) => {
			if(option.value === val) {
				cloneState.radioState = idx;
			}
		});

		setState(cloneState);
	};
	
	const submitRef = useRef(null);
	const radioRef = useRef(null);

	return(
		<Page
			title={ utils.m('paymentStatus.title', state.currentFlow) }
			subtitle={ utils.m('paymentStatus.subtitle', state.currentFlow) }
			isShowError={ state.isShowError }
			errorMsg={ state.errorMsg }
			hasNextButton={ true }
			onBackButtonClick = { history.goBack }
			onFormSubmit={ onFormSubmit }
			nextButtonDisabled={ hasEmptyFields() }
			location={ location }
			currentFlow={ state.currentFlow }
			pageName="PaymentStatus"
			currentStep={ C.PROGRESS_BAR.statement }
			screen='B1'
			showButtonDivider={ false }
			submitRef={ submitRef }
			prevRef={ radioRef }
			nextRef={ submitRef }
			gtmStep="userdata_paymentstatus"
			gtmStatus="registration_pending"
			gtmSubStatus={ null }
		>
			<Row justifyContent="center">
				<Col>
					<RadioGroupMulti
						radioOnChange={ (val) => { onRadioChange(val); } }
						radioState={ state.radioState }
						hasError={ state.isRadioErr }
						init={ '' }
						maxlength={ 150 }
						ref={ radioRef }
						prevRef={ hasEmptyFields() ? radioRef : submitRef }
						nextRef={ submitRef }
						regex={ regex.wordWithSpaceAndDashAndCommaAndDot() }
						testId="payment-status"
						options = { options }
					/>
				</Col>
			</Row>
		</Page>
	);
};

PaymentStatus.propTypes = {
	reducers: PropTypes.object,
	actions: PropTypes.object,
	history: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators( _.merge({}, AppActions, EntryActions), dispatch)
});

const mapStateToProps = (state) => {
	return{
		reducers: state
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentStatus);
