/**
 * Action Type
*/

const UPDATE_CREDIT_CARD_NUMBER = 'UPDATE_CREDIT_CARD_NUMBER';

/*
* Action Creators
*/

export const onUpdateCreditCardNumber = payload => ({
	type: UPDATE_CREDIT_CARD_NUMBER,
	payload
});