import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'mod/FlexGrid/index';
import PageTitle from 'mod/PageTitle/PageTitle';
import ErrorMsg from 'mod/ErrorMsg/ErrorMsg';
import SuccessMsg from 'mod/SuccessMsg/SuccessMsg';
import Button from 'ui/buttons/Button/Button';
import Pagination from 'mod/Pagination/Pagination';
import Header from 'mod/Header/Header';
import CustomProgressBar from 'mod/CustomProgressBar/CustomProgressBar';
import Overlay from 'mod/Overlay/Overlay';
import * as googleEvents from '../../../utils/googleEvents';
import C from '../../../constants';
import {
	updateCurrentStep,
	updateCurrentScreen,
	updateProgress,
	updateSteps
} from '../../../store/App/actions';

import LoadingSpinner from 'ui/svg/LoadingSpinner';
import TooltipIcon from 'ui/TooltipIcon/TooltipIcon';
import {useScreenWidth} from '../../../hooks';

import styles from './Page.scss';
import * as utils from '../../../utils/utils';

const Page = props => {
	const {
		app: { steps, progress },
		product: { product }
	} = props.reducers;
	const { currentStep, screen: currentScreen } = props;
	const screenWidth = useScreenWidth();

	const getProgress = () => {
		if (['B5', 'F5'].includes(currentScreen)) {
			return '100%';
		}

		const currentIndex = steps.findIndex(s => s.display === currentStep);
		if (currentIndex === -1) return '0%';

		const step = steps[currentIndex];
		const currentSubIndex = step.steps.findIndex(
			screen => screen === currentScreen
		);

		const stepWidth = 100 / steps.length;
		const subStepWidth = stepWidth / step.steps.length;

		const progressPerc =
			stepWidth * currentIndex + subStepWidth * currentSubIndex + '%';

		return progressPerc;
	};

	useEffect(() => {
		// if credit card flow show only first two steps
		// otherwise show all 4 of them
		if (product === 'limit' || product === 'creditcard') {
			const clonedSteps = JSON.parse(JSON.stringify(C.STEPS.slice(0, 2)));
			props.actions.updateSteps(clonedSteps);
		} else {
			const clonedSteps = JSON.parse(JSON.stringify(C.STEPS));
			props.actions.updateSteps(clonedSteps);
		}
	}, [product]);	

	useEffect(() => {
		props.actions.updateCurrentScreen(props.screen);
		props.actions.updateProgress(getProgress());
	}, [product, props.screen]);

	useEffect(() => {
		// Scrolls window top on new page
		window.scrollTo(0, 0);

		// Load Google events
		if (props.location && props.location.pathname) {
			googleEvents.virtualPageLoad(props.location.pathname);
		}
		googleEvents.setDataToWindowObject(
			props.currentFlow,
			props.gtmStep,
			props.gtmStatus,
			props.gtmSubStatus
		);
	}, []);

	const renderCustomBottomLayout = () => {
		let content = null;
		if (props.isMandatoryFields) {
			content = (
				<Row justifyContent="center" customClass={ styles.customButtonsRow }>
					<Col sm={ 4 } xs={ 12 } customClass={ styles.mandatoryFieldsCol }>
						<div className={ styles.mandatoryFieldsWrapper }>
							<span>{props.mandatoryLabel}</span>
						</div>
					</Col>
					<Col sm={ 8 } xs={ 12 }>
						<div className={ styles.buttonRow }>
							{!utils.isEmpty(props.prevButtonPath) ? (
								props.prevButtonPath === 'hidden' ? null : (
									<Button
										text={ utils.m('prev.label', 'fields') }
										type="button"
										buttonStyle="secondary"
										path={ props.prevButtonPath }
										onAdditionalClick={ googleEvents.goBack }
										onButtonClick={ props.onBackButtonClick }
									/>
								)
							) : (
								<Button
									text={ utils.m('prev.label', 'fields') }
									type="button"
									buttonStyle="secondary"
									onAdditionalClick={ googleEvents.goBack }
									onButtonClick={ props.onBackButtonClick }
								/>
							)}

							{props.hasNextButton ? (
								<Button
									text={
										props.nextButtonText
											? props.nextButtonText
											: utils.m('next.label', 'fields')
									}
									type="submit"
									buttonStyle="primary"
									disabled={ props.nextButtonDisabled }
									isLoading={ props.reducers.app.buttonLoading }
									onButtonClick={ () => {
										props.onFormSubmit();
									} }
									buttonRef={ props.submitRef }
									prevRef={ props.prevRef }
									nextRef={ props.nextRef }
								/>
							) : null}
						</div>
					</Col>
				</Row>
			);
		} else {
			content = (
				<Row justifyContent="center">
					<Col xs={ 12 }>
						<div className={ styles.buttonRow }>
							{!utils.isEmpty(props.prevButtonPath) ? (
								props.prevButtonPath === 'hidden' ? null : (
									<Button
										text={ utils.m('prev.label', 'fields') }
										type="button"
										buttonStyle="secondary"
										path={ props.prevButtonPath }
										onAdditionalClick={ googleEvents.goBack }
										onButtonClick={ props.onBackButtonClick }
									/>
								)
							) : (
								<Button
									text={ utils.m('prev.label', 'fields') }
									type="button"
									buttonStyle="secondary"
									onAdditionalClick={ googleEvents.goBack }
									onButtonClick={ props.onBackButtonClick }
								/>
							)}

							{props.hasNextButton ? (
								<Button
									text={
										props.nextButtonText
											? props.nextButtonText
											: utils.m('next.label', 'fields')
									}
									type="submit"
									buttonStyle="primary"
									disabled={ props.nextButtonDisabled }
									isLoading={ props.reducers.app.buttonLoading }
									onButtonClick={ () => {
										props.onFormSubmit();
									} }
									buttonRef={ props.submitRef }
									prevRef={ props.prevRef }
									nextRef={ props.nextRef }
								/>
							) : null}
						</div>
					</Col>
				</Row>
			);
		}

		return content;
	};

	return (
		<div className={ styles.pageContainer }>
			<Overlay />
			<Header location={ props.location } />

			{!props.hideProgressBar ? (
				<CustomProgressBar
					steps={ steps }
					currentStep={ currentStep }
					currentScreen={ currentScreen }
					progress={ progress }
				/>
			) : (
				<div className={ styles.headerSpacer } />
			)}

			<Container>
				<Row justifyContent="center">
					<Col md={ 10 } lg={ 8 } xl={ 6 }>
						{props.reducers.app.appLoading ? (
							<>
								<PageTitle
									title={ utils.m('loadingPage.title', 'fields') }
									textAlign="center"
								/>

								<div className={ styles.loadingSpinner }>
									<div className={ styles.loadingSpinnerInner }>
										<LoadingSpinner />
									</div>
								</div>
							</>
						) : (
							<>
								{props.title ? (
									<div className = { styles.pageTitleRow }>
										<PageTitle
											title={ props.title }
											subtitle={ props.subtitle ? props.subtitle : null }
										/>
										{props.tooltipInfo && (
											<div className = { styles.tooltipIconWrapper } onClick = { event => event.stopPropagation() } >
												<TooltipIcon 
													background = '#d2f0f2' 
													isInfoIcon
													isClickable = { screenWidth <= C.SCREEN_SIZES.MAX_MOBILE_WIDTH }
													content = { 
														<div className = { styles.tooltipContentWrapper }>
															<div className = { styles.tooltipText }>
																{ props.tooltipInfo.text }
															</div>
															
														</div> } 
												/>
											</div>
										)}
									</div>
								) : null}

								{props.isShowError ? <ErrorMsg msg={ props.errorMsg } /> : null}
								{props.isShowSuccess ? (
									<SuccessMsg msg={ props.successMsg } />
								) : null}

								<form
									data-testid={ props.pageName }
									onSubmit={ event => {
										event.preventDefault();
									} }
								>
									<Row justifyContent="center">
										<Col>{props.children}</Col>
									</Row>

									{props.showButtonDivider ? (
										<div className={ styles.divider } />
									) : null}

									{renderCustomBottomLayout()}
								</form>
							</>
						)}
					</Col>
				</Row>
			</Container>
			{process.env.NODE_ENV === 'development' ? (
				<Pagination validation={ () => props.onFormSubmit() } />
			) : null}
		</div>
	);
};

Page.propTypes = {
	title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
	isShowError: PropTypes.bool,
	isShowSuccess: PropTypes.bool,
	isShowOverlay: PropTypes.bool,
	errorMsg: PropTypes.string,
	successMsg: PropTypes.string,
	hasNextButton: PropTypes.bool,
	prevButtonPath: PropTypes.string,
	onFormSubmit: PropTypes.func,
	nextButtonText: PropTypes.string,
	nextButtonContentLeft: PropTypes.element,
	nextButtonDontBlock: PropTypes.bool,
	nextButtonDisabled: PropTypes.bool,
	location: PropTypes.object,
	currentFlow: PropTypes.string.isRequired,
	hideProgressBar: PropTypes.bool,
	currentStep: PropTypes.string,
	screen: PropTypes.string,
	showButtonDivider: PropTypes.bool,
	submitRef: PropTypes.object,
	prevRef: PropTypes.object,
	nextRef: PropTypes.object,
	pageName: PropTypes.string.isRequired,
	gtmStep: PropTypes.string,
	gtmStatus: PropTypes.string,
	gtmSubStatus: PropTypes.string,
	onBackButtonClick: PropTypes.func,
	isMandatoryFields: PropTypes.bool,
	tooltipInfo: PropTypes.shape({
		text: PropTypes.string
	}),
	mandatoryLabel: PropTypes.string
};

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(
		{
			updateCurrentStep,
			updateCurrentScreen,
			updateProgress,
			updateSteps
		},
		dispatch
	)
});

const mapStateToProps = state => {
	return {
		reducers: state
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Page);
