/**
 * FinancingNumber Page
 *
 * Created by Cristian Restituyo
 * Last modified by Cristian Restituyo
 * Last modified date : 23-07-2019
 */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Page from 'mod/Page/Page';
import { Row, Col } from 'mod/FlexGrid/index';
import _ from 'lodash';
import C from '../../constants';

import DefaultInput from 'ui/inputs/DefaultInput/DefaultInput';
import TooltipIcon from 'ui/TooltipIcon/TooltipIcon';
import * as AppActions from '../../store/App/actions';
import * as EntryActions from '../../store/FinancingNumber/actions';
import CheckboxInput from 'ui/inputs/CheckboxInput/CheckboxInput';
import IconExternalLink from 'ui/svg/IconExternalLink';
import * as utils from '../../utils/utils';
import * as googleEvents from '../../utils/googleEvents';
import * as regex from '../../utils/regex';

import {useScreenWidth} from '../../hooks';

import styles from './FinancingNumber.scss';

const FinancingNumber = ({ reducers, actions, history, location }) => {
	const [state, setState] = useState({
		path: C.ROUTES.FINANCING_NUMBER,
		currentFlow: utils.getCurrentFlow(location),
		financingNumber: '',
		isFinancingNumberErr: false,
		errorMsg: '',
		isShowError: false,
		acceptPrivacyPolicies: false,
		acceptPrivacyPoliciesErr: false
	});
	const screenWidth = useScreenWidth();

	useEffect(() => {
		// Set the page Index for navigation
		actions.updatePage(utils.getPageIndex(state.path, state.currentFlow));

		// Set the default value on page load
		setState({
			...state,
			financingNumber: reducers.financingNumber.financingNumber,
			acceptPrivacyPolicies: reducers.financingNumber.acceptPrivacyPolicies
		});
		// Block access from user type in url directly
		utils.checkIsFromNav(history, reducers.app.isReload, state.currentFlow + C.ROUTES.NAME);

	}, []);

	const onFormSubmit = () => {
		/*** Validation ***/
		let isValid = true;
		let cloneState = _.cloneDeep(state);

		cloneState.errorMsg = '';

		// Start Validation

		if (state.financingNumber.split('').length < 10) {
			
			cloneState.financingNumber = utils.appendLeadingZeros(
				cloneState.financingNumber,
				10
			);
			
			setState(cloneState);
		} 

		if (isValid) {
			// on validation success
			utils.hideError(state, setState);

			googleEvents.continueValid();

			actions.onUpdateFinancingNumber(cloneState);
			actions.updateReload(false);
			utils.goToPage(history, state.currentFlow + C.ROUTES.ADDRESS);
		}
	};

	const hasEmptyFields = () => {
		return utils.isEmpty(state.financingNumber)
				|| state.financingNumber.length < 9
				|| !state.acceptPrivacyPolicies;
	};

	const getCurrentFinancialNumber = () => {
		return state.isFinancingNumberErr
			? state.financingNumber
			: reducers.financingNumber.financingNumber;
	};

	const onChecked = () => {
		setState({
			...state,
			acceptPrivacyPolicies: !state.acceptPrivacyPolicies
		});
	};

	// const titleRef = useRef(null);
	const salutationRef = useRef(null);
	const financingNumberRef = useRef(null);
	const lastNameRef = useRef(null);
	const submitRef = useRef(null);
	const acceptPrivacyPoliciesRef = useRef(null);

	return (
		<Page
			title={ utils.m('financingNumber.title', state.currentFlow) }
			subtitle={ utils.m('financingNumber.subtitle', state.currentFlow) }
			isShowError={ state.isShowError }
			errorMsg={ state.errorMsg }
			hasNextButton={ true }
			onBackButtonClick={ history.goBack }
			onFormSubmit={ onFormSubmit }
			nextButtonDisabled={ hasEmptyFields() }
			location={ location }
			currentFlow={ state.currentFlow }
			pageName='FinancingNumber'
			currentStep={ C.PROGRESS_BAR.personalData }
			screen={ 'A3' }
			showButtonDivider={ true }
			submitRef={ submitRef }
			prevRef={ lastNameRef }
			nextRef={ salutationRef }
			gtmStep='userdata_financingnumber'
			gtmStatus='registration_pending'
			gtmSubStatus={ null }
			// tooltipInfo = { {
			// 	text: utils.m('financingNumber.tooltipText', state.currentFlow)
			// } }
		>
			<Row justifyContent='center'>
				<Col xs={ 12 } sm={ 12 } customClass = { styles.financingNumberCol }>
					<DefaultInput
						inputOnChange={ val => {
							utils.onInputChange(setState, state, 'financingNumber', val);
						} }
						label={ utils.m('financingNumber.label', 'fields') }
						hasError={ state.isFinancingNumberErr }
						init={ getCurrentFinancialNumber() }
						maxlength={ 10 }
						ref={ financingNumberRef }
						prevRef={ salutationRef }
						nextRef={ acceptPrivacyPoliciesRef }
						regex={ regex.digit() }
						testId='financing-number'
					/>
					<div className = { styles.tooltipIconWrapper } onClick = { event => event.stopPropagation() } >
						<TooltipIcon 
							background = '#d2f0f2' 
							isInfoIcon
							isClickable = { screenWidth <= C.SCREEN_SIZES.MAX_MOBILE_WIDTH }
							content = { 
								<div className = { styles.tooltipContentWrapper }>
									<div className = { styles.tooltipText }>
										{ utils.m('financingNumber.tooltipText', state.currentFlow)}
									</div>
															
								</div> } 
						/>
					</div>
				</Col>
				<Col xs={ 12 }>
					<CheckboxInput
						inputOnChange={ onChecked }
						label={
							<>
								{utils.m('acceptPrivacyPolicies.description', 'fields')}{' '}
								<a
									className='link'
									href='https://www.santander.de/datenschutz'
									target='_blank'
									rel='noopener noreferrer'
								>
									{utils.m('acceptPrivacyPolicies.linkText', 'fields')}{' '}
									<IconExternalLink />
								</a>
							</>
						}
						hasError={ state.acceptPrivacyPoliciesErr }
						checked={ state.acceptPrivacyPolicies }
						ref={ acceptPrivacyPoliciesRef }
						prevRef={ financingNumberRef }
						nextRef={ hasEmptyFields() ? financingNumberRef : submitRef }
						required={ true }
						testId='accept-privacy-policies'
					/>
				</Col>
			</Row>
		</Page>
	);
};

FinancingNumber.propTypes = {
	reducers: PropTypes.object,
	actions: PropTypes.object,
	history: PropTypes.object
};

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(_.merge({}, AppActions, EntryActions), dispatch)
});

const mapStateToProps = state => {
	return {
		reducers: state
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(FinancingNumber);
