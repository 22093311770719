import _ from 'lodash';

const defaultState = {
	month: '',
	year: '',
	month2: '',
	year2: ''
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case 'UPDATE_SHORT_EMPLOYMENT':
			return _.merge({}, state, {
				month: action.payload.month,
				year: action.payload.year,
				month2: action.payload.month2,
				year2: action.payload.year2
			});
		default:
			return state;
	}
};

export default reducers;
