/**
 * Action Type
*/

const UPDATE_PAYMENT_STATUS = 'UPDATE_PAYMENT_STATUS';

/*
* Action Creators
*/

export const onUpdatePaymentStatus = payload => ({
	type: UPDATE_PAYMENT_STATUS,
	payload
});