const defaultState = {
	amount: ''
};

const reducers = (state = defaultState, { type, payload: { amount } = {} }) => {
	switch (type) {
		case 'UPDATE_GENERAL_RATE_REDUCTION_AMOUNT':
			return {
				...state,
				amount
			};
		default:
			return state;
	}
};

export default reducers;
