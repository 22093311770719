// import bestGiro from './bestGiro';
// import gmk from './gmk';
// import creditCard from './creditCard';
import cbuRoutes from './cbu';

export const routes = [
	...cbuRoutes
];

export default routes;
