/**
 * Name Page
 *
 * Created by Simon Orzel
 * Last modified by Moritz Kippenberg
 * Last modified date : 23-07-2019
 */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Page from 'mod/Page/Page';
import { Row, Col } from 'mod/FlexGrid/index';
import _ from 'lodash';
import C from '../../constants';

import DefaultInput from 'ui/inputs/DefaultInput/DefaultInput';
import DropdownInput from 'ui/inputs/DropdownInput/DropdownInput';

import * as AppActions from '../../store/App/actions';
import * as EntryActions from '../../store/Name/actions';
import * as utils from '../../utils/utils';
import * as googleEvents from '../../utils/googleEvents';
import * as regex from '../../utils/regex';

const Name = ({ reducers, actions, history, location }) => {
	const [state, setState] = useState({
		path: C.ROUTES.NAME,
		currentFlow: utils.getCurrentFlow(location),
		firstName: '',
		isFirstNameErr: false,
		lastName: '',
		isLastNameErr: false,
		salutation: '',
		salutationItems: [
			{ text: utils.m('salutation.items.female', 'fields'), value: 'FEMALE' },
			{ text: utils.m('salutation.items.male', 'fields'), value: 'MALE' }
		],
		isSalutationErr: false,
		title: '',
		titleItems: [
			{ text: utils.m('noEntry.label', 'fields'), value: 'none' },
			{ text: utils.m('title.items.dr', 'fields'), value: 'Dr.' },
			{ text: utils.m('title.items.prof', 'fields'), value: 'Prof.' },
			{ text: utils.m('title.items.profDr', 'fields'), value: 'Prof. Dr.' }
		],
		isTitleErr: false,
		errorMsg: '',
		isShowError: false
	});

	useEffect(() => {
		// Set the page Index for navigation
		actions.updatePage(utils.getPageIndex(state.path, state.currentFlow));
		// Set the default value on page load
		setState({
			...state,
			firstName: reducers.name.firstName,
			lastName: reducers.name.lastName,
			salutation: reducers.name.salutation,
			title: reducers.name.title
		});
	}, [reducers.queryParams]);

	const onFormSubmit = () => {
		googleEvents.continueValid();

		actions.onUpdateName(state);
		actions.updateReload(false);

		utils.goToPage(history, state.currentFlow + C.ROUTES.BIRTHDAY);
	};

	const hasEmptyFields = () => {
		return (
			utils.isEmpty(state.salutation) ||
			utils.isEmpty(state.firstName) ||
			utils.isEmpty(state.lastName)
		);
	};

	// const titleRef = useRef(null);
	const salutationRef = useRef(null);
	const firstNameRef = useRef(null);
	const lastNameRef = useRef(null);
	const submitRef = useRef(null);

	return (
		<Page
			title={ utils.m('name.title', state.currentFlow) }
			subtitle={ utils.m('name.subtitle', state.currentFlow) }
			isShowError={ state.isShowError }
			errorMsg={ state.errorMsg }
			hasNextButton={ true }
			onFormSubmit={ onFormSubmit }
			prevButtonPath='hidden'
			nextButtonDisabled={ hasEmptyFields() }
			location={ location }
			currentFlow={ state.currentFlow }
			currentStep={ C.PROGRESS_BAR.personalData }
			screen='A1'
			pageName='Name'
			showButtonDivider={ false }
			submitRef={ submitRef }
			prevRef={ lastNameRef }
			nextRef={ salutationRef }
			gtmStep='userdata_name'
			gtmStatus='registration_pending'
			gtmSubStatus={ null }
		>
			<Row justifyContent='center'>
				<Col xs={ 12 } sm={ 4 }>
					<DropdownInput
						inputOnChange={ val => {
							utils.onInputChange(setState, state, 'salutation', val);
						} }
						label={ utils.m('salutation.label', 'fields') }
						hasError={ state.isSalutationErr }
						items={ state.salutationItems }
						init={ state.salutation }
						ref={ salutationRef }
						prevRef={ hasEmptyFields() ? lastNameRef : submitRef }
						nextRef={ firstNameRef }
						testId='salutation'
					/>
				</Col>

				<Col xs={ 12 } sm={ 4 }>
					<DefaultInput
						inputOnChange={ val => {
							utils.onInputChange(setState, state, 'firstName', val);
						} }
						label={ utils.m('firstName.label', 'fields') }
						hasError={ state.isFirstNameErr }
						init={ state.firstName }
						maxlength={ 50 }
						ref={ firstNameRef }
						prevRef={ salutationRef }
						nextRef={ lastNameRef }
						regex={ regex.wordWithSpaceAndDash() }
						testId='first-name'
					/>
				</Col>

				<Col xs={ 12 } sm={ 4 }>
					<DefaultInput
						inputOnChange={ val => {
							utils.onInputChange(setState, state, 'lastName', val);
						} }
						label={ utils.m('lastName.label', 'fields') }
						hasError={ state.isLastNameErr }
						init={ state.lastName }
						maxlength={ 50 }
						ref={ lastNameRef }
						prevRef={ firstNameRef }
						nextRef={ hasEmptyFields() ? salutationRef : submitRef }
						regex={ regex.wordWithSpaceAndDash() }
						testId='last-name'
					/>
				</Col>
			</Row>
		</Page>
	);
};

Name.propTypes = {
	reducers: PropTypes.object,
	actions: PropTypes.object,
	history: PropTypes.object
};

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(_.merge({}, AppActions, EntryActions), dispatch)
});

const mapStateToProps = state => {
	return {
		reducers: state
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Name);
