/**
 * ButtonQuestionmarkIcon
 *
 * Created by Moritz Kippenberg
 * Last modified by Moritz Kippenberg
 * Last modified date : 01-07-2019
 */

import React from 'react';
import PropTypes from 'prop-types';

import IconQuestionMark from 'ui/svg/IconQuestionMark';

import styles from './ButtonQuestionmarkIcon.scss';

const ButtonQuestionmarkIcon = (props) => {

	return(
		<IconQuestionMark
			fill={ props.color ? props.color : '#979797' }
			className={ styles.iconQuestionmark }
			onClick={ (event) => {
				if(props.onButtonClick) {
					event.stopPropagation();
					props.onButtonClick();
				}
			} }
		/>
	);

};

ButtonQuestionmarkIcon.propTypes = {
	onButtonClick: PropTypes.func,
	color: PropTypes.string
};

export default ButtonQuestionmarkIcon;
