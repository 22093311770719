/**
 * Action Type
*/

const UPDATE_DEFERMENT_OF_PAYMENT = 'UPDATE_DEFERMENT_OF_PAYMENT';

/*
* Action Creators
*/

export const onUpdateDefermentOfPayment = payload => ({
	type: UPDATE_DEFERMENT_OF_PAYMENT,
	payload
});