import C from '../../constants';
import Name from '../../pages/Name/Name';
import Birthday from '../../pages/Birthday/Birthday';
import FinancingNumber from '../../pages/FinancingNumber/FinancingNumber';
import CreditCardNumber from '../../pages/CreditCardNumber/CreditCardNumber';
import Address from '../../pages/Address/Address';
import Contact from '../../pages/Contact/Contact';
import PaymentStatus from '../../pages/PaymentStatus/PaymentStatus';
import PaymentStatusPaid from '../../pages/PaymentStatusPaid/PaymentStatusPaid';
import PaymentStatusWillPay from '../../pages/PaymentStatusWillPay/PaymentStatusWillPay';
import FuturePaymentProblem from '../../pages/FuturePaymentProblem/FuturePaymentProblem';
import PaymentProblems from '../../pages/PaymentProblems/PaymentProblems';
import Thankyou from '../../pages/Thankyou/Thankyou';
import Unemployment from '../../pages/Unemployment/Unemployment';
import NewAddress from '../../pages/NewAddress/NewAddress';
import ShortEmployment from '../../pages/ShortEmployment/ShortEmployment';
import InabilityOfWork from '../../pages/InabilityOfWork/InabilityOfWork';
import InputOtherReason from '../../pages/InputOtherReason/InputOtherReason';
import EconomicSituationChanged from '../../pages/EconomicSituationChanged/EconomicSituationChanged';
import ProvideSecondBorrower from '../../pages/ProvideSecondBorrower/ProvideSecondBorrower';
import MonthlyIncome from '../../pages/MonthlyIncome/MonthlyIncome';
import NumberOfChildren from '../../pages/NumberOfChildren/NumberOfChildren';
import MonthlyOutgoings from '../../pages/MonthlyOutgoings/MonthlyOutgoings';
import GeneralRateReduction from '../../pages/GeneralRateReduction/GeneralRateReduction';
import TempRateReduction from '../../pages/TempRateReduction/TempRateReduction';
import Solutions from '../../pages/Solutions/Solutions';
import DefermentOfPayment from '../../pages/DefermentOfPayment/DefermentOfPayment';
import ThankyouEnd from '../../pages/ThankyouEnd/ThankyouEnd';

const flow = C.FRONTEND.CBU;

export const routes = [
	{
		path: flow + C.ROUTES.NAME,
		component: Name
	},
	{
		path: flow + C.ROUTES.BIRTHDAY,
		component: Birthday
	},
	{
		path: flow + C.ROUTES.CREDIT_CARD_NUMBER,
		component: CreditCardNumber
	},
	{
		path: flow + C.ROUTES.FINANCING_NUMBER,
		component: FinancingNumber
	},
	{
		path: flow + C.ROUTES.ADDRESS,
		component: Address
	},
	{
		path: flow + C.ROUTES.CONTACT,
		component: Contact
	},
	{
		path: flow + C.ROUTES.PAYMENT_STATUS,
		component: PaymentStatus
	},
	{
		path: flow + C.ROUTES.PAYMENT_STATUS_PAID,
		component: PaymentStatusPaid
	},
	{
		path: flow + C.ROUTES.PAYMENT_STATUS_WILL_PAY,
		component: PaymentStatusWillPay
	},
	{
		path: flow + C.ROUTES.FUTURE_PAYMENT_PROBLEM,
		component: FuturePaymentProblem
	},
	{
		path: flow + C.ROUTES.THANKYOU,
		component: Thankyou
	},
	{
		path: flow + C.ROUTES.PAYMENT_PROBLEMS,
		component: PaymentProblems
	},
	{
		path: flow + C.ROUTES.UNEMPLOYMENT,
		component: Unemployment
	},
	{
		path: flow + C.ROUTES.NEW_ADDRESS,
		component: NewAddress
	},
	{
		path: flow + C.ROUTES.SHORT_EMPLOYMENT,
		component: ShortEmployment
	},
	{
		path: flow + C.ROUTES.INABILITY_TO_WORK,
		component: InabilityOfWork
	},
	{
		path: flow + C.ROUTES.INPUT_OTHER_REASON,
		component: InputOtherReason
	},
	{
		path: flow + C.ROUTES.ECONOMIC_SITUATION_CHANGED,
		component: EconomicSituationChanged
	},
	{
		path: flow + C.ROUTES.NUMBER_OF_CHILDREN,
		component: NumberOfChildren
	},
	{
		path: flow + C.ROUTES.PROVIDE_SECOND_BORROWER,
		component: ProvideSecondBorrower
	},
	{
		path: flow + C.ROUTES.MONTHLY_INCOME,
		component: MonthlyIncome
	},
	{
		path: flow + C.ROUTES.MONTHLY_OUTGOINGS,
		component: MonthlyOutgoings
	},
	{
		path: flow + C.ROUTES.SOLUTIONS,
		component: Solutions
	},
	{
		path: flow + C.ROUTES.DEFERMENT_OF_PAYMENT,
		component: DefermentOfPayment
	},
	{
		path: flow + C.ROUTES.TEMP_RATE_REDUCTION,
		component: TempRateReduction
	},
	{
		path: flow + C.ROUTES.GENERAL_RATE_REDUCTION,
		component: GeneralRateReduction
	},
	{
		path: flow + C.ROUTES.THANKYOU_END,
		component: ThankyouEnd
	}
];

export default routes;
