/**
 * Address Page
 *
 * Created by Perry Tang
 * Last modified by Moritz Kippenberg
 * Last modified date : 23-07-2019
 */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Page from 'mod/Page/Page';
import { Row, Col } from 'mod/FlexGrid/index';
import _ from 'lodash';
import C from '../../constants';

import * as AppActions from '../../store/App/actions';
import * as EntryActions from '../../store/Address/actions';
import * as utils from '../../utils/utils';
import DefaultInput from 'ui/inputs/DefaultInput/DefaultInput';
import * as googleEvents from '../../utils/googleEvents';
import * as regex from '../../utils/regex';
// import * as azDirect from '../../utils/azDirect';

// import styles from './Address.scss';

const Address = ({ reducers, actions, history, location }) => {

	// TODO: implement AZ Direct for autocomplete

	const [state, setState] = useState({
		path: C.ROUTES.ADDRESS,
		currentFlow: utils.getCurrentFlow(location),
		state: '',
		stateItems: [
			{ text: 'Baden-Württemberg', value: 'Baden-Württemberg' },
			{ text: 'Bayern', value: 'Bayern' },
			{ text: 'Berlin', value: 'Berlin' },
			{ text: 'Brandenburg', value: 'Brandenburg' },
			{ text: 'Bremen', value: 'Bremen' },
			{ text: 'Hamburg', value: 'Hamburg' },
			{ text: 'Hessen', value: 'Hessen' },
			{ text: 'Mecklenburg-Vorpommern', value: 'Mecklenburg-Vorpommern' },
			{ text: 'Niedersachsen', value: 'Niedersachsen' },
			{ text: 'Nordrhein-Westfalen', value: 'Nordrhein-Westfalen' },
			{ text: 'Rheinland-Pfalz', value: 'Rheinland-Pfalz' },
			{ text: 'Saarland', value: 'Saarland' },
			{ text: 'Sachsen', value: 'Sachsen' },
			{ text: 'Sachsen-Anhalt', value: 'Sachsen-Anhalt' },
			{ text: 'Schleswig-Holstein', value: 'Schleswig-Holstein' },
			{ text: 'Thüringen', value: 'Thüringen' }
		],
		isStateErr: false,
		postcode: '',
		isPostcodeErr: false,
		city: '',
		isCityErr: false,
		street: '',
		isStreetErr: false,
		number: '',
		isNumberErr: false,
		hasPreviousAddress: false,
		errorMsg: '',
		isShowError: false
	});

	useEffect(() => {
		// Set the page Index for navigation
		actions.updatePage(utils.getPageIndex(state.path, state.currentFlow));

		// Set the default value on page load
		setState({
			...state,
			state: reducers.address.state,
			postcode: reducers.address.postcode,
			city: reducers.address.city,
			street: reducers.address.street,
			number: reducers.address.number,
			hasPreviousAddress: reducers.address.hasPreviousAddress
		});

		// Block access from user type in url directly
		utils.checkIsFromNav(history, reducers.app.isReload, state.currentFlow + C.ROUTES.NAME);

	}, []);

	const onFormSubmit = () => {

		/*** Validation ***/
		let isValid = true;
		let cloneState = _.cloneDeep(state);

		cloneState.errorMsg = '';

		if (state.postcode.trim().length !== 5) {
			isValid = false;
			utils.showError(cloneState, 'isPostcodeErr', utils.m('postcode.validation.invalid', 'fields'));
		} 
		else {
			cloneState.isPostcodeErr = false;
		}
		
		if (state.city.trim().length < 2) {
			isValid = false;
			utils.showError(cloneState, 'isCityErr', utils.m('city.validation.invalid', 'fields'));
		} 
		else {
			cloneState.isCityErr = false; 
		}

		if (state.street.trim().length < 2) {
			isValid = false;
			utils.showError(cloneState, 'isStreetErr', utils.m('street.validation.invalid', 'fields'));
		} 
		else {
			cloneState.isStreetErr = false;
		}

		if (isValid) {

			// on validation success
			googleEvents.continueValid();

			actions.onUpdateAddress(state);
			actions.updateReload(false);

			utils.goToPage(history, state.currentFlow + C.ROUTES.CONTACT);

		}
		else {
			// on validation failure
			googleEvents.continueInvalid();
			setState(cloneState);
		}

	};

	const hasEmptyFields = () => {
		return utils.isEmpty(state.postcode) ||
			utils.isEmpty(state.city) ||
			utils.isEmpty(state.street) ||
			utils.isEmpty(state.number);
	};

	const stateRef = useRef(null);
	const postcodeRef = useRef(null);
	const cityRef = useRef(null);
	const streetRef = useRef(null);
	const numberRef = useRef(null);
	const hasPreviousAddressRef = useRef(null);
	const submitRef = useRef(null);

	return (
		<Page
			title={ utils.m('address.title', state.currentFlow) }
			subtitle={ utils.m('address.subtitle', state.currentFlow) }
			isShowError={ state.isShowError }
			errorMsg={ state.errorMsg }
			hasNextButton={ true }
			onBackButtonClick = { history.goBack }
			onFormSubmit={ onFormSubmit }
			nextButtonDisabled={ hasEmptyFields() }
			location={ location }
			currentFlow={ state.currentFlow }
			pageName="Address"
			currentStep={ C.PROGRESS_BAR.personalData }
			screen='A5'
			showButtonDivider={ false }
			submitRef={ submitRef }
			prevRef={ state.currentFlow !== C.FRONTEND.GMK ? hasPreviousAddressRef : numberRef }
			nextRef={ stateRef }
			gtmStep="personaldata_address"
			gtmStatus="application_pending"
			gtmSubStatus={ null }
		>
			<Row justifyContent="center">

				<Col xs={ 12 } sm={ 6 }>
					<DefaultInput
						inputOnChange={ (val) => {
							utils.onInputChange(setState, state, 'street', val);
						} }
						label={ utils.m('street.label', 'fields') }
						hasError={ state.isStreetErr }
						init={ reducers.address.street }
						maxlength={ 50 }
						ref={ streetRef }
						prevRef={ cityRef }
						nextRef={ numberRef }
						regex={ regex.wordWithSpaceAndDash() }
						testId="street"
					/>
				</Col>

				<Col xs={ 12 } sm={ 6 }>
					<DefaultInput
						inputOnChange={ (val) => {
							utils.onInputChange(setState, state, 'number', val);
						} }
						label={ utils.m('houseNumber.label', 'fields') }
						hasError={ state.isNumberErr }
						init={ reducers.address.number }
						maxlength={ 5 }
						ref={ numberRef }
						prevRef={ streetRef }
						nextRef={ postcodeRef }
						regex={ regex.wordWithSpaceAndDashAndNumbers() }
						testId="house-number"
					/>
				</Col>

				<Col xs={ 12 } sm={ 6 }>
					<DefaultInput
						inputOnChange={ (val) => {
							utils.onInputChange(setState, state, 'postcode', val);
						} }
						label={ utils.m('postcode.label', 'fields') }
						hasError={ state.isPostcodeErr }
						init={ reducers.address.postcode }
						maxlength={ 5 }
						type="number"
						ref={ postcodeRef }
						prevRef={ numberRef }
						nextRef={ cityRef }
						regex={ regex.digit() }
						testId="postcode"
					/>
				</Col>

				

				<Col xs={ 12 } sm={ 6 }>
					<DefaultInput
						inputOnChange={ (val) => {
							utils.onInputChange(setState, state, 'city', val);
						} }
						label={ utils.m('city.label', 'fields') }
						hasError={ state.isCityErr }
						init={ reducers.address.city }
						maxlength={ 50 }
						ref={ cityRef }
						prevRef={ postcodeRef }
						nextRef={ streetRef }
						regex={ regex.wordWithSpaceAndDash() }
						testId="city"
					/>
				</Col>

			</Row>
		</Page>
	);
};

Address.propTypes = {
	reducers: PropTypes.object,
	actions: PropTypes.object,
	history: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(_.merge({}, AppActions, EntryActions), dispatch)
});

const mapStateToProps = (state) => {
	return {
		reducers: state
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Address);
