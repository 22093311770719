/**
 * Component for the pagination button
 *
 * Created by Simon Orzel
 * Last modified by Moritz Kippenberg
 * Last modified date : 15-07-2019
 */

import React from 'react';
import PropTypes from 'prop-types';

import Arrow from 'ui/svg/Arrow';

import buttonStyles from './PaginationButton.scss';

const PaginationButton = (props) => {
	const style = props.backwards ?
		buttonStyles.buttonWrapper+' '+buttonStyles.left : 
		buttonStyles.buttonWrapper+' '+buttonStyles.right;

	return (
		<div className={ style } onClick={ props.onClick }>
			<span>
				<Arrow />
			</span>
		</div>
	);
};

// Check the props type
PaginationButton.propTypes = {
	backwards: PropTypes.bool,
	onClick: PropTypes.func
};

// Specifies the default values for props:
PaginationButton.defaultProps = {
	backwards: false,
	onClick: () => {console.log('arrow func not found');}
};

export default PaginationButton;
