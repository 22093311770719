/**
 * ButtonQuestionmarkIcon
 *
 * Created by Moritz Kippenberg
 * Last modified by Moritz Kippenberg
 * Last modified date : 01-07-2019
 */

import React from 'react';
import PropTypes from 'prop-types';

import ArrowWithCircle from 'ui/svg/ArrowWithCircle';

import styles from './ButtonLink.scss';

const ButtonLink = (props) => {

	return(
		<div className={ styles.ButtonLink }>
			<ArrowWithCircle
				fill={ props.color ? props.color : '#979797' }
				
				onClick={ (event) => {
					if(props.onButtonClick) {
						event.stopPropagation();
						props.onButtonClick();
					}
				} }
			/>
		</div>
	);

};

ButtonLink.propTypes = {
	onButtonClick: PropTypes.func,
	color: PropTypes.string
};

export default ButtonLink;
