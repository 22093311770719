/**
 * The redux store
 *
 * Created by Perry Tang
 * Last modified by Perry Tang
 * Last modified date : 20-5-2019
 */

import { applyMiddleware, createStore, compose } from 'redux';
import thunk from 'redux-thunk';
import reducer from './reducers';

const logger = store => next => action => {
	//console.log('dispatching', action);
	let result = next(action);
	//console.log('next state', store.getState());
	return result;
};

const errorHandler = (store) => (next) => (action) => {
	try {
		return next(action);
	}
	catch(e) {
		console.log('ERROR!', e);
	}
};

const middleware = applyMiddleware(
	logger,
	errorHandler,
	thunk
);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

let store;

if (process.env.NODE_ENV === 'development'){
	store = createStore(reducer, composeEnhancers(middleware));
}
else{
	store = createStore(reducer, middleware);
}

export default store;