// C7
import React, { useState, useEffect } from 'react';

import Page from 'mod/Page/Page';
import { Row, Col } from 'mod/FlexGrid/index';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import C from '../../constants';
import * as utils from '../../utils/utils';

import * as AppActions from '../../store/App/actions';
import { onUpdateOtherReason } from '../../store/InputOtherReason/actions';

import DefaultInput from 'ui/inputs/DefaultInput/DefaultInput';

import styles from './InputOtherReason.scss';

const InputOtherReason = ({ actions, reducers, history, location }) => {
	const [path] = useState(C.ROUTES.INPUT_OTHER_REASON);
	const [currentFlow] = useState(utils.getCurrentFlow(location));
	const [textValue, setTextValue] = useState('');

	useEffect(() => {
		actions.updatePage(utils.getPageIndex(path, currentFlow));
		// Block access from user type in url directly
		utils.checkIsFromNav(history, reducers.app.isReload, currentFlow + C.ROUTES.NAME);

		setTextValue(reducers.otherReason.otherReason);
	}, []);

	const hasEmptyFields = textValue === '';

	const onFormSubmit = () => {
		actions.onUpdateOtherReason({ otherReason: textValue });
		utils.goToPage(history, currentFlow + C.ROUTES.ECONOMIC_SITUATION_CHANGED);
	};

	return (
		<Page
			title={ utils.m('otherReason.title', currentFlow) }
			subtitle={ utils.m('otherReason.subtitle', currentFlow) }
			isShowError={ false }
			errorMsg={ '' }
			hasNextButton={ true }
			onBackButtonClick={ history.goBack }
			onFormSubmit={ onFormSubmit }
			nextButtonDisabled={ hasEmptyFields }
			location={ location }
			currentFlow={ currentFlow }
			pageName='inputOtherReason'
			currentStep={ C.PROGRESS_BAR.statement }
			screen='C7'
			showButtonDivider={ false }
			gtmStep='userdata_geburtsdatum'
			gtmStatus='registration_pending'
			gtmSubStatus={ null }
		>
			<Row justifyContent='center'>
				<Col xs={ 12 }>
					<DefaultInput
						inputOnChange={ val => {
							setTextValue(val);
						} }
						label={ utils.m('otherReason.label', 'fields') }
						hasError={ false }
						init={ textValue }
						maxlength={ 120 }
						placeholder={ '' }
						type='textarea'
						rows={ 50 }
						cols={ 40 }
						customClass={ styles.reasonInput }
						testId='other-reason'
					/>
				</Col>
			</Row>
		</Page>
	);
};

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({ ...AppActions, onUpdateOtherReason }, dispatch)
});

const mapStateToProps = state => ({
	reducers: state
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(InputOtherReason);
