import _ from 'lodash';

const defaultState = {
	email: '',
	allowAdvertising: false,
	showAlreadyExistsPopup: false
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case 'UPDATE_EMAIL':
			return _.merge({}, state, {
				email: action.payload.email,
				allowAdvertising: action.payload.allowAdvertising
			});
		case 'UPDATE_SHOW_ALREADY_EXISTS_POPUP':
			return _.merge({}, state, {
				showAlreadyExistsPopup: action.payload.showAlreadyExistsPopup
			});
		default:
			return state;
	}
};

export default reducers;
