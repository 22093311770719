import _ from 'lodash';

const defaultState = {
	state: '',
	postcode: '',
	city: '',
	street: '',
	number: '',
	hasPreviousAddress: false
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case 'UPDATE_ADDRESS':
			return _.merge({}, state, {
				state: action.payload.state,
				postcode: action.payload.postcode,
				city: action.payload.city,
				street: action.payload.street,
				number: action.payload.number,
				hasPreviousAddress: action.payload.hasPreviousAddress
			});
		default:
			return state;
	}
};

export default reducers;