import * as React from 'react';

const Plus = props => (
	<svg width={ 24 } height={ 25 } viewBox="0 0 24 25" { ...props }>
		<path 
			fill="#000" 
			fillRule="evenodd" 
			d="M12.6 12.4V7.343a.6.6 0 0 0-1.2 0V12.4H6.344a.599.599 0 1 0 0 1.2h5.058v5.057a.599.599 0 1 0 1.199 0V13.6h5.058a.595.595 0 0 0 .599-.6.6.6 0 0 0-.6-.6H12.6z"
		/>
	</svg>
);

export default Plus;
