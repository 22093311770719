import _ from 'lodash';

const defaultState = {
	accessToken: '',
	isAuth:
		process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test',
	pageIndex: 0,
	isReload: true,
	buttonLoading: false,
	appLoading: false,
	isShowOverlay: false,
	steps: [],
	currentStep: '',
	currentScreen: '',
	progress: 0
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case 'UPDATE_TOKEN':
			return _.merge({}, state, {
				accessToken: action.payload.registrationToken
			});
		case 'UPDATE_AUTH':
			return _.merge({}, state, {
				isAuth: action.payload
			});
		case 'UPDATE_PAGE':
			return _.merge({}, state, {
				pageIndex: action.payload
			});
		case 'UPDATE_STEPS':
			return {
				...state,
				steps: action.payload
			};
		case 'UPDATE_CURRENT_STEP':
			return _.merge({}, state, {
				currentStep: action.payload
			});
		case 'UPDATE_CURRENT_SCREEN':
			return _.merge({}, state, {
				currentScreen: action.payload
			});
		case 'UPDATE_PROGRESS':
			return _.merge({}, state, {
				progress: action.payload
			});
		case 'UPDATE_RELOAD':
			return _.merge({}, state, {
				isReload: action.payload
			});
		case 'UPDATE_BUTTON_LOADING':
			return _.merge({}, state, {
				buttonLoading: action.payload
			});
		case 'UPDATE_APP_LOADING':
			return _.merge({}, state, {
				appLoading: action.payload
			});
		case 'IS_SHOW_OVERLAY':
			return _.merge({}, state, {
				isShowOverlay: action.payload
			});
		default:
			return state;
	}
};

export default reducers;
