import _ from 'lodash';

const defaultState = {
	numberChildren: 1,
	numberChildrenWithBenefit: 0
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case 'UPDATE_CHILDREN':
			return _.merge({}, state, {
				numberChildren: action.payload.numberChildren,
				numberChildrenWithBenefit: action.payload.numberChildrenWithBenefit
			});
		default:
			return state;
	}
};

export default reducers;
