/**
 * Api functions and calls
 *
 * Created by Moritz Kippenberg
 * Last modified by Moritz Kippenberg
 * Last modified date : 22-07-2019
 */

import axios from 'axios/index';
import * as utils from './utils';
import _ from 'lodash';
import C from '../constants';
import { getDateFromApi } from './utils';
import * as googleEvents from './googleEvents';

export const getApiBaseURL = () => {

	let baseURL;

	switch(process.env.NODE_ENV) {
		case 'development':
			baseURL = process.env.REACT_APP_API_URL;
			break;
		case 'production':
			baseURL = '/api';
			break;
		default:
			baseURL = '/api';
			break;
	}
	return baseURL;

};

export const saveDataToServer = (data) => {	
	const options = {
		method: 'post',
		url: getApiBaseURL(),
		data
	};

	if (process.env.NODE_ENV === 'development') {
		return axios({
			...options,
			headers: {
				'Content-Type': 'application/json',
				'Authorization': 'Bearer ' + process.env.REACT_APP_API_TOKEN
			}
		});
	}
	return axios(options);
};

export const sendDataToServer = async (data, updateButtonLoading, setErrorMessage) => {
	await updateButtonLoading(true);
	// here you have to get the formatted data
	try {
		await saveDataToServer(data);
		await updateButtonLoading(false);
		return true;
	} catch (e) {
		// As we don not have error handling in the front end
		// We are assuming everything goes well
		await updateButtonLoading(false);
		if (!e.status) {
			// network error
			// setErrorMessage(e.message);
			return true;
		} else if (e.response.status === 400){
			// setErrorMessage(e.response.data.message);
			return true;
		} else if (e.response.status === 501) {
			// setErrorMessage(e.response.data.message);
			return true;
		}
	}
	return true;
};

const showDefaultConnectionError = (setState, state) => {
	googleEvents.continueInvalid();
	utils.showErrorDirectly(state, setState, 'isApiErr', utils.m('error.connection', 'fields'));
};

const showDefaultMissingDataError = (setState, state) => {
	googleEvents.continueInvalid();
	utils.showErrorDirectly(state, setState, 'isApiErr', utils.m('error.missingData', 'fields'));
};

const prepareApiCall = (state, setState, actions) => {
	try {
		actions.updateButtonLoading(true);
	}
	catch(e) {} // eslint-disable-line
	try {
		actions.updateOverlay(true);
	}
	catch(e) {} // eslint-disable-line
	utils.hideError(state, setState);
	utils.hideSuccess(state, setState);
	return _.cloneDeep(state);
};

const stopApiCall = (actions) => {
	try {
		actions.updateButtonLoading(false);
	}
	catch(e) {} // eslint-disable-line
	try {
		actions.updateOverlay(false);
	}
	catch(e) {} // eslint-disable-line
};

const addUserDataToStore = (actions, data) => {

	try {
		actions.updateToken(data);

		actions.updateNameFromAPI(data);

		actions.onUpdateEmail(data);

		if(data.mobilePhone) {
			let mobilePhoneArray = data.mobilePhone.split(' ');
			actions.onUpdateMobilePhone({
				countryCode: mobilePhoneArray[0].replace(/[^0-9]/, ''),
				mobilePhone: mobilePhoneArray[1]
			});
		}

		actions.onUpdateBirthday(getDateFromApi(data.dateOfBirth));

		actions.updateAuth(true);
	}
	catch (e) {
		console.error(e);
	}

};



// Giro API Calls

/**
 * POST /registration
 */
export const registration = (state, setState, reducers, actions, history, currentFlow) => {

	prepareApiCall(state, setState, actions);

	if(
		reducers.name.salutation &&
		reducers.name.firstName &&
		reducers.name.lastName &&
		reducers.birthday.day &&
		reducers.birthday.month &&
		reducers.birthday.year &&
		state.email
	) {

		let data = {
			email: state.email,
			salutation: reducers.name.salutation,
			surname: reducers.name.lastName,
			givenname: reducers.name.firstName,
			dateOfBirth: utils.getDateForApi(reducers.birthday.day, reducers.birthday.month, reducers.birthday.year),
			productType: utils.getProductType(currentFlow)
		};

		if(reducers.name.title) {
			data.title = reducers.name.title;
		}

		if(state.allowAdvertising === true) {
			data.allowAdvertising = true;
		}
		else {
			data.allowAdvertising = false;
		}

		if(process.env.NODE_ENV !== 'development') {

			axios.post(getApiBaseURL() + '/registration', data)
				.then((response) => {
					if (response.status === 200) {
						onRegistrationSuccess(state, setState, actions, history, currentFlow);
					}
					else {
						showDefaultConnectionError(setState, state);
					}
					stopApiCall(actions);
				})
				.catch((err) => {
					if (err.response) {
						switch (err.response.status) {
							case 401:
								showDefaultConnectionError(setState, state);
								break;
							case 406:
								googleEvents.continueInvalid();
								utils.showErrorDirectly(state, setState, 'isApiErr', utils.m('error.mobileNotVerified', 'fields'));
								break;
							case 409:
								actions.updateShowAlreadyExistsPopup({ showAlreadyExistsPopup: true });
								break;
							case 500:
								showDefaultConnectionError(setState, state);
								break;
							default:
								showDefaultConnectionError(setState, state);
								break;
						}
					}
					stopApiCall(actions);
				});

		}
		else {

			console.log('API Mockup: POST /registration');
			//actions.updateShowAlreadyExistsPopup({ showAlreadyExistsPopup: true });
			onRegistrationSuccess(state, setState, actions, history, currentFlow);

			stopApiCall(actions);

		}

	}
	else {
		showDefaultMissingDataError(setState, state);
		stopApiCall(actions);
	}

};
const onRegistrationSuccess = (state, setState, actions, history, currentFlow) => {

	utils.hideError(state, setState);

	actions.onUpdateEmail(state);
	actions.updateReload(false);

	googleEvents.continueValid();

	utils.goToPage(history, currentFlow + C.ROUTES.EMAIL_SENT);

};



/**
 * PATCH /email-confirmation
 */
export const emailConfirm = (actions, history, accessToken, currentFlow) => {

	if(accessToken) {

		let data = {
			registrationToken: accessToken
		};

		if(process.env.NODE_ENV !== 'development') {

			axios.patch(getApiBaseURL() + '/email-confirmation', data)
				.then((response) => {
					if(response.status === 200) {
						onEmailConfirmSuccess(response, actions, history, currentFlow);
					}
					else {
						googleEvents.continueInvalid();
						history.push(currentFlow + C.ROUTES.EMAIL_ERROR);
					}
				})
				.catch((err) => {
					if(err.response) {
						switch (err.response.status) {
							case 401:
								googleEvents.continueInvalid();
								history.push(currentFlow + C.ROUTES.EMAIL_ERROR);
								break;
							case 500:
								googleEvents.continueInvalid();
								history.push(currentFlow + C.ROUTES.EMAIL_ERROR);
								break;
							default:
								googleEvents.continueInvalid();
								history.push(currentFlow + C.ROUTES.EMAIL_ERROR);
								break;
						}
					}
				});

		}
		else {

			console.log('API Mockup: PATCH /email-confirmation');
			onEmailConfirmSuccess({
				data: {
					registrationToken: accessToken,
					salutation: 'Herr',
					firstname: 'Moritz',
					lastname: 'Kippenberg',
					allowAdvertising: true,
					email: 'moritz.kippenberg@ext.denkwerk.com',
					mobilePhone: '+49 1234567890',
					dateOfBirth: '1990-04-06'
				}
			}, actions, history, currentFlow);

		}

	}
	else {
		googleEvents.continueInvalid();
		history.push('/');
	}

};
const onEmailConfirmSuccess = (response, actions, history, currentFlow) => {

	if(
		response.data &&
		response.data.registrationToken &&
		response.data.salutation &&
		response.data.firstname &&
		response.data.lastname &&
		response.data.allowAdvertising !== null && response.data.allowAdvertising !== undefined &&
		response.data.email &&
		response.data.dateOfBirth
	) {
		googleEvents.continueValid();
		addUserDataToStore(actions, response.data);
	}
	else {
		googleEvents.continueInvalid();
		history.push(currentFlow + C.ROUTES.EMAIL_ERROR);
	}

};



/**
 * POST /login
 */
export const login = (state, setState, reducers, actions, history, currentFlow) => {

	prepareApiCall(state, setState, actions);

	if(state.email) {

		let data = {
			email: state.email
		};

		if(process.env.NODE_ENV !== 'development') {

			axios.post(getApiBaseURL() + '/login', data)
				.then((response) => {
					if (response.status === 200) {
						onLoginSuccess(response, state, setState, reducers, actions, history, currentFlow);
					}
					else {
						showDefaultConnectionError(setState, state);
					}
					stopApiCall(actions);
				})
				.catch((err) => {
					if (err.response) {
						switch (err.response.status) {
							case 401:
								showDefaultConnectionError(setState, state);
								break;
							case 500:
								showDefaultConnectionError(setState, state);
								break;
							default:
								showDefaultConnectionError(setState, state);
								break;
						}
					}
					stopApiCall(actions);
				});

		}
		else {

			console.log('API Mockup: POST /login');

			onLoginSuccess({
				data: {
					emailVerified: true,
					mobileVerified: true,
					registrationToken: 'zuksdf7u32u9uojasdkfu'
				}
			}, state, setState, reducers, actions, history, currentFlow);

			stopApiCall(actions);

		}

	}
	else {
		showDefaultMissingDataError(setState, state);
		stopApiCall(actions);
	}

};
const onLoginSuccess = (response, state, setState, reducers, actions, history, currentFlow) => {

	if (response.data.emailVerified && response.data.mobileVerified) {

		utils.hideError(state, setState);

		actions.updateReload(false);
		actions.onUpdateMobilePhone(response.data);
		actions.updateToken(response.data);

		googleEvents.continueValid();

		utils.goToPage(history, currentFlow + C.ROUTES.PASSCODE);

	}
	else {
		googleEvents.continueInvalid();
		utils.showErrorDirectly(state, setState, 'isEmailErr', utils.m('email.validation.invalid', 'fields'));
	}

};



/**
 * PATCH /generate-otp
 */
export const generateOtp = (state, setState, reducers, actions, history, currentFlow) => {

	prepareApiCall(state, setState, actions);

	if(
		reducers.app.accessToken &&
		(state.mobilePhone || reducers.mobilePhone.mobilePhone) &&
		(state.countryCode || reducers.mobilePhone.countryCode)
	) {

		let data = {
			registrationToken: reducers.app.accessToken,
			mobileNumber: '+' + (state.countryCode ? state.countryCode : reducers.mobilePhone.countryCode) + ' ' + utils.formatPhoneForApi(state.mobilePhone ? state.mobilePhone : reducers.mobilePhone.mobilePhone)
		};

		if(process.env.NODE_ENV !== 'development') {

			axios.patch(getApiBaseURL() + '/generate-otp', data)
				.then((response) => {
					if(response.status === 200) {
						onGenerateOtpSuccess(state, setState, actions, history, currentFlow);
					}
					else {
						showDefaultConnectionError(setState, state);
					}
					stopApiCall(actions);
				})
				.catch((err) => {
					if (err.response) {
						switch (err.response.status) {
							case 401:
								showDefaultConnectionError(setState, state);
								break;
							case 500:
								showDefaultConnectionError(setState, state);
								break;
							default:
								showDefaultConnectionError(setState, state);
								break;
						}
					}
					stopApiCall(actions);
				});

		}
		else {

			console.log('API Mockup: PATCH /generate-otp');

			onGenerateOtpSuccess(state, setState, actions, history, currentFlow);

			stopApiCall(actions);

		}

	}
	else {
		showDefaultMissingDataError(setState, state);
		stopApiCall(actions);
	}

};
const onGenerateOtpSuccess = (state, setState, actions, history, currentFlow) => {

	utils.hideError(state, setState);
	utils.hideSuccess(state, setState);

	if(history.location.pathname === currentFlow + C.ROUTES.MOBILE_PHONE) {

		actions.onUpdateMobilePhone(state);
		actions.updateReload(false);

		googleEvents.continueValid();

		utils.goToPage(history, currentFlow + C.ROUTES.PASSCODE + '/registrierung');

	}
	else {

		googleEvents.continueValid();

		utils.showSuccessDirectly(state, setState, 'isPasscodeSuccess', utils.m('code.success.newCode', 'fields'));

		console.log('Code wurde erneut gesendet');
	}

};



/**
 * PATCH /verify-otp
 */
export const verifyOtp = (state, setState, reducers, actions, history, currentFlow) => {

	prepareApiCall(state, setState, actions);

	if(
		!utils.isEmpty(state.passcode) &&
		reducers.app.accessToken
	) {

		let data = {
			registrationToken: reducers.app.accessToken,
			otp: state.passcode,
			productType: utils.getProductType(currentFlow)
		};

		if(process.env.NODE_ENV !== 'development') {

			axios.patch(getApiBaseURL() + '/verify-otp', data)
				.then((response) => {
					if (response.status === 200) {
						onVerifyOtpSuccess(response, state, setState, actions, history, currentFlow);
					}
					else {
						googleEvents.continueInvalid();
						utils.showErrorDirectly(state, setState, 'isPasscodeErr', utils.m('code.validation.invalid', 'fields'));
					}
					stopApiCall(actions);
				})
				.catch((err) => {
					if (err.response) {
						switch (err.response.status) {
							case 401:
								googleEvents.continueInvalid();
								utils.showErrorDirectly(state, setState, 'isPasscodeErr', utils.m('code.validation.invalid', 'fields'));
								break;
							case 500:
								googleEvents.continueInvalid();
								utils.showErrorDirectly(state, setState, 'isPasscodeErr', utils.m('code.validation.invalid', 'fields'));
								break;
							default:
								googleEvents.continueInvalid();
								utils.showErrorDirectly(state, setState, 'isPasscodeErr', utils.m('code.validation.invalid', 'fields'));
								break;
						}
					}
					stopApiCall(actions);
				});

		}
		else {

			console.log('API Mockup: PATCH /verify-otp');
			onVerifyOtpSuccess({
				data: {
					registrationToken: reducers.app.accessToken,
					salutation: 'Herr',
					firstname: 'Moritz',
					lastname: 'Kippenberg',
					allowAdvertising: false,
					email: 'moritz.kippenberg@ext.denkwerk.com',
					mobilePhone: '+49 1234567890',
					dateOfBirth: '1990-04-06'
				}
			}, state, setState, actions, history, currentFlow);

			stopApiCall(actions);

		}

	}
	else {
		showDefaultMissingDataError(setState, state);
		stopApiCall(actions);
	}

};
const onVerifyOtpSuccess = (response, state, setState, actions, history, currentFlow) => {

	if (typeof response.data !== 'undefined' && typeof response.data.redirectVcUrl !== 'undefined' && response.data.redirectVcUrl !== null) {
		googleEvents.continueValid();
		window.location.href = response.data.redirectVcUrl;
	}
	else if (
		response.data &&
		response.data.registrationToken &&
		response.data.salutation &&
		response.data.firstname &&
		response.data.lastname &&
		response.data.allowAdvertising !== null && response.data.allowAdvertising !== undefined &&
		response.data.email &&
		response.data.mobilePhone &&
		response.data.dateOfBirth
	) {

		utils.hideError(state, setState);

		addUserDataToStore(actions, response.data);

		googleEvents.continueValid();

		utils.goToPage(history, currentFlow + C.ROUTES.NATIONALITY);

	}
	else {
		showDefaultConnectionError(setState, state);
	}

};



/**
 * POST /application
 */
export const application = (state, setState, reducers, actions, history, currentFlow) => {

	prepareApiCall(state, setState, actions);
	actions.updateAppLoading(true);

	if(
		reducers.app.accessToken &&

		reducers.email.email &&

		reducers.name.salutation &&
		reducers.name.firstName &&
		reducers.name.lastName &&

		reducers.mobilePhone.countryCode &&
		reducers.mobilePhone.mobilePhone &&

		reducers.birthday.day &&
		reducers.birthday.month &&
		reducers.birthday.year &&

		reducers.address.state &&
		reducers.address.postcode &&
		reducers.address.city &&
		reducers.address.street &&
		reducers.address.number &&

		reducers.nationality.nationality &&
		reducers.occupation.occupation &&
		reducers.maritalStatus.maritalStatus
	) {

		let data = {
			registrationToken: reducers.app.accessToken,
			productType: utils.getProductType(currentFlow),
			allowAdvertising: state.allowAdvertising,
			ownResponsibility: state.ownResponsibility,
			acceptTermsAndConditions: state.acceptTermsAndConditions,
			acceptSchufa: state.acceptSchufa ? state.acceptSchufa : null,
			applicant: {
				email: reducers.email.email,
				givenname: reducers.name.firstName,
				salutation: reducers.name.salutation,
				surname: reducers.name.lastName,
				mobilePhone: ('+' + reducers.mobilePhone.countryCode + ' ' + utils.formatPhoneForApi(reducers.mobilePhone.mobilePhone)),
				dateOfBirth: utils.getDateForApi(reducers.birthday.day, reducers.birthday.month, reducers.birthday.year),
				address: {
					street: reducers.address.street,
					streetNr: reducers.address.number,
					zip: reducers.address.postcode,
					city: reducers.address.city,
					state: reducers.address.state
				},
				nationality: reducers.nationality.nationality,
				occupation: reducers.occupation.occupation,
				maritalState: reducers.maritalStatus.maritalStatus
			}
		};

		if(reducers.name.title) {
			data.applicant.title = reducers.name.title;
		}

		if(reducers.fatca.fatcaCountry) {
			data.applicant.fatcaCountries = reducers.fatca.fatcaCountry;
		}
		if(reducers.fatca.fatcaTin) {
			data.applicant.taxIdentificationNumber = reducers.fatca.fatcaTin;
		}

		if(
			reducers.previousAddress.previousPostcode &&
			reducers.previousAddress.previousCity &&
			reducers.previousAddress.previousStreet &&
			reducers.previousAddress.previousNumber
		) {
			data.applicant.previousAddress = {
				street: reducers.previousAddress.previousStreet,
				streetNr: reducers.previousAddress.previousNumber,
				zip: reducers.previousAddress.previousPostcode,
				city: reducers.previousAddress.previousCity
			};
		}

		if(reducers.occupation.occupationMonth && reducers.occupation.occupationYear) {
			data.applicant.jobStartingDate = utils.getOccupationDay(reducers.occupation.occupationYear, reducers.occupation.occupationMonth);
		}

		if(reducers.children) {
			data.numberChildren = reducers.children.numberChildren ? reducers.children.numberChildren : 0;
			data.numberChildrenWithBenefit = reducers.children.numberChildrenWithBenefit ? reducers.children.numberChildrenWithBenefit : 0;
		}
		else {
			data.numberChildren = 0;
			data.numberChildrenWithBenefit = 0;
		}

		if(
			reducers.giroAccount &&
			reducers.giroAccount.iban
		) {
			data.iban = reducers.giroAccount.iban;
		}

		if(reducers.financialStatus) {
			data.earnings = reducers.financialStatus.earnings ? reducers.financialStatus.earnings : 0;
			data.livingCosts = reducers.financialStatus.livingCosts ? reducers.financialStatus.livingCosts : 0;
			data.typeOfLiving = reducers.financialStatus.typeOfLiving ? reducers.financialStatus.typeOfLiving : null;
		}



		if(process.env.NODE_ENV !== 'development') {

			axios.post(getApiBaseURL() + '/application', data)
				.then((response) => {
					if (response.status === 200) {
						onApplicationSuccess(response, state, setState, history, actions, currentFlow);
					}
					else {
						googleEvents.continueInvalid();
						googleEvents.setDataToWindowObject(currentFlow, 'thank_you', 'registration_failed', response.status + '');
						utils.goToPage(history, currentFlow + C.ROUTES.THANKYOU);
						stopApiCall(actions);
					}
				})
				.catch((err) => {

					if (err.response) {
						switch (err.response.status) {
							case 401:
								googleEvents.continueInvalid();
								googleEvents.setDataToWindowObject(currentFlow, 'thank_you', 'registration_failed', '401');
								utils.goToPage(history, currentFlow + C.ROUTES.THANKYOU);
								break;
							case 500:
								googleEvents.continueInvalid();
								googleEvents.setDataToWindowObject(currentFlow, 'thank_you', 'registration_failed', '500');
								utils.goToPage(history, currentFlow + C.ROUTES.THANKYOU);
								break;
							default:
								googleEvents.continueInvalid();
								googleEvents.setDataToWindowObject(currentFlow, 'thank_you', 'registration_failed', '500');
								utils.goToPage(history, currentFlow + C.ROUTES.THANKYOU);
								break;
						}
					}
					stopApiCall(actions);
				});

		}
		else {

			console.log('API Mockup: POST /application');

			onApplicationSuccess({
				data: {
					redirectVcUrl: 'https://www.santander.de'
				}
			}, state, setState, actions, history, actions, currentFlow);

		}

	}
	else {
		showDefaultMissingDataError(setState, state);
		stopApiCall(actions);
		actions.updateAppLoading(false);
	}

};
const onApplicationSuccess = (response, state, setState, history, actions, currentFlow) => {

	utils.hideError(state, setState);

	if (typeof response.data !== 'undefined' && typeof response.data.redirectVcUrl !== 'undefined') {
		googleEvents.continueValid();
		window.location.href = response.data.redirectVcUrl;
	}
	else {
		googleEvents.continueInvalid();
		googleEvents.setDataToWindowObject(currentFlow, 'thank_you', 'registration_failed', 'no_response_data' );
		utils.goToPage(history, currentFlow + C.ROUTES.THANKYOU);
		stopApiCall(actions);
	}

};
