/**
 * Action Type
*/

const UPDATE_FUTURE_PAYMENT_PROBLEM = 'UPDATE_FUTURE_PAYMENT_PROBLEM';

/*
* Action Creators
*/

export const onUpdateFuturePaymentProblem = payload => ({
	type: UPDATE_FUTURE_PAYMENT_PROBLEM,
	payload
});