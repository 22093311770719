/**
 * Name PaymentProblems
 *
 * Created by Cristian Restituyo
 */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Page from 'mod/Page/Page';
import { Row, Col } from 'mod/FlexGrid/index';
import _ from 'lodash';
import C from '../../constants';
import DropdownInput from 'ui/inputs/DropdownInput/DropdownInput';
import DefaultInput from 'ui/inputs/DefaultInput/DefaultInput';

import * as AppActions from '../../store/App/actions';
import * as EntryActions from '../../store/PaymentProblems/actions';
import * as SolutionActions from '../../store/Solutions/actions';
import * as utils from '../../utils/utils';
import * as googleEvents from '../../utils/googleEvents';
import * as regex from '../../utils/regex';
import styles from './PaymentProblems.scss';

const PaymentProblems = ({ reducers, actions, history, location }) => {
	const [state, setState] = useState({
		path: C.ROUTES.PAYMENT_PROBLEMS,
		currentFlow: utils.getCurrentFlow(location),
		paymentProblem: '',
		paymentProblemItems: [
			{
				text: utils.m(
					'paymentProblems.items.changeInEmploymentRelationship.label',
					'fields'
				),
				value: C.PAYMENT_PROBLEMS_OPTIONS.CHANGE_IN_EMPLOYMENT_RELATIONSHIP
			},
			{
				text: utils.m(
					'paymentProblems.items.oneOffSpecialExpenses.label',
					'fields'
				),
				value: C.PAYMENT_PROBLEMS_OPTIONS.ONE_OFF_SPECIAL_EXPENSES
			},
			{
				text: utils.m('paymentProblems.items.parentalLeave.label', 'fields'),
				value: C.PAYMENT_PROBLEMS_OPTIONS.PARENTAL_LEAVE
			},
			{
				text: utils.m('paymentProblems.items.retirementAge.label', 'fields'),
				value: C.PAYMENT_PROBLEMS_OPTIONS.RETIREMENT_AGE
			},
			{
				text: utils.m('paymentProblems.items.divorce.label', 'fields'),
				value: C.PAYMENT_PROBLEMS_OPTIONS.DIVORCE
			},
			{
				text: utils.m('paymentProblems.items.excessiveDebt.label', 'fields'),
				value: C.PAYMENT_PROBLEMS_OPTIONS.EXCESSIVE_DEBT
			},
			{
				text: utils.m('paymentProblems.items.energyCosts.label', 'fields'),
				value: C.PAYMENT_PROBLEMS_OPTIONS.ENERGY_COSTS
			}
			/*{
				text: utils.m('paymentProblems.items.other.label', 'fields'),
				value: C.PAYMENT_PROBLEMS_OPTIONS.OTHER
			}*/
		],
		changeInEmploymentRelationship: '',
		changeInEmploymentRelationshipSubItems: [
			{
				text: utils.m(
					'paymentProblems.items.changeInEmploymentRelationship.subItems.unemployedWithFutureEmployment',
					'fields'
				),
				value:
					C.CHANGE_IN_EMPLOYMENT_RELATIONSHIP_OPTIONS
						.UNEMPLOYED_WITH_FUTURE_EMPLOYMENT
			},
			{
				text: utils.m(
					'paymentProblems.items.changeInEmploymentRelationship.subItems.unemployedWithoutFutureEmployment',
					'fields'
				),
				value:
					C.CHANGE_IN_EMPLOYMENT_RELATIONSHIP_OPTIONS
						.UNEMPLOYED_WITHOUT_FUTURE_EMPLOYMENT
			},
			{
				text: utils.m(
					'paymentProblems.items.changeInEmploymentRelationship.subItems.temporaryShortTimeWork',
					'fields'
				),
				value:
					C.CHANGE_IN_EMPLOYMENT_RELATIONSHIP_OPTIONS.TEMPORARY_SHORT_TIME_WORK
			},
			{
				text: utils.m(
					'paymentProblems.items.changeInEmploymentRelationship.subItems.incomeReduction',
					'fields'
				),
				value: C.CHANGE_IN_EMPLOYMENT_RELATIONSHIP_OPTIONS.INCOME_REDUCTION
			},
			{
				text: utils.m(
					'paymentProblems.items.changeInEmploymentRelationship.subItems.inabilityToWork',
					'fields'
				),
				value: C.CHANGE_IN_EMPLOYMENT_RELATIONSHIP_OPTIONS.INABILITY_TO_WORK
			}
			/*{
				text: utils.m(
					'paymentProblems.items.changeInEmploymentRelationship.subItems.other',
					'fields'
				),
				value: C.CHANGE_IN_EMPLOYMENT_RELATIONSHIP_OPTIONS.OTHER
			}*/
		],
		oneOffSpecialExpenses: '',
		oneOffSpecialExpensesSubItems: [
			{
				text: utils.m(
					'paymentProblems.items.oneOffSpecialExpenses.subItems.accidentCosts',
					'fields'
				),
				value: C.ONE_OFF_SPECIAL_EXPENSES_OPTIONS.ACCIDENT_COSTS
			},
			{
				text: utils.m(
					'paymentProblems.items.oneOffSpecialExpenses.subItems.illnessDeath',
					'fields'
				),
				value: C.ONE_OFF_SPECIAL_EXPENSES_OPTIONS.ILLNESS_DEATH
			},
			{
				text: utils.m(
					'paymentProblems.items.oneOffSpecialExpenses.subItems.subsequentPayment',
					'fields'
				),
				value: C.ONE_OFF_SPECIAL_EXPENSES_OPTIONS.SUBSEQUENT_PAYMENT
			},
			{
				text: utils.m(
					'paymentProblems.items.oneOffSpecialExpenses.subItems.parade',
					'fields'
				),
				value: C.ONE_OFF_SPECIAL_EXPENSES_OPTIONS.PARADE
			}
			/*{
				text: utils.m(
					'paymentProblems.items.oneOffSpecialExpenses.subItems.other',
					'fields'
				),
				value: C.ONE_OFF_SPECIAL_EXPENSES_OPTIONS.OTHER
			}*/
		],
		specialExpenses: '',
		isPaymentProblemErr: false,
		isChangeInEmploymentRelationshipErr: false,
		extraInfo: '',
		isExtraInfoErr: false,
		extraInfo2: true,
		isExtraInfo2Err: false,
		errorMsg: '',
		isShowError: false
	});

	// nextPage state
	const [nextPage, setNextPage] = useState('');
	const [isFirstDropdownChangeTriggered, setIsFirstDropdownChangeTriggered] = useState(false);

	const [toggleSubmission, setToggleSubmission] = useState(false);
	const [toggleAvailableOptions, setToggleAvailableOptions] = useState(false);
	const [isFirstSubmitted, setIsFirstSubmitted] = useState(false);

	// Information needed to skip Route
	const [records, setRecords] = useState('');
	// const [selectedOption] = useState('');

	const [availableOptions, setAvailableOptions] = useState([]);

	// Update current route when dropdown change
	useEffect(() => {
		updateNextPage();
	}, [
		state.changeInEmploymentRelationship,
		state.specialExpenses,
		state.paymentProblem
	]);



	useEffect(() => {
		// Set the page Index for navigation
		actions.updatePage(utils.getPageIndex(state.path, state.currentFlow));
		// Set the default value on page load
		setState({
			...state,
			paymentProblem: reducers.paymentProblems.paymentProblem,
			changeInEmploymentRelationship:reducers.paymentProblems.changeInEmploymentRelationship,
			extraInfo: reducers.paymentProblems.extraInfo,
			extraInfo2: reducers.paymentProblems.extraInfo2,
			oneOffSpecialExpenses: reducers.paymentProblems.oneOffSpecialExpenses,
			specialExpenses: reducers.paymentProblems.specialExpenses
		});
		// Block access from user type in url directly
		utils.checkIsFromNav(history, reducers.app.isReload, state.currentFlow + C.ROUTES.NAME);

		setAvailableOptions(reducers.solutions.availableOptions);

	}, []);

	useEffect(()=> {

		if(isFirstDropdownChangeTriggered) {
			const clonedState = { ...state };
			clonedState.changeInEmploymentRelationship = '';
			clonedState.specialExpenses = '';
			clonedState.extraInfo = '';
			clonedState.extraInfo2 = '';
			setState(clonedState);
		}

	}, [state.paymentProblem]);

	useEffect(()=> {
		if(isFirstSubmitted) {
			// const{ paymentProblem } = reducers.paymentProblems;
			refreshAvailableSolutions();
			setToggleAvailableOptions(!toggleAvailableOptions);

		}
	},[toggleSubmission]);

	useEffect(() => {
		if(isFirstSubmitted) {
			actions.onUpdateSolutions({
				// selectedOption: availableOptions.length === 1 ? 'option1' : selectedOption,
				records,
				availableOptions,
				radioState: availableOptions.length === 1 ? 0 : -1
			});
			utils.goToPage(history, state.currentFlow + nextPage);
		}
	},[toggleAvailableOptions]);

	const onFormSubmit = () => {
		googleEvents.continueValid();

		actions.onUpdatePaymentProblems(state);
		actions.updateReload(false);
		setToggleSubmission(!toggleSubmission);
		setIsFirstSubmitted(true);


	};

	const setDefaultRadius = () => {};
	const refreshAvailableSolutions = () => {
		const originalOptions = [...reducers.solutions.originalOptions];
		utils.updateAvailableSolutions(reducers, originalOptions, setRecords, setAvailableOptions, setDefaultRadius);
	};
	const updateNextPage = () => {
		if (!utils.isEmpty(state.changeInEmploymentRelationship)) {
			const {
				UNEMPLOYED_WITH_FUTURE_EMPLOYMENT,
				UNEMPLOYED_WITHOUT_FUTURE_EMPLOYMENT,
				TEMPORARY_SHORT_TIME_WORK,
				INCOME_REDUCTION,
				INABILITY_TO_WORK,
				OTHER
			} = C.CHANGE_IN_EMPLOYMENT_RELATIONSHIP_OPTIONS;

			switch (state.changeInEmploymentRelationship) {
				case UNEMPLOYED_WITH_FUTURE_EMPLOYMENT:
					setNextPage(C.ROUTES.UNEMPLOYMENT); //C2
					break;
				case UNEMPLOYED_WITHOUT_FUTURE_EMPLOYMENT:
					setNextPage(C.ROUTES.NUMBER_OF_CHILDREN); //d3
					break;
				case TEMPORARY_SHORT_TIME_WORK:
					setNextPage(C.ROUTES.SHORT_EMPLOYMENT); //C4
					break;
				case INCOME_REDUCTION:
					setNextPage(C.ROUTES.NUMBER_OF_CHILDREN); //d3
					break;
				case INABILITY_TO_WORK:
					setNextPage(C.ROUTES.INABILITY_TO_WORK);
					break;
				case OTHER:
					setNextPage(C.ROUTES.ECONOMIC_SITUATION_CHANGED); //C7
					break;
				default:
					setNextPage('');
					break;
			}
		} else if (!utils.isEmpty(state.specialExpenses)) {
			const {
				ACCIDENT_COSTS,
				ILLNESS_DEATH,
				SUBSEQUENT_PAYMENT,
				PARADE,
				OTHER
			} = C.ONE_OFF_SPECIAL_EXPENSES_OPTIONS;

			switch (state.specialExpenses) {
				case ACCIDENT_COSTS:
					setNextPage(C.ROUTES.ECONOMIC_SITUATION_CHANGED);
					break;
				case ILLNESS_DEATH:
					setNextPage(C.ROUTES.ECONOMIC_SITUATION_CHANGED);
					break;
				case SUBSEQUENT_PAYMENT:
					setNextPage(C.ROUTES.ECONOMIC_SITUATION_CHANGED);
					break;
				case PARADE:
					setNextPage(C.ROUTES.NEW_ADDRESS);
					break;
				case OTHER:
					setNextPage(C.ROUTES.ECONOMIC_SITUATION_CHANGED);
					break;
				default:
					setNextPage('');
			}
		} else if (!utils.isEmpty(state.paymentProblem)) {
			const {
				PARENTAL_LEAVE,
				RETIREMENT_AGE,
				DIVORCE,
				EXCESSIVE_DEBT,
				ENERGY_COSTS,
				OTHER
			} = C.PAYMENT_PROBLEMS_OPTIONS;

			switch (state.paymentProblem) {
				case PARENTAL_LEAVE:
					setNextPage(C.ROUTES.NUMBER_OF_CHILDREN); //d3
					break;
				case RETIREMENT_AGE:
					setNextPage(C.ROUTES.NUMBER_OF_CHILDREN); //d3
					break;
				case DIVORCE:
					setNextPage(C.ROUTES.ECONOMIC_SITUATION_CHANGED);
					break;
				case EXCESSIVE_DEBT:
					setNextPage(C.ROUTES.NUMBER_OF_CHILDREN); //d3
					break;
				case ENERGY_COSTS:
					setNextPage(C.ROUTES.ECONOMIC_SITUATION_CHANGED); //d3
					break;
				case OTHER:
					setNextPage(C.ROUTES.ECONOMIC_SITUATION_CHANGED);
					break;
				default:
					setNextPage('');
					break;
			}
		}
	};

	const hasEmptyFields = () => {
		return utils.isEmpty(state.paymentProblem) || areCurrentFieldsEmpty();
	};

	const areCurrentFieldsEmpty = () => {
		let areEmpty;
		const {
			CHANGE_IN_EMPLOYMENT_RELATIONSHIP,
			ONE_OFF_SPECIAL_EXPENSES,
			DIVORCE,
			EXCESSIVE_DEBT,
			OTHER
		} = C.PAYMENT_PROBLEMS_OPTIONS;

		switch (state.paymentProblem) {

			case CHANGE_IN_EMPLOYMENT_RELATIONSHIP:
				// areEmpty = utils.isEmpty(state.changeInEmploymentRelationship) || (utils.isEmpty(state.extraInfo) || parseFloat(state.extraInfo) <= 0);
				areEmpty = state.changeInEmploymentRelationship !== C.PAYMENT_PROBLEMS_OPTIONS.OTHER ? (
					utils.isEmpty(state.changeInEmploymentRelationship)
				):
					(
						utils.isEmpty(state.changeInEmploymentRelationship) || (utils.isEmpty(state.extraInfo) || parseFloat(state.extraInfo) <= 0)
					);
				break;
			case ONE_OFF_SPECIAL_EXPENSES:

				areEmpty = state.specialExpenses !== C.ONE_OFF_SPECIAL_EXPENSES_OPTIONS.OTHER ? (
					utils.isEmpty(state.specialExpenses) ||
					utils.isEmpty(state.extraInfo) ||
					parseFloat(state.extraInfo) <= 0
				): (
					utils.isEmpty(state.specialExpenses) ||
					utils.isEmpty(state.extraInfo) ||
					parseFloat(state.extraInfo) <= 0 ||
					utils.isEmpty(state.extraInfo2)
				);

				break;
			case DIVORCE:
				areEmpty = utils.isEmpty(state.extraInfo) ||
				parseFloat(state.extraInfo) <= 0;
				break;
			case EXCESSIVE_DEBT:
				areEmpty = utils.isEmpty(state.extraInfo) ||
				parseFloat(state.extraInfo) <= 0;
				break;
			case OTHER:
				areEmpty = utils.isEmpty(state.extraInfo) ||
				parseFloat(state.extraInfo) <= 0;
				break;
			default:
				areEmpty = false;
		}

		return areEmpty;
	};

	const renderExtraDropdown = () => {
		let content;
		const {
			CHANGE_IN_EMPLOYMENT_RELATIONSHIP,
			ONE_OFF_SPECIAL_EXPENSES,
			DIVORCE,
			EXCESSIVE_DEBT,
			OTHER
		} = C.PAYMENT_PROBLEMS_OPTIONS;

		switch (state.paymentProblem) {
			case CHANGE_IN_EMPLOYMENT_RELATIONSHIP:
				content = (
					<Col xs={ 12 }>
						<DropdownInput
							inputOnChange={ val => {
								utils.onInputChange(
									setState,
									state,
									'changeInEmploymentRelationship',
									val
								);
							} }
							label={ utils.m(
								'paymentProblems.items.changeInEmploymentRelationship.subLabel',
								'fields'
							) }
							hasError={ state.isChangeInEmploymentRelationshipErr }
							items={ state.changeInEmploymentRelationshipSubItems }
							init={ state.changeInEmploymentRelationship }
							ref={ changeInEmploymentRelationshipRef }
							prevRef={ paymentProblemsRef }
							nextRef={ submitRef }
							testId='employment'
							noPlaceholder={ true }
						/>
					</Col>
				);
				break;
			case ONE_OFF_SPECIAL_EXPENSES:
				content = (
					<>
						<Col xs={ 12 }>
							<DropdownInput
								inputOnChange={ val => {
									utils.onInputChange(setState, state, 'specialExpenses', val);
								} }
								label={ utils.m(
									'paymentProblems.items.oneOffSpecialExpenses.subLabel',
									'fields'
								) }
								hasError={ state.isChangeInEmploymentRelationshipErr }
								items={ state.oneOffSpecialExpensesSubItems }
								init={ state.specialExpenses }
								ref={ specialExpensesRef }
								prevRef={ paymentProblemsRef }
								nextRef={ extraInputFieldRef }
								testId='special-expenses'
								noPlaceholder={ true }
							/>
						</Col>
						{!utils.isEmpty(state.specialExpenses) && (
							<>
							<Col xs={ 12 }>
								<DefaultInput
									inputOnChange={ val => {
										utils.onInputChange(setState, state, 'extraInfo', val);
									} }
									label={ utils.m(
										'paymentProblems.items.oneOffSpecialExpenses.extraLabel',
										'fields'
									) }
									hasError={ state.isExtraInfoErr }
									init={ state.extraInfo }
									maxlength={ 8 }
									ref={ extraInputFieldRef }
									prevRef={ specialExpensesRef }
									nextRef={
										state.specialExpenses === C.ONE_OFF_SPECIAL_EXPENSES_OPTIONS.OTHER ?
											extraInput2Field : submitRef
									}
									regex={ regex.numberWithDotAndComma() }
									placeholder={ '_ _' }
									isCurrency
									type = "number"
									originalValue = { state.extraInfo }
									testId='extra-info'
								/>
							</Col>
							{state.specialExpenses === C.ONE_OFF_SPECIAL_EXPENSES_OPTIONS.OTHER && (
								<Col xs={ 12 }>
									<DefaultInput
										inputOnChange={ val => {
											utils.onInputChange(setState, state, 'extraInfo2', val);
										} }
										label={ utils.m(
											'paymentProblems.items.oneOffSpecialExpenses.extraLabel2',
											'fields'
										) }
										hasError={ state.isExtraInfoErr }
										init={ state.extraInfo2 }
										maxlength={ 1000 }
										ref={ extraInput2Field }
										prevRef={ extraInputFieldRef }
										nextRef={ submitRef }
										placeholder={ '_ _' }
										customClass={ styles.freeText }
										testId='extra-info2'
										type='textarea'
										rows={ 20 }
										cols={ 40 }
									/>
								</Col>
							)}
							</>

						)}
					</>
				);
				break;
			case DIVORCE:
				content = (
					<Col xs={ 12 }>
						<div className = { styles.freeTextCol }>
							<DefaultInput
								inputOnChange={ val => {
									utils.onInputChange(setState, state, 'extraInfo', val);
								} }
								label={ utils.m(
									'paymentProblems.items.divorce.extraLabel',
									'fields'
								) }
								hasError={ state.isExtraInfoErr }
								init={ state.extraInfo }
								maxlength={ 1000 }
								ref={ extraInputFieldRef }
								prevRef={ specialExpensesRef }
								nextRef={ submitRef }
								placeholder={ '_ _' }
								customClass={ styles.freeText }
								testId='extra-info'
								type='textarea'
								rows={ 20 }
								cols={ 40 }
							/>
						</div>
					</Col>
				);
				break;
			case EXCESSIVE_DEBT:
				content = (
					<Col xs={ 12 }>
						<DefaultInput
							inputOnChange={ val => {
								utils.onInputChange(setState, state, 'extraInfo', val);
							} }
							label={ utils.m(
								'paymentProblems.items.excessiveDebt.extraLabel',
								'fields'
							) }
							hasError={ state.isExtraInfoErr }
							init={ state.extraInfo }
							maxlength={ 1000 }
							ref={ extraInputFieldRef }
							prevRef={ specialExpensesRef }
							nextRef={ submitRef }
							placeholder={ '_ _' }
							customClass={ styles.freeText }
							testId='extra-info'
							type='textarea'
							rows={ 20 }
							cols={ 40 }
						/>
					</Col>
				);
				break;
			case OTHER:
				content = (
					<Col xs={ 12 }>
						<div className = { styles.freeTextCol }>
							<DefaultInput
								inputOnChange={ val => {

									utils.onInputChange(setState, state, 'extraInfo', val);
								} }
								label={ utils.m(
									'paymentProblems.items.other.extraLabel',
									'fields'
								) }
								hasError={ state.isExtraInfoErr }
								init={ state.extraInfo }
								maxlength={ 1000 }
								ref={ extraInputFieldRef }
								prevRef={ specialExpensesRef }
								nextRef={ submitRef }
								placeholder={ '_ _' }
								customClass={ styles.freeText }
								testId='extra-info'
								type='textarea'
								rows={ 20 }
								cols={ 40 }
							/>
						</div>
					</Col>
				);
				break;
			default:
				content = null;
		}
		return content;
	};

	const renderThirdInputField = () => {
		return <Col xs={ 12 }>
			<div className = { styles.freeTextCol }>
				<DefaultInput
					inputOnChange={ val => {

						utils.onInputChange(setState, state, 'extraInfo', val);
					} }
					label={ utils.m(
						'paymentProblems.items.other.extraLabel',
						'fields'
					) }
					hasError={ state.isExtraInfoErr }
					init={ state.extraInfo }
					maxlength={ 1000 }
					ref={ extraInputFieldRef }
					prevRef={ specialExpensesRef }
					nextRef={ submitRef }
					placeholder={ '_ _' }
					customClass={ styles.freeText }
					testId='extra-info'
					type='textarea'
					rows={ 20 }
					cols={ 40 }
				/>
			</div>
		</Col>;
	};

	// const titleRef = useRef(null);
	const paymentProblemsRef = useRef(null);
	const changeInEmploymentRelationshipRef = useRef(null);
	const specialExpensesRef = useRef(null);
	const extraInputFieldRef = useRef(null);
	const extraInput2Field = useRef(null);
	const submitRef = useRef(null);

	return (
		<Page
			title={ utils.m('paymentProblems.title', state.currentFlow) }
			subtitle={ utils.m('paymentProblems.subtitle', state.currentFlow) }
			isShowError={ state.isShowError }
			errorMsg={ state.errorMsg }
			hasNextButton={ true }
			onBackButtonClick={ history.goBack }
			onFormSubmit={ onFormSubmit }
			nextButtonDisabled={ hasEmptyFields() }
			location={ location }
			currentFlow={ state.currentFlow }
			pageName='PaymentProblems'
			currentStep={ C.PROGRESS_BAR.statement }
			screen='C1'
			showButtonDivider={ false }
			submitRef={ submitRef }
			prevRef={ changeInEmploymentRelationshipRef }
			nextRef={ paymentProblemsRef }
			gtmStep='userdata_name'
			gtmStatus='registration_pending'
			gtmSubStatus={ null }
		>
			<Row justifyContent='center' customClass={ styles.PaymentProblems }>
				<Col xs={ 12 } customClass={ styles.Col1 }>
					<DropdownInput
						inputOnChange={ val => {
							setIsFirstDropdownChangeTriggered(true);
							utils.onInputChange(setState, state, 'paymentProblem', val);
						} }
						label={ utils.m('paymentProblems.label', 'fields') }
						hasError={ state.isPaymentProblemErr }
						items={ state.paymentProblemItems }
						init={ state.paymentProblem }
						ref={ paymentProblemsRef }
						nextRef={
							!utils.isEmpty(state.changeInEmploymentRelationship)
								? changeInEmploymentRelationshipRef
								: !utils.isEmpty(state.specialExpenses)
									? specialExpensesRef
									: submitRef
						}
						testId='payment-problem'
						noPlaceholder={ true }
					/>
				</Col>

				{renderExtraDropdown()}
				{state.paymentProblem === C.PAYMENT_PROBLEMS_OPTIONS.CHANGE_IN_EMPLOYMENT_RELATIONSHIP && state.changeInEmploymentRelationship === C.CHANGE_IN_EMPLOYMENT_RELATIONSHIP_OPTIONS.OTHER && renderThirdInputField()}

			</Row>
		</Page>
	);
};

PaymentProblems.propTypes = {
	reducers: PropTypes.object,
	actions: PropTypes.object,
	history: PropTypes.object
};

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(_.merge({}, AppActions, EntryActions, SolutionActions), dispatch)
});

const mapStateToProps = state => {
	return {
		reducers: state
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(PaymentProblems);
