import * as React from 'react';

const Footer = props => (
	<svg width={ 420 } height={ 118 } viewBox="0 0 420 118" { ...props }>
		<defs>
			<path id="footer_svg__a" d="M0 166.607h419.817V.167H0z" />
			<path id="footer_svg__c" d="M.29.135h100.02v80.222H.29z" />
			<path id="footer_svg__e" d="M.067.098h102.202v79.969H.067z" />
			<path id="footer_svg__g" d="M.287.006h41.454v23.461H.287z" />
			<path id="footer_svg__i" d="M.238.284H63.99V13.89H.238z" />
		</defs>
		<g fill="none" fillRule="evenodd">
			<g transform="translate(0 -3.877)">
				<path
					d="M396.665 82.917c-34.558-31.757-91.317 10.45-166.148 10.45-152.727.008-154.13-54.168-179.252-72.179C25.21 2.513 1.86 12.112 4.307 26.218c1.658 5.722 5.864 9.381 11.059 12.934 9.71 6.63 12.368 12.107 8.676 23.261l-6.154 18.623-1.12 3.376c-.261 0-2.322.026-4.583 0l-1.382 22.414.967 15.328h398.613c.121-13.958-3.768-30.094-13.718-39.237"
					fill="#FFF"
				/>
				<path
					d="M30.697 82.662c-.119.432-1.671.824-4.15 1.12-3.718.385-7.455.564-11.193.537-3.737.029-7.474-.15-11.192-.537-2.476-.29-4.037-.688-4.153-1.12l5.758 39.379h19.21l5.761-39.38h-.04z"
					fill="#DEEDF2"
				/>
				<path
					fill="#FFD871"
					d="M57.045 19.959l2.759-2.759 2.758 2.759-2.758 2.758zM319.375 22.323l2.759-2.758 2.758 2.758-2.758 2.758z"
				/>
				<mask id="footer_svg__b" fill="#fff">
					<use xlinkHref="#footer_svg__a" />
				</mask>
				<path
					fill="#34476A"
					mask="url(#footer_svg__b)"
					d="M32.55 37.623h3.9v-3.901h-3.9z"
				/>
				<path
					d="M397.309 40.084h2.917c.017-.95.792-1.71 1.741-1.71v-1.387a4.093 4.093 0 0 1 8.185 0v.18h2.122a1.742 1.742 0 0 1 1.295 2.917h4.542a1.637 1.637 0 1 1 0 3.271h-20.8a1.637 1.637 0 0 1 0-3.27h-.002z"
					fill="#FFF"
					mask="url(#footer_svg__b)"
				/>
				<path
					d="M108.483 79.553h-2.508v-.064c0-.841-.682-1.524-1.524-1.524-.186 0-.37.036-.542.105v-.14a2.638 2.638 0 1 0-4.711 1.623h-.694a.983.983 0 1 0 0 1.965h9.98a.984.984 0 1 0 0-1.965M330.238 94.521h-2.51a1.524 1.524 0 0 0-2.07-1.486V92.9a2.639 2.639 0 1 0-4.714 1.622h-.69a.984.984 0 1 0 0 1.966h9.97a.985.985 0 1 0 0-1.966h.014z"
					fill="#E5F0F4"
					mask="url(#footer_svg__b)"
				/>
				<path
					d="M150.405 72.656h-3.832a2.293 2.293 0 0 0-2.293-2.246v-1.823a5.375 5.375 0 0 0-10.74 0v.238h-2.789a2.293 2.293 0 0 0-1.7 3.831h-5.971a2.15 2.15 0 0 0 0 4.3h27.325a2.15 2.15 0 1 0 0-4.3M356.46 81.091h-3.831a2.293 2.293 0 0 0-2.293-2.246v-1.823a5.376 5.376 0 0 0-10.74 0v.238h-2.79a2.293 2.293 0 0 0-1.7 3.831h-5.97a2.15 2.15 0 0 0 0 4.3h27.324a2.151 2.151 0 1 0 0-4.3"
					fill="#D9E9EF"
					mask="url(#footer_svg__b)"
				/>
				<path
					d="M284.288 8.28h-2.51a1.521 1.521 0 0 0-1.518-1.588l-.004.001a1.49 1.49 0 0 0-.545.105V6.66a2.638 2.638 0 1 0-4.714 1.62h-.694a.984.984 0 0 0 0 1.965h9.973a.984.984 0 0 0 0-1.965h.012z"
					fill="#E5F0F4"
					mask="url(#footer_svg__b)"
				/>
				<path
					d="M136.29 101.322c.647.032 1.219 1.338 1.41 2.482.192 1.143.534 5.99.688 6.676.154.685.433 3.84-.374 4.78-.807.94-1.649 1.324-2.108 1.286-.458-.038-2.81-.647-2.81-3.463 0-2.815.648-7.836.99-8.852.343-1.016.625-2.984 2.204-2.909"
					fill="#D7F2F4"
					mask="url(#footer_svg__b)"
				/>
				<path
					d="M150.512 111.557a4.728 4.728 0 0 1-1.271 3.99c-1.353 1.452-2.903 1.716-4.537 1.533a4.643 4.643 0 0 1-3.95-4.467 8.885 8.885 0 0 1 1.248-5.044c.479-.871 1.55-1.774 1.698-4.395.148-2.62-.148-5.515 2.214-5.515.7 0 1.742.29 1.919 1.997.177 1.707-.29 7.428 1.318 9.222a5.25 5.25 0 0 1 1.352 2.679"
					fill="#C3DEE7"
					mask="url(#footer_svg__b)"
				/>
				<path
					fill="#D2F0F2"
					mask="url(#footer_svg__b)"
					d="M191.514 91.607v30.512h2.67V90.23zM163.21 97.709v24.41h2.221V96.333zM168.514 107.589v14.53h2.217v-15.906zM199.22 111.13v10.989h2.67v-12.365z"
				/>
				<path
					fill="#E5F0F4"
					mask="url(#footer_svg__b)"
					d="M201.864 122.119h12.362v-12.362h-12.362z"
				/>
				<path
					fill="#D2F0F2"
					mask="url(#footer_svg__b)"
					d="M215.497 106.274v15.845h2.674v-17.224z"
				/>
				<path
					fill="#E5F0F4"
					mask="url(#footer_svg__b)"
					d="M218.145 122.119h9.877v-17.218h-9.877z"
				/>
				<path
					fill="#D2F0F2"
					mask="url(#footer_svg__b)"
					d="M181.282 111.13v10.989h2.67v-12.365z"
				/>
				<path
					fill="#E5F0F4"
					mask="url(#footer_svg__b)"
					d="M183.926 122.119h9.085v-12.362h-9.085zM170.65 122.119h9.085v-15.976h-9.085zM165.431 122.122h4.192V96.405h-4.192z"
				/>
				<path
					fill="#C1DCE6"
					mask="url(#footer_svg__b)"
					d="M171.782 110.904h7.021v-.535h-7.021zM171.782 113.072h7.021v-.534h-7.021zM184.96 112.242h7.02v-.534h-7.02zM184.96 114.413h7.02v-.534h-7.02zM203.614 112.242h9.837v-.534h-9.837zM203.614 114.413h9.837v-.534h-9.837z"
				/>
				<path
					fill="#D2F0F2"
					mask="url(#footer_svg__b)"
					d="M76.941 111.13v10.989h2.673v-12.365z"
				/>
				<path
					fill="#E5F0F4"
					mask="url(#footer_svg__b)"
					d="M79.588 122.119H91.95v-12.362H79.588z"
				/>
				<path
					fill="#C1DCE6"
					mask="url(#footer_svg__b)"
					d="M81.335 112.242h9.837v-.534h-9.837zM81.335 114.413h9.837v-.534h-9.837zM171.782 118.177h7.021v-.534h-7.021zM171.782 120.22h7.021v-.533h-7.021zM166.113 110.904h2.944v-.535h-2.944zM166.113 113.072h2.944v-.534h-2.944zM166.113 104.802h2.944v-.534h-2.944zM166.113 118.177h2.944v-.534h-2.944zM166.113 120.22h2.944v-.533h-2.944z"
				/>
				<path
					fill="#7DACC1"
					mask="url(#footer_svg__b)"
					d="M135.895 122.078h.174v-11.903h-.174zM145.343 122.078h.174v-11.903h-.174z"
				/>
				<path
					d="M240.903 101.406c.648.032 1.22 1.336 1.411 2.479.192 1.144.534 5.991.688 6.676.154.685.432 3.837-.377 4.78-.81.944-1.646 1.324-2.105 1.286-.458-.037-2.783-.65-2.783-3.483 0-2.832.647-7.82.99-8.85.342-1.03.603-2.963 2.176-2.888"
					fill="#C3DEE7"
					mask="url(#footer_svg__b)"
				/>
				<path
					fill="#7DACC1"
					mask="url(#footer_svg__b)"
					d="M240.523 122.163h.174v-11.907h-.174z"
				/>
				<path
					d="M413.257 101.406c.65.032 1.222 1.336 1.41 2.479.19 1.144.535 5.991.689 6.676.154.685.433 3.837-.375 4.78-.807.944-1.648 1.324-2.107 1.286-.458-.037-2.783-.65-2.783-3.483 0-2.832.647-7.82.993-8.85.345-1.03.609-2.963 2.173-2.888"
					fill="#D7F2F4"
					mask="url(#footer_svg__b)"
				/>
				<path
					fill="#7DACC1"
					mask="url(#footer_svg__b)"
					d="M412.877 122.163h.174v-11.907h-.174z"
				/>
				<path
					fill="#E5F0F4"
					mask="url(#footer_svg__b)"
					d="M199.6 122.119h-5.416V90.223l5.416 5.146z"
				/>
				<path
					d="M62.565 108.669c-.035-1.222 2.275-2.2 2.862-2.456.586-.255 1.358-.955 1.43-2.386.073-1.43.953-1.945 1.577-1.945h2.322s1.358-.255 1.285 1.873c-.072 2.127-1.651.952-1.651 2.713v5.245s-.073 2.447-1.161 2.543c-1.161.107-.697 1.309-1.393 1.309 0 0-.979.258-1.016-1.724-.038-1.983-.183-2.641-1.161-2.824-.978-.183-3.025.148-3.094-2.348"
					fill="#9CC4D2"
					mask="url(#footer_svg__b)"
				/>
				<path
					fill="#7DACC1"
					mask="url(#footer_svg__b)"
					d="M67.638 122.13h.29v-11.444h-.29z"
				/>
				<path
					d="M245.292 108.669c-.038-1.222 2.273-2.2 2.86-2.456.586-.255 1.358-.955 1.43-2.386.073-1.43.955-1.945 1.58-1.945h2.321s1.356-.255 1.283 1.873c-.072 2.127-1.651.952-1.651 2.713v5.245s-.073 2.447-1.161 2.543c-1.161.107-.697 1.309-1.394 1.309 0 0-.978.258-1.016-1.724-.037-1.983-.182-2.641-1.16-2.824-.979-.183-3.02.148-3.092-2.348"
					fill="#D2F0F2"
					mask="url(#footer_svg__b)"
				/>
				<path
					fill="#7DACC1"
					mask="url(#footer_svg__b)"
					d="M250.366 122.13h.29v-11.444h-.29z"
				/>
				<path
					fill="#C1DCE6"
					mask="url(#footer_svg__b)"
					d="M195.45 110.111h2.943v-.534h-2.944zM195.45 112.28h2.943v-.535h-2.944zM195.45 104.01h2.943v-.534h-2.944zM195.45 117.385h2.943v-.534h-2.944zM195.45 119.429h2.943v-.535h-2.944zM219.056 110.111h7.985v-.534h-7.985zM219.056 112.28h7.985v-.535h-7.985zM219.056 117.385h7.985v-.534h-7.985zM219.056 119.429h7.985v-.535h-7.985zM194.184 122.119h-1.17v-12.362l1.17 11.9zM170.792 122.16h-1.17v-15.3l1.17 14.73z"
				/>
				<path
					fill="#D2F0F2"
					mask="url(#footer_svg__b)"
					d="M99.183 97.709v24.41h2.218V96.333zM104.483 107.589v14.53h2.22v-15.906zM117.252 111.13v10.989h2.673v-12.365z"
				/>
				<path
					fill="#E5F0F4"
					mask="url(#footer_svg__b)"
					d="M119.899 122.119h9.085v-12.362h-9.085zM106.62 122.119h9.085v-15.976h-9.085zM101.4 122.122h4.192V96.405h-4.191z"
				/>
				<path
					fill="#C1DCE6"
					mask="url(#footer_svg__b)"
					d="M107.755 110.904h7.02v-.535h-7.02zM107.755 113.072h7.02v-.534h-7.02zM120.932 112.242h7.021v-.534h-7.02zM120.932 114.413h7.021v-.534h-7.02zM107.755 118.177h7.02v-.534h-7.02zM107.755 120.22h7.02v-.533h-7.02zM102.083 110.904h2.943v-.535h-2.943zM102.083 113.072h2.943v-.534h-2.943zM102.083 118.177h2.943v-.534h-2.943zM102.083 120.22h2.943v-.533h-2.943zM106.765 122.16h-1.17v-15.3l1.17 14.73zM201.864 122.119H199.6v-11.216l2.264 10.798zM215.497 119.315v-2.804l2.265 5.399v.209h-2.265z"
				/>
				<path
					fill="#D2F0F2"
					mask="url(#footer_svg__b)"
					d="M368.198 91.622v30.511h2.67V90.246zM339.898 97.723v24.41h2.218V96.348zM345.198 107.603v14.53h2.22v-15.905zM375.904 111.145v10.988h2.674V109.77z"
				/>
				<path
					fill="#E5F0F4"
					mask="url(#footer_svg__b)"
					d="M378.551 122.134h12.362v-12.362h-12.362z"
				/>
				<path
					fill="#D2F0F2"
					mask="url(#footer_svg__b)"
					d="M392.185 106.286v15.848h2.67V104.91z"
				/>
				<path
					fill="#E5F0F4"
					mask="url(#footer_svg__b)"
					d="M394.829 122.133h9.877v-17.217h-9.877z"
				/>
				<path
					fill="#D2F0F2"
					mask="url(#footer_svg__b)"
					d="M357.967 111.145v10.988h2.67V109.77z"
				/>
				<path
					fill="#E5F0F4"
					mask="url(#footer_svg__b)"
					d="M360.614 122.134h9.085v-12.362h-9.085zM347.334 122.134h9.086v-15.976h-9.086zM342.116 122.134h4.19V96.417h-4.19z"
				/>
				<path
					fill="#C1DCE6"
					mask="url(#footer_svg__b)"
					d="M348.47 110.918h7.02v-.534h-7.02zM348.47 113.086h7.02v-.534h-7.02zM361.647 112.256h7.021v-.534h-7.021zM361.647 114.427h7.021v-.534h-7.021zM380.302 112.256h9.837v-.534h-9.837zM380.302 114.427h9.837v-.534h-9.837zM348.47 118.192h7.02v-.534h-7.02zM348.47 120.235h7.02v-.534h-7.02zM342.798 110.918h2.943v-.534h-2.943zM342.798 113.086h2.943v-.534h-2.943zM342.798 104.817h2.943v-.534h-2.943zM342.798 118.192h2.943v-.534h-2.943zM342.798 120.235h2.943v-.534h-2.943z"
				/>
				<path
					fill="#E5F0F4"
					mask="url(#footer_svg__b)"
					d="M376.287 122.133h-5.419V90.237l5.42 5.144z"
				/>
				<path
					fill="#C1DCE6"
					mask="url(#footer_svg__b)"
					d="M372.134 110.126h2.943v-.534h-2.943zM372.134 112.294h2.943v-.534h-2.943zM372.134 104.024h2.943v-.534h-2.943zM372.134 117.4h2.943v-.535h-2.943zM372.134 119.443h2.943v-.534h-2.943zM395.743 110.126h7.985v-.534h-7.985zM395.743 112.294h7.985v-.534h-7.985zM395.743 117.4h7.985v-.535h-7.985zM395.743 119.443h7.985v-.534h-7.985zM370.868 122.133h-1.17v-12.362l1.17 11.9zM347.48 122.174h-1.17v-15.3l1.17 14.731z"
				/>
				<path
					fill="#D2F0F2"
					mask="url(#footer_svg__b)"
					d="M262.165 97.723v24.41h2.22V96.348zM267.468 107.603v14.53h2.217v-15.905zM280.236 111.145v10.988h2.67V109.77z"
				/>
				<path
					fill="#E5F0F4"
					mask="url(#footer_svg__b)"
					d="M282.88 122.134h9.085v-12.362h-9.085zM269.604 122.134h9.085v-15.976h-9.085zM264.385 122.134h4.191V96.417h-4.19z"
				/>
				<path
					fill="#C1DCE6"
					mask="url(#footer_svg__b)"
					d="M270.736 110.918h7.021v-.534h-7.021zM270.736 113.086h7.021v-.534h-7.021zM283.914 112.256h7.02v-.534h-7.02zM283.914 114.427h7.02v-.534h-7.02zM270.736 118.192h7.021v-.534h-7.021zM270.736 120.235h7.021v-.534h-7.021zM265.067 110.918h2.944v-.534h-2.944zM265.067 113.086h2.944v-.534h-2.944zM265.067 104.817h2.944v-.534h-2.944zM265.067 118.192h2.944v-.534h-2.944zM265.067 120.235h2.944v-.534h-2.944zM269.746 122.174h-1.17v-15.3l1.17 14.731zM378.552 122.133h-2.265v-11.215l2.265 10.798zM392.185 119.33v-2.804l2.264 5.398v.21h-2.264z"
				/>
				<path
					fill="#C3DEE7"
					mask="url(#footer_svg__b)"
					d="M41.483 54.902H52.6V43.785H41.483z"
				/>
				<path
					fill="#9E3667"
					mask="url(#footer_svg__b)"
					d="M386.487 84.392h3.9V80.49h-3.9z"
				/>
				<path
					fill="#C3DEE7"
					mask="url(#footer_svg__b)"
					d="M302.49 44.31h11.117V33.193H302.49zM394.034 74.108h8.727V65.38h-8.727z"
				/>
				<path
					d="M82.729 63.804a4.296 4.296 0 1 1 0 8.591 4.296 4.296 0 0 1 0-8.591"
					fill="#FC3"
					mask="url(#footer_svg__b)"
				/>
				<path
					d="M281.917 114.331a.133.133 0 0 0 0-.264.133.133 0 0 0 0 .264"
					fill="#F4A38C"
					mask="url(#footer_svg__b)"
				/>
				<path
					d="M289.11 63.74a.13.13 0 0 0 0-.261.13.13 0 0 0 0 .26"
					fill="#F9C8BC"
					mask="url(#footer_svg__b)"
				/>
				<path
					d="M370.851 60.51a.134.134 0 0 1-.13-.099l-.621-2.284a.135.135 0 1 1 .26-.07l.621 2.283a.134.134 0 0 1-.13.17M373.472 60.51a.134.134 0 0 1-.13-.17l.62-2.284a.135.135 0 0 1 .26.07l-.62 2.285c-.016.06-.07.1-.13.1M378.847 38.607c-1.465-1.225-4.179-2.54-6.606-2.717 1.452.325 2.935 1.579 3.86 2.882 2.079 2.938 2.793 6.987 2.143 10.684-.54 3.063-2.613 6.13-4.766 8.458h.746c3.523-2.484 6.226-5.514 7.404-8.707 1.4-3.823.508-7.843-2.78-10.6"
					fill="#9BC3D3"
					mask="url(#footer_svg__b)"
				/>
				<path
					d="M372.874 57.914c.673-1.956 1.472-5.834 1.472-10.298 0-6.435-.357-11.726-2.212-11.726-1.855 0-2.209 5.291-2.209 11.726 0 4.464.798 8.342 1.472 10.298h1.477z"
					fill="#9BC3D3"
					mask="url(#footer_svg__b)"
				/>
				<path
					d="M370.793 57.914c-2.15-2.322-4.226-5.395-4.766-8.458-.65-3.697.06-7.746 2.142-10.684.926-1.303 2.415-2.557 3.86-2.882-2.426.177-5.143 1.492-6.606 2.717-3.297 2.757-4.18 6.777-2.78 10.588 1.178 3.193 3.877 6.235 7.404 8.708l.746.011z"
					fill="#9BC3D3"
					mask="url(#footer_svg__b)"
				/>
				<path
					d="M370.105 57.914h4.06a.18.18 0 0 1 0 .357h-4.06a.18.18 0 0 1-.18-.178.18.18 0 0 1 .18-.179"
					fill="#9BC3D3"
					mask="url(#footer_svg__b)"
				/>
				<path
					fill="#C3DEE7"
					mask="url(#footer_svg__b)"
					d="M370.3 64.047h3.671v-3.671H370.3z"
				/>
				<path
					fill="#D2F0F2"
					mask="url(#footer_svg__b)"
					d="M369.925 60.765h4.42v-.39h-4.42z"
				/>
				<path
					d="M1.338 96.655l1.87 12.786s2.258 1.323 11.67 1.323c9.413 0 12.682-1.538 12.682-1.538l1.852-12.664S25.847 98.04 14.73 98.04c-11.116 0-13.392-1.384-13.392-1.384M7.042 81.271v-.095C2.807 81.48 0 82.009 0 82.609c0 .453 1.59.871 4.165 1.161.999.102 1.994.192 2.993.265l-.116-2.764zM17.923 80.929l-.035.107L16.8 84.32c3.257-.023 6.506-.202 9.747-.537 2.577-.307 4.162-.72 4.162-1.161 0-.856-5.532-1.559-12.786-1.692"
					fill="#9BC3D3"
					mask="url(#footer_svg__b)"
				/>
				<path
					fill="#95C3D3"
					mask="url(#footer_svg__b)"
					d="M153.226 111.841l-8.124-3.924 4.795-9.933 8.124 3.924z"
				/>
				<path
					fill="#DDEDF2"
					mask="url(#footer_svg__b)"
					d="M189.035 106.21l-8.055 4.067-4.969-9.849 8.052-4.063z"
				/>
				<path
					fill="#000"
					mask="url(#footer_svg__b)"
					d="M152.088 100.791l-.403-.592 3.433-2.342.407.592zM156.581 102.469l-.717.029-.165-4.154.717-.029zM178.234 101.31l-.716.032-.18-4.156.717-.03zM182.03 98.382l-.353.621-3.617-2.05.354-.623z"
				/>
				<path
					d="M164.264 109.984a1.71 1.71 0 1 1-3.273.988 1.71 1.71 0 0 1 3.273-.988M172.203 110.28a1.713 1.713 0 1 1-1.716-1.71h.003c.945 0 1.711.765 1.713 1.71M165.428 102.82a2.09 2.09 0 1 1 0-.003v.003M170.426 103.308a2.093 2.093 0 1 1-2.095-2.09h.003c1.154 0 2.091.935 2.092 2.09"
					fill="#9BC3D3"
					mask="url(#footer_svg__b)"
				/>
				<path
					fill="#9BC3D3"
					mask="url(#footer_svg__b)"
					d="M161.443 104.094l-.517 6.188 3.405.325 1.15-6.786zM166.34 103.943l2.51 6.83 3.21-1.17-1.633-6.232zM160.99 110.979l1.26 9.297 2.226 1.672-.142-11.34zM158.845 121.948l3.405-1.67 2.226 1.67zM172.136 109.812l7.338 6.89-2.967.619-7.131-5.741z"
				/>
				<path
					fill="#9BC3D3"
					mask="url(#footer_svg__b)"
					d="M176.519 117.306l-.993 2.735 3.948-3.338z"
				/>
				<path
					d="M165.727 86.885a1.33 1.33 0 1 1-1.283-1.376 1.33 1.33 0 0 1 1.295 1.363v.001M161.754 93.311a1.141 1.141 0 1 1-1.12-1.161h.01a1.14 1.14 0 0 1 1.11 1.161"
					fill="#F9C8BC"
					mask="url(#footer_svg__b)"
				/>
				<path
					fill="#F9C8BC"
					mask="url(#footer_svg__b)"
					d="M163.182 86.31l-3.48 6.287 1.941 1.173 3.893-6.23zM156.23 96.942l3.303-4.023 1.774 1.269-3.826 3.834zM154.727 97.41l-.395.246s-.343.25-.148.52l.58.79s.253.324.505.18l.436-.332-.978-1.404zM165.492 77.873s-1.492.049-1.814 1.187l-.543 2.275h.378s.037.792.052 1.208c.024.299.108.59.247.856.15.232.438.332.7.24l3.12-1.061s1.012-5.007-2.14-4.714"
				/>
				<path
					fill="#F9C8BC"
					mask="url(#footer_svg__b)"
					d="M165.14 83.17l.097 1.645 2.168-.626.043-1.643z"
				/>
				<path
					d="M172.255 106.274l-3.553-21.528s-.35-1.161-1.245-.92l-3.132.902s-.69.177-.836 1.382l-3.146 19.85 11.912.314z"
					fill="#1BB3BC"
					mask="url(#footer_svg__b)"
				/>
				<path
					d="M169.733 85.718a1.332 1.332 0 1 1-1.47-1.18l.019-.001a1.33 1.33 0 0 1 1.45 1.181M175.268 90.202a1.204 1.204 0 1 1-1.327-1.068c.661-.069 1.254.408 1.327 1.068M178.272 94.948a.825.825 0 1 1-1.64.177.825.825 0 0 1 1.64-.178v.001"
					fill="#F9C8BC"
					mask="url(#footer_svg__b)"
				/>
				<path
					fill="#F9C8BC"
					mask="url(#footer_svg__b)"
					d="M169.257 84.812l5.462 4.505-1.573 1.785-5.602-4.231zM174.998 89.561l3.045 4.9-1.303 1.065-3.42-4.252zM157.713 97.453a.824.824 0 1 1-.803-.844h.002c.452.009.81.383.802.835v.01"
				/>
				<path
					fill="#F9C8BC"
					mask="url(#footer_svg__b)"
					d="M178.234 94.745l.349 1.875-1.51 1.115-.333-2.21zM177.073 97.735l.45.62a.5.5 0 0 0 .58.065l.805-.935a.421.421 0 0 0 0-.58l-.314-.29-1.52 1.12zM156.343 96.815l-1.616.58.963 1.405 1.62-.66z"
				/>
				<path
					fill="#95C3D3"
					mask="url(#footer_svg__b)"
					d="M188.657 107.362l-8.051 4.067-4.972-9.848 8.051-4.064z"
				/>
				<path
					fill="#DDEDF2"
					mask="url(#footer_svg__b)"
					d="M153.574 112.92l-8.121-3.924 4.795-9.932 8.124 3.921z"
				/>
				<path
					d="M165.925 84.267l-1.359 7.166a.356.356 0 1 1-.702-.119l.458-6.592 1.603-.455z"
					fill="#FFF"
					mask="url(#footer_svg__b)"
				/>
				<path
					fill="#137E84"
					mask="url(#footer_svg__b)"
					d="M169.268 88.17l-1.724-1.303 2.177 4.064z"
				/>
				<path
					fill="#F9BEB0"
					mask="url(#footer_svg__b)"
					d="M165.196 84.142l1.109-1.106-1.15.392z"
				/>
				<path
					d="M166.39 81.547c-.764 2.099.228 3.15.228 3.15 1.359 1.65 3.364 1.056 3.364 1.056s1.553-.427 1.983-1.625c0 0 .5-1.161-.528-2.613a21.66 21.66 0 0 0-2.528-3.085 4.828 4.828 0 0 0-.926-.749s-1.3-.77-2.447-.395c-.65.217-1.207.65-1.576 1.228 0 0 .322 1.715 1.602 2.415 0 0 0-1.001.72-.891 0 0 .624.09.107 1.512"
					fill="#000"
					mask="url(#footer_svg__b)"
				/>
			</g>
			<path
				d="M298.267 64.966c-3.297-8.032-6.415-17.006-13.863-21.48-2.128-1.27-4.493-2.086-6.838-2.902l-19.447-6.676-14.612 1.77 4.31 8.58 30.33 25.189 21.583-1.042c-.502-1.134-.99-2.28-1.463-3.44"
				fill="#F9C8BC"
			/>
			<path
				d="M254.133 89.925c2.166 1.686 3.774 3.99 5.663 5.985 4.334 4.586 10.818 6.069 15.474 10.185 4.655 4.116 15.067 11.459 6.713-11.782-4.896-13.642-14.994-14.527-18.335-14.852-3.34-.325-15.09 6.116-9.515 10.464"
				fill="#FACCCD"
			/>
			<path
				d="M266.905 90.386c-.961 3.977-16.205-.641-19.247-3.756-2.032-2.092-2.462-4.838-2.348-7.207l6.638-11.32 15.337 9.823-.38 12.46"
				fill="#F8B2B6"
			/>
			<path
				d="M266.19 88.61c-.96 3.976-16.204-.644-19.246-3.756-2.032-2.096-2.462-4.841-2.348-7.21l6.638-11.32 15.337 9.822-.38 12.461"
				fill="#F9C8BC"
			/>
			<path
				d="M263.567 81.136c-.958 3.976-16.202-.645-19.244-3.756-2.032-2.096-2.462-4.842-2.348-7.21l6.635-11.32 15.34 9.82-.383 12.46"
				fill="#F8B2B6"
			/>
			<path
				d="M263.462 79.322c-.96 3.976-16.202-.645-19.247-3.756-2.032-2.096-2.458-4.842-2.348-7.21l6.638-11.32 15.338 9.82-.38 12.46"
				fill="#F9C8BC"
			/>
			<path
				d="M213.308 58.457L234.89 1.88a2.526 2.526 0 0 1 3.26-1.459l34.316 13.09a2.525 2.525 0 0 1 1.46 3.26l-21.582 56.577a2.525 2.525 0 0 1-3.26 1.46l-34.316-13.092a2.525 2.525 0 0 1-1.46-3.259"
				fill="#000"
			/>
			<g transform="translate(236.558 37.92)">
				<mask id="footer_svg__d" fill="#fff">
					<use xlinkHref="#footer_svg__c" />
				</mask>
				<path
					d="M18.556 26.95c1.451 1.742 3.988 5.547 4.644 7.884 2.549 9.108 5.61 18.175 9.869 26.764A37.519 37.519 0 0 0 45.38 75.774s17.787 19.76 17.706 19.76c-.081 0 37.222-21.234 37.222-21.234L78.296 60.5a149.185 149.185 0 0 1-15.15-28.363l-.608.096s-12.135-.258-18.26-3.045C27.786 21.688 7.921-5.399 1.718 1.147-4.485 7.37 11.064 18.063 18.556 26.95"
					fill="#F8B2B6"
					mask="url(#footer_svg__d)"
				/>
			</g>
			<g transform="translate(235.978 38.21)">
				<mask id="footer_svg__f" fill="#fff">
					<use xlinkHref="#footer_svg__e" />
				</mask>
				<path
					d="M19.04 26.44c1.519 1.688 4.16 5.419 4.889 7.732 2.835 9.021 6.182 17.996 10.713 26.436a37.512 37.512 0 0 0 12.771 13.779S65.815 93.573 65.74 93.579c-.076.005 36.529-22.405 36.529-22.405L79.829 58.08a149.442 149.442 0 0 1-16.057-27.864l-.604.116s-12.135.125-18.347-2.464C28.1 20.9 7.378-5.552 1.396 1.188c-6.011 6.411 9.872 16.605 17.645 25.251"
					fill="#F9C8BC"
					mask="url(#footer_svg__f)"
				/>
			</g>
			<g transform="translate(291.997 94.81)">
				<mask id="footer_svg__h" fill="#fff">
					<use xlinkHref="#footer_svg__g" />
				</mask>
				<path
					fill="#FFF"
					mask="url(#footer_svg__h)"
					d="M.287 37.1l9.387 7.565L41.741 9.187 30.096.006z"
				/>
			</g>
			<g transform="translate(302.737 104.388)">
				<mask id="footer_svg__j" fill="#fff">
					<use xlinkHref="#footer_svg__i" />
				</mask>
				<path
					fill="#9BC3D3"
					mask="url(#footer_svg__j)"
					d="M30.265.284L63.99 20.152l-37.403 38.19L.238 33.19z"
				/>
			</g>
			<path
				d="M266.324 36.683l1.451.523-2.049 19.13s-4.354-3.219-5.204-4.28c-.261-.323 5.802-15.373 5.802-15.373"
				fill="#F8B2B6"
			/>
			<path
				d="M322.457 102.235a3.33 3.33 0 1 1 0 6.658 3.33 3.33 0 0 1 0-6.658"
				fill="#9BC3D3"
			/>
			<path
				d="M322.422 101.773a3.181 3.181 0 1 1 0 6.362 3.181 3.181 0 0 1 0-6.362"
				fill="#D2F0F2"
			/>
			<path
				d="M212.206 35.165a.892.892 0 0 1-.689-.324 9.682 9.682 0 0 1-2.214-6.046v-.03a9.353 9.353 0 0 1 2.325-5.951.894.894 0 1 1 1.342 1.18 7.563 7.563 0 0 0-1.879 4.797 7.889 7.889 0 0 0 1.804 4.91.894.894 0 0 1-.689 1.464M208.093 37.479a.892.892 0 0 1-.664-.295c-.127-.141-3.115-3.508-3.072-8.377a12.845 12.845 0 0 1 3.196-8.2.893.893 0 1 1 1.342 1.182 11.048 11.048 0 0 0-2.75 7.045c-.037 4.17 2.585 7.122 2.611 7.151a.894.894 0 0 1-.663 1.494"
				fill="#9BC3D3"
			/>
			<path
				d="M204.28 40.297a.892.892 0 0 1-.663-.294c-.17-.187-4.153-4.655-4.098-11.176.054-6.53 4.122-10.793 4.295-10.971a.894.894 0 1 1 1.283 1.245c-.036.038-3.742 3.95-3.79 9.741-.049 5.83 3.598 9.92 3.634 9.96a.894.894 0 0 1-.662 1.495"
				fill="#9BC3D3"
			/>
			<path
				d="M199.876 43.272a.89.89 0 0 1-.662-.293c-.214-.236-5.247-5.87-5.167-14.127.082-8.265 5.207-13.634 5.426-13.859a.895.895 0 0 1 1.282 1.246c-.048.05-4.846 5.103-4.92 12.63-.073 7.537 4.655 12.856 4.703 12.909a.894.894 0 0 1-.662 1.494"
				fill="#9BC3D3"
			/>
		</g>
	</svg>
);

export default Footer;
