/**
 * Component for the pagination container, consists of the functions for navigation of the pages
 * 
 * Created by Perry Tang
 * Last modified by Moritz Kippenberg
 * Last modified date : 31-07-2019
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {connect} from 'react-redux';
import PaginationButton from 'ui/buttons/PaginationButton/PaginationButton';
import * as utils from '../../../utils/utils.js';
import { getCurrentFlow } from '../../../utils/utils';

// import paginationStyles from './Pagination.scss';

const goToPrevPage = (reducers, history) => {
	utils.goPrevPage(reducers, history);
};

const goToNextPage = (reducers, history) => {
	utils.goNextPage(reducers, history);
};

const Pagination = ( {reducers, history } ) => {

	const prevBtn = reducers.pageIndex !== 0
		? <PaginationButton
			onClick={ () => goToPrevPage(reducers, history) }
			backwards={ true }
		/>
		: null;

	const nextBtn = reducers.pageIndex < utils.getFlowRoutes(getCurrentFlow(history.location)).length - 1
		? <PaginationButton
			onClick={ () => goToNextPage(reducers, history) }
			backwards={ false }
		/>
		: null;

	return(
		<div>
			{ prevBtn }
			{ nextBtn }
		</div>
	);
};

Pagination.propTypes = {
	reducers: PropTypes.object,
	history: PropTypes.object
};

const mapStateToProps = (state) => {
	return{
		reducers: state.app
	};
};

export default connect(mapStateToProps, null)(withRouter(Pagination));
