/**
 * Root component for the app, consists of the router here
 *
 * Created by Perry Tang
 * Last modified by Moritz Kippenberg
 * Last modified date : 29-07-2019
 */

import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import qs from 'query-string';
import C from './constants';
import * as googleEvents from './utils/googleEvents';

import Footer from 'mod/Footer/Footer';
import Error404 from './pages/Error/Error404';

import { onUpdateQueryParams } from './store/QueryParams/actions';

import { routes } from './routes';
import store from './store';

import axios from 'axios';

axios.defaults.headers.common['Content-Type'] = 'application/json';

const routeComponents = routes.map(({ path, component }, key) => <Route exact path={ path } component={ component } key={ key }/>);

const App = ({ actions, reducers, location: { search, pathname } } ) => {

	// const pageIndex = reducers.app.pageIndex;
	// const footer = (pageIndex === 20 || pageIndex === 11) ? <Footer/> : null;
	const footer = pathname === C.FRONTEND.CBU + C.ROUTES.THANKYOU ? <Footer /> : null;

	// Update the query params when the URL changes
	useEffect(() => {
		const query = qs.parse(search, { ignoreQueryPrefix: true });
		store.dispatch(onUpdateQueryParams(query));
	}, [search]);

	return (
		<div id='wrapper'>
			<Switch>
				{/* <Route exact path="/" render={ () => (<Redirect to={ C.FRONTEND.NAME } />) } /> */}
				{ routeComponents }
				<Route component={ Error404 }/>
			</Switch>

			{ footer }
		</div>
	);
};

googleEvents.initGoogleTagManager();

const mapStateToProps = (state) => {
	return {
		reducers: state
	};
};

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators(_.merge({}, { onUpdateQueryParams }), dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

