import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';

import * as formStyles from '../form.scss';
import styles from './RadioGroup.scss';

import CheckWhite from 'ui/svg/CheckWhite';
import { findDOMNode } from 'react-dom';

const RadioGroup = React.forwardRef(( props, ref ) => {

	const [val, setVal] = useState(props.init || '');
	const [focus, setFocus] = useState(false);
	const [isInputFocus, setInputFocus] = useState(false);

	const id = uuid();

	const getWrapperClassNames = () => {

		let className = styles.formItem + ' ' + styles.radioGroupWrapper;
		className += (props.isHidden ? ' ' + formStyles.itemHidden : '' );
		className += (props.disabled ? ' ' + formStyles.itemDisabled : '' );
		className += (props.hasError ? ' ' + formStyles.formItemError + ' ' + styles.formItemError : '' );
		className += (props.isValid ? ' ' + formStyles.formItemValid : '' );
		className += (props.radioState === 1 && val ? ' ' + styles.isOpen : '' );
		className += ( (focus || isInputFocus) ? ' ' + styles.formItemActive : '');
		return className;

	};

	const onClick = (event) => {

		event.preventDefault();
		if (event.target.nodeName.toLowerCase() !== 'input') {
			event.target.querySelector('input').focus();
		}

	};

	const onFocus = (event) => {
		event.preventDefault();
		setFocus(true);
	};

	const onBlur = (event) => {
		event.preventDefault();
		setFocus(false);
	};

	const onInputFocus = (event) => {
		event.preventDefault();
		setInputFocus(true);
	};

	const onInputBlur = (event) => {
		event.preventDefault();
		setInputFocus(false);
	};

	const handleInputChange = (event) => {
		event.preventDefault();

		if(props.regex !== undefined) {
			if((props.regex.test(event.target.value) || event.target.value === '') && (!props.maxlength || (props.maxlength && event.target.value.length <= props.maxlength))) {
				props.inputOnChange(event.currentTarget.value);
				setVal(event.target.value);
			}
		}
		else {
			props.inputOnChange(event.currentTarget.value);
			setVal(event.target.value);
		}
	};

	// Called upon the radio button is clicked
	const onBtnClick = (value) => {

		props.radioOnChange(value);

		if(textRef && value === 'Ja' && val) {
			let textInput = findDOMNode(textRef.current);
			if(textInput) {
				setTimeout(() => {
					textInput.focus();
				}, 500);
			}
		}

	};

	const handleKeyDown = (event) => {

		// Tab Behaviour
		if (!event.shiftKey && (event.keyCode === 'Tab' || event.keyCode === 9)) {
			event.preventDefault();

			if(props.radioState === 1 && val) {
				if(textRef) {
					let nextInput = findDOMNode(textRef.current);
					if(nextInput) {
						nextInput.focus();
					}
				}
			}
			else {
				if(props.nextRef) {
					let nextInput = findDOMNode(props.nextRef.current);
					if(nextInput) {
						nextInput.focus();
					}
				}
			}

		}
		else if (event.shiftKey && (event.keyCode === 'Tab' || event.keyCode === 9)) {
			event.preventDefault();

			if(props.radioState === 1 && val) {
				if(textRef) {
					let prevInput = findDOMNode(ref.current);
					if(prevInput) {
						prevInput.focus();
					}
				}
			}
			else {
				if(props.prevRef) {
					let prevInput = findDOMNode(props.prevRef.current);
					if(prevInput) {
						prevInput.focus();
					}
				}
			}

		}

		// Arrow Left Behaviour
		if ( event.keyCode === 37 ) {
			props.radioOnChange('Ja');
		}

		// Arrow Right Behaviour
		if ( event.keyCode === 39 ) {
			props.radioOnChange('Nein');
		}

	};

	const handleKeyDownTextInput = (event) => {

		if (event.keyCode === 9 || event.keyCode === 'Tab') {
			event.preventDefault();

			if(textRef && props.nextRef && val) {
				let nextInput = findDOMNode(props.nextRef.current);
				if(nextInput) {
					nextInput.focus();
				}
			}

		}

	};

	const textRef = useRef(null);

	return (
		<div className={ getWrapperClassNames() }>
			<div
				className={ styles.radioWrapper }
				onKeyDown={ handleKeyDown }
				tabIndex="0"
				onFocus={ onFocus }
				onBlur={ onBlur }
				ref={ ref }
			>
				<div
					className={ styles.radioElem + ' ' + styles.borderRight }
					onClick={ (e) => onBtnClick('Ja') }
					data-testid={ props.testId ? 'radio-group-' + props.testId + '-yes' : null }
				>
					<div className={ styles.radioBtn } >
						<div className={ styles.radioBtnInnerBorder } />
						<div className={ styles.radioBtnInnerCircle + (props.radioState === 1 ? ' ' + styles.active : '') }>
							<CheckWhite />
						</div>
					</div>
					<div>
						Ja
					</div>
				</div>
				<div
					className={ styles.radioElem }
					onClick={ (e) => onBtnClick('Nein') }
					data-testid={ props.testId ? 'radio-group-' + props.testId + '-no' : null }
				>
					<div className={ styles.radioBtn } >
						<div className={ styles.radioBtnInnerBorder } />
						<div className={ styles.radioBtnInnerCircle + (props.radioState === 0 ? ' ' + styles.active : '') }>
							<CheckWhite />
						</div>
					</div>
					<div>
						Nein
					</div>
				</div>
			</div>
			<div className={ styles.inputWrapper + ((props.radioState === 1 && val) ? ' ' + styles.active : '') }
				onClick={ onClick }>
				<label htmlFor={ id } className={ formStyles.formLabel }>{ props.label }</label>
				<input
					id={ id }
					className={ styles.inputElem }
					disabled={ props.disabled ? props.disabled : false }
					required={ props.required ? props.required : false }
					maxLength={ props.maxlength ? props.maxlength : null }
					onKeyDown={ handleKeyDownTextInput }
					onFocus={ onInputFocus }
					onBlur={ onInputBlur }
					value={ val }
					onChange={ handleInputChange }
					tabIndex="0"
					ref={ textRef }
					data-testid={ props.testId ? 'radio-group-input-' + props.testId : null }
				>
				</input>
			</div>
		</div>
	);

});

RadioGroup.propTypes = {
	label: PropTypes.string.isRequired,
	init: PropTypes.string,
	radioState: PropTypes.number,
	inputOnChange: PropTypes.func.isRequired,
	radioOnChange: PropTypes.func.isRequired,
	isHidden: PropTypes.bool,
	disabled: PropTypes.bool,
	required: PropTypes.bool,
	maxlength: PropTypes.number,
	hasError: PropTypes.bool,
	isValid: PropTypes.bool,
	nextRef: PropTypes.object,
	testId: PropTypes.string
};

export default RadioGroup;
