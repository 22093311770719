/**
 * CreditCardNumber Page
 *
 * Created by Cristian Restituyo
 * Last modified by Cristian Restituyo
 * Last modified date : 23-07-2019
 */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Page from 'mod/Page/Page';
import { Row, Col } from 'mod/FlexGrid/index';
import _ from 'lodash';
import C from '../../constants';

import DefaultInput from 'ui/inputs/DefaultInput/DefaultInput';
import CheckboxInput from 'ui/inputs/CheckboxInput/CheckboxInput';
import IconExternalLink from 'ui/svg/IconExternalLink';
import * as AppActions from '../../store/App/actions';
import * as EntryActions from '../../store/CreditCardNumber/actions';
import * as utils from '../../utils/utils';
import * as googleEvents from '../../utils/googleEvents';
import * as regex from '../../utils/regex';


const CreditCardNumber = ({ reducers, actions, history, location }) => {

	const [state, setState] = useState({
		path: C.ROUTES.CREDIT_CARD_NUMBER,
		currentFlow: utils.getCurrentFlow(location),
		creditCardNumber: '',
		isCreditCardNumberErr: false,
		errorMsg: '',
		isShowError: false,
		acceptPrivacyPolicies: false,
		acceptPrivacyPoliciesErr: false
	});

	useEffect(() => {
		// Set the page Index for navigation
		actions.updatePage(utils.getPageIndex(state.path, state.currentFlow));

		// Set the default value on page load
		setState({
			...state,
			creditCardNumber: reducers.creditCardNumber.creditCardNumber,
			acceptPrivacyPolicies: reducers.creditCardNumber.acceptPrivacyPolicies
		});

		// Block access from user type in url directly
		utils.checkIsFromNav(history, reducers.app.isReload, state.currentFlow + C.ROUTES.NAME);


	}, []);


	const onFormSubmit = () => {

		/*** Validation ***/
		let isValid = true;
		let cloneState = _.cloneDeep(state);

		cloneState.errorMsg = '';

		// Start Validation

		if(state.creditCardNumber.split('').length < 19 ) {
			isValid = false;

			googleEvents.continueInvalid();
			cloneState.isCreditCardNumberErr = true;
			utils.showError(cloneState, 'isCreditCardNumberErr', utils.m('creditCardNumber.validation.numberLessThan16Digits', 'fields'));
			setState(cloneState);
			
		}
		else {
			cloneState.isCreditCardNumberErr = false;
		}

		if(isValid) {
			// on validation success
			utils.hideError(state, setState);

			googleEvents.continueValid();

			// cloneState.creditCardNumber = utils.formatCreditCard(cloneState.creditCardNumber);

			actions.onUpdateCreditCardNumber(state);
			actions.updateReload(false);
			utils.goToPage(history, state.currentFlow + C.ROUTES.ADDRESS);
		}

	};

	const hasEmptyFields = () => {
		return utils.isEmpty(state.creditCardNumber) || !state.acceptPrivacyPolicies;		
	};

	const onChecked = () => {
		setState({
			...state,
			acceptPrivacyPolicies: !state.acceptPrivacyPolicies
		});
	};


	// const titleRef = useRef(null);
	const acceptPrivacyPoliciesRef = useRef(null);
	const financingNumberRef = useRef(null);
	const submitRef = useRef(null);
	

	return(
		<Page
			title={ utils.m('creditCardNumber.title', state.currentFlow) }
			subtitle={ utils.m('creditCardNumber.subtitle', state.currentFlow) }
			isShowError={ state.isShowError }
			errorMsg={ state.errorMsg }
			hasNextButton={ true }
			onFormSubmit={ onFormSubmit }
			nextButtonDisabled={ hasEmptyFields() }
			onBackButtonClick = { history.goBack }
			location={ location }
			currentFlow={ state.currentFlow }
			pageName="CreditCardNumber"
			currentStep={ C.PROGRESS_BAR.personalData }
			screen={ 'A4' }
			showButtonDivider={ true }
			submitRef={ submitRef }
			prevRef={ acceptPrivacyPoliciesRef }
			nextRef={ financingNumberRef }
			gtmStep="userdata_creditcardnumber"
			gtmStatus="registration_pending"
			gtmSubStatus={ null }
		>
			<Row justifyContent="center">
				<Col xs={ 12 } sm={ 12 }>
					<DefaultInput
						inputOnChange={ (val) => { 
							utils.onInputChange(setState, state, 'creditCardNumber', utils.formatCreditCard(val));
						} }
						label={ utils.m('creditCardNumber.label', 'fields') }
						hasError={ state.isCreditCardNumberErr }
						init={ state.creditCardNumber }
						maxlength={ 19 }
						ref={ financingNumberRef }
						prevRef={ submitRef }
						nextRef={ submitRef }
						regex={ regex.creditCardCharacters() }
						testId="creditcard-number"
					/>
				</Col>
				<Col xs={ 12 }>
					<CheckboxInput
						inputOnChange={ onChecked }
						label={
							<>
								{utils.m('acceptPrivacyPolicies.description', 'fields')}{' '}
								<a
									className='link'
									href='https://www.santander.de/datenschutz'
									target='_blank'
									rel='noopener noreferrer'
								>
									{utils.m('acceptPrivacyPolicies.linkText', 'fields')}{' '}
									<IconExternalLink />
								</a>
							</>
						}
						hasError={ state.acceptPrivacyPoliciesErr }
						checked={ state.acceptPrivacyPolicies }
						ref={ acceptPrivacyPoliciesRef }
						prevRef={ financingNumberRef }
						nextRef={ hasEmptyFields() ? financingNumberRef : submitRef }
						required={ true }
						testId='accept-privacy-policies'
					/>
				</Col>
			</Row>
		</Page>
	);
};

CreditCardNumber.propTypes = {
	reducers: PropTypes.object,
	actions: PropTypes.object,
	history: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators( _.merge({}, AppActions, EntryActions), dispatch)
});

const mapStateToProps = (state) => {
	return{
		reducers: state
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(CreditCardNumber);
