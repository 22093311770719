import React, { useState, useEffect, useRef } from 'react';
import { findDOMNode } from 'react-dom';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';

import * as formStyles from '../form.scss';
import styles from './TwoStepsInput.scss';

const TwoStepsInput = React.forwardRef(( props, ref ) => {

	const [focus, setFocus] = useState(false);
	const [state, setState] = useState({
		firstVal: 0,
		secondVal: 0
	});

	useEffect(() => {
		
		setState({
			...state,
			firstVal: props.initFirstVal,
			secondVal: props.initSecondVal
		});
		
	}, [props.initFirstVal, props.initSecondVal]);

	const id = uuid();

	const getWrapperClassNames = () => {
		const {initialValRange} = props;

		let className = styles.formItem + ' ' + styles.twoStepsWrapper;
		className += (props.isHidden ? ' ' + formStyles.itemHidden : '' );
		className += (props.disabled ? ' ' + formStyles.itemDisabled : '' );
		className += (props.hasError ? ' ' + formStyles.formItemError + ' ' + styles.formItemError : '' );
		className += (props.isValid ? ' ' + formStyles.formItemValid : '' );
		className += (state.firstVal > initialValRange ? ' ' + styles.isOpen : '' );
		className += ( focus ? ' ' + styles.formItemActive : '');
		return className;

	};

	const onFocus = (event) => {
		event.preventDefault();
		setFocus(true);
	};

	const onBlur = (event) => {
		event.preventDefault();
		setFocus(false);
	};

	// Called upon the radio button is clicked
	const onFirstNumberClick = (value) => {
		setState({
			...state,
			firstVal: value,
			secondVal: props.initSecondVal
		});

		props.inputOnChangeFirst(value);
	};

	const onSecondNumberClick = (value) => {

		setState({
			...state,
			secondVal: value
		});

		props.inputOnChangeSecond(value);
	};

	const handleKeyDownFirstStep = (event) => {

		// on Enter
		if ( event.keyCode === 13 ) {
			event.preventDefault();

			if(props.onFormSubmit){
				props.onFormSubmit();
			}
		}

		// Tab Behaviour
		if (!event.shiftKey && (event.keyCode === 'Tab' || event.keyCode === 9)) {
			event.preventDefault();

			let nextInput = findDOMNode(state.firstVal !== 0 ? secondStepRef.current : props.nextRef.current);
			if(nextInput) {
				nextInput.focus();
			}

		}
		else if (event.shiftKey && (event.keyCode === 'Tab' || event.keyCode === 9)) {
			event.preventDefault();

			let prevInput = findDOMNode(props.prevRef.current);
			if(prevInput) {
				prevInput.focus();
			}

		}

		// Arrow Left Behaviour
		if ( event.keyCode === 37 ) {
			let val = state.firstVal;

			if(val > 0){
				val--;
				setState({
					...state,
					firstVal: val
				});
				props.inputOnChangeFirst(val);
			}
		}

		// Arrow Right Behaviour
		if ( event.keyCode === 39 ) {
			let val = state.firstVal;

			if(val < props.maxNo){
				val++;
				setState({
					...state,
					firstVal: val
				});
				props.inputOnChangeFirst(val);
			}
		}

	};

	const handleKeyDownSecondStep = (event) => {

		// Tab Behaviour
		if (!event.shiftKey && (event.keyCode === 'Tab' || event.keyCode === 9)) {
			event.preventDefault();

			if(props.nextRef) {
				let nextInput = findDOMNode(props.nextRef.current);
				if(nextInput) {
					nextInput.focus();
				}
			}
		}
		else if (event.shiftKey && (event.keyCode === 'Tab' || event.keyCode === 9)) {
			event.preventDefault();

			let prevInput = findDOMNode(ref.current);
			if(prevInput) {
				prevInput.focus();
			}

		}

		// Arrow Left Behaviour
		if ( event.keyCode === 37 ) {
			let val = state.secondVal;

			if(val > 0){
				val--;
				setState({
					...state,
					secondVal: val
				});
				props.inputOnChangeSecond(val);
			}
		}

		// Arrow Right Behaviour
		if ( event.keyCode === 39 ) {
			let val = state.secondVal;

			if(val < state.firstVal){
				val++;
				setState({
					...state,
					secondVal: val
				});
				props.inputOnChangeSecond(val);
			}
		}

	};

	const renderNumber = (start, end, stateVal, func, testIdExtension) => {
		let comp = [];
		for(let i = start; i <= end; i++){
			comp.push( <span
				key={ i }
				className={ (stateVal === i) ? styles.active : '' }
				onClick={ () => { func(i); } }
				data-testid={ props.testId ? 'two-steps-input-' + (testIdExtension ? testIdExtension + '-' : '') + props.testId + '-' + i : null }
			>{i}</span> );
		}
		return comp;
	};

	const secondStepRef = useRef(null);
	const { initialValRange } = props;
	return (
		<div 
			className={ getWrapperClassNames() }
			onFocus={ onFocus }
			onBlur={ onBlur }
		>
			<div
				className={ styles.firstStepWrapper }
				tabIndex="0"
				onKeyDown={ handleKeyDownFirstStep }
				ref={ ref }
			>
				<label htmlFor={ id } className={ formStyles.formLabel }>{ props.firstStepLabel }</label>
				<div className={ styles.numberContainer }>
					{ renderNumber(props.initialValRange, props.maxNo, state.firstVal, onFirstNumberClick, 'step-1') }
				</div>
			</div>
			<div
				className={ styles.secondStepWrapper + (state.firstVal > initialValRange ? ' ' + styles.active : '') }
				tabIndex="0"
				onKeyDown={ handleKeyDownSecondStep }
				ref={ secondStepRef }
			>
				<label htmlFor={ id } className={ formStyles.formLabel }>{ props.secondStepLabel }</label>
				<div className={ styles.numberContainer }>
					{ renderNumber(props.initialValRange - 1, state.firstVal - 1, state.secondVal , onSecondNumberClick, 'step-2') }
				</div>
			</div>
		</div>
	);

});

TwoStepsInput.propTypes = {
	firstStepLabel: PropTypes.string.isRequired,
	secondStepLabel: PropTypes.string.isRequired,
	maxNo: PropTypes.number.isRequired,
	initFirstVal: PropTypes.number,
	initSecondVal: PropTypes.number,
	inputOnChangeFirst: PropTypes.func.isRequired,
	inputOnChangeSecond: PropTypes.func.isRequired,
	isHidden: PropTypes.bool,
	disabled: PropTypes.bool,
	required: PropTypes.bool,
	hasError: PropTypes.bool,
	isValid: PropTypes.bool,
	nextRef: PropTypes.object,
	testId: PropTypes.string,
	initialValRange: PropTypes.number
};

TwoStepsInput.defaultProps = {
	initialValRange: 0
};

export default TwoStepsInput;
