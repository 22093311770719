/**
 * Component for the header
 *
 * Created by Perry Tang
 * Last modified by Moritz Kippenberg
 * Last modified date : 11-07-2019
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'mod/FlexGrid/index';
import headerStyles from 'mod/Header/Header.scss';
import Logo from 'ui/svg/Logo';
import LogoMobile from 'ui/svg/LogoMobile';

import { connect } from 'react-redux';
import * as utils from '../../../utils/utils';

import DropdownArrow from 'ui/svg/DropdownArrow';
import Engine from 'ui/svg/Engine';

const Header = (props) => {

	const currentFlow = utils.getCurrentFlow(props.location);

	const [tooltipActive, setTooltipActive] = useState(false);

	return(
		<div className={ headerStyles.headerBorder }>
			<Container>
				<Row>
					<Col xs={ 12 }>
						<div className={ headerStyles.headerWrapper }>

							<div className={ headerStyles.logo } >
								<Logo className={ headerStyles.isDesktop } />
								<LogoMobile className={ headerStyles.isMobile }/>
							</div>

							<div className={ headerStyles.headerHeadline }>
								<h2 className={ headerStyles.headerTitle }>
									<strong>{ utils.m('title', currentFlow, null, true) }</strong><br />
									{ props.reducers.name.salutation !== '' && props.reducers.name.firstName !== '' && props.reducers.name.lastName !== '' ? utils.m('salutation.for', 'fields', { salutation: (props.reducers.name.salutation === 'FEMALE' ? utils.m('salutation.items.female', 'fields') : utils.m('salutation.items.maleExt', 'fields')) /*+ (props.reducers.name.title && props.reducers.name.title !== '' && props.reducers.name.title !== 'none' ? ' ' + props.reducers.name.title : '')*/, firstName: props.reducers.name.firstName, lastName: props.reducers.name.lastName }) : '' }
								</h2>
							</div>

							{ props.reducers.app.monthlyRate ||
								props.reducers.app.amount ||
								props.reducers.app.runtime ? (
									<div className={ headerStyles.headerRight }>
										<div
											className={ headerStyles.headerRightItemIcon + (tooltipActive ? ' ' + headerStyles.headerRightItemIconActive : '') }
											onClick={ () => { setTooltipActive(!tooltipActive); } }
										>
											<div className={ headerStyles.headerRightItemIconLeft }>
												<Engine />
											</div>
											<div className={ headerStyles.headerRightItemIconRight }>
												<DropdownArrow />
											</div>
											<div className={ headerStyles.headerRightItemIconTooltip + (tooltipActive ? ' ' + headerStyles.headerRightItemIconTooltipActive : '') }>
												{ props.reducers.app.amount ? (
													<div className={ headerStyles.headerRightItemIconTooltipItem }>
														<h6>{ utils.m('amount.label', 'fields') }</h6>
														<p>{ props.reducers.app.amount },00 €</p>
													</div>
												) : null }
												{ props.reducers.app.runtime ? (
													<div className={ headerStyles.headerRightItemIconTooltipItem }>
														<h6>{ utils.m('runtime.label', 'fields') }</h6>
														<p>{ props.reducers.app.runtime } { utils.m('month.plural', 'fields') }</p>
													</div>
												) : null }
												{ props.reducers.app.monthlyRate ? (
													<div className={ headerStyles.headerRightItemIconTooltipItem }>
														<h6>{ utils.m('monthlyRate.label', 'fields') }</h6>
														<p>{ props.reducers.app.monthlyRate },00 €</p>
													</div>
												) : null }
											</div>
										</div>
										{ props.reducers.app.amount ? (
											<div className={ headerStyles.headerRightItem }>
												<h6>{ utils.m('amount.label', 'fields') }</h6>
												<p>{ props.reducers.app.amount },00 €</p>
											</div>
										) : null }
										{ props.reducers.app.runtime ? (
											<div className={ headerStyles.headerRightItem }>
												<h6>{ utils.m('runtime.label', 'fields') }</h6>
												<p>{ props.reducers.app.runtime } { utils.m('month.plural', 'fields') }</p>
											</div>
										) : null }
										{ props.reducers.app.monthlyRate ? (
											<div className={ headerStyles.headerRightItem }>
												<h6>{ utils.m('monthlyRate.label', 'fields') }</h6>
												<p>{ props.reducers.app.monthlyRate },00 €</p>
											</div>
										) : null }
									</div>
								) : null }

						</div>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

Header.propTypes = {
	reducers: PropTypes.object,
	location: PropTypes.object
};

const mapStateToProps = (state) => {
	return{
		reducers: state
	};
};

export default connect(mapStateToProps, null)(Header);
