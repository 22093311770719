/**
 * Action Type
*/

const UPDATE_FINANCING_NUMBER = 'UPDATE_FINANCING_NUMBER';

/*
* Action Creators
*/

export const onUpdateFinancingNumber = payload => ({
	type: UPDATE_FINANCING_NUMBER,
	payload
});