/**
 * ButtonQuestionmarkIcon
 *
 * Created by Moritz Kippenberg
 * Last modified by Moritz Kippenberg
 * Last modified date : 01-07-2019
 */

import React from 'react';
import PropTypes from 'prop-types';

import InfoIcon from 'ui/svg/Info';

import styles from './ButtonInfoIcon.scss';

const ButtonInfoIcon = (props) => {

	return(
		<InfoIcon
			fill={ props.color ? props.color : '#979797' }
			className={ styles.iconInfo }
			onClick={ (event) => {
				if(props.onButtonClick) {
					event.stopPropagation();
					props.onButtonClick();
				}
			} }
		/>
	);

};

ButtonInfoIcon.propTypes = {
	onButtonClick: PropTypes.func,
	color: PropTypes.string
};

export default ButtonInfoIcon;
