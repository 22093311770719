import * as React from 'react';

const SuccessIcon = props => (
	<svg width={ 18 } height={ 17 } viewBox="0 0 18 17" { ...props }>
		<defs>
			<path
				d="M10.367 1.06l6.602 12.4c.519.98.149 2.19-.826 2.71-.289.15-.612.23-.94.23H2a1.996 1.996 0 0 1-1.765-2.94l6.601-12.4A2.014 2.014 0 0 1 9.541.23c.352.19.639.48.826.83zm-.883.47a.946.946 0 0 0-.413-.41.996.996 0 0 0-1.352.41l-6.602 12.4A1.002 1.002 0 0 0 2 15.4h13.203a.97.97 0 0 0 .47-.12.997.997 0 0 0 .413-1.35L9.484 1.53zm-.841 9.71a.485.485 0 0 1-.479-.45l-.338-4.67a.817.817 0 0 1 .758-.88h.059a.814.814 0 0 1 .816.88l-.338 4.67a.484.484 0 0 1-.478.45zm.029 2.34a.932.932 0 0 1-.93-.93c0-.52.416-.93.93-.93.513 0 .929.41.929.93 0 .51-.416.93-.929.93z"
				id="success_icon_svg__a"
			/>
		</defs>
		<g fill="none" fillRule="evenodd">
			<mask id="success_icon_svg__b" fill="#fff">
				<use xlinkHref="#success_icon_svg__a" />
			</mask>
			<path
				fill="#3A8340"
				mask="url(#success_icon_svg__b)"
				d="M-5-5h27.204v26.4H-5z"
			/>
		</g>
	</svg>
);

export default SuccessIcon;
