import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid/v4';
import { findDOMNode } from 'react-dom';

import styles from './DefaultInput.scss';
import * as formStyles from '../form.scss';
import * as utils from '../../../../utils/utils';

const DefaultInput = React.forwardRef(( props, ref ) => {

	const [val, setVal] = useState('');
	const [err, setErr] = useState(false);
	const [focus, setFocus] = useState(false);

	useEffect(() => {
		setVal(props.init);
	}, [props.init]);

	useEffect(() => {
		if(props.type === 'number' && props.isCurrency && !utils.isEmpty(props.init)) {
			setVal(utils.getCurrencyValue(props.init));
		}
	},[]);

	const id = uuid();

	const getWrapperClassNames = () => {
		let className = formStyles.formItem;
		className += (props.customClass ? ' ' + props.customClass : '');
		className += (props.isHidden ? ' ' + formStyles.itemHidden : '');
		className += (props.disabled ? ' ' + formStyles.itemDisabled : '');
		className += (props.hasError || err ? ' ' + formStyles.formItemError : '');
		className += (props.isValid ? ' ' + formStyles.formItemValid : '');
		className += (focus ? ' ' + formStyles.formItemActive : '');
		return className;
	};

	const getType = () => {

		if(	props.type === 'email' ||
			props.type === 'password' ||
			props.type === 'tel'

		) {
			return props.type;
		}
		else {
			return 'text';
		}

	};

	const onClick = (event) => {

		event.preventDefault();
		if (event.target.nodeName.toLowerCase() !== 'input' && event.target.nodeName.toLowerCase() !== 'textarea') {
			if(props.type === 'textarea') {
				event.target.querySelector('textarea').focus();
			} 
			else {
				event.target.querySelector('input').focus();
			}
		}

	};

	const onFocus = (event) => {
		event.preventDefault();
		setFocus(true);

		if(props.type === 'number' && props.isCurrency) {
			const cleanedVal = utils.removeCurrencySign(props.originalValue);
			setVal(cleanedVal);
		}
	};

	const onBlur = (event) => {
		event.preventDefault();
		setFocus(false);

		if(props.type === 'number' && props.isCurrency && !utils.isEmpty(props.originalValue)) {
			const currentCurrency = utils.getCurrencyValue(props.originalValue);
			setVal(currentCurrency);
		}
	};

	const handleInputChange = (event) => {

		event.preventDefault();

		if(!props.maxlength || (props.maxlength && event.target.value.length <= props.maxlength)) {

			if (props.regex !== undefined) {
				if (props.regex.test(event.target.value) || event.target.value === '') {
					setInputVal(event);
				}
			}
			else {
				setInputVal(event);
			}

		}

	};

	const setInputVal = (event) => {

		setErr(false);

		if(props.type === 'number' && props.isCurrency) {
			props.inputOnChange(utils.cleanCurrency(event.currentTarget.value));
			setVal(utils.cleanCurrency(val));
			
		} else {
			props.inputOnChange(event.currentTarget.value);
			setVal(event.target.value);
		}
		
		if(props.jumpToNextOnMaxLength && props.maxlength && event.target.value.length === props.maxlength) {
			goToNextRef();
		}

	};

	const handleKeyDown = (event) => {

		if(!event.shiftKey && (event.keyCode === 'Tab' || event.keyCode === 9)) {
			event.preventDefault();
			if(val && val.length > 0) {
				goToNextRef();
			}
			else {
				setErr(true);
			}
		}
		else if(event.shiftKey && (event.keyCode === 'Tab' || event.keyCode === 9)) {
			event.preventDefault();
			goToPrevRef();
		}

	};

	const goToNextRef = () => {
		
		if(ref && props.nextRef) {
			let nextInput = findDOMNode(props.nextRef.current) || props.nextRef;
			if(nextInput) {
				nextInput.focus();
			}
		}

	};

	const goToPrevRef = () => {

		if(ref && props.prevRef) {
			let prevInput = findDOMNode(props.prevRef.current) || props.prevRef;
			if(prevInput) {
				prevInput.focus();
			}
		}

	};

	const renderInput = () => {
		if(props.type === 'textarea') {
			return (
				<textarea 
					data-testid={ props.testId ? 'input-' + props.testId : null }
					id={ id }
					className={ styles.inputElem }
					style = { { resize: 'none' } }
					placeholder={ props.placeholder ? props.placeholder : null }
					disabled={ props.disabled ? props.disabled : false }
					required={ props.required ? props.required : false }
					value={ val }
					onChange={ handleInputChange }
					pattern={ props.type === 'number' ? '\\d*' : (props.customTypeValidation ? props.customTypeValidation : null) }
					onFocus={ onFocus }
					onBlur={ onBlur }
					onKeyDown={ handleKeyDown }
					maxLength={ props.maxlength ? props.maxlength : null }
					rows = { props.rows }
					cols = { props.cols }
					ref={ ref }
					tabIndex="0"
				/>
			);
		}
		return (
			<input
				data-testid={ props.testId ? 'input-' + props.testId : null }
				id={ id }
				type={ getType() }
				className={ styles.inputElem }
				placeholder={ props.placeholder ? props.placeholder : null }
				disabled={ props.disabled ? props.disabled : false }
				required={ props.required ? props.required : false }
				value={ val }
				onChange={ handleInputChange }
				pattern={ props.type === 'number' ? '\\d*' : (props.customTypeValidation ? props.customTypeValidation : null) }
				onFocus={ onFocus }
				onBlur={ onBlur }
				onKeyDown={ handleKeyDown }
				maxLength={ props.maxlength ? props.maxlength : null }
				ref={ ref }
				tabIndex="0"
			/>
		);
	};

	return (
		<div className={ getWrapperClassNames() } onClick={ onClick }>
			<label htmlFor={ id } className={ formStyles.formLabel }>{ props.label }</label>
			{ renderInput() }
		</div>
	);

});

DefaultInput.propTypes = {
	label: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	init: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.func
	]).isRequired,
	inputOnChange: PropTypes.func.isRequired,
	type: PropTypes.string,
	customTypeValidation: PropTypes.string,
	isHidden: PropTypes.bool,
	disabled: PropTypes.bool,
	required: PropTypes.bool,
	hasError: PropTypes.bool,
	isValid: PropTypes.bool,
	maxlength: PropTypes.number,
	jumpToNextOnMaxLength: PropTypes.bool,
	regex: PropTypes.instanceOf(RegExp),
	prevRef: PropTypes.object,
	nextRef: PropTypes.object,
	testId: PropTypes.string,
	rows: PropTypes.number,
	cols: PropTypes.number,
	isCurrency: PropTypes.bool,
	originalValue: PropTypes.string
};

export default DefaultInput;