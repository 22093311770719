import * as React from 'react';

const LoadingSpinner = props => (
	<svg width={ 50 } height={ 50 } viewBox="0 0 50 50" { ...props }>
		<defs>
			<path
				d="M20 39.5c10.77 0 19.5-8.73 19.5-19.5S30.77.5 20 .5.5 9.23.5 20a1.5 1.5 0 0 0 3 0c0-9.113 7.387-16.5 16.5-16.5S36.5 10.887 36.5 20 29.113 36.5 20 36.5a1.5 1.5 0 0 0 0 3z"
				id="loading-spinner_svg__a"
			/>
		</defs>
		<g fill="none" fillRule="evenodd">
			<circle fill="#FFF" cx={ 25 } cy={ 25 } r={ 25 } />
			<circle stroke="#DEEDF2" strokeWidth={ 3 } cx={ 25 } cy={ 25 } r={ 18 } />
			<use
				fill="#1BB3BE"
				xlinkHref="#loading-spinner_svg__a"
				transform="translate(5 5)"
			/>
		</g>
	</svg>
);

export default LoadingSpinner;
