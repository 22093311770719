/**
 * Functions that are fired to Google Tag Manager on Buttons
 *
 * Created by Moritz Kippenberg
 * Last modified by Moritz Kippenberg
 * Last modified date : 08-07-2019
 */

import TagManager from 'react-gtm-module';
import axios from 'axios';
import C from '../constants';

/**
 * Initialize Google Tag Manager
 */
export const initGoogleTagManager = () => {
	
	if(process.env.NODE_ENV !== 'development') {

		axios.get(window.location)
			.then((response) => {

				if(
					response.headers &&
					response.headers.gtmid &&
					response.headers.gtmauth &&
					response.headers.gtmpreview
				) {
					TagManager.initialize({
						gtmId: response.headers.gtmid,
						auth: response.headers.gtmauth,
						preview: response.headers.gtmpreview
					});
				}

			})
			.catch((err) => {});

	}
	else {
		TagManager.initialize({
			gtmId: process.env.REACT_APP_GTM_ID,
			auth: process.env.REACT_APP_GTM_AUTH,
			preview: process.env.REACT_APP_GTM_PREVIEW
		});
	}

};

/**
 * Set page data to the window object
 *
 * @param type
 * @param step
 * @param status
 * @param substatus
 */
export const setDataToWindowObject = (type, step, status, substatus) => {

	let formattedType = 'BestGiro';

	switch (type) {
		case C.FRONTEND.CBU:
			formattedType = 'CBU';
			break;
		case C.FRONTEND.GMK:
			formattedType = 'GMK';
			break;
		case C.FRONTEND.GIRO:
			formattedType = 'BestGiro';
			break;
		default:
			formattedType = 'BestGiro';
	}

	window.applicationInfo = {
		type: formattedType,
		step: step !== undefined ? step : (window.applicationInfo && window.applicationInfo.step ? window.applicationInfo.step : null),
		status: status !== undefined ? status : (window.applicationInfo && window.applicationInfo.status ? window.applicationInfo.status : null),
		substatus: substatus !== undefined ? substatus : (window.applicationInfo && window.applicationInfo.substatus ? window.applicationInfo.substatus : null),
		id: null
	};
};

/**
 * Should be fired on page load and page change
 */
export const virtualPageLoad = (path) => {
	try {
		const tagManagerArgs = {
			dataLayer: {
				event: 'virtual_pageview',
				page_path: path
			}
		};
		TagManager.dataLayer(tagManagerArgs);
	}
	catch(err) {
		console.log('Google Tag Manager: DataLayer.push on "event": "virtual_pageview" failed');
	}
};

/**
 * Should be fired on valid next button
 */
export const continueValid = () => {
	try {
		const tagManagerArgs = {
			dataLayer: {
				event: 'continue_valid'
			}
		};
		TagManager.dataLayer(tagManagerArgs);
	}
	catch(err) {
		console.log('Google Tag Manager: DataLayer.push on "event": "continue_valid" failed');
	}
};

/**
 * Should be fired on invalid next button
 */
export const continueInvalid = () => {
	try {
		const tagManagerArgs = {
			dataLayer: {
				event: 'continue_invalid'
			}
		};
		TagManager.dataLayer(tagManagerArgs);
	}
	catch(err) {
		console.log('Google Tag Manager: DataLayer.push on "event": "continue_invalid" failed');
	}
};

/**
 * Should be fired on click to the previous button
 */
export const goBack = () => {
	try {
		const tagManagerArgs = {
			dataLayer: {
				event: 'return'
			}
		};
		TagManager.dataLayer(tagManagerArgs);
	}
	catch(err) {
		console.log('Google Tag Manager: DataLayer.push on "event": "return" failed');
	}
};
