import _ from 'lodash';

const defaultState = {
	radioState: -1
};

const reducers = (state = defaultState, action) => {
	switch(action.type) {
		case 'UPDATE_PAYMENT_STATUS':
			return _.merge({}, state, {
				radioState: action.payload.radioState
			});
		default:
			return state;
	}
};
export default reducers;