/**
 * Functions to get regex
 *
 * Created by Moritz Kippenberg
 * Last modified by Moritz Kippenberg
 * Last modified date : 10-07-2019
 */

/*
 * - Regex special char ref from : https://maximilian.schalch.de/2018/05/complete-list-of-european-special-characters/
 * - [ALERT] Check if the 'shift Ä' button working on the US international keyboard
 * - Plan B is npm normalize-diacritics for validation and checking
 */

export const email = () => {
	return /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
};

export const digit = () => {
	return /^\d*$/;
};

export const numberWithoutZero = () => {
	return /^[1-9]\d*$/;
};

export const numberWithDot = () => {
	return /^[\d.]*$/;
};

export const numberWithDotAndComma = () => {
	return /^[\d.,]*$/;
};

export const occupationDateFormat = () => {
	return /^(0[1-9]{1}|1[0-2]{1})\.\d{4}$/;
};

export const wordWithSpace = () => {
	return /^[A-Za-zäöüÄÖÜß\s]+$/;
};

export const wordWithSpaceAndDash = () => {
	return /^[A-Za-zäöüÄÖÜß\-\s]+$/;
};

export const wordWithSpaceAndDashAndNumbers = () => {
	return /^[0-9A-Za-zäöüÄÖÜß\-\s]+$/;
};

export const wordWithSpaceAndDashAndComma = () => {
	return /^[A-Za-zäöüÄÖÜß\-,\s]+$/;
};

export const wordWithSpaceAndDashAndCommaAndDot = () => {
	return /^[A-Za-zäöüÄÖÜß\-,.\s]+$/;
};

export const wordWithoutSpace = () => {
	return /^[A-Za-zäöüÄÖÜß]+$/;
};

export const tin = () => {
	return /^[0-9A-Za-zäöüÄÖÜß.\-/]+$/;
};

export const wordWithEmailCharacters = () => {
	return /^[0-9A-Za-z"\-@.+_!#$%&/=?^{|}~]+$/;
};

export const wordWithIbanCharacters = () => {
	return /^[0-9A-Z]+$/;
};

export const creditCardCharacters = () => {
	return /^[\d\s]+$/;
};