import { onUpdateProduct } from '../Product/actions';
import { onUpdateName } from '../Name/actions';
import { onUpdateAddress } from '../Address/actions';
import { onUpdateBirthday } from '../Birthday/actions';
import { onUpdateFinancingNumber } from '../FinancingNumber/actions';
import { onUpdateCreditCardNumber } from '../CreditCardNumber/actions';

/** Actions Type */
const UPDATE_QUERY_PARAMS = 'UPDATE_QUERY_PARAMS';

// Sample URL
// http://localhost:3000?vorname=cristina&name=muller&adresse_str=meister-gerhard-strasse&gebdat_tt=05&gebdat_mm=12&gebdat_jjjj=2019&finanzierungsnummer=1231231121&kreditkartennummer=1123123123&product=kreditcart

/** 
 * Permitted Query Params
 * FORMAT => nesting_key: nested_key
 * 
 * Ex => name: 'title'
 * Because 'name' is the parent key for 'title'
 * 
 	{
		'product': 'product.product',
		'type': 'product.type',
		'name': 'name.lastName',
		'vorname': 'name.firstName',
		'finanzierungsnummer': 'financingNumber.financingNumber',
		'kreditkartennummer': 'creditCardNumber.creditCardNumber',
		'gebdat_tt': 'birthday.day',
		'gebdat_mm': 'birthday.month',
		'gebdat_jjjj': 'birthday.year',
		'adresse_str': 'address.street',
		'adresse_hnr': 'address.number',
		'adresse_ort': 'address.postcode',
		'adresse_plz': 'address.state'
	}
*/

const updateQueryParams = payload => ({
	type: UPDATE_QUERY_PARAMS,
	payload
});

/**
 * Actions Creators
 * TODO: add validation inside each reducers
 * so that invalid initialization can be avoided
 */
export const onUpdateQueryParams = payload => {
	// de-structure paylod
	// validate params if needed
	// rename them to avoid naming conflicts
	const {
		product: productQuery,
		type: typeQuery,
		name: lastName,
		vorname: firstName,
		finanzierungsnummer: financingNumberQuery,
		kreditkartennummer: creditCardNumberQuery,
		gebdat_tt: day,
		gebdat_mm: month,
		gebdat_jjjj: year,
		adresse_str: street,
		adresse_hnr: number,
		adresse_ort: postcode,
		adresse_plz: state
	} = payload;

	// dispatch all related state updates for related query params
	return (dispatch, getState) => {
		const {
			product,
			name,
			address,
			birthday,
			financingNumber,
			creditCardNumber
		} = getState();

		// handle default product if none of the params are there
		if (productQuery || typeQuery) {
			dispatch(
				onUpdateProduct({
					...product,
					...(productQuery && { product: productQuery }), // add only if given
					...(typeQuery && { type: typeQuery }) // add only if given
				})
			);
		}

		if (firstName || lastName) {
			dispatch(
				onUpdateName({
					...name,
					...(firstName && { firstName }), // add only if given
					...(lastName && { lastName }) // add only if given
				})
			);
		}

		if (street || number || postcode || state) {
			dispatch(
				onUpdateAddress({
					...address,
					...(street && { street }), // add only if given
					...(number && { number }), // add only if given
					...(postcode && { postcode }), // add only if given
					...(state && { state }) // add only if given
				})
			);
		}

		if (day || month || year) {
			dispatch(
				onUpdateBirthday({
					...birthday,
					...(day && { day }), // add only if given
					...(month && { month }), // add only if given
					...(year && { year }) // add only if given
				})
			);
		}

		if (financingNumberQuery) {
			dispatch(
				onUpdateFinancingNumber({
					...financingNumber,
					financingNumber: financingNumberQuery
				})
			);
		}

		if (creditCardNumberQuery) {
			dispatch(
				onUpdateCreditCardNumber({
					...creditCardNumber,
					creditCardNumber: creditCardNumberQuery
				})
			);
		}

		// store all queryparams in their original form as well
		return dispatch(updateQueryParams(payload));
	};
};
