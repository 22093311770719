import _ from 'lodash';

const defaultState = {
	previousPostcode: '',
	previousCity: '',
	previousStreet: '',
	previousNumber: ''
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case 'UPDATE_PREVIOUS_ADDRESS':
			return _.merge({}, state, {
				previousPostcode: action.payload.previousPostcode,
				previousCity: action.payload.previousCity,
				previousStreet: action.payload.previousStreet,
				previousNumber: action.payload.previousNumber
			});
		default:
			return state;
	}
};

export default reducers;