import _ from 'lodash';

const defaultState = {
	product: '',
	type: ''
};

const reducers = (state = defaultState, action) => {
	switch(action.type) {
		case 'UPDATE_PRODUCT':
			return _.merge({}, state, {
				product: action.payload.product,
				type: action.payload.type
			});
		default:
			return state;
	}
};
export default reducers;