/**
 * Action Type
*/

const UPDATE_SOLUTIONS = 'UPDATE_SOLUTIONS';

/*
* Action Creators
*/

export const onUpdateSolutions = payload => ({
	type: UPDATE_SOLUTIONS,
	payload
});