import * as React from 'react';

const Engine = props => (
	<svg width={ 24 } height={ 24 } viewBox="0 0 24 24" { ...props }>
		<defs>
			<path id="engine_svg__a" d="M0 24h24V0H0z" />
		</defs>
		<g fill="none" fillRule="evenodd">
			<path
				d="M10.688 20.71h2.506v-1.957l.45-.116a6.743 6.743 0 0 0 1.748-.724l.4-.236 1.385 1.384 1.768-1.768-1.384-1.384.236-.4a6.76 6.76 0 0 0 .723-1.748l.117-.45h1.957V10.81h-1.957l-.117-.451a6.76 6.76 0 0 0-.723-1.748l-.236-.4 1.384-1.385-1.768-1.767-1.384 1.385-.401-.237a6.768 6.768 0 0 0-1.747-.724l-.45-.115v-1.96h-2.502v1.96l-.45.115a6.746 6.746 0 0 0-1.748.724l-.401.237L6.71 5.059 4.942 6.826l1.385 1.386-.237.399a6.793 6.793 0 0 0-.723 1.748l-.117.45H3.292v2.502H5.25l.117.45c.159.615.402 1.203.723 1.748l.237.4-1.385 1.384L6.71 19.06l1.385-1.384.4.236a6.723 6.723 0 0 0 1.748.724l.454.117-.003.467s-.006.88-.006 1.49zm3.705 1.2H9.845l-.176-.176c-.175-.174-.188-.187-.178-2.066a8.083 8.083 0 0 1-1.197-.495L6.71 20.758l-3.463-3.465 1.584-1.585a7.83 7.83 0 0 1-.495-1.198H2.092v-4.9h2.243a7.83 7.83 0 0 1 .495-1.198L3.245 6.826 6.71 3.36l1.584 1.586a7.853 7.853 0 0 1 1.2-.496V2.21h4.9v2.24a7.89 7.89 0 0 1 1.197.497l1.586-1.586 3.465 3.465-1.585 1.586c.197.385.363.785.495 1.198h2.242v4.9h-2.242a8.037 8.037 0 0 1-.495 1.198l1.585 1.585-3.465 3.465-1.585-1.585a7.903 7.903 0 0 1-1.199.495v2.242z"
				fill="#000"
			/>
			<path
				d="M11.999 8.636A3.37 3.37 0 0 0 8.635 12a3.368 3.368 0 0 0 3.364 3.364A3.368 3.368 0 0 0 15.363 12a3.37 3.37 0 0 0-3.364-3.365m0 7.929A4.568 4.568 0 0 1 7.436 12a4.568 4.568 0 0 1 4.563-4.564A4.568 4.568 0 0 1 16.562 12a4.568 4.568 0 0 1-4.563 4.564"
				fill="#000"
			/>
		</g>
	</svg>
);

export default Engine;
