module.exports = {
	next: {
		label: 'Weiter'
	},
	prev: {
		label: 'Zurück'
	},
	submit: {
		label: 'Bestätigen'
	},
	noEntry: {
		label: 'Keine Angabe'
	},
	salutation: {
		label: 'Anrede',
		for: 'für {salutation} {firstName} {lastName}',
		items: {
			male: 'Herr',
			maleExt: 'Herrn',
			female: 'Frau'
		}
	},
	title: {
		label: 'Titel',
		items: {
			dr: 'Dr.',
			prof: 'Prof.',
			profDr: 'Prof. Dr.'
		}
	},
	firstName: {
		label: 'Vorname'
	},
	lastName: {
		label: 'Nachname'
	},
	date: {
		label: 'Datum',
		validation: {
			invalid: 'Bitte geben Sie ein gültiges Datum ein.<br/>',
			notOver18:
				'Bitte beachten Sie, dass Sie volljährig sein müssen.<br/>',
			notInTheFuture:
				'Bitte beachten Sie, dass das angegebene Datum nicht in der Zukunft liegen darf.<br/>',
			notInThePast:
				'Bitte beachten Sie, dass das angegebene Datum nicht in der Zukunft liegen darf.<br/>'
		}
	},
	day: {
		label: 'Tag',
		validation: {
			invalid: 'Die Eingabe für Tag muss zwischen 1 und {maxDays} liegen.<br/>'
		}
	},
	month: {
		label: 'Monat',
		validation: {
			invalid: 'Die Eingabe für den Monat muss zwischen 1 und 12 liegen.<br/>'
		}
	},
	year: {
		label: 'Jahr',
		validation: {
			occupationStartNotInThePast:
				'Ihr Beschäftigungsstart muss in der Vergangenheit liegen.<br/>',
			occupationStartBeforeBirthday:
				'Ihr Beschäftigungsstart darf nicht vor dem eigenen Geburtsdatum liegen.<br/>',
			paymentStatusNotInThePast:
				'Bitte beachten Sie, dass das angegebene Datum nicht in der Zukunft liegen darf.<br/>',
			paymentStatusNotInTheFuture:
				'Bitte beachten Sie, dass das angegebene Datum nicht in der Vergangenheit und höchstens 30 Tage in der Zukunft liegen darf.<br/>',
			invalid: 'Bitte geben Sie ein gültiges Jahr an.</br>',
			invalid2: 'Bitte geben Sie ein gültiges Jahr ein.</br>'
		}
	},
	email: {
		label: 'E-Mail',
		validation: {
			invalid: 'Bitte geben Sie eine gültige E-Mail Adresse ein.<br/>',
			alreadyExists:
				'Es existiert bereits ein Account mit dieser E-Mail-Adresse.<br/>'
		}
	},
	financingNumber: {
		label: 'Finanzierungsnummer',
		validation: {
			numberLessThan10Digits:
				'Bitte geben Sie Ihre 10-stellige Finanzierungsnummer ein.'
		}
	},
	creditCardNumber: {
		label: 'Kreditkartennummer',
		validation: {
			numberLessThan16Digits:
				'Bitte geben Sie Ihre 16-stellige Kreditkartenummer ein.'
		}
	},
	paymentStatus: {
		option1: {
			label: 'Ich habe bereits überwiesen'
		},
		option2: {
			label: 'Ich werde kurzfristig überweisen'
		},
		option3: {
			label: 'Ich kann nicht überweisen / einzahlen'
		}
	},
	amountTransfered: {
		label: 'Überwiesener Betrag',
		validation: {
			numberLessThan16Digits:
				'Bitte geben Sie Ihre 16-stellige Finanzierungsnummer ein.'
		}
	},
	reasonForLateTransfer: {
		label: 'Möchten Sie uns noch etwas mitteilen?',
		validation: 'test'
	},
	alreadyExistsPopup: {
		text:
			'Sie sind bereits mit Ihrer E-Mail-Adresse bei uns registriert. Bitte klicken Sie auf "Weiter", um Ihren Antrag fortzuführen.',
		submit: 'Weiter'
	},
	countryCode: {
		label: 'Ländervorwahl',
		validation: {
			tooShort:
				'Die Ländervorwahl darf nicht weniger als 2 Zeichen enthalten.<br/>'
		}
	},
	mobilePhone: {
		label: 'Mobilfunknummer',
		validation: {
			tooShort:
				'Die Mobilfunknummer darf nicht weniger als 11 Zeichen enthalten.<br/>'
		}
	},
	landlinePhone: {
		label: 'Festnetz',
		validation: {
			tooShort: 'Die Festnetznummer darf nicht weniger als 5 Zeichen enthalten.'
		}
	},
	paymentProblems: {
		label: 'Wählen Sie den Grund Ihres Zahlungsproblems',
		items: {
			changeInEmploymentRelationship: {
				label: 'Arbeitsverhältnis',
				labelForApi: 'Arbeitsverhaeltnis',
				subLabel: 'Details zum Arbeitsverhältnis',
				subItems: {
					unemployedWithFutureEmployment:
						'Arbeitslos mit neuer zukünftiger Beschäftigung',
					unemployedWithoutFutureEmployment:
						'Arbeitslos ohne neue Beschäftigung',
					temporaryShortTimeWork: 'Befristete Kurzarbeit',
					incomeReduction: 'Reduzierung des Einkommens',
					inabilityToWork: 'Arbeitsunfähigkeit',
					other: 'Sonstiges'
				},
				labelsForApi: {
					unemployedWithFutureEmployment:
						'voruebergehende_Arbeitslosigkeit',
					unemployedWithoutFutureEmployment:
						'Arbeitslosigkeit',
					temporaryShortTimeWork: 'Kurzarbeit',
					incomeReduction: 'Einkommensreduzierung',
					inabilityToWork: 'Arbeitsunfaehigkeit',
					other: 'Arbeitsverhaeltnis_Sonstiges'
				}
			},
			oneOffSpecialExpenses: {
				label: 'Sonderausgaben',
				subLabel: 'Art der Sonderausgaben',
				extraLabel: 'Höhe der Sonderausgabe',
				extraLabel2: 'Um welche Ausgabe handelt es sich?',
				labelForApi: 'Sonderausgaben',
				subItems: {
					accidentCosts: 'Unfall/Reparaturkosten',
					illnessDeath: 'Krankheit/Todesfall',
					subsequentPayment: 'Nachzahlung',
					parade: 'Umzug',
					other: 'Sonstiges'
				},
				labelsForApi: {
					accidentCosts: 'Reparatur',
					illnessDeath: 'Krankheit',
					subsequentPayment: 'Versicherung',
					parade: 'Umzug',
					other: 'Sonderausgaben_Sonstiges'
				}
			},
			parentalLeave: {
				label: 'Elternzeit',
				labelForApi: 'Elternzeit'
			},
			retirementAge: {
				label: 'Renteneintritt',
				labelForApi: 'Renteneintritt'
			},
			divorce: {
				label: 'Scheidung',
				labelForApi: 'Scheidung',
				extraLabel:
					'Bitte erläutern Sie kurz die finanzielle Belastung durch Ihre Scheidung'
			},
			excessiveDebt: {
				label: 'Überschuldung',
				labelForApi: 'Ueberschuldung',
				extraLabel: 'Bitte erläutern Sie kurz die Umstände Ihrer Überschuldung'
			},
			energyCosts: {
				label: 'Energiekosten',
				labelForApi: 'Energiekosten'
			},
			other: {
				label: 'Sonstiges',
				labelForApi: 'Sonstiges',
				extraLabel:
					'Bitte erläutern Sie uns den Grund genauer'
			}
		}
	},
	busyAgain: {
		label: 'Wann werden Sie wieder beschäftigt sein?'
	},
	specificDateOfMoving: {
		label: 'Bitte geben Sie das Datum des Umzuges an'
	},
	howlongToLast: {
		label: 'Wie lange wird das voraussichtlich anhalten?'
	},
	whenUnabledToWork: {
		label: 'Bis wann werden Sie voraussichtlich arbeitsunfähig sein?'
	},
	otherReason: {
		label: 'Bitte erläutern Sie uns die Umstände genauer',
		placeholder: 'Max. 120 Zeichen'
	},
	numberOfChildren: {
		label: 'Anzahl aller Personen'
	},
	numberOfChildrenWithBenefit: {
		label: 'Davon Kindergeld berechtigt'
	},
	monthlyIncome: {
		primaryColumn: {
			label: 'Art der Einnahmen'
		},
		secondaryColumn1: {
			label: 'Darlehensnehmer 1'
		},
		secondaryColumn2: {
			label: 'Darlehensnehmer 2'
		},
		addRowButton: {
			label: 'Weitere Einnahmen hinzufügen'
		},
		items: {
			netIncome: {
				label: 'Nettoeinkommen',
				tooltipInfo: {
					text:
						'Bitte geben Sie hier Ihr monatliches Durchschnittseinkommen an.'
				}
			},
			annuity: {
				label: 'Rente',
				tooltipInfo: {
					text:
						'Bitte geben Sie hier die Summe Ihrer monatlichen Rentenbezüge an. Hierzu gehören die Regelaltersrente, Witwenrente, Waisenrente, Betriebsrente, Erwerbsunfähigkeitsrente, …'
				}
			},
			unemploymentAllowance: {
				label: 'Arbeitslosengeld',
				tooltipInfo: {
					text:
						'Bitte geben Sie hier die Höhe Ihrer monatlichen Arbeitslosengeldbezüge an.'
				}
			},
			insuranceBenefits: {
				label: 'Versicherungsleistungen',
				tooltipInfo: {
					text: 'Bitte geben Sie hier Krankengeld und/oder Unfallrente an.'
				}
			},
			additionalIncome: {
				label: 'Nebeneinkünfte',
				tooltipInfo: {
					text:
						'Bitte geben Sie hier alle Einkünfte aus Nebenbeschäftigungen an.'
				}
			},
			parentalAllowance: {
				label: 'Elterngeld',
				tooltipInfo: {
					text: 'Bitte geben Sie die Höhe des Elterngeldes gemäß Bescheid ein.'
				}
			},
			alimony: {
				label: 'Unterhalt',
				tooltipInfo: {
					text:
						'Bitte geben Sie die Höhe Ihrer Unterhaltseinkünfte (z.B. Kindesunterhalt, Ehegattenunterhalt) an.'
				}
			},
			rentalIncome: {
				label: 'Mieteinnahme',
				tooltipInfo: {
					text:
						'Bitte geben Sie hier alle Einkünfte aus Vermietung und Verpachtung an.'
				}
			}
		}
	},
	monthlyOutgoings: {
		primaryColumn: {
			label: 'Art der Ausgabe'
		},
		secondaryColumn1: {
			label: 'Darlehensnehmer 1'
		},
		secondaryColumn2: {
			label: 'Darlehensnehmer 2'
		},
		addRowButton: {
			label: 'Weitere Ausgabe hinzufügen'
		},
		items: {
			housingCosts: {
				label: 'Wohnkosten',
				tooltipInfo: {
					text:
						'Bitte geben Sie hier Ihre Warmmiete oder monatlichen Ausgaben für Wohneigentum an.'
				}
			},
			incidentalExpenses: {
				label: 'Nebenkosten',
				tooltipInfo: {
					text:
						'Bitte geben Sie hier alle weiteren Kosten an, die nicht in Ihrer Miete enthalten sind (z.B. Strom, Wasser, Gas).'
				}
			},
			unemploymentAllowance: {
				label: 'Arbeitslosengeld',
				tooltipInfo: {
					text:
						'Bitte geben Sie hier die Höhe Ihrer monatlichen Arbeitslosengeldbezüge an.'
				}
			},
			telecommunicationCosts: {
				label: 'Telekommunikation',
				tooltipInfo: {
					text:
						'Hierzu zählen wir Kosten für Handy-, Internet- und Telefonverträge.'
				}
			},
			insuranceContributions: {
				label: 'Versicherungsbeiträge',
				tooltipInfo: {
					text:
						'Bitte nennen Sie uns ihre durchschnittlichen monatlichen Kosten für alle Versicherungsbeiträge.'
				}
			},
			mobilityCosts: {
				label: 'Mobilitätskosten',
				tooltipInfo: {
					text:
						'Hierzu zählen wir Kosten für Kraftstoffe und/oder Fahrkarten für öffentliche Verkehrsmittel.'
				}
			},
			thirdPartyObligations: {
				label: 'Fremdverpflichtungen',
				tooltipInfo: {
					text:
						'Bitte nennen Sie uns Ihre monatlichen Kosten für Kredite,Kreditkarten o.ä. bei anderen Banken/Gläubigern.'
				}
			},
			costsForMedia: {
				label: 'Kosten für Medien',
				tooltipInfo: {
					text: 'Hierzu zählen wir Kosten für z.B. Sky, Amazon, Netflix, ...'
				}
			},
			maintenanceObligations: {
				label: 'Unterhaltsverpflichtungen',
				tooltipInfo: {
					text:
						'Bitte geben Sie die Höhe Ihrer Unterhaltszahlungen (z.B.Kindesunterhalt, Ehegattenunterhalt) an.'
				}
			},
			onlineAndMailOrderBussiness: {
				label: 'Online- und Versandhandel',
				tooltipInfo: {
					text:
						'Hierzu zählen alle Ratenzahlungen an Händler wie z.B. Otto,Baur, bonprix, Klarna, ...'
				}
			},
			savingsRate: {
				label: 'Sparrate',
				tooltipInfo: {
					text:
						'Bitte geben Sie Ihre monatlichen Ausgaben für z.B. Bausparverträge, Sparverträge,'
				}
			},
			otherExpenditure: {
				label: 'Sonstige Ausgaben',
				tooltipInfo: {
					text:
						'Hierzu zählen wir Kosten wie z.B. Kindergartenbeitrag,Schulgeld, Vereinsbeiträge, ...'
				}
			}
		}
	},
	solutions: {
		option1: {
			label: 'Stundung',
			labelForApi: 'Stundung',
			tooltipInfo: {
				title:'',
				description:'Setzen Sie Ihre Ratenzahlung bis zu 3 Monaten aus. Bitte beachten Sie, dass sich die Dauer Ihrer Ratenzahlung hierdurch verlängert.'
			}
		},
		option2: {
			label: 'Befristete Ratenreduzierung',
			labelForApi: 'Befristete_Ratenreduzierung',
			tooltipInfo: {
				title: '',
				description: 'Reduzieren Sie Ihre Rate vorübergehend auf einen für Sie zahlbaren Betrag. Bitte beachten Sie, dass sich die Dauer Ihrer Ratenzahlung hierdurch verlängert.'
			}
		},
		option3: {
			label: 'Generelle Ratenreduzierung',
			labelForApi: 'Generelle_Ratenreduzierung',
			tooltipInfo: {
				title: '',
				description: 'Reduzieren Sie Ihre Rate dauerhaft auf einen für Sie zahlbaren Betrag. Bitte beachten Sie, dass sich die Dauer Ihrer Ratenzahlung hierdurch verlängert.'
			}
		},
		records: {
			newEmploymentContract: {
				label: 'Neuer Arbeitsvertrag'
			},
			unemploymentBenefitNotice: {
				label: 'Arbeitslosengeldbescheid'
			},
			noticeOfSickPay: {
				label: 'Krankengeldbescheid'
			},
			proofOfShortimeWork: {
				label: 'Nachweis über die Kurzarbeit'
			},
			evidenceIncomeReduction: {
				label: 'Nachweis über die Reduzierung Ihres Einkommens'
			},
			parentalAllowanceNotice: {
				label:'Elterngeldbescheid'
			},
			noticeOfRetirement: {
				label: 'Rentenbescheid'
			}
		}
	},
	defermentOfPayment: {
		option1: {
			label: '1 Rate'
		},
		option2: {
			label: '2 Raten'
		},
		option3: {
			label: '3 Raten'
		}
	},
	code: {
		label: 'Code',
		enter: 'Code eingeben',
		resend: 'Code erneut anfordern',
		validation: {
			invalid: 'Der eingegebene Code ist falsch.<br/>'
		},
		success: {
			newCode: 'Wir haben Ihnen eine SMS mit einem neuen Code zugesandt.<br/>'
		}
	},
	nationality: {
		label: 'Staatsangehörigkeit'
	},
	state: {
		label: 'Bundesland'
	},
	postcode: {
		label: 'PLZ',
		validation: {
			invalid: 'Bitte geben Sie eine gültige PLZ an.<br/>'
		}
	},
	city: {
		label: 'Ort',
		validation: {
			invalid: 'Bitte geben Sie einen gültigen Ortsnamen ein.<br/>'
		}
	},
	street: {
		label: 'Straße',
		validation: {
			invalid: 'Bitte geben Sie eine gültige Straße ein.<br/>'
		}
	},
	houseNumber: {
		label: 'Hausnummer'
	},
	maritalStatus: {
		label: 'Familienstand',
		items: {
			single: 'ledig',
			married: 'verheiratet',
			partnered: 'eingetragene Lebensgemeinschaft',
			separated: 'getrennt lebend',
			divorced: 'geschieden',
			widowed: 'verwitwet'
		}
	},
	numberChildren: {
		label: 'Anzahl im Haushalt lebende Kinder'
	},
	numberChildrenWithBenefit: {
		label: 'Anzahl davon kindergeldberechtigte Kinder'
	},
	occupation: {
		label: 'Beschäftigungsverhältnis',
		items: {
			employed: 'Angestellter',
			pensioner: 'Renter/Pensionär',
			officer: 'Beamter',
			freelancer: 'Selbstständiger',
			student: 'Student',
			unemployed: 'Arbeitslos'
		}
	},
	iban: {
		label: 'IBAN',
		validation: {
			invalid: 'Bitte geben Sie eine gültige IBAN ein.<br/>'
		}
	},
	earnings: {
		label: 'Einkünfte (Netto)'
	},
	livingCosts: {
		label: 'Wohnkosten'
	},
	typeOfLiving: {
		label: 'Wohnart',
		items: {
			rentedApartment: 'Mietwohnung',
			ownerOccupiedApartment: 'Eigentumswohnung',
			ownHouse: 'Eigenes Haus',
			residentWithTheParents: 'Wohnhaft bei den Eltern'
		}
	},
	fatca: {
		label: 'Bitte Länder angeben'
	},
	tin: {
		label: 'Steuer-ID'
	},
	acceptPrivacyPolicies: {
		description:
			'Ja, ich willige ein, dass meine personenbezogenen Daten durch Santander zu dem o.g. Konto und zur Kontaktaufnahme im Rahmen der Geschäftsbeziehung erhoben, verarbeitet und genutzt werden.',
		linkText: 'Datenschutzhinweise',
		validation: {
			invalid:
				'Bitte willigen Sie in die Verarbeitung Ihrer personenbezogenen Daten ein.<br/>'
		}
	},
	allowAdvertising: {
		description: 'Darf Santander Sie',
		linkText: 'beraten und informieren?',
		popup: {
			headline: 'Darf Santander Sie beraten und informieren?',
			content1:
				'Ich bin einverstanden, dass mich die Santander Consumer Bank AG und die Santander Consumer Leasing GmbH per E-Mail, telefonisch, per Brief oder per sonstiger elektronischer Kommunikationsdienste (SMS o.ä.) personalisiert über neue Services und interessante Produktangebote informieren können und stimme insoweit der Verarbeitung meiner personenbezogenen Daten zu (Details hierzu siehe',
			content2:
				'). Dieses Einverständnis bezieht sich auf eigene und auf vermittelte Produkte und Services. Mir ist bewusst, dass ich dieses Einverständnis jederzeit <span class="underlined">widerrufen</span> kann.',
			content3:
				'Ich habe das Recht, auch ohne Einwilligung zulässiger Werbung jederzeit zu <span class="underlined">widersprechen</span> (Details hierzu siehe',
			content4: 'Ziffer III.).',
			linkText: 'Santander-Werbehinweise'
		}
	},
	hasPreviousAddress: {
		description: 'Ich lebe weniger als 3 Jahre hier.'
	},
	ownResponsibility: {
		description:
			'Ich handele auf eigene Rechnung (§11 Abs. 5 GwG) und bin verpflichtet, sich ergebende Änderungen der Pflichtangaben gem. §11 Abs. 6 GwG der Bank unverzüglich mitzuteilen.'
	},
	acceptSchufa: {
		description1: 'Ich stimme der',
		linkText: 'Datenübermittlung an die SCHUFA',
		description2: 'zu.',
		popup: {
			headline: 'Datenübermittlung an die Schufa',
			content1:
				'Die Santander übermittelt im Rahmen dieses Vertragsverhältnisses erhobene personenbezogene Daten über die Beantragung, die Durchführung und Beendigung dieser Geschäftsbeziehung sowie Daten über nicht vertragsgemäßes Verhalten oder betrügerisches Verhalten an die SCHUFA Holding AG, Kormoranweg 5, 65201 Wiesbaden. Rechtsgrundlagen dieser Übermittlungen sind Artikel 6 Absatz 1 Buchstabe b und Artikel 6 Absatz 1 Buchstabe f der Datenschutz-Grundverordnung (DSGVO). Übermittlungen auf der Grundlage von Artikel 6 Absatz 1 Buchstabe f DSGVO dürfen nur erfolgen, soweit dies zur Wahrung berechtigter Interessen der Bank oder Dritter erforderlich ist und nicht die Interessen oder Grundrechte und Grundfreiheiten der betroffenen Person, die den Schutz personenbezogener Daten erfordern, überwiegen. Der Datenaustausch mit der SCHUFA dient auch der Erfüllung gesetzlicher Pflichten zur Durchführung von Kreditwürdigkeitsprüfungen von Kunden (§ 505a des Bürgerlichen Gesetzbuches, § 18a des Kreditwesengesetzes).',
			content2: 'Der Kunde befreit Santander insoweit auch vom Bankgeheimnis.',
			content3:
				'Die SCHUFA verarbeitet die erhaltenen Daten und verwendet sie auch zum Zwecke der Profilbildung (Scoring), um ihren Vertragspartnern im Europäischen Wirtschaftsraum und in der Schweiz sowie ggf. weiteren Drittländern (sofern zu diesen ein Angemessenheitsbeschluss der Europäischen Kommission besteht) Informationen unter anderem zur Beurteilung der Kreditwürdigkeit von natürlichen Personen zu geben. Nähere Informationen zur Tätigkeit der SCHUFA können dem SCHUFA-Informationsblatt nach Art. 14 DSGVO entnommen oder online unter',
			content4: 'eingesehen werden.',
			linkText: 'www.schufa.de/datenschutz'
		}
	},
	serviceHotline: {
		content1:
			'Bei Fragen steht Ihnen unser Service Team 24 Stunden und 7 Tage die Woche unter der Rufnummer',
		content2: 'zur Verfügung.',
		content3: 'Bei Fragen steht unser Service Team Ihnen (Mo – Do 9:00 bis 18:00 Uhr, Fr 9:00 bis 17:00 Uhr) unter der Rufnummer'
	},
	loadingPage: {
		title: 'Wir prüfen Ihre Daten. Das kann einige Sekunden dauern.'
	},
	error: {
		404: '404 - Die Seite wurde nicht gefunden.',
		connection:
			'Verbindungsfehler, bitte versuchen Sie es später noch einmal.<br/>',
		mobileNotVerified:
			'Bitte klicken Sie auf den Registrierungslink in der E-Mail um fortzufahren.<br/>',
		missingData: 'Es fehlen Daten, um eine Verbindung herzustellen.<br/>'
	},
	generalRateReduction: {
		label: 'Betrag',
		error: 'Bitte geben Sie den Betrag der zahlbaren Ratean.'
	},
	tempRateReduction: {
		label: 'Betrag',
		error: 'Bitte geben Sie den Betrag der zahlbaren Ratean.',
		radio: {
			label: 'Wie lange möchten Sie die Raten reduzieren?',
			option1: {
				label: 'Bis zu 6 Monaten',
				value: 'Bis zu 6 Monaten'
			},
			option2: {
				label: 'Bis zu 12 Monaten',
				value: 'Bis zu 12 Monaten'
			},
			option3: {
				label: 'Mehr als 12 Monate',
				value: 'Mehr als 12 Monate'
			}
		}
	},
	noteFee: {
		label1: 'Details zu anfallenden Gebühren können Ihrem Darlehensvertrag und dem',
		label2: 'entnommen werden.'
	}
};
