/**
 * Birthday Page
 *
 * Created by Simon Orzel
 * Last modified by Moritz Kippenberg
 * Last modified date : 23-07-2019
 */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Page from 'mod/Page/Page';
import { Row, Col } from 'mod/FlexGrid/index';
import _ from 'lodash';
import C from '../../constants';
import moment from 'moment';

import * as AppActions from '../../store/App/actions';
import * as EntryActions from '../../store/Birthday/actions';
import * as utils from '../../utils/utils';

import DefaultInput from 'ui/inputs/DefaultInput/DefaultInput';
import * as googleEvents from '../../utils/googleEvents';
import * as regex from '../../utils/regex';

// import styles from './Birthday.scss';

const Birthday = ({ reducers, actions, history, location }) => {
	const [state, setState] = useState({
		path: C.ROUTES.BIRTHDAY,
		currentFlow: utils.getCurrentFlow(location),
		day: '',
		isDayErr: false,
		month: '',
		isMonthErr: false,
		year: '',
		isYearErr: false,
		errorMsg: '',
		isShowError: false,
		isDateErr: false
	});

	useEffect(() => {
		// Set the page Index for navigation
		actions.updatePage(utils.getPageIndex(state.path, state.currentFlow));

		// Set the default value on page load
		setState({
			...state,
			day: reducers.birthday.day,
			month: reducers.birthday.month,
			year: reducers.birthday.year
		});

		// Block access from user type in url directly
		utils.checkIsFromNav(
			history,
			reducers.app.isReload,
			state.currentFlow + C.ROUTES.NAME
		);

		// Set the first element on focus
		if (!reducers.app.appLoading) {
			dayRef.current.focus();
		}
	}, []);

	const onFormSubmit = () => {
		/*** Validation ***/
		let isValid = true;
		let cloneState = _.cloneDeep(state);

		cloneState.errorMsg = '';

		// Define max days of month
		let maxDays = 31;

		if (
			!utils.isEmpty(state.month) &&
			parseInt(state.month) > 0 &&
			parseInt(state.month) <= 12
		) {
			let monthNumber = parseInt(state.month);

			if (monthNumber === 2) {
				maxDays = 29;
			} else if (
				monthNumber === 4 ||
				monthNumber === 6 ||
				monthNumber === 9 ||
				monthNumber === 11
			) {
				maxDays = 30;
			} else {
				maxDays = 31;
			}
		}

		if (
			parseInt(state.day) < 1 ||
			state.day.length > 2 ||
			parseInt(state.day) > maxDays
		) {
			isValid = false;
			utils.showError(
				cloneState,
				'isDayErr',
				utils.m('day.validation.invalid', 'fields', { maxDays: maxDays })
			);
		} else {
			cloneState.isDayErr = false;
		}

		if (
			parseInt(state.month) < 1 ||
			state.month.length > 2 ||
			parseInt(state.month) > 12
		) {
			isValid = false;
			utils.showError(
				cloneState,
				'isMonthErr',
				utils.m('month.validation.invalid', 'fields')
			);
		} else {
			cloneState.isMonthErr = false;
		}

		let currentYear = moment().year();

		if (
			!utils.isNumber(state.year) ||
			state.year.length !== 4 ||
			parseInt(state.year) < currentYear - 110
		) {
			isValid = false;
			utils.showError(
				cloneState,
				'isYearErr',
				utils.m('year.validation.invalid2', 'fields')
			);
		} else {
			cloneState.isYearErr = false;
		}

		if (
			!utils.isEmpty(state.day) &&
			!utils.isEmpty(state.month) &&
			!utils.isEmpty(state.year)
		) {
			let currentDate = moment();
			let inputDate = moment(
				state.year + '-' + state.month + '-' + state.day,
				'YYYY-MM-DD'
			);

			if (inputDate.isAfter(currentDate)) {
				isValid = false;
				utils.showError(
					cloneState,
					'isDateErr',
					utils.m('date.validation.notInThePast', 'fields')
				);
				cloneState.isYearErr = true;
			} else {
				// cloneState.isYearErr = false;
			}
		}

		if (cloneState.isDayErr || cloneState.isMonthErr || cloneState.isYearErr) {
			cloneState.errorMsg =
				utils.m('date.validation.invalid', 'fields') + cloneState.errorMsg;
		}

		if (isValid) {
			if (!utils.checkAge18(state.year, state.month, state.day)) {
				// on age validation failure
				googleEvents.continueInvalid();

				cloneState.isDayErr = false;
				cloneState.isMonthErr = false;
				cloneState.isYearErr = true;
				cloneState.isShowError = true;
				cloneState.errorMsg +=
					utils.m('date.validation.invalid', 'fields') +
					utils.m('date.validation.notOver18', 'fields');

				setState(cloneState);
			} else {
				// on validation success
				utils.hideError(state, setState);

				googleEvents.continueValid();

				actions.onUpdateBirthday(state);
				actions.updateReload(false);

				utils.goToPage(
					history,
					state.currentFlow +
						(reducers.creditCardNumber.creditCardNumber ||
						reducers.product.product === 'creditcard' ||
						reducers.product.product === 'limit' ||
						reducers.product.type
							? C.ROUTES.CREDIT_CARD_NUMBER
							: C.ROUTES.FINANCING_NUMBER)
				);
			}
		} else {
			// on validation failure
			googleEvents.continueInvalid();
			setState(cloneState);
		}
	};


	const hasEmptyFields = () => {
		return (
			utils.isEmpty(state.day) ||
			utils.isEmpty(state.month) ||
			utils.isEmpty(state.year)
		);
	};

	const dayRef = useRef(null);
	const monthRef = useRef(null);
	const yearRef = useRef(null);
	const acceptPrivacyPoliciesRef = useRef(null);
	const submitRef = useRef(null);

	return (
		<Page
			title={ utils.m('birthday.title', state.currentFlow) }
			subtitle={ utils.m('birthday.subtitle', state.currentFlow) }
			isShowError={ state.isShowError }
			errorMsg={ state.errorMsg }
			hasNextButton={ true }
			onBackButtonClick = { history.goBack }
			onFormSubmit={ onFormSubmit }
			nextButtonDisabled={ hasEmptyFields() }
			location={ location }
			currentFlow={ state.currentFlow }
			currentStep={ C.PROGRESS_BAR.personalData }
			screen='A2'
			pageName='Birthday'
			showButtonDivider={ false }
			submitRef={ submitRef }
			prevRef={ acceptPrivacyPoliciesRef }
			nextRef={ dayRef }
			gtmStep='userdata_geburtsdatum'
			gtmStatus='registration_pending'
			gtmSubStatus={ null }
		>
			<Row justifyContent='center'>
				<Col xs={ 12 } sm={ 4 }>
					<DefaultInput
						inputOnChange={ val => {
							utils.onInputChange(setState, state, 'day', val);
						} }
						label={ utils.m('day.label', 'fields') }
						hasError={ state.isDayErr }
						init={ reducers.birthday.day }
						maxlength={ 2 }
						placeholder='TT'
						type='number'
						ref={ dayRef }
						prevRef={ hasEmptyFields() ? acceptPrivacyPoliciesRef : submitRef }
						nextRef={ monthRef }
						regex={ regex.digit() }
						jumpToNextOnMaxLength={ true }
						testId='day'
					/>
				</Col>

				<Col xs={ 12 } sm={ 4 }>
					<DefaultInput
						inputOnChange={ val => {
							utils.onInputChange(setState, state, 'month', val);
						} }
						label={ utils.m('month.label', 'fields') }
						hasError={ state.isMonthErr }
						init={ reducers.birthday.month }
						maxlength={ 2 }
						placeholder='MM'
						type='number'
						ref={ monthRef }
						prevRef={ dayRef }
						nextRef={ yearRef }
						regex={ regex.digit() }
						jumpToNextOnMaxLength={ true }
						testId='month'
					/>
				</Col>

				<Col xs={ 12 } sm={ 4 }>
					<DefaultInput
						inputOnChange={ val => {
							utils.onInputChange(setState, state, 'year', val);
						} }
						label={ utils.m('year.label', 'fields') }
						hasError={ state.isYearErr }
						init={ reducers.birthday.year }
						maxlength={ 4 }
						placeholder='JJJJ'
						type='number'
						ref={ yearRef }
						prevRef={ monthRef }
						nextRef={ acceptPrivacyPoliciesRef }
						regex={ regex.digit() }
						testId='year'
					/>
				</Col>
			</Row>
		</Page>
	);
};

Birthday.propTypes = {
	reducers: PropTypes.object,
	actions: PropTypes.object,
	history: PropTypes.object
};

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(_.merge({}, AppActions, EntryActions), dispatch)
});

const mapStateToProps = state => {
	return {
		reducers: state
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(Birthday);
