import _ from 'lodash';

const defaultState = {
	day: '',
	month: '',
	year: '',
	amountTransfered: '',
	reasonForLateTransfer: ''
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case 'UPDATE_PAYMENT_STATUS_PAID':
			return _.merge({}, state, {
				day: action.payload.day,
				month: action.payload.month,
				year: action.payload.year,
				amountTransfered: action.payload.amountTransfered,
				reasonForLateTransfer: action.payload.reasonForLateTransfer
			});
		default:
			return state;
	}
};

export default reducers;
