/**
 * paymentStatusWillPay Page
 *
 * Created by Nowshad
 * Last modified by Nowshad
 * Last modified date : 14-11-2019
 */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Page from 'mod/Page/Page';
import { Row, Col } from 'mod/FlexGrid';
import _ from 'lodash';
import C from '../../constants';

import * as AppActions from '../../store/App/actions';
import { onUpdateGeneralRateReductionAmount } from '../../store/GeneralRateReduction/actions';
import * as utils from '../../utils/utils';
import { sendDataToServer } from '../../utils/api';

import DefaultInput from 'ui/inputs/DefaultInput/DefaultInput';
import * as googleEvents from '../../utils/googleEvents';
import * as regex from '../../utils/regex';
import IconExternalLink from 'ui/svg/IconExternalLink';
import styles from './GeneralRateReduction.scss';

const GeneralRateReduction = ({ reducers, actions, history, location }) => {
	const [path] = useState(C.ROUTES.GENERAL_RATE_REDUCTION);
	const [currentFlow] = useState(utils.getCurrentFlow(location));
	const [amount, setAmount] = useState(reducers.generalRateReduction.amount);
	const [amountErr] = useState(false);
	const [isDataUpdated, setIsDataUpdated] = useState(false);

	useEffect(() => {
		// Set the page Index for navigation
		actions.updatePage(utils.getPageIndex(path, currentFlow));

		// Block access from user type in url directly
		utils.checkIsFromNav(
			history,
			reducers.app.isReload,
			currentFlow + C.ROUTES.NAME
		);

		// Set the first element on focus
		if (!reducers.app.appLoading) {
			amountRef.current.focus();
		}
	}, []);

	useEffect(()=> {
		if(isDataUpdated) {
			const request = async() => {
				const data = utils.getDataToSendToAPI({ reducers });
				const shouldGoToNext = await sendDataToServer(data, actions.updateButtonLoading, alert);

				if (shouldGoToNext) {
					utils.goToPage(history, currentFlow + C.ROUTES.THANKYOU_END);
				}
			};
			request();
		}
	}, [isDataUpdated]);

	const onFormSubmit = async () => {
		googleEvents.continueValid();

		await actions.onUpdateGeneralRateReductionAmount({ amount });
		actions.updateReload(false);

		setIsDataUpdated(true);
	};

	const hasEmptyFields = utils.isEmpty(amount) || parseFloat(amount) <= 0 ;

	const amountRef = useRef(null);
	const submitRef = useRef(null);

	return (
		<Page
			title={ utils.m('generalRateReduction.title', currentFlow) }
			subtitle={ utils.m('generalRateReduction.subtitle', currentFlow) }
			isShowError={ false }
			errorMsg={ utils.m('generalRateReduction.error', 'fields') }
			hasNextButton={ true }
			onBackButtonClick={ history.goBack }
			onFormSubmit={ onFormSubmit }
			nextButtonDisabled={ hasEmptyFields }
			location={ location }
			currentFlow={ currentFlow }
			pageName='GeneralRateReduction'
			currentStep={ C.PROGRESS_BAR.solution }
			screen='F4'
			showButtonDivider={ true }
			submitRef={ submitRef }
			prevRef={ submitRef }
			gtmStep='userdata_geburtsdatum'
			gtmStatus='registration_pending'
			gtmSubStatus={ null }
			nextButtonText = { utils.m('generalRateReduction.nextButtonText', currentFlow) }
		>
			<Row justifyContent='center'>
				<Col xs={ 12 }>
					<DefaultInput
						inputOnChange={ val => {
							setAmount(val);
						} }
						label={ utils.m('generalRateReduction.label', 'fields') }
						hasError={ amountErr }
						init={ amount }
						maxlength={ 4 }
						placeholder=''
						type='number'
						ref={ amountRef }
						testId='general-rate-reduction'
						regex={ regex.numberWithDotAndComma() }
						isCurrency
						originalValue={ amount }
					/>
				</Col>
				<Col xs = { 12 }>
					<span className = { styles.noteFee }>
						{utils.m('noteFee.label1', 'fields')}
						<a 
							rel='noopener noreferrer' 
							href = "http://santander.de/preise" 
							className = "link" 
							target="_blank"> Preis- und Leistungsverzeichnis <IconExternalLink /> 
						</a>  {utils.m('noteFee.label2', 'fields')}
					</span>
				</Col>
			</Row>
		</Page>
	);
};

GeneralRateReduction.propTypes = {
	reducers: PropTypes.object,
	actions: PropTypes.object,
	history: PropTypes.object
};

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators(
		_.merge({}, AppActions, { onUpdateGeneralRateReductionAmount }),
		dispatch
	)
});

const mapStateToProps = state => ({
	reducers: state
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(GeneralRateReduction);
