import * as React from 'react';

const DropdownArrow = props => (
	<svg width={ 10 } height={ 6 } viewBox="0 0 10 6" { ...props }>
		<defs>
			<path
				d="M5.003 5.598a.6.6 0 0 1-.424-.176l-4-4a.6.6 0 0 1 .849-.847l3.575 3.574L8.576.577a.6.6 0 0 1 .848.848L5.427 5.422a.6.6 0 0 1-.424.176"
				id="dropdown_arrow_svg__a"
			/>
		</defs>
		<use fill="#000" xlinkHref="#dropdown_arrow_svg__a" fillRule="evenodd" />
	</svg>
);

export default DropdownArrow;
