import * as React from 'react';

const Talk = props => (
	<svg width={ 30 } height={ 27 } viewBox="0 0 30 27" { ...props }>
		<g stroke="#B0BDC0" fill="none" fillRule="evenodd">
			<path
				d="M26.875 4.033A10.367 10.367 0 0 0 19.492.925h-9.167A9.942 9.942 0 0 0 3.1 3.958a9.725 9.725 0 0 0-2.767 7.067 9.558 9.558 0 0 0 1.184 4.317c.26-.228.54-.434.833-.617A8.583 8.583 0 0 1 1.333 11a8.708 8.708 0 0 1 2.484-6.333A8.983 8.983 0 0 1 10.3 1.942h9.167a9.408 9.408 0 0 1 6.666 2.8 8.6 8.6 0 0 1 2.5 6.216 8.8 8.8 0 0 1-7.05 8.442c-.783.167-.783.833-.775 1.383v4.042c.01.425-.272.8-.683.908a.9.9 0 0 1-.975-.416l-2.95-5a1.425 1.425 0 0 0-1.2-.742h-3.758v.092a6.567 6.567 0 0 1-.075.908H15c.15.001.289.08.367.208l2.95 5c.348.587.984.943 1.666.934.143 0 .286-.017.425-.05a1.925 1.925 0 0 0 1.467-1.884v-4.166-.325a9.808 9.808 0 0 0 7.792-9.325 9.525 9.525 0 0 0-2.792-6.934z"
				strokeWidth={ 0.5 }
				fill="#B0BDC0"
				fillRule="nonzero"
			/>
			<path
				d="M5.417 15.5a4.167 4.167 0 1 0 0 8.333 4.167 4.167 0 0 0 0-8.333z"
				strokeWidth={ 1.5 }
			/>
		</g>
	</svg>
);

export default Talk;
