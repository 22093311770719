/**
 * Solutions Page
 *
 * Created by Cristian Restituyo
 * Last modified by Cristian Restituyo
 * Last modified date : 23-07-2019
 */

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Page from 'mod/Page/Page';
import { Row, Col } from 'mod/FlexGrid/index';
import _ from 'lodash';
import C from '../../constants';

import RadioGroupMulti from 'ui/inputs/RadioGroupMulti/RadioGroupMulti';

import * as AppActions from '../../store/App/actions';
import * as EntryActions from '../../store/Solutions/actions';
import * as utils from '../../utils/utils';
import * as googleEvents from '../../utils/googleEvents';
import * as regex from '../../utils/regex';

const Solutions = ({ reducers, actions, history, location }) => {

	const [state, setState] = useState({
		path: C.ROUTES.SOLUTIONS,
		currentFlow: utils.getCurrentFlow(location),
		radioState: -1,
		isRadioErr: false
	});
	const [records, setRecords] = useState('');
	const [nextRoute, setNextRoute] = useState('');
	const [selectedOption, setSelectedOption] = useState('');


	const [availableOptions, setAvailableOptions] = useState([]);

	useEffect(() => {
		// Set the page Index for navigation
		actions.updatePage(utils.getPageIndex(state.path, state.currentFlow));


		// Set the default value on page load
		setState({
			...state,
			radioState: reducers.solutions.radioState
		});

		// Block access from user type in url directly
		utils.checkIsFromNav(history, reducers.app.isReload, state.currentFlow + C.ROUTES.NAME);

		let availableOptions = _.clone(reducers.solutions.availableOptions);
		if (reducers.paymentProblems.paymentProblem === 'ENERGY_COSTS') {
			availableOptions = _.reject(availableOptions, (item) => {
				return item.value === 'option3';
			});
		}
		setAvailableOptions(availableOptions);

		const newSelectedOptions = reducers.solutions.availableOptions.length === 1 ?
			reducers.solutions.availableOptions[0].value : reducers.solutions.selectedOption;

		setSelectedOption(newSelectedOptions);
		setRecords(reducers.solutions.records);
	}, []);

	useEffect(() => {
		switch(selectedOption) {
			case 'option1':
				// CHANGE ROUTE TO F2
				setNextRoute(C.SCREENS.F2);
				break;
			case 'option2':
				// CHANGE ROUTE TO F3
				setNextRoute(C.SCREENS.F3);
				break;
			case 'option3':
				// CHANGE ROUTE TO F4
				setNextRoute(C.SCREENS.F4);
				break;
			default:
				break;
		}
	}, [selectedOption]);




	const onFormSubmit = () => {


		googleEvents.continueValid();

		actions.onUpdateSolutions({
			...state,
			selectedOption: utils.isEmpty(selectedOption) ? availableOptions[0].value : selectedOption,
			records,
			availableOptions
		});
		actions.updateReload(false);

		utils.goToPage(history, state.currentFlow + nextRoute);


	};

	const hasEmptyFields = () => {
		return state.radioState === -1;
	};


	const onRadioChange = (val) => {

		const cloneState = { ...state };

		cloneState.radioState = 0;

		availableOptions.forEach((option, idx) => {
			if(option.value === val) {
				cloneState.radioState = idx;
				setSelectedOption(option.value);
			}
		});

		setState(cloneState);
	};

	const getHeaderInfo = () => {
		const title = availableOptions.length > 1 ? utils.m('solutions.title2', state.currentFlow) : utils.m('solutions.title1', state.currentFlow);
		const subtitle = availableOptions.length > 1 ? utils.m('solutions.subtitle', state.currentFlow) : '';
		return {
			title,
			subtitle
		};
	};

	const submitRef = useRef(null);
	const radioRef = useRef(null);

	return (
		<Page
			title={ getHeaderInfo().title }
			subtitle={ getHeaderInfo().subtitle }
			isShowError={ state.isShowError }
			errorMsg={ state.errorMsg }
			hasNextButton={ true }
			onBackButtonClick = { history.goBack }
			onFormSubmit={ onFormSubmit }
			nextButtonDisabled={ hasEmptyFields() }
			location={ location }
			currentFlow={ state.currentFlow }
			pageName="Solutions"
			currentStep={ C.PROGRESS_BAR.solution }
			screen='F1'
			showButtonDivider={ false }
			submitRef={ submitRef }
			prevRef={ radioRef }
			nextRef={ submitRef }
			gtmStep="userdata_paymentstatus"
			gtmStatus="registration_pending"
			gtmSubStatus={ null }
		>

			<Row justifyContent="center">
				<Col>
					<RadioGroupMulti
						radioOnChange={ (val) => { onRadioChange(val); } }
						radioState={ state.radioState }
						hasError={ state.isRadioErr }
						init={ '' }
						maxlength={ 150 }
						ref={ radioRef }
						prevRef={ hasEmptyFields() ? radioRef : submitRef }
						nextRef={ submitRef }
						regex={ regex.wordWithSpaceAndDashAndCommaAndDot() }
						testId="solutions"
						options = { availableOptions }
					/>
				</Col>
			</Row>

		</Page>
	);
};

Solutions.propTypes = {
	reducers: PropTypes.object,
	actions: PropTypes.object,
	history: PropTypes.object
};

const mapDispatchToProps = (dispatch) => ({
	actions: bindActionCreators( _.merge({}, AppActions, EntryActions), dispatch)
});

const mapStateToProps = (state) => {
	return{
		reducers: state
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(Solutions);
