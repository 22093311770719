

const defaultState = {
	borrowers:[],
	extraFields: [],
	currentProvidedBorrowerOption: -2
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case 'UPDATE_MONTHLY_INCOME':
			return {
				...state,
				borrowers: action.payload.borrowers,
				extraFields: action.payload.extraFields,
				currentProvidedBorrowerOption: action.payload.currentProvidedBorrowerOption
			};
		default:
			return state;
	}
};

export default reducers;
