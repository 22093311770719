/**
 * Action Type
 */

const UPDATE_OTHER_REASON = 'UPDATE_OTHER_REASON';

/*
 * Action Creators
 */

export const onUpdateOtherReason = payload => ({
	type: UPDATE_OTHER_REASON,
	payload
});
