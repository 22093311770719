// D3
import React, { useState, useEffect } from 'react';

import Page from 'mod/Page/Page';
import { Row, Col } from 'mod/FlexGrid';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import C from '../../constants';
import * as utils from '../../utils/utils';

import * as AppActions from '../../store/App/actions';
import { onUpdateChildren } from '../../store/Children/actions';

import TwoStepsInput from 'ui/inputs/TwoStepsInput/TwoStepsInput';

const NumberOfChildren = ({ actions, reducers, history, location }) => {
	const [path] = useState(C.ROUTES.NUMBER_OF_CHILDREN);
	const [currentFlow] = useState(utils.getCurrentFlow(location));
	const [numberChildren, setNumberChildren] = useState(1);
	const [numberChildrenWithBenefit, setNumberChildrenWithBenefit] = useState(0);

	useEffect(() => {
		actions.updatePage(utils.getPageIndex(path, currentFlow));

		// Block access from user type in url directly
		utils.checkIsFromNav(history, reducers.app.isReload,currentFlow + C.ROUTES.NAME);

		// Set the default value on page load
		setNumberChildren(reducers.children.numberChildren);
		setNumberChildrenWithBenefit(reducers.children.numberChildrenWithBenefit);
	}, []);

	const onFormSubmit = () => {
		actions.onUpdateChildren({
			numberChildren,
			numberChildrenWithBenefit
		});
		utils.goToPage(history, currentFlow + C.ROUTES.PROVIDE_SECOND_BORROWER);
	};

	const hasFieldsEmpty = () => {
		return numberChildren <= numberChildrenWithBenefit;
	};

	return (
		<Page
			title={ utils.m('numberOfChildren.title', currentFlow) }
			isShowError={ false }
			errorMsg={ '' }
			hasNextButton={ true }
			onBackButtonClick={ history.goBack }
			onFormSubmit={ onFormSubmit }
			nextButtonDisabled={ hasFieldsEmpty() }
			location={ location }
			currentFlow={ currentFlow }
			pageName='NumberOfChildren'
			currentStep={ C.PROGRESS_BAR.budgetAccounting }
			screen='D3'
			showButtonDivider={ false }
			gtmStep='userdata_geburtsdatum'
			gtmStatus='registration_pending'
			gtmSubStatus={ null }
		>
			<Row justifyContent='center'>
				<Col xs={ 12 }>
					<TwoStepsInput
						firstStepLabel={ utils.m('numberOfChildren.label', 'fields') }
						secondStepLabel={ utils.m(
							'numberOfChildrenWithBenefit.label',
							'fields'
						) }
						maxNo={ 10 }
						initFirstVal={ numberChildren }
						initSecondVal={ numberChildrenWithBenefit }
						hasError={ false }
						onFormSubmit={ onFormSubmit }
						inputOnChangeFirst={ val => {
							setNumberChildren(val);
						} }
						inputOnChangeSecond={ val => {
							setNumberChildrenWithBenefit(val);
						} }
						testId='number-of-children'
						initialValRange = { 1 }
					/>
				</Col>
			</Row>
		</Page>
	);
};

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({ ...AppActions, onUpdateChildren }, dispatch)
});

const mapStateToProps = state => ({
	reducers: state
});

export default connect(
	mapStateToProps,
	mapDispatchToProps
)(NumberOfChildren);
