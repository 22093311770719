import _ from 'lodash';

const defaultState = {
	countryCodeLandine: '49',
	landline: '',
	countryCodeMobile: '49',
	mobilePhone: '',
	email: '',
	acceptPrivacyPolicies: false
};

const reducers = (state = defaultState, action) => {
	switch (action.type) {
		case 'UPDATE_CONTACT_INFO':
			return _.merge({}, state, {
				countryCodeLandine: action.payload.countryCodeLandline,
				landline: action.payload.landline,
				countryCodeMobile: action.payload.countryCodeMobile,
				mobilePhone: action.payload.mobilePhone,
				email: action.payload.email,
				acceptPrivacyPolicies: action.payload.acceptPrivacyPolicies
			});
		default:
			return state;
	}
};

export default reducers;
