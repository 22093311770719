/**
 * Component for the error message
 * 
 * Created by Perry Tang
 * Last modified by Moritz Kippenberg
 * Last modified date : 15-07-2019
 */

import React from 'react';
import PropTypes from 'prop-types';

import ErrorIcon from 'ui/svg/ErrorIcon';

import errorMsgStyles from 'mod/ErrorMsg/ErrorMsg.scss';

const ErrorMsg = (props) => {
	return(
		<div
			data-testid="error-message"
			className={ errorMsgStyles.errorContainer }
		>
			<span dangerouslySetInnerHTML={ {__html: props.msg} } />
			<ErrorIcon />
		</div>
	);
};

// Check the props type
ErrorMsg.propTypes = {
	msg: PropTypes.string
};

// Specifies the default values for props:
ErrorMsg.defaultProps = {
	msg: ''
};

export default ErrorMsg;
