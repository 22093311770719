/** Actions Type */
const UPDATE_NAME = 'UPDATE_NAME';
const UPDATE_NAME_FROM_API = 'UPDATE_NAME_FROM_API';

/** Actions Creators */
export const onUpdateName = (payload) => ({
	type: UPDATE_NAME,
	payload
});

export const updateNameFromAPI = (payload) => ({
	type: UPDATE_NAME_FROM_API,
	payload
});